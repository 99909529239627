import { apiGateway } from "@root/lib/http";
import { API } from "../constants";
import { FetchStore } from "@root/data/lib";
import { SerializedAllAccountsAggregatedBilling, DeserializedBillingResource } from "@root/data/management/models/billing-resources";
import { mergePlanTiers } from "@root/data/management/utils/billing-plan-merger";

export class AllAccountsBillingStore extends FetchStore<DeserializedBillingResource[], SerializedAllAccountsAggregatedBilling> {
  protected deserialize(data: SerializedAllAccountsAggregatedBilling): DeserializedBillingResource[] {
    return data.aggregatedBillings.map((billing) => {
      return {
        resourceId: billing.accountType?.toLowerCase() === "user" ? `user-${billing.id}` : `org-${billing.id}`,
        azureSubscriptionId: billing.azureSubscriptionId,
        azureSubscriptionState: billing.azureSubscriptionState,
        isAccountDeleted: billing.isAccountDeleted,
        usage: billing.usage,
        id: billing.id,
        billingPlans: billing.billingPlans,
        plan: mergePlanTiers(billing),
        accountType: billing.accountType,
      };
    });
  }

  protected fetchData() {
    return apiGateway.get(API.ALL_ACCOUNTS_AGGREGATED_BILLING, {});
  }
}

export const allAccountsBillingStore = new AllAccountsBillingStore();
