/**
 * api-gateway
 * Microsoft Visual Studio App Center API
 *
 * OpenAPI spec version: v0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { apiGateway } from "@root/lib/http";
import { ApiClientOptions } from "../api-client-options";

interface ApiMethodResult<T> {
  statusCode: number;
  body: T;
}

function getApiMethod<T>(httpMethod, path, payload, options: ApiClientOptions = {}): Promise<ApiMethodResult<T>> {
  if (options.timeout !== undefined) {
    console.warn("API Client timeout option is ignored.");
  }
  return apiGateway.call<T>(httpMethod, path, payload).then((body) => {
    return { statusCode: 200, body };
  });
}

// @ts-ignore
function stringifyParams(params: { [key: string]: any }): { [key: string]: string | string[] } {
  return Object.assign(
    {},
    ...Object.keys(params)
      .filter((key) => params[key])
      .map((key) => ({ [key]: params[key].toString() }))
  );
}

function validateSwaggerResult(model, result, operation, shouldIterate = true) {
  if (process.env.NODE_ENV !== "production") {
    const swagger = require("./swagger.json");
    const Validator = require("swagger-model-validator");
    // @ts-ignore
    const validator = new Validator(swagger);
    if (model) {
      if (model.startsWith("Api")) {
        model = model.substr(3);
      }
      const validation = [];
      if (shouldIterate && Array.isArray(result)) {
        result.forEach((r) => {
          const validationResult = swagger.validateModel(model, r, true, true);
          if (!validationResult.valid) {
            // @ts-ignore
            validation.push(validationResult.GetFormattedErrors());
          }
        });
      } else {
        const validationResult = swagger.validateModel(model, result, true, true);
        if (!validationResult.valid) {
          // @ts-ignore
          validation.push(validationResult.GetFormattedErrors());
        }
      }
      if (validation.length > 0) {
        console.warn("Swagger validation for model " + model + " failed for API call '" + operation + "'", result, validation);
      }
    }
  }
}

export interface ApiApiTokensCreateRequest {
  /**
   * The description of the token
   */
  description?: string;
  /**
   * The scope for this token.
   */
  scope?: ApiApiTokensCreateRequest.ApiScopeEnum;
}

// @ts-ignore
function deserializeApiApiTokensCreateRequest(input: any): ApiApiTokensCreateRequest {
  return {
    description: input["description"],
    scope: input["scope"],
  };
}

// @ts-ignore
function serializeApiApiTokensCreateRequest(input: ApiApiTokensCreateRequest): any {
  return {
    description: input["description"],
    scope: input["scope"],
  };
}

export namespace ApiApiTokensCreateRequest {
  export type ApiScopeEnum = "all" | "viewer";
}

export interface ApiApiTokensCreateResponse {
  /**
   * The unique id (UUID) of the api token
   */
  id: string;
  /**
   * The api token generated will not be accessible again
   */
  apiToken: string;
  /**
   * The description of the token
   */
  description?: string;
  /**
   * The scope for this token.
   */
  scope?: ApiApiTokensCreateResponse.ApiScopeEnum;
  /**
   * The creation time
   */
  createdAt: string;
}

// @ts-ignore
function deserializeApiApiTokensCreateResponse(input: any): ApiApiTokensCreateResponse {
  return {
    id: input["id"],
    apiToken: input["api_token"],
    description: input["description"],
    scope: input["scope"],
    createdAt: input["created_at"],
  };
}

// @ts-ignore
function serializeApiApiTokensCreateResponse(input: ApiApiTokensCreateResponse): any {
  return {
    id: input["id"],
    api_token: input["apiToken"],
    description: input["description"],
    scope: input["scope"],
    created_at: input["createdAt"],
  };
}

export namespace ApiApiTokensCreateResponse {
  export type ApiScopeEnum = "all" | "viewer";
}

export interface ApiApiTokensGetResponse {
  /**
   * The unique id (UUID) of the api token
   */
  id: string;
  /**
   * The description of the token
   */
  description?: string;
  /**
   * The scope for this token.
   */
  scope?: ApiApiTokensGetResponse.ApiScopeEnum;
  /**
   * The creation time
   */
  createdAt: string;
}

// @ts-ignore
function deserializeApiApiTokensGetResponse(input: any): ApiApiTokensGetResponse {
  return {
    id: input["id"],
    description: input["description"],
    scope: input["scope"],
    createdAt: input["created_at"],
  };
}

// @ts-ignore
function serializeApiApiTokensGetResponse(input: ApiApiTokensGetResponse): any {
  return {
    id: input["id"],
    description: input["description"],
    scope: input["scope"],
    created_at: input["createdAt"],
  };
}

export namespace ApiApiTokensGetResponse {
  export type ApiScopeEnum = "all" | "viewer";
}

export interface ApiErrorDetails {
  code: ApiErrorDetails.ApiCodeEnum;
  message: string;
}

// @ts-ignore
function deserializeApiErrorDetails(input: any): ApiErrorDetails {
  return {
    code: input["code"],
    message: input["message"],
  };
}

// @ts-ignore
function serializeApiErrorDetails(input: ApiErrorDetails): any {
  return {
    code: input["code"],
    message: input["message"],
  };
}

export namespace ApiErrorDetails {
  export type ApiCodeEnum =
    | "BadRequest"
    | "Conflict"
    | "NotAcceptable"
    | "NotFound"
    | "InternalServerError"
    | "Unauthorized"
    | "TooManyRequests";
}

export interface ApiErrorResponse {
  error: ApiErrorDetails;
}

// @ts-ignore
function deserializeApiErrorResponse(input: any): ApiErrorResponse {
  return {
    error: input["error"],
  };
}

// @ts-ignore
function serializeApiErrorResponse(input: ApiErrorResponse): any {
  return {
    error: input["error"],
  };
}

export interface ApiFailure {
  message: string;
}

// @ts-ignore
function deserializeApiFailure(input: any): ApiFailure {
  return {
    message: input["message"],
  };
}

// @ts-ignore
function serializeApiFailure(input: ApiFailure): any {
  return {
    message: input["message"],
  };
}

export interface ApiGetInAppUpdateTokenResponse {
  /**
   * The api token generated will not be accessible again
   */
  token: string;
}

// @ts-ignore
function deserializeApiGetInAppUpdateTokenResponse(input: any): ApiGetInAppUpdateTokenResponse {
  return {
    token: input["token"],
  };
}

// @ts-ignore
function serializeApiGetInAppUpdateTokenResponse(input: ApiGetInAppUpdateTokenResponse): any {
  return {
    token: input["token"],
  };
}

export interface ApiStatus {
  status: string;
}

// @ts-ignore
function deserializeApiStatus(input: any): ApiStatus {
  return {
    status: input["status"],
  };
}

// @ts-ignore
function serializeApiStatus(input: ApiStatus): any {
  return {
    status: input["status"],
  };
}

export interface ApiSuccessResponse {
  message: string;
}

// @ts-ignore
function deserializeApiSuccessResponse(input: any): ApiSuccessResponse {
  return {
    message: input["message"],
  };
}

// @ts-ignore
function serializeApiSuccessResponse(input: ApiSuccessResponse): any {
  return {
    message: input["message"],
  };
}

export namespace ApiGatewayApi {
  export interface DeleteAppApiTokensParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // The unique ID (UUID) of the api token
    apiTokenId: string;
  }

  // @ts-ignore
  function serializeDeleteAppApiTokensParams(input: DeleteAppApiTokensParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
      api_token_id: input["apiTokenId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/api_tokens/{api_token_id}
   *
   * Delete the App Api Token object with the specific ID
   **/
  export function deleteAppApiTokens(params: DeleteAppApiTokensParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/api_tokens/{api_token_id}";
    const payload = {
      params: stringifyParams(serializeDeleteAppApiTokensParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "DELETE /v0.1/apps/{owner_name}/{app_name}/api_tokens/{api_token_id}", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DeleteUserApiTokensParams {
    [key: string]: any;
    // The unique ID (UUID) of the api token
    apiTokenId: string;
  }

  // @ts-ignore
  function serializeDeleteUserApiTokensParams(input: DeleteUserApiTokensParams): any {
    const pathParams = {
      api_token_id: input["apiTokenId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/api_tokens/{api_token_id}
   *
   * Delete the user api_token object with the specific id
   **/
  export function deleteUserApiTokens(params: DeleteUserApiTokensParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/api_tokens/{api_token_id}";
    const payload = {
      params: stringifyParams(serializeDeleteUserApiTokensParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "DELETE /v0.1/api_tokens/{api_token_id}", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface ListAppApiTokensParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeListAppApiTokensParams(input: ListAppApiTokensParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/api_tokens
   *
   * Returns App API tokens for the app
   **/
  export function listAppApiTokens(params: ListAppApiTokensParams, options?: ApiClientOptions): Promise<ApiApiTokensGetResponse[]> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/api_tokens";
    const payload = {
      params: stringifyParams(serializeListAppApiTokensParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiApiTokensGetResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiApiTokensGetResponse", result?.body, "GET /v0.1/apps/{owner_name}/{app_name}/api_tokens");
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiApiTokensGetResponse(r));
        return resultOrResults as ApiApiTokensGetResponse[];
      });
  }
  export interface ListUserApiTokensParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeListUserApiTokensParams(input: ListUserApiTokensParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/api_tokens
   *
   * Returns api tokens for the authenticated user
   **/
  export function listUserApiTokens(params: ListUserApiTokensParams, options?: ApiClientOptions): Promise<ApiApiTokensGetResponse[]> {
    const path = "/v0.1/api_tokens";
    const payload = {
      params: stringifyParams(serializeListUserApiTokensParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiApiTokensGetResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiApiTokensGetResponse", result?.body, "GET /v0.1/api_tokens");
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiApiTokensGetResponse(r));
        return resultOrResults as ApiApiTokensGetResponse[];
      });
  }
  export interface NewAppApiTokensParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeNewAppApiTokensParams(input: NewAppApiTokensParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/api_tokens
   *
   * Creates a new App API token
   **/
  export function newAppApiTokens(
    params: NewAppApiTokensParams,
    body?: ApiApiTokensCreateRequest,
    options?: ApiClientOptions
  ): Promise<ApiApiTokensCreateResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/api_tokens";
    const payload = {
      params: stringifyParams(serializeNewAppApiTokensParams(params)),
      body: body && serializeApiApiTokensCreateRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiApiTokensCreateResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiApiTokensCreateResponse",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/api_tokens",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiApiTokensCreateResponse(result);
        return resultOrResults as ApiApiTokensCreateResponse;
      });
  }
  export interface NewUserApiTokensParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeNewUserApiTokensParams(input: NewUserApiTokensParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/api_tokens
   *
   * Creates a new User API token
   **/
  export function newUserApiTokens(
    params: NewUserApiTokensParams,
    body?: ApiApiTokensCreateRequest,
    options?: ApiClientOptions
  ): Promise<ApiApiTokensCreateResponse> {
    const path = "/v0.1/api_tokens";
    const payload = {
      params: stringifyParams(serializeNewUserApiTokensParams(params)),
      body: body && serializeApiApiTokensCreateRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiApiTokensCreateResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiApiTokensCreateResponse", result?.body, "POST /v0.1/api_tokens", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiApiTokensCreateResponse(result);
        return resultOrResults as ApiApiTokensCreateResponse;
      });
  }
  export interface PreviewExternalDependencyGetParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializePreviewExternalDependencyGetParams(input: PreviewExternalDependencyGetParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /preview/external_dependency
   *
   * Returns the service dependency status to the caller
   **/
  export function previewExternalDependencyGet(
    params: PreviewExternalDependencyGetParams,
    options?: ApiClientOptions
  ): Promise<ApiStatus> {
    const path = "/preview/external_dependency";
    const payload = {
      params: stringifyParams(serializePreviewExternalDependencyGetParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiStatus>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiStatus", result?.body, "GET /preview/external_dependency", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiStatus(result);
        return resultOrResults as ApiStatus;
      });
  }
  export interface V01InAppUpdateTokenDeleteParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeV01InAppUpdateTokenDeleteParams(input: V01InAppUpdateTokenDeleteParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/in_app_update_token
   *
   * Delete the in-app update token. Warning - deleting this token will break in-app updates for apps that were installed from App Center by the current user.
   **/
  export function v01InAppUpdateTokenDelete(params: V01InAppUpdateTokenDeleteParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/in_app_update_token";
    const payload = {
      params: stringifyParams(serializeV01InAppUpdateTokenDeleteParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "DELETE /v0.1/in_app_update_token", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface V01InAppUpdateTokenGetParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeV01InAppUpdateTokenGetParams(input: V01InAppUpdateTokenGetParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/in_app_update_token
   *
   * Gets the in-app update token. A token that only has access to retrieve the latest release assigned for a user in an app.
   **/
  export function v01InAppUpdateTokenGet(
    params: V01InAppUpdateTokenGetParams,
    options?: ApiClientOptions
  ): Promise<ApiGetInAppUpdateTokenResponse> {
    const path = "/v0.1/in_app_update_token";
    const payload = {
      params: stringifyParams(serializeV01InAppUpdateTokenGetParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiGetInAppUpdateTokenResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiGetInAppUpdateTokenResponse", result?.body, "GET /v0.1/in_app_update_token", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiGetInAppUpdateTokenResponse(result);
        return resultOrResults as ApiGetInAppUpdateTokenResponse;
      });
  }
  export interface V01ManagementSwaggerRefreshPostParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeV01ManagementSwaggerRefreshPostParams(input: V01ManagementSwaggerRefreshPostParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/management/swagger_refresh
   *
   * Trigger ondemand swagger merge from all beacon service
   **/
  export function v01ManagementSwaggerRefreshPost(
    params: V01ManagementSwaggerRefreshPostParams,
    options?: ApiClientOptions
  ): Promise<ApiSuccessResponse> {
    const path = "/v0.1/management/swagger_refresh";
    const payload = {
      params: stringifyParams(serializeV01ManagementSwaggerRefreshPostParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiSuccessResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiSuccessResponse", result?.body, "POST /v0.1/management/swagger_refresh", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiSuccessResponse(result);
        return resultOrResults as ApiSuccessResponse;
      });
  }
}
