import { observable } from "mobx";
import { App } from "@root/data/shell/models/app";
import { IApp } from "@lib/common-interfaces";
import { Build, SerializedBuild, DeserializedBuild } from "@root/data/install/models/build";

export type Permissions = "can_remove_from_app";

export interface SerializedTesterApp extends IApp {
  release?: SerializedBuild;
  permissions?: Permissions[];
}

export interface DeserializedTesterApp extends IApp {
  build?: DeserializedBuild;
  permissions?: Permissions[];
}

export class TesterApp extends App {
  @observable public build?: Build;
  @observable public permissions?: Permissions[];
}

export type ForeignKey = keyof DeserializedTesterApp;
