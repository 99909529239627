import { Store } from "../../lib";
import { SerializedBillingResource, BillingResource, DeserializedBillingResource, BillingQuery } from "../models/billing-resources";
import { apiGateway } from "@root/lib/http";
import { API } from "../constants";
import { mergePlanTiers } from "@root/data/management/utils/billing-plan-merger";
import { action, observable } from "mobx";

export class BillingStore extends Store<DeserializedBillingResource, SerializedBillingResource, BillingResource, BillingQuery> {
  @observable private cacheId?: string;
  @action public setCacheId(id: string): void {
    this.cacheId = id;
  }

  public getGlobalCacheKey() {
    return this.cacheId;
  }

  public generateId(isOrg: boolean, orgName?: string) {
    return isOrg ? `org-${orgName}` : "user";
  }

  protected ModelClass = BillingResource;
  protected generateIdFromResponse(resource: SerializedBillingResource, query?: BillingQuery) {
    if (query && query.orgName) {
      return `org-${query.orgName}`;
    } else if (query && query.app && query.app.name && query.app.ownerName) {
      return `app-${query.app.ownerName}-${query.app.name}`;
    } else {
      return "user";
    }
  }
  protected getModelId(model: BillingResource): string {
    return model.resourceId;
  }

  protected getResource(id: string, query?: BillingQuery): Promise<SerializedBillingResource> {
    // We're just going to kid of ignore the ID here, because the actual info we need to look up the
    // billing plan is included in query (or not at all if it's just the user's billing plan).
    let params = {};
    let url: string;
    if (query && query.orgName) {
      url = API.ORG_BILLING_PLAN;
      params = { org_name: query.orgName };
    } else if (query && query.app && query.app.name && query.app.ownerName) {
      url = API.APP_BILLING_PLAN;
      params = { owner_name: query.app.ownerName, app_name: query.app.name };
    } else {
      url = API.USER_BILLING_PLAN;
    }

    return apiGateway.get<SerializedBillingResource>(url, { params: params });
  }

  protected deserialize(serialized: SerializedBillingResource, queryOrOptions?: any): DeserializedBillingResource {
    let resourceId: string;
    if (queryOrOptions && queryOrOptions.orgName) {
      resourceId = `org-${queryOrOptions.orgName}`;
    } else if (queryOrOptions && queryOrOptions.app && queryOrOptions.app.ownerName && queryOrOptions.app.name) {
      resourceId = `app-${queryOrOptions.app.ownerName}-${queryOrOptions.app.name}`;
    } else {
      resourceId = "user";
    }

    return {
      resourceId: resourceId,
      azureSubscriptionId: serialized.azureSubscriptionId,
      azureSubscriptionState: serialized.azureSubscriptionState,
      isAccountDeleted: serialized.isAccountDeleted,
      usage: serialized.usage,
      id: serialized.id,
      billingPlans: serialized.billingPlans,
      plan: mergePlanTiers(serialized),
    };
  }
}

export const billingStore = new BillingStore();
