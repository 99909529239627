export const VERSION = "/v0.1";

export const LATEST: string = "latest";

export const API = {
  TESTER_APPS: `${VERSION}/tester/apps`,
  TESTER_APP: `${VERSION}/tester_apps/:owner_name/:app_name`,
  BUILD: `${VERSION}/apps/:owner_name/:app_name/releases/:package_id`,
  PROVISION: `${VERSION}/apps/:owner_name/:app_name/releases/:release_id/provision`,
  BUILDS_FOR_TESTER: `${VERSION}/apps/:owner_id/:app_id/releases`,
  LATEST_FOR_GROUP: `${VERSION}/apps/:owner_name/:app_name/distribution_groups/:group_name/releases/{release_id}`,
  PUBLIC_RELEASES_FOR_GROUP: `${VERSION}/apps/:owner_id/:app_id/distribution_groups/:distribution_group_name/public_releases`,
};
