import { first } from "lodash";
import { differenceInCalendarDays } from "date-fns";
import { SerializedBillingTierDocument } from "@root/data/management/models/billing-tier";
import {
  SerializedService,
  TestBillingPlan,
  BuildBillingPlan,
  TestBilledUsage,
  BuildBilledUsage,
  PaymentSource,
  SerializedPlan,
} from "@root/data/management/models/billing-resources";

// Helpers
const durationInDays = (duration: string): number => {
  return duration ? parseInt(first(duration.split(".")), 10) || 0 : 0;
};

const inHours = (minutes?: number): number => {
  const minutesInAnHour = 60;
  return minutes ? minutes / minutesInAnHour : 0;
};

export const translateTestPlan = (
  plan: SerializedBillingTierDocument | SerializedPlan,
  count: number = 1,
  serviceBillingPeriod?: SerializedService
): TestBillingPlan | null => {
  if (plan) {
    if (plan.attributes && plan.limits) {
      const { attributes, limits, id, parentId, maximumDuration, expirationTime, paymentSource } = plan;
      const { canSelectTrialPlan = false, lastTrialPlanExpirationTime } = serviceBillingPeriod || ({} as SerializedService);

      const expirationDate = expirationTime ? new Date(expirationTime!) : undefined;
      const lastTrialPlanExpirationDate = lastTrialPlanExpirationTime ? new Date(lastTrialPlanExpirationTime) : undefined;
      const trialDaysLeft = lastTrialPlanExpirationTime
        ? differenceInCalendarDays(lastTrialPlanExpirationDate!, Date.now())
        : undefined;
      const planId = parentId || id || "";

      return {
        id: planId,
        concurrentDevices: limits["test/regular/concurrentDevices"],
        deviceHours: limits["test/regular/deviceHours"]!,
        unlimitedDeviceHours: !!attributes["test/regular/unlimitedHours"],
        isXTCTransition: plan.paymentSource === PaymentSource.Xtc,
        isCustomOffer: plan.paymentSource === PaymentSource.CustomOffer,
        service: "Test",
        maximumDuration: durationInDays(maximumDuration!),
        count: count,
        paymentSource,
        isFreeTier: paymentSource === PaymentSource.None,
        isTrialPlan: !!maximumDuration,
        canSelectTrialPlan,
        expirationDate,
        lastTrialPlanExpirationDate,
        trialDaysLeft,
        isInternal: !!attributes["internal"],
      };
    }
  }
  return null;
};

export const translateBuildPlan = (
  plan: SerializedBillingTierDocument | SerializedPlan,
  count: number = 1
): BuildBillingPlan | null => {
  if (plan) {
    if (plan.attributes && plan.limits) {
      const { attributes, limits, id, parentId, paymentSource } = plan;
      return {
        id: parentId || id || "",
        concurrentBuilds: limits["build/maximumConcurrentBuilds"],
        buildHours: inHours(limits["build/totalBillableMinutes"]),
        unlimitedBuildHours: !!attributes["build/unlimitedTotalBillableMinutes"],
        service: "Build",
        isFreeTier: paymentSource === PaymentSource.None,
        count: count,
        paymentSource,
        isInternal: !!attributes["internal"],
      };
    }
  }
  return null;
};

export const translateBuildUsage = (usage: any): BuildBilledUsage | null => {
  if (!usage) {
    return null;
  }
  return {
    billableMins: usage["build/totalBillableMinutes"],
  };
};

export const translateTestUsage = (usage: any): TestBilledUsage | null => {
  if (!usage) {
    return null;
  }
  return {
    deviceHours: usage["test/regular/deviceHours"],
    devices: usage["test/regular/devices"],
    testRuns: usage["test/regular/testRuns"],
  };
};
