/**
 * AppCenter.Analytics
 * Microsoft AppCenter Analytics REST API.
 *
 * OpenAPI spec version: 1.0.0-preview20161028
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { apiGateway } from "@root/lib/http";

export const clientSettings = {
  defaultBasePath: "/v0.1",
  bypassBifrost: false,
  isWatsonApp: false,
};

// ===============================================
// This file is autogenerated - Please do not edit
// ===============================================

import { ActiveDeviceCounts } from "../model/api";
import { AnalyticsModels } from "../model/api";
import { AvailableVersions } from "../model/api";
import { CrashCounts } from "../model/api";
import { CrashFreeDevicePercentages } from "../model/api";
import { CrashGroupCarriers } from "../model/api";
import { CrashGroupContainer } from "../model/api";
import { CrashGroupLanguages } from "../model/api";
import { CrashGroupModels } from "../model/api";
import { CrashGroupOperatingSystems } from "../model/api";
import { CrashGroupPlaces } from "../model/api";
import { CrashOverall } from "../model/api";
import { CrashesOverall } from "../model/api";
import { DateTimeDownloadReleaseCounts } from "../model/api";
import { DeleteReleasesContainer } from "../model/api";
import { ErrorCounts } from "../model/api";
import { ErrorDownload } from "../model/api";
import { ErrorFreeDevicePercentages } from "../model/api";
import { ErrorGroupModels } from "../model/api";
import { ErrorGroupOperatingSystems } from "../model/api";
import { ErrorGroupState } from "../model/api";
import { ErrorGroups } from "../model/api";
import { EventCount } from "../model/api";
import { EventCountPerDevice } from "../model/api";
import { EventCountPerSession } from "../model/api";
import { EventDeviceCount } from "../model/api";
import { EventProperties } from "../model/api";
import { EventPropertyValues } from "../model/api";
import { Events } from "../model/api";
import { FilterReleasesContainer } from "../model/api";
import { FilterVersionsContainer } from "../model/api";
import { GetReleasesContainer } from "../model/api";
import { HandledErrors } from "../model/api";
import { Languages } from "../model/api";
import { Migration } from "../model/api";
import { Modules } from "../model/api";
import { NotifyReleasesContainer } from "../model/api";
import { OSes } from "../model/api";
import { Places } from "../model/api";
import { ReleaseCounts } from "../model/api";
import { ReleaseDailySessions } from "../model/api";
import { SessionCounts } from "../model/api";
import { SessionDurationsDistribution } from "../model/api";
import { SessionsPerDevice } from "../model/api";
import { Versions } from "../model/api";
import { ErrorGroup } from "../model/api";
import { HandledErrorDetails } from "../model/api";
export class DefaultApi {
  protected app: { owner_name: string; app_name: string; app_id: string };
  protected basePath = clientSettings.defaultBasePath;
  public defaultHeaders: any = {};
  protected _useQuerystring: boolean = false;

  constructor(app: { owner_name: string; app_name: string; app_id: string }, basePath?: string) {
    this.app = app || ({} as any);
    if (!this.app.owner_name) {
      this.app.owner_name = "Unknown";
    }
    if (!this.app.app_name) {
      this.app.app_name = "Unknown";
    }
    if (!this.app.app_id) {
      this.app.app_id = "Unknown";
    }
    if (basePath) {
      this.basePath = basePath;
    }
  }

  set useQuerystring(value: boolean) {
    this._useQuerystring = value;
  }

  public getBasePath(noSlugTransform: boolean = false) {
    if (noSlugTransform || clientSettings.bypassBifrost) {
      return `${this.basePath}/apps/${this.app.app_id}`;
    } else {
      return `${this.basePath}/apps/${this.app.owner_name}/${this.app.app_name}`;
    }
  }

  public getManagementBasePath(noSlugTransform: boolean = false) {
    if (noSlugTransform || clientSettings.bypassBifrost) {
      return `${this.basePath}/management/crashes/apps/${this.app.app_id}`;
    } else {
      return `${this.basePath}/management/crashes/apps/${this.app.owner_name}/${this.app.app_name}`;
    }
  }

  protected parseParams(param: any): any {
    if (param instanceof Date) {
      return param.toISOString();
    } else {
      return param;
    }
  }

  /**
   *
   * Get all available versions in the time range.
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   * @param $Skip The offset (starting at 0) of the first result to return. This parameter along with limit is used to perform pagination.
   * @param $Filter A filter as specified in https://github.com/Microsoft/api-guidelines/blob/master/Guidelines.md#97-filtering.
   * @param $Inlinecount Controls whether or not to include a count of all the items across all pages.
   */
  public analyticsAvailableVersions(
    start: Date,
    end?: Date,
    $Top?: number,
    $Skip?: number,
    $Filter?: string,
    $Inlinecount?: string
  ): Promise<AvailableVersions> {
    const localVarPath = this.getBasePath() + "/analytics/available_versions";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsAvailableVersions.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }
    if ($Skip !== undefined) {
      queryParameters["$skip"] = this.parseParams($Skip);
    }
    if ($Filter !== undefined) {
      queryParameters["$filter"] = this.parseParams($Filter);
    }
    if ($Inlinecount !== undefined) {
      queryParameters["$inlinecount"] = this.parseParams($Inlinecount);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<AvailableVersions>("GET", localVarPath, options)
      .then((body: AvailableVersions) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Return a list of configured modules
   * @param versions
   */
  public analyticsConfiguredModules(versions?: string[]): Promise<Modules> {
    const localVarPath = this.getBasePath() + "/analytics/configuredModules";

    const queryParameters: any = {};
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<Modules>("GET", localVarPath, options)
      .then((body: Modules) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of crashes by day in the time range based the selected versions.
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsCrashCounts(start: Date, end?: Date, versions?: string[]): Promise<CrashCounts> {
    const localVarPath = this.getBasePath() + "/analytics/crash_counts";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsCrashCounts.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<CrashCounts>("GET", localVarPath, options)
      .then((body: CrashCounts) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Percentage of crash-free device by day in the time range based on the selected versions. Api will return -1 if crash devices is greater than active devices
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsCrashFreeDevicePercentages(start: Date, end?: Date, versions?: string[]): Promise<CrashFreeDevicePercentages> {
    const localVarPath = this.getBasePath() + "/analytics/crashfree_device_percentages";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsCrashFreeDevicePercentages.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<CrashFreeDevicePercentages>("GET", localVarPath, options)
      .then((body: CrashFreeDevicePercentages) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * top carriers of the selected crash group with selected version
   * @param crashGroupId The id of the crash group
   * @param version
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   */
  public analyticsCrashGroupCarriersCounts(crashGroupId: string, version: string, $Top?: number): Promise<CrashGroupCarriers> {
    const localVarPath =
      this.getBasePath() +
      "/analytics/crash_groups/{crash_group_id}/carriers".replace("{" + "crash_group_id" + "}", String(crashGroupId));

    // verify required parameter 'crashGroupId' is not null or undefined
    if (crashGroupId === null || crashGroupId === undefined) {
      throw new Error("Required parameter crashGroupId was null or undefined when calling analyticsCrashGroupCarriersCounts.");
    }

    // verify required parameter 'version' is not null or undefined
    if (version === null || version === undefined) {
      throw new Error("Required parameter version was null or undefined when calling analyticsCrashGroupCarriersCounts.");
    }

    const queryParameters: any = {};
    if (version !== undefined) {
      queryParameters["version"] = this.parseParams(version);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<CrashGroupCarriers>("GET", localVarPath, options)
      .then((body: CrashGroupCarriers) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of crashes by day in the time range of the selected crash group with selected version
   * @param crashGroupId The id of the crash group
   * @param version
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   */
  public analyticsCrashGroupCounts(crashGroupId: string, version: string, start: Date, end?: Date): Promise<CrashCounts> {
    const localVarPath =
      this.getBasePath() +
      "/analytics/crash_groups/{crash_group_id}/crash_counts".replace("{" + "crash_group_id" + "}", String(crashGroupId));

    // verify required parameter 'crashGroupId' is not null or undefined
    if (crashGroupId === null || crashGroupId === undefined) {
      throw new Error("Required parameter crashGroupId was null or undefined when calling analyticsCrashGroupCounts.");
    }

    // verify required parameter 'version' is not null or undefined
    if (version === null || version === undefined) {
      throw new Error("Required parameter version was null or undefined when calling analyticsCrashGroupCounts.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsCrashGroupCounts.");
    }

    const queryParameters: any = {};
    if (version !== undefined) {
      queryParameters["version"] = this.parseParams(version);
    }
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<CrashCounts>("GET", localVarPath, options)
      .then((body: CrashCounts) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * top languages of the selected crash group with selected version
   * @param crashGroupId The id of the crash group
   * @param version
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   */
  public analyticsCrashGroupLanguagesCounts(crashGroupId: string, version: string, $Top?: number): Promise<CrashGroupLanguages> {
    const localVarPath =
      this.getBasePath() +
      "/analytics/crash_groups/{crash_group_id}/languages".replace("{" + "crash_group_id" + "}", String(crashGroupId));

    // verify required parameter 'crashGroupId' is not null or undefined
    if (crashGroupId === null || crashGroupId === undefined) {
      throw new Error("Required parameter crashGroupId was null or undefined when calling analyticsCrashGroupLanguagesCounts.");
    }

    // verify required parameter 'version' is not null or undefined
    if (version === null || version === undefined) {
      throw new Error("Required parameter version was null or undefined when calling analyticsCrashGroupLanguagesCounts.");
    }

    const queryParameters: any = {};
    if (version !== undefined) {
      queryParameters["version"] = this.parseParams(version);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<CrashGroupLanguages>("GET", localVarPath, options)
      .then((body: CrashGroupLanguages) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * top models of the selected crash group with selected version
   * @param crashGroupId The id of the crash group
   * @param version
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   */
  public analyticsCrashGroupModelCounts(crashGroupId: string, version: string, $Top?: number): Promise<CrashGroupModels> {
    const localVarPath =
      this.getBasePath() +
      "/analytics/crash_groups/{crash_group_id}/models".replace("{" + "crash_group_id" + "}", String(crashGroupId));

    // verify required parameter 'crashGroupId' is not null or undefined
    if (crashGroupId === null || crashGroupId === undefined) {
      throw new Error("Required parameter crashGroupId was null or undefined when calling analyticsCrashGroupModelCounts.");
    }

    // verify required parameter 'version' is not null or undefined
    if (version === null || version === undefined) {
      throw new Error("Required parameter version was null or undefined when calling analyticsCrashGroupModelCounts.");
    }

    const queryParameters: any = {};
    if (version !== undefined) {
      queryParameters["version"] = this.parseParams(version);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<CrashGroupModels>("GET", localVarPath, options)
      .then((body: CrashGroupModels) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * top OSes of the selected crash group with selected version
   * @param crashGroupId The id of the crash group
   * @param version
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   */
  public analyticsCrashGroupOperatingSystemCounts(
    crashGroupId: string,
    version: string,
    $Top?: number
  ): Promise<CrashGroupOperatingSystems> {
    const localVarPath =
      this.getBasePath() +
      "/analytics/crash_groups/{crash_group_id}/operating_systems".replace("{" + "crash_group_id" + "}", String(crashGroupId));

    // verify required parameter 'crashGroupId' is not null or undefined
    if (crashGroupId === null || crashGroupId === undefined) {
      throw new Error("Required parameter crashGroupId was null or undefined when calling analyticsCrashGroupOperatingSystemCounts.");
    }

    // verify required parameter 'version' is not null or undefined
    if (version === null || version === undefined) {
      throw new Error("Required parameter version was null or undefined when calling analyticsCrashGroupOperatingSystemCounts.");
    }

    const queryParameters: any = {};
    if (version !== undefined) {
      queryParameters["version"] = this.parseParams(version);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<CrashGroupOperatingSystems>("GET", localVarPath, options)
      .then((body: CrashGroupOperatingSystems) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * top places of the selected crash group with selected version
   * @param crashGroupId The id of the crash group
   * @param version
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   */
  public analyticsCrashGroupPlacesCounts(crashGroupId: string, version: string, $Top?: number): Promise<CrashGroupPlaces> {
    const localVarPath =
      this.getBasePath() +
      "/analytics/crash_groups/{crash_group_id}/places".replace("{" + "crash_group_id" + "}", String(crashGroupId));

    // verify required parameter 'crashGroupId' is not null or undefined
    if (crashGroupId === null || crashGroupId === undefined) {
      throw new Error("Required parameter crashGroupId was null or undefined when calling analyticsCrashGroupPlacesCounts.");
    }

    // verify required parameter 'version' is not null or undefined
    if (version === null || version === undefined) {
      throw new Error("Required parameter version was null or undefined when calling analyticsCrashGroupPlacesCounts.");
    }

    const queryParameters: any = {};
    if (version !== undefined) {
      queryParameters["version"] = this.parseParams(version);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<CrashGroupPlaces>("GET", localVarPath, options)
      .then((body: CrashGroupPlaces) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Overall crashes and affected users count of the selected crash group with selected version
   * @param crashGroupId The id of the crash group
   * @param version
   */
  public analyticsCrashGroupTotals(crashGroupId: string, version: string): Promise<CrashOverall> {
    const localVarPath =
      this.getBasePath() +
      "/analytics/crash_groups/{crash_group_id}/overall".replace("{" + "crash_group_id" + "}", String(crashGroupId));

    // verify required parameter 'crashGroupId' is not null or undefined
    if (crashGroupId === null || crashGroupId === undefined) {
      throw new Error("Required parameter crashGroupId was null or undefined when calling analyticsCrashGroupTotals.");
    }

    // verify required parameter 'version' is not null or undefined
    if (version === null || version === undefined) {
      throw new Error("Required parameter version was null or undefined when calling analyticsCrashGroupTotals.");
    }

    const queryParameters: any = {};
    if (version !== undefined) {
      queryParameters["version"] = this.parseParams(version);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<CrashOverall>("GET", localVarPath, options)
      .then((body: CrashOverall) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Overall crashes and affected users count of the selected crash groups with selected versions
   * @param crashGroups
   */
  public analyticsCrashGroupsTotals(crashGroups: CrashGroupContainer): Promise<CrashesOverall> {
    const localVarPath = this.getBasePath() + "/analytics/crash_groups";

    // verify required parameter 'crashGroups' is not null or undefined
    if (crashGroups === null || crashGroups === undefined) {
      throw new Error("Required parameter crashGroups was null or undefined when calling analyticsCrashGroupsTotals.");
    }

    const queryParameters: any = {};

    const options = {
      body: crashGroups,
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<CrashesOverall>("POST", localVarPath, options)
      .then((body: CrashesOverall) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of active devices by interval in the time range.
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsDeviceCounts(start: Date, end?: Date, versions?: string[]): Promise<ActiveDeviceCounts> {
    const localVarPath = this.getBasePath() + "/analytics/active_device_counts";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsDeviceCounts.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<ActiveDeviceCounts>("GET", localVarPath, options)
      .then((body: ActiveDeviceCounts) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of total downloads for the provided distribution releases.
   * @param releases The releases to retrieve.
   */
  public analyticsDistributionReleaseCounts(releases: GetReleasesContainer): Promise<ReleaseCounts> {
    const localVarPath = this.getBasePath() + "/analytics/distribution/release_counts";

    // verify required parameter 'releases' is not null or undefined
    if (releases === null || releases === undefined) {
      throw new Error("Required parameter releases was null or undefined when calling analyticsDistributionReleaseCounts.");
    }

    const queryParameters: any = {};

    const options = {
      body: releases,
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<ReleaseCounts>("POST", localVarPath, options)
      .then((body: ReleaseCounts) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of sessions for the provided distribution releases per day.
   * @param start Start date time in data in ISO 8601 date time format
   * @param distributionId the id of the distribution group
   * @param versions The versions to filter the data by.
   * @param end Last date time in data in ISO 8601 date time format
   */
  public analyticsDistributionReleaseDailySessions(
    start: Date,
    distributionId: string,
    versions: FilterVersionsContainer,
    end?: Date
  ): Promise<ReleaseDailySessions> {
    const localVarPath =
      this.getBasePath() +
      "/analytics/distribution/{distribution_id}/release_daily_session".replace("{" + "distribution_id" + "}", String(distributionId));

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsDistributionReleaseDailySessions.");
    }

    // verify required parameter 'distributionId' is not null or undefined
    if (distributionId === null || distributionId === undefined) {
      throw new Error(
        "Required parameter distributionId was null or undefined when calling analyticsDistributionReleaseDailySessions."
      );
    }

    // verify required parameter 'versions' is not null or undefined
    if (versions === null || versions === undefined) {
      throw new Error("Required parameter versions was null or undefined when calling analyticsDistributionReleaseDailySessions.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }

    const options = {
      body: versions,
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<ReleaseDailySessions>("POST", localVarPath, options)
      .then((body: ReleaseDailySessions) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Delete analytics for the provided distribution release(s).
   * @param releases The releases to delete.
   */
  public analyticsDistributionReleaseDelete(releases: DeleteReleasesContainer): Promise<any> {
    const localVarPath = this.getBasePath() + "/analytics/distribution";

    // verify required parameter 'releases' is not null or undefined
    if (releases === null || releases === undefined) {
      throw new Error("Required parameter releases was null or undefined when calling analyticsDistributionReleaseDelete.");
    }

    const queryParameters: any = {};

    const options = {
      body: releases,
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("DELETE", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of total downloads for the provided distribution releases per day.
   * @param start Start date time in data in ISO 8601 date time format
   * @param distributionId the id of the distribution group
   * @param releases The releases to filter the data by.
   * @param end Last date time in data in ISO 8601 date time format
   */
  public analyticsDistributionReleaseDownloadCounts(
    start: Date,
    distributionId: string,
    releases: FilterReleasesContainer,
    end?: Date
  ): Promise<DateTimeDownloadReleaseCounts> {
    const localVarPath =
      this.getBasePath() +
      "/analytics/distribution/{distribution_id}/release_download_counts".replace(
        "{" + "distribution_id" + "}",
        String(distributionId)
      );

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsDistributionReleaseDownloadCounts.");
    }

    // verify required parameter 'distributionId' is not null or undefined
    if (distributionId === null || distributionId === undefined) {
      throw new Error(
        "Required parameter distributionId was null or undefined when calling analyticsDistributionReleaseDownloadCounts."
      );
    }

    // verify required parameter 'releases' is not null or undefined
    if (releases === null || releases === undefined) {
      throw new Error("Required parameter releases was null or undefined when calling analyticsDistributionReleaseDownloadCounts.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }

    const options = {
      body: releases,
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<DateTimeDownloadReleaseCounts>("POST", localVarPath, options)
      .then((body: DateTimeDownloadReleaseCounts) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Notify download(s) for the provided distribution release(s).
   * @param releases The releases to notify.
   */
  public analyticsDistributionReleaseNotify(releases: NotifyReleasesContainer): Promise<any> {
    const localVarPath = this.getBasePath() + "/analytics/distribution";

    // verify required parameter 'releases' is not null or undefined
    if (releases === null || releases === undefined) {
      throw new Error("Required parameter releases was null or undefined when calling analyticsDistributionReleaseNotify.");
    }

    const queryParameters: any = {};

    const options = {
      body: releases,
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("POST", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Session duration for a specific distribution grouped by the version in the time range.
   * @param start Start date time in data in ISO 8601 date time format
   * @param distributionId the id of the distribution group
   * @param versions The versions to filter the data by.
   * @param end Last date time in data in ISO 8601 date time format
   */
  public analyticsDistributionReleaseSessionsDuration(
    start: Date,
    distributionId: string,
    versions: FilterVersionsContainer,
    end?: Date
  ): Promise<SessionDurationsDistribution> {
    const localVarPath =
      this.getBasePath() +
      "/analytics/distribution/{distribution_id}/release_session_duration".replace(
        "{" + "distribution_id" + "}",
        String(distributionId)
      );

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsDistributionReleaseSessionsDuration.");
    }

    // verify required parameter 'distributionId' is not null or undefined
    if (distributionId === null || distributionId === undefined) {
      throw new Error(
        "Required parameter distributionId was null or undefined when calling analyticsDistributionReleaseSessionsDuration."
      );
    }

    // verify required parameter 'versions' is not null or undefined
    if (versions === null || versions === undefined) {
      throw new Error("Required parameter versions was null or undefined when calling analyticsDistributionReleaseSessionsDuration.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }

    const options = {
      body: versions,
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<SessionDurationsDistribution>("POST", localVarPath, options)
      .then((body: SessionDurationsDistribution) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of events by interval in the time range.
   * @param eventName The id of the event
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsEventCount(eventName: string, start: Date, end?: Date, versions?: string[]): Promise<EventCount> {
    const localVarPath =
      this.getBasePath() + "/analytics/events/{event_name}/event_count".replace("{" + "event_name" + "}", String(eventName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventCount.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsEventCount.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<EventCount>("GET", localVarPath, options)
      .then((body: EventCount) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of events by interval in the time range.
   * @param eventName The id of the event
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsEventCountlogs(eventName: string, start: Date, end?: Date, versions?: string[]): Promise<EventCount> {
    const localVarPath =
      this.getBasePath() + "/analytics/event_logs/{event_name}/event_count".replace("{" + "event_name" + "}", String(eventName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventCountlogs.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsEventCountlogs.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<EventCount>("GET", localVarPath, options)
      .then((body: EventCount) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of devices for an event by interval in the time range.
   * @param eventName The id of the event
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsEventDeviceCount(eventName: string, start: Date, end?: Date, versions?: string[]): Promise<EventDeviceCount> {
    const localVarPath =
      this.getBasePath() + "/analytics/events/{event_name}/device_count".replace("{" + "event_name" + "}", String(eventName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventDeviceCount.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsEventDeviceCount.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<EventDeviceCount>("GET", localVarPath, options)
      .then((body: EventDeviceCount) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of devices for an event by interval in the time range.
   * @param eventName The id of the event
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsEventDeviceCountlogs(eventName: string, start: Date, end?: Date, versions?: string[]): Promise<EventDeviceCount> {
    const localVarPath =
      this.getBasePath() + "/analytics/event_logs/{event_name}/device_count".replace("{" + "event_name" + "}", String(eventName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventDeviceCountlogs.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsEventDeviceCountlogs.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<EventDeviceCount>("GET", localVarPath, options)
      .then((body: EventDeviceCount) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of events per device by interval in the time range.
   * @param eventName The id of the event
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsEventPerDeviceCount(eventName: string, start: Date, end?: Date, versions?: string[]): Promise<EventCountPerDevice> {
    const localVarPath =
      this.getBasePath() + "/analytics/events/{event_name}/count_per_device".replace("{" + "event_name" + "}", String(eventName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventPerDeviceCount.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsEventPerDeviceCount.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<EventCountPerDevice>("GET", localVarPath, options)
      .then((body: EventCountPerDevice) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of events per device by interval in the time range.
   * @param eventName The id of the event
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsEventPerDeviceCountlogs(
    eventName: string,
    start: Date,
    end?: Date,
    versions?: string[]
  ): Promise<EventCountPerDevice> {
    const localVarPath =
      this.getBasePath() + "/analytics/event_logs/{event_name}/count_per_device".replace("{" + "event_name" + "}", String(eventName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventPerDeviceCountlogs.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsEventPerDeviceCountlogs.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<EventCountPerDevice>("GET", localVarPath, options)
      .then((body: EventCountPerDevice) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of events per session by interval in the time range.
   * @param eventName The id of the event
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsEventPerSessionCount(
    eventName: string,
    start: Date,
    end?: Date,
    versions?: string[]
  ): Promise<EventCountPerSession> {
    const localVarPath =
      this.getBasePath() + "/analytics/events/{event_name}/count_per_session".replace("{" + "event_name" + "}", String(eventName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventPerSessionCount.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsEventPerSessionCount.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<EventCountPerSession>("GET", localVarPath, options)
      .then((body: EventCountPerSession) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of events per session by interval in the time range.
   * @param eventName The id of the event
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsEventPerSessionCountlogs(
    eventName: string,
    start: Date,
    end?: Date,
    versions?: string[]
  ): Promise<EventCountPerSession> {
    const localVarPath =
      this.getBasePath() + "/analytics/event_logs/{event_name}/count_per_session".replace("{" + "event_name" + "}", String(eventName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventPerSessionCountlogs.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsEventPerSessionCountlogs.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<EventCountPerSession>("GET", localVarPath, options)
      .then((body: EventCountPerSession) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Event properties.  Up to the first 5 received properties.
   * @param eventName The id of the event
   */
  public analyticsEventProperties(eventName: string): Promise<EventProperties> {
    const localVarPath =
      this.getBasePath() + "/analytics/events/{event_name}/properties".replace("{" + "event_name" + "}", String(eventName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventProperties.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<EventProperties>("GET", localVarPath, options)
      .then((body: EventProperties) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Event properties.  Up to the first 5 received properties.
   * @param eventName The id of the event
   */
  public analyticsEventPropertieslogs(eventName: string): Promise<EventProperties> {
    const localVarPath =
      this.getBasePath() + "/analytics/event_logs/{event_name}/properties".replace("{" + "event_name" + "}", String(eventName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventPropertieslogs.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<EventProperties>("GET", localVarPath, options)
      .then((body: EventProperties) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Event properties value counts during the time range in descending order.  Limited up to 5 values.
   * @param eventName The id of the event
   * @param eventPropertyName The id of the event property
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   * @param count The number of property values to return
   */
  public analyticsEventPropertyCounts(
    eventName: string,
    eventPropertyName: string,
    start: Date,
    end?: Date,
    versions?: string[],
    count?: number
  ): Promise<EventPropertyValues> {
    const localVarPath =
      this.getBasePath() +
      "/analytics/events/{event_name}/properties/{event_property_name}/counts"
        .replace("{" + "event_name" + "}", String(eventName))
        .replace("{" + "event_property_name" + "}", String(eventPropertyName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventPropertyCounts.");
    }

    // verify required parameter 'eventPropertyName' is not null or undefined
    if (eventPropertyName === null || eventPropertyName === undefined) {
      throw new Error("Required parameter eventPropertyName was null or undefined when calling analyticsEventPropertyCounts.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsEventPropertyCounts.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }
    if (count !== undefined) {
      queryParameters["count"] = this.parseParams(count);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<EventPropertyValues>("GET", localVarPath, options)
      .then((body: EventPropertyValues) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Event properties value counts during the time range in descending order.  Limited up to 5 values.
   * @param eventName The id of the event
   * @param eventPropertyName The id of the event property
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   * @param count The number of property values to return
   */
  public analyticsEventPropertyCountslogs(
    eventName: string,
    eventPropertyName: string,
    start: Date,
    end?: Date,
    versions?: string[],
    count?: number
  ): Promise<EventPropertyValues> {
    const localVarPath =
      this.getBasePath() +
      "/analytics/event_logs/{event_name}/properties/{event_property_name}/counts"
        .replace("{" + "event_name" + "}", String(eventName))
        .replace("{" + "event_property_name" + "}", String(eventPropertyName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventPropertyCountslogs.");
    }

    // verify required parameter 'eventPropertyName' is not null or undefined
    if (eventPropertyName === null || eventPropertyName === undefined) {
      throw new Error("Required parameter eventPropertyName was null or undefined when calling analyticsEventPropertyCountslogs.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsEventPropertyCountslogs.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }
    if (count !== undefined) {
      queryParameters["count"] = this.parseParams(count);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<EventPropertyValues>("GET", localVarPath, options)
      .then((body: EventPropertyValues) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of active events in the time range ordered by event.
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   * @param eventName to select the specific events
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   * @param $Skip The offset (starting at 0) of the first result to return. This parameter along with limit is used to perform pagination.
   * @param $Inlinecount Controls whether or not to include a count of all the items across all pages.
   * @param $Orderby controls the sorting order and sorting based on which column
   */
  public analyticsEventlogs(
    start: Date,
    end?: Date,
    versions?: string[],
    eventName?: string[],
    $Top?: number,
    $Skip?: number,
    $Inlinecount?: string,
    $Orderby?: string
  ): Promise<Events> {
    const localVarPath = this.getBasePath() + "/analytics/event_logs";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsEventlogs.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }
    if (eventName !== undefined) {
      queryParameters["event_name"] = this.parseParams(eventName);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }
    if ($Skip !== undefined) {
      queryParameters["$skip"] = this.parseParams($Skip);
    }
    if ($Inlinecount !== undefined) {
      queryParameters["$inlinecount"] = this.parseParams($Inlinecount);
    }
    if ($Orderby !== undefined) {
      queryParameters["$orderby"] = this.parseParams($Orderby);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<Events>("GET", localVarPath, options)
      .then((body: Events) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of active events in the time range ordered by event.
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   * @param eventName to select the specific events
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   * @param $Skip The offset (starting at 0) of the first result to return. This parameter along with limit is used to perform pagination.
   * @param $Inlinecount Controls whether or not to include a count of all the items across all pages.
   * @param $Orderby controls the sorting order and sorting based on which column
   */
  public analyticsEvents(
    start: Date,
    end?: Date,
    versions?: string[],
    eventName?: string[],
    $Top?: number,
    $Skip?: number,
    $Inlinecount?: string,
    $Orderby?: string
  ): Promise<Events> {
    const localVarPath = this.getBasePath() + "/analytics/events";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsEvents.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }
    if (eventName !== undefined) {
      queryParameters["event_name"] = this.parseParams(eventName);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }
    if ($Skip !== undefined) {
      queryParameters["$skip"] = this.parseParams($Skip);
    }
    if ($Inlinecount !== undefined) {
      queryParameters["$inlinecount"] = this.parseParams($Inlinecount);
    }
    if ($Orderby !== undefined) {
      queryParameters["$orderby"] = this.parseParams($Orderby);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<Events>("GET", localVarPath, options)
      .then((body: Events) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Delete the set of Events with the specified event names
   * @param eventName The id of the event
   */
  public analyticsEventsDelete(eventName: string): Promise<any> {
    const localVarPath = this.getBasePath() + "/analytics/events/{event_name}".replace("{" + "event_name" + "}", String(eventName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventsDelete.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("DELETE", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Delete the set of Events with the specified event names
   * @param eventName The id of the event
   */
  public analyticsEventsDeleteLogs(eventName: string): Promise<any> {
    const localVarPath =
      this.getBasePath() + "/analytics/event_logs/{event_name}".replace("{" + "event_name" + "}", String(eventName));

    // verify required parameter 'eventName' is not null or undefined
    if (eventName === null || eventName === undefined) {
      throw new Error("Required parameter eventName was null or undefined when calling analyticsEventsDeleteLogs.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("DELETE", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * languages in the time range
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   * @param versions
   */
  public analyticsLanguageCounts(start: Date, end?: Date, $Top?: number, versions?: string[]): Promise<Languages> {
    const localVarPath = this.getBasePath() + "/analytics/languages";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsLanguageCounts.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<Languages>("GET", localVarPath, options)
      .then((body: Languages) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param storageAccount Storage account name
   * @param queueName Name of the queue
   * @param queueType Type of the queue
   */
  public analyticsManagementClearQueue(storageAccount: string, queueName: string, queueType: string): Promise<any> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/queues/clear".replace("/management", "");

    // verify required parameter 'storageAccount' is not null or undefined
    if (storageAccount === null || storageAccount === undefined) {
      throw new Error("Required parameter storageAccount was null or undefined when calling analyticsManagementClearQueue.");
    }

    // verify required parameter 'queueName' is not null or undefined
    if (queueName === null || queueName === undefined) {
      throw new Error("Required parameter queueName was null or undefined when calling analyticsManagementClearQueue.");
    }

    // verify required parameter 'queueType' is not null or undefined
    if (queueType === null || queueType === undefined) {
      throw new Error("Required parameter queueType was null or undefined when calling analyticsManagementClearQueue.");
    }

    const queryParameters: any = {};
    if (storageAccount !== undefined) {
      queryParameters["storageAccount"] = this.parseParams(storageAccount);
    }
    if (queueName !== undefined) {
      queryParameters["queueName"] = this.parseParams(queueName);
    }
    if (queueType !== undefined) {
      queryParameters["queueType"] = this.parseParams(queueType);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("POST", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param storageAccount Storage account name
   * @param queueName Name of the queue
   * @param queueType Type of the queue
   */
  public analyticsManagementDeleteQueue(storageAccount: string, queueName: string, queueType: string): Promise<any> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/queues".replace("/management", "");

    // verify required parameter 'storageAccount' is not null or undefined
    if (storageAccount === null || storageAccount === undefined) {
      throw new Error("Required parameter storageAccount was null or undefined when calling analyticsManagementDeleteQueue.");
    }

    // verify required parameter 'queueName' is not null or undefined
    if (queueName === null || queueName === undefined) {
      throw new Error("Required parameter queueName was null or undefined when calling analyticsManagementDeleteQueue.");
    }

    // verify required parameter 'queueType' is not null or undefined
    if (queueType === null || queueType === undefined) {
      throw new Error("Required parameter queueType was null or undefined when calling analyticsManagementDeleteQueue.");
    }

    const queryParameters: any = {};
    if (storageAccount !== undefined) {
      queryParameters["storageAccount"] = this.parseParams(storageAccount);
    }
    if (queueName !== undefined) {
      queryParameters["queueName"] = this.parseParams(queueName);
    }
    if (queueType !== undefined) {
      queryParameters["queueType"] = this.parseParams(queueType);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("DELETE", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   */
  public analyticsManagementFlushPipeline(): Promise<any> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/pipeline/flush".replace("/management", "");

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("POST", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param counterId Counter Id
   */
  public analyticsManagementGet2DHllCounter(counterId: string): Promise<string> {
    const localVarPath =
      this.getManagementBasePath() +
      "/management/analytics/counters/2dhlls/{counterId}"
        .replace("/management", "")
        .replace("{" + "counterId" + "}", String(counterId));

    // verify required parameter 'counterId' is not null or undefined
    if (counterId === null || counterId === undefined) {
      throw new Error("Required parameter counterId was null or undefined when calling analyticsManagementGet2DHllCounter.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string>("GET", localVarPath, options)
      .then((body: string) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param counterId Counter Id
   */
  public analyticsManagementGet2DIntCounter(counterId: string): Promise<string> {
    const localVarPath =
      this.getManagementBasePath() +
      "/management/analytics/counters/2dints/{counterId}"
        .replace("/management", "")
        .replace("{" + "counterId" + "}", String(counterId));

    // verify required parameter 'counterId' is not null or undefined
    if (counterId === null || counterId === undefined) {
      throw new Error("Required parameter counterId was null or undefined when calling analyticsManagementGet2DIntCounter.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string>("GET", localVarPath, options)
      .then((body: string) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param counterId Counter Id
   */
  public analyticsManagementGet3DHllCounter(counterId: string): Promise<string> {
    const localVarPath =
      this.getManagementBasePath() +
      "/management/analytics/counters/3dhlls/{counterId}"
        .replace("/management", "")
        .replace("{" + "counterId" + "}", String(counterId));

    // verify required parameter 'counterId' is not null or undefined
    if (counterId === null || counterId === undefined) {
      throw new Error("Required parameter counterId was null or undefined when calling analyticsManagementGet3DHllCounter.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string>("GET", localVarPath, options)
      .then((body: string) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param counterId Counter Id
   */
  public analyticsManagementGet3DIntCounter(counterId: string): Promise<string> {
    const localVarPath =
      this.getManagementBasePath() +
      "/management/analytics/counters/3dints/{counterId}"
        .replace("/management", "")
        .replace("{" + "counterId" + "}", String(counterId));

    // verify required parameter 'counterId' is not null or undefined
    if (counterId === null || counterId === undefined) {
      throw new Error("Required parameter counterId was null or undefined when calling analyticsManagementGet3DIntCounter.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string>("GET", localVarPath, options)
      .then((body: string) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param counterId Counter Id
   */
  public analyticsManagementGetHllCounter(counterId: string): Promise<number> {
    const localVarPath =
      this.getManagementBasePath() +
      "/management/analytics/counters/hlls/{counterId}".replace("/management", "").replace("{" + "counterId" + "}", String(counterId));

    // verify required parameter 'counterId' is not null or undefined
    if (counterId === null || counterId === undefined) {
      throw new Error("Required parameter counterId was null or undefined when calling analyticsManagementGetHllCounter.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<number>("GET", localVarPath, options)
      .then((body: number) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   */
  public analyticsManagementGetIngestionPipelineSize(): Promise<string> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/ingestion".replace("/management", "");

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string>("GET", localVarPath, options)
      .then((body: string) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   */
  public analyticsManagementGetPipelineSize(): Promise<string> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/pipeline".replace("/management", "");

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string>("GET", localVarPath, options)
      .then((body: string) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param counterId Counter Id
   */
  public analyticsManagementGetSetCounter(counterId: string): Promise<string> {
    const localVarPath =
      this.getManagementBasePath() +
      "/management/analytics/counters/set/{counterId}".replace("/management", "").replace("{" + "counterId" + "}", String(counterId));

    // verify required parameter 'counterId' is not null or undefined
    if (counterId === null || counterId === undefined) {
      throw new Error("Required parameter counterId was null or undefined when calling analyticsManagementGetSetCounter.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string>("GET", localVarPath, options)
      .then((body: string) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   */
  public analyticsManagementGetShardingConfig(): Promise<string> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/shardingConfig".replace("/management", "");

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string>("GET", localVarPath, options)
      .then((body: string) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param appId The id of the application
   */
  public analyticsManagementGetStorageAccount(appId?: string): Promise<string> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/storageAccounts".replace("/management", "");

    const queryParameters: any = {};
    if (appId !== undefined) {
      queryParameters["appId"] = this.parseParams(appId);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string>("GET", localVarPath, options)
      .then((body: string) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param start Start date time in data in ISO 8601 date time format
   * @param storageAccount Storage account name
   * @param end Last date time in data in ISO 8601 date time format
   */
  public analyticsManagementListApps(start: Date, storageAccount?: string, end?: Date): Promise<string[]> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/apps".replace("/management", "");

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsManagementListApps.");
    }

    const queryParameters: any = {};
    if (storageAccount !== undefined) {
      queryParameters["storageAccount"] = this.parseParams(storageAccount);
    }
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string[]>("GET", localVarPath, options)
      .then((body: string[]) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   */
  public analyticsManagementListContainers(): Promise<string[]> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/containers".replace("/management", "");

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string[]>("GET", localVarPath, options)
      .then((body: string[]) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param name Name
   * @param migration Migration parameters
   */
  public analyticsManagementMigrate(name: string, migration: Migration): Promise<string> {
    const localVarPath =
      this.getManagementBasePath() +
      "/management/analytics/migration/{name}".replace("/management", "").replace("{" + "name" + "}", String(name));

    // verify required parameter 'name' is not null or undefined
    if (name === null || name === undefined) {
      throw new Error("Required parameter name was null or undefined when calling analyticsManagementMigrate.");
    }

    // verify required parameter 'migration' is not null or undefined
    if (migration === null || migration === undefined) {
      throw new Error("Required parameter migration was null or undefined when calling analyticsManagementMigrate.");
    }

    const queryParameters: any = {};

    const options = {
      body: migration,
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string>("POST", localVarPath, options)
      .then((body: string) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param name Name
   * @param migration Migration parameters
   */
  public analyticsManagementMigrationStarted(name: string, migration: Migration): Promise<any> {
    const localVarPath =
      this.getManagementBasePath() +
      "/management/analytics/migration/{name}".replace("/management", "").replace("{" + "name" + "}", String(name));

    // verify required parameter 'name' is not null or undefined
    if (name === null || name === undefined) {
      throw new Error("Required parameter name was null or undefined when calling analyticsManagementMigrationStarted.");
    }

    // verify required parameter 'migration' is not null or undefined
    if (migration === null || migration === undefined) {
      throw new Error("Required parameter migration was null or undefined when calling analyticsManagementMigrationStarted.");
    }

    const queryParameters: any = {};

    const options = {
      body: migration,
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("HEAD", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param partitionIndex
   */
  public analyticsManagementSnapshotIngestionPartition(partitionIndex?: number): Promise<any> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/ingestion/snapshotPartition".replace("/management", "");

    const queryParameters: any = {};
    if (partitionIndex !== undefined) {
      queryParameters["partitionIndex"] = this.parseParams(partitionIndex);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("POST", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   */
  public analyticsManagementStartIngestion(): Promise<any> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/ingestion/start".replace("/management", "");

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("POST", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   */
  public analyticsManagementStartPipeline(): Promise<any> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/pipeline/start".replace("/management", "");

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("POST", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param end Last date time in data in ISO 8601 date time format
   */
  public analyticsManagementStopIngestion(end?: Date): Promise<any> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/ingestion/stop".replace("/management", "");

    const queryParameters: any = {};
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("POST", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param end Last date time in data in ISO 8601 date time format
   */
  public analyticsManagementStopPipeline(end?: Date): Promise<any> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/pipeline/stop".replace("/management", "");

    const queryParameters: any = {};
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("POST", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param body
   */
  public analyticsManagementTestShardingConfig(body?: string): Promise<string> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/shardingConfig/test".replace("/management", "");

    const queryParameters: any = {};

    const options = {
      body: body,
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string>("POST", localVarPath, options)
      .then((body: string) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   *
   * @param body
   */
  public analyticsManagementUpdateShardingConfig(body?: string): Promise<string> {
    const localVarPath = this.getManagementBasePath() + "/management/analytics/shardingConfig".replace("/management", "");

    const queryParameters: any = {};

    const options = {
      body: body,
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<string>("PUT", localVarPath, options)
      .then((body: string) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * models in the time range
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   * @param versions
   */
  public analyticsModelCounts(start: Date, end?: Date, $Top?: number, versions?: string[]): Promise<AnalyticsModels> {
    const localVarPath = this.getBasePath() + "/analytics/models";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsModelCounts.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<AnalyticsModels>("GET", localVarPath, options)
      .then((body: AnalyticsModels) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * OSes in the time range
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   * @param versions
   */
  public analyticsOperatingSystemCounts(start: Date, end?: Date, $Top?: number, versions?: string[]): Promise<OSes> {
    const localVarPath = this.getBasePath() + "/analytics/oses";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsOperatingSystemCounts.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<OSes>("GET", localVarPath, options)
      .then((body: OSes) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of sessions per device in the time range
   * @param start Start date time in data in ISO 8601 date time format
   * @param interval Size of interval in ISO 8601 duration format. (PnYnMnDTnHnMnS|PnW|P&lt;date&gt;T&lt;time&gt;). The valid durations are 1 day (P1D), 1 week (P1W), and 28 days (P28D).
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsPerDeviceCounts(start: Date, interval: string, end?: Date, versions?: string[]): Promise<SessionsPerDevice> {
    const localVarPath = this.getBasePath() + "/analytics/sessions_per_device";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsPerDeviceCounts.");
    }

    // verify required parameter 'interval' is not null or undefined
    if (interval === null || interval === undefined) {
      throw new Error("Required parameter interval was null or undefined when calling analyticsPerDeviceCounts.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (interval !== undefined) {
      queryParameters["interval"] = this.parseParams(interval);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<SessionsPerDevice>("GET", localVarPath, options)
      .then((body: SessionsPerDevice) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Places in the time range
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   * @param versions
   */
  public analyticsPlaceCounts(start: Date, end?: Date, $Top?: number, versions?: string[]): Promise<Places> {
    const localVarPath = this.getBasePath() + "/analytics/places";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsPlaceCounts.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<Places>("GET", localVarPath, options)
      .then((body: Places) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Warm up analytics overview and events page
   */
  public analyticsPrepareOverview(): Promise<any> {
    const localVarPath = this.getBasePath() + "/analytics/prepare_overview";

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call("POST", localVarPath, options)
      .then((body?: any) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of sessions in the time range.
   * @param start Start date time in data in ISO 8601 date time format
   * @param interval Size of interval in ISO 8601 duration format. (PnYnMnDTnHnMnS|PnW|P&lt;date&gt;T&lt;time&gt;). The valid durations are 1 day (P1D), 1 week (P1W), and 28 days (P28D).
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsSessionCounts(start: Date, interval: string, end?: Date, versions?: string[]): Promise<SessionCounts> {
    const localVarPath = this.getBasePath() + "/analytics/session_counts";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsSessionCounts.");
    }

    // verify required parameter 'interval' is not null or undefined
    if (interval === null || interval === undefined) {
      throw new Error("Required parameter interval was null or undefined when calling analyticsSessionCounts.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (interval !== undefined) {
      queryParameters["interval"] = this.parseParams(interval);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<SessionCounts>("GET", localVarPath, options)
      .then((body: SessionCounts) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Gets session duration .
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public analyticsSessionDurationsDistribution(start: Date, end?: Date, versions?: string[]): Promise<SessionDurationsDistribution> {
    const localVarPath = this.getBasePath() + "/analytics/session_durations_distribution";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsSessionDurationsDistribution.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<SessionDurationsDistribution>("GET", localVarPath, options)
      .then((body: SessionDurationsDistribution) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of active versions in the time range ordered by version.
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   * @param versions
   */
  public analyticsVersions(start: Date, end?: Date, $Top?: number, versions?: string[]): Promise<Versions> {
    const localVarPath = this.getBasePath() + "/analytics/versions";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling analyticsVersions.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<Versions>("GET", localVarPath, options)
      .then((body: Versions) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of errors by day in the time range based the selected versions.
   * @param start Start date time in data in ISO 8601 date time format
   * @param version
   * @param end Last date time in data in ISO 8601 date time format
   */
  public errorsCountsPerDay(start: Date, version?: string, end?: Date): Promise<ErrorCounts> {
    const localVarPath = this.getBasePath() + "/errors/errorCountsPerDay";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling errorsCountsPerDay.");
    }

    const queryParameters: any = {};
    if (version !== undefined) {
      queryParameters["version"] = this.parseParams(version);
    }
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<ErrorCounts>("GET", localVarPath, options)
      .then((body: ErrorCounts) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Error details.
   * @param errorGroupId The id of the error group
   * @param errorId The id of the error
   */
  public errorsErrorDetails(errorGroupId: string, errorId: string): Promise<HandledErrorDetails> {
    const localVarPath =
      this.getBasePath() +
      "/errors/errorGroups/{errorGroupId}/errors/{errorId}"
        .replace("{" + "errorGroupId" + "}", String(errorGroupId))
        .replace("{" + "errorId" + "}", String(errorId));

    // verify required parameter 'errorGroupId' is not null or undefined
    if (errorGroupId === null || errorGroupId === undefined) {
      throw new Error("Required parameter errorGroupId was null or undefined when calling errorsErrorDetails.");
    }

    // verify required parameter 'errorId' is not null or undefined
    if (errorId === null || errorId === undefined) {
      throw new Error("Required parameter errorId was null or undefined when calling errorsErrorDetails.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<HandledErrorDetails>("GET", localVarPath, options)
      .then((body: HandledErrorDetails) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Download details for a specific error.
   * @param errorGroupId The id of the error group
   * @param errorId The id of the error
   */
  public errorsErrorDownload(errorGroupId: string, errorId: string): Promise<ErrorDownload> {
    const localVarPath =
      this.getBasePath() +
      "/errors/errorGroups/{errorGroupId}/errors/{errorId}/download"
        .replace("{" + "errorGroupId" + "}", String(errorGroupId))
        .replace("{" + "errorId" + "}", String(errorId));

    // verify required parameter 'errorGroupId' is not null or undefined
    if (errorGroupId === null || errorGroupId === undefined) {
      throw new Error("Required parameter errorGroupId was null or undefined when calling errorsErrorDownload.");
    }

    // verify required parameter 'errorId' is not null or undefined
    if (errorId === null || errorId === undefined) {
      throw new Error("Required parameter errorId was null or undefined when calling errorsErrorDownload.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<ErrorDownload>("GET", localVarPath, options)
      .then((body: ErrorDownload) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Percentage of error-free device by day in the time range based on the selected versions. Api will return -1 if crash devices is greater than active devices
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   * @param versions
   */
  public errorsErrorFreeDevicePercentages(start: Date, end?: Date, versions?: string[]): Promise<ErrorFreeDevicePercentages> {
    const localVarPath = this.getBasePath() + "/errors/errorfreeDevicePercentages";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling errorsErrorFreeDevicePercentages.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (versions !== undefined) {
      queryParameters["versions"] = this.parseParams(versions);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<ErrorFreeDevicePercentages>("GET", localVarPath, options)
      .then((body: ErrorFreeDevicePercentages) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Count of errors by day in the time range of the selected error group with selected version
   * @param errorGroupId The id of the error group
   * @param start Start date time in data in ISO 8601 date time format
   * @param version
   * @param end Last date time in data in ISO 8601 date time format
   */
  public errorsGroupCountsPerDay(errorGroupId: string, start: Date, version?: string, end?: Date): Promise<ErrorCounts> {
    const localVarPath =
      this.getBasePath() +
      "/errors/errorGroups/{errorGroupId}/errorCountsPerDay".replace("{" + "errorGroupId" + "}", String(errorGroupId));

    // verify required parameter 'errorGroupId' is not null or undefined
    if (errorGroupId === null || errorGroupId === undefined) {
      throw new Error("Required parameter errorGroupId was null or undefined when calling errorsGroupCountsPerDay.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling errorsGroupCountsPerDay.");
    }

    const queryParameters: any = {};
    if (version !== undefined) {
      queryParameters["version"] = this.parseParams(version);
    }
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<ErrorCounts>("GET", localVarPath, options)
      .then((body: ErrorCounts) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Error group details
   * @param errorGroupId The id of the error group
   */
  public errorsGroupDetails(errorGroupId: string): Promise<ErrorGroup> {
    const localVarPath =
      this.getBasePath() + "/errors/errorGroups/{errorGroupId}".replace("{" + "errorGroupId" + "}", String(errorGroupId));

    // verify required parameter 'errorGroupId' is not null or undefined
    if (errorGroupId === null || errorGroupId === undefined) {
      throw new Error("Required parameter errorGroupId was null or undefined when calling errorsGroupDetails.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<ErrorGroup>("GET", localVarPath, options)
      .then((body: ErrorGroup) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Percentage of error-free device by day in the time range. Api will return -1 if crash devices is greater than active devices
   * @param errorGroupId The id of the error group
   * @param start Start date time in data in ISO 8601 date time format
   * @param end Last date time in data in ISO 8601 date time format
   */
  public errorsGroupErrorFreeDevicePercentages(errorGroupId: string, start: Date, end?: Date): Promise<ErrorFreeDevicePercentages> {
    const localVarPath =
      this.getBasePath() +
      "/errors/errorGroups/{errorGroupId}/errorfreeDevicePercentages".replace("{" + "errorGroupId" + "}", String(errorGroupId));

    // verify required parameter 'errorGroupId' is not null or undefined
    if (errorGroupId === null || errorGroupId === undefined) {
      throw new Error("Required parameter errorGroupId was null or undefined when calling errorsGroupErrorFreeDevicePercentages.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling errorsGroupErrorFreeDevicePercentages.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<ErrorFreeDevicePercentages>("GET", localVarPath, options)
      .then((body: ErrorFreeDevicePercentages) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * List of error groups
   * @param start Start date time in data in ISO 8601 date time format
   * @param version
   * @param groupState
   * @param end Last date time in data in ISO 8601 date time format
   * @param $Orderby controls the sorting order and sorting based on which column
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   */
  public errorsGroupList(
    start: Date,
    version?: string,
    groupState?: string,
    end?: Date,
    $Orderby?: string,
    $Top?: number
  ): Promise<ErrorGroups> {
    const localVarPath = this.getBasePath() + "/errors/errorGroups";

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling errorsGroupList.");
    }

    const queryParameters: any = {};
    if (version !== undefined) {
      queryParameters["version"] = this.parseParams(version);
    }
    if (groupState !== undefined) {
      queryParameters["groupState"] = this.parseParams(groupState);
    }
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if ($Orderby !== undefined) {
      queryParameters["$orderby"] = this.parseParams($Orderby);
    }
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<ErrorGroups>("GET", localVarPath, options)
      .then((body: ErrorGroups) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Top models of the selected error group.
   * @param errorGroupId The id of the error group
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   */
  public errorsGroupModelCounts(errorGroupId: string, $Top?: number): Promise<ErrorGroupModels> {
    const localVarPath =
      this.getBasePath() + "/errors/errorGroups/{errorGroupId}/models".replace("{" + "errorGroupId" + "}", String(errorGroupId));

    // verify required parameter 'errorGroupId' is not null or undefined
    if (errorGroupId === null || errorGroupId === undefined) {
      throw new Error("Required parameter errorGroupId was null or undefined when calling errorsGroupModelCounts.");
    }

    const queryParameters: any = {};
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<ErrorGroupModels>("GET", localVarPath, options)
      .then((body: ErrorGroupModels) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Top OSes of the selected error group.
   * @param errorGroupId The id of the error group
   * @param $Top The maximum number of results to return. (0 will fetch all results)
   */
  public errorsGroupOperatingSystemCounts(errorGroupId: string, $Top?: number): Promise<ErrorGroupOperatingSystems> {
    const localVarPath =
      this.getBasePath() +
      "/errors/errorGroups/{errorGroupId}/operatingSystems".replace("{" + "errorGroupId" + "}", String(errorGroupId));

    // verify required parameter 'errorGroupId' is not null or undefined
    if (errorGroupId === null || errorGroupId === undefined) {
      throw new Error("Required parameter errorGroupId was null or undefined when calling errorsGroupOperatingSystemCounts.");
    }

    const queryParameters: any = {};
    if ($Top !== undefined) {
      queryParameters["$top"] = this.parseParams($Top);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<ErrorGroupOperatingSystems>("GET", localVarPath, options)
      .then((body: ErrorGroupOperatingSystems) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Latest error details.
   * @param errorGroupId The id of the error group
   */
  public errorsLatestErrorDetails(errorGroupId: string): Promise<HandledErrorDetails> {
    const localVarPath =
      this.getBasePath() +
      "/errors/errorGroups/{errorGroupId}/errors/latest".replace("{" + "errorGroupId" + "}", String(errorGroupId));

    // verify required parameter 'errorGroupId' is not null or undefined
    if (errorGroupId === null || errorGroupId === undefined) {
      throw new Error("Required parameter errorGroupId was null or undefined when calling errorsLatestErrorDetails.");
    }

    const queryParameters: any = {};

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<HandledErrorDetails>("GET", localVarPath, options)
      .then((body: HandledErrorDetails) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Get all errors for group
   * @param errorGroupId The id of the error group
   * @param start Start date time in data in ISO 8601 date time format
   * @param model
   * @param os
   * @param end Last date time in data in ISO 8601 date time format
   */
  public errorsListForGroup(errorGroupId: string, start: Date, model: string, os: string, end?: Date): Promise<HandledErrors> {
    const localVarPath =
      this.getBasePath() + "/errors/errorGroups/{errorGroupId}/errors".replace("{" + "errorGroupId" + "}", String(errorGroupId));

    // verify required parameter 'errorGroupId' is not null or undefined
    if (errorGroupId === null || errorGroupId === undefined) {
      throw new Error("Required parameter errorGroupId was null or undefined when calling errorsListForGroup.");
    }

    // verify required parameter 'start' is not null or undefined
    if (start === null || start === undefined) {
      throw new Error("Required parameter start was null or undefined when calling errorsListForGroup.");
    }

    // verify required parameter 'model' is not null or undefined
    if (model === null || model === undefined) {
      throw new Error("Required parameter model was null or undefined when calling errorsListForGroup.");
    }

    // verify required parameter 'os' is not null or undefined
    if (os === null || os === undefined) {
      throw new Error("Required parameter os was null or undefined when calling errorsListForGroup.");
    }

    const queryParameters: any = {};
    if (start !== undefined) {
      queryParameters["start"] = this.parseParams(start);
    }
    if (end !== undefined) {
      queryParameters["end"] = this.parseParams(end);
    }
    if (model !== undefined) {
      queryParameters["model"] = this.parseParams(model);
    }
    if (os !== undefined) {
      queryParameters["os"] = this.parseParams(os);
    }

    const options = {
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<HandledErrors>("GET", localVarPath, options)
      .then((body: HandledErrors) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
  /**
   *
   * Update error state
   * @param errorGroupId The id of the error group
   * @param errorGroupState The state of the error group
   */
  public errorsUpdateState(errorGroupId: string, errorGroupState: ErrorGroupState): Promise<ErrorGroup> {
    const localVarPath =
      this.getBasePath() + "/errors/errorGroups/{errorGroupId}".replace("{" + "errorGroupId" + "}", String(errorGroupId));

    // verify required parameter 'errorGroupId' is not null or undefined
    if (errorGroupId === null || errorGroupId === undefined) {
      throw new Error("Required parameter errorGroupId was null or undefined when calling errorsUpdateState.");
    }

    // verify required parameter 'errorGroupState' is not null or undefined
    if (errorGroupState === null || errorGroupState === undefined) {
      throw new Error("Required parameter errorGroupState was null or undefined when calling errorsUpdateState.");
    }

    const queryParameters: any = {};

    const options = {
      body: errorGroupState,
      params: queryParameters,
      responseType: "json",
      headers: Object.assign(this.defaultHeaders, {
        Accept: "application/json",
      }),
    };

    if (clientSettings.isWatsonApp && clientSettings.bypassBifrost) {
      options.headers["internal-app-platform"] = "UWP";
    }

    return apiGateway
      .call<ErrorGroup>("PATCH", localVarPath, options)
      .then((body: ErrorGroup) => {
        return body;
      })
      .catch(function (error: any) {
        throw error;
      });
  }
}
