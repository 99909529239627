import { Model } from "@root/data/lib/model";
import { observable, computed } from "mobx";
import { IDeserializedBuildInfo, IDistributionGroup, ISerializedBuildInfo } from "../types";

export interface SerializedBuild {
  id: number;
  app_name: string;
  app_display_name: string;
  app_os: string;
  version: string;
  short_version: string;
  uploaded_at: string;
  release_notes: string;
  size: number;
  min_os: string;
  app_icon_url: string;
  download_url: string;
  install_url: string;
  distribution_groups: IDistributionGroup[];
  package_hashes: string[];
  bundle_identifier: string;
  provisioning_profile_type: string;
  is_provisioning_profile_syncing: boolean;
  owner?: { name: string; display_name: string };
  is_udid_provisioned?: boolean;
  is_latest?: boolean;
  can_resign?: boolean;
  build?: ISerializedBuildInfo;
  is_external_build?: boolean;
}

export interface DeserializedBuild {
  id?: string;
  appName?: string;
  appDisplayName?: string;
  appOs?: string;
  appVersion?: string;
  buildNumber?: string;
  timestamp?: string;
  releaseNotes?: string;
  size?: number;
  minOsVersion?: string;
  iconLink?: string;
  downloadLink?: string;
  installLink?: string;
  distributionGroups?: IDistributionGroup[];
  packageHashes?: string[];
  bundleIdentifier?: string;
  provisioningProfileType?: string;
  isProvisioningProfileSyncing?: boolean;
  owner?: { name: string; display_name: string };
  isUdidProvisioned?: boolean;
  isLatest?: boolean;
  appIdentifier?: string;
  canResign?: boolean;
  build?: IDeserializedBuildInfo;
  isExternalBuild?: boolean;
}

export class Build extends Model<DeserializedBuild> implements DeserializedBuild {
  @observable public id?: string;
  @observable public appName?: string;
  @observable public appDisplayName?: string;
  @observable public appOs?: string;
  @observable public appVersion?: string;
  @observable public buildNumber?: string;
  @observable public timestamp?: string;
  @observable public releaseNotes?: string;
  @observable public size?: number;
  @observable public minOsVersion?: string;
  @observable public iconLink?: string;
  @observable public downloadLink?: string;
  @observable public installLink?: string;
  @observable public distributionGroups?: IDistributionGroup[];
  @observable public packageHashes?: string[];
  @observable public bundleIdentifier?: string;
  @observable public provisioningProfileType?: string;
  @observable public isProvisioningProfileSyncing?: boolean;
  @observable public owner?: { name: string; display_name: string };
  @observable public isUdidProvisioned?: boolean;
  @observable public isLatest?: boolean;
  public appIdentifier?: string;
  public canResign?: boolean;
  public isExternalBuild?: boolean;
  @observable public build?: IDeserializedBuildInfo;

  @computed get version(): string | undefined {
    if (this.appVersion && this.buildNumber) {
      return `${this.appVersion} (${this.buildNumber})`;
    } else if (this.appVersion && !this.buildNumber) {
      return `${this.appVersion}`;
    } else if (!this.appVersion && this.buildNumber) {
      return `${this.buildNumber}`;
    }
  }
}
