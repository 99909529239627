import { apiGateway } from "@root/lib/http";
import { computed } from "mobx";
import { sortBy } from "lodash";
import { Store } from "../../lib";
import { ForeignKey, TesterApp, DeserializedTesterApp, SerializedTesterApp } from "../models/tester-app";
import { DeserializedBuild } from "../models/build";
import { API } from "../constants";
import { getOsNameFromUserAgent } from "@root/lib/utils/user-agent";
import { locationStore } from "@root/stores/location-store";

export type TesterAppQueryOrOptions = {
  orgName?: string;
  ownerName?: string;
  appName?: string;
};

export class TesterAppStore extends Store<DeserializedTesterApp, SerializedTesterApp, TesterApp> {
  protected ModelClass = TesterApp;
  private sorty = (app: TesterApp) => app.display_name?.toLowerCase();

  @computed
  public get appsForCurrentOs() {
    return this.resources
      ? sortBy(
          this.resources.filter((app) => this.currentOs && app.os.toLocaleLowerCase().startsWith(this.currentOs.toLocaleLowerCase())),
          this.sorty
        )
      : [];
  }

  @computed
  public get appsForCurrentOsAndOrg() {
    return this.filterByOrg(this.appsForCurrentOs);
  }

  @computed
  public get appsForOtherOs() {
    if (!this.currentOs) {
      return this.resources || [];
    }

    return this.resources
      ? sortBy(
          this.resources.filter((app) => !app.os.toLocaleLowerCase().startsWith(this.currentOs.toLocaleLowerCase())),
          this.sorty
        )
      : [];
  }

  @computed
  public get appsForOtherOsAndOrg() {
    return this.filterByOrg(this.appsForOtherOs);
  }

  protected generateIdFromResponse(resource: SerializedTesterApp, query?: TesterAppQueryOrOptions) {
    return resource.id;
  }

  protected getModelId(model: TesterApp): string {
    return model.id;
  }

  protected getResource(_id: string, query?: TesterAppQueryOrOptions): Promise<SerializedTesterApp> {
    const params = {
      owner_name: query?.ownerName,
      app_name: query?.appName,
    };

    return apiGateway.get<SerializedTesterApp>(API.TESTER_APP, { params });
  }

  protected getCollection(
    query?: TesterAppQueryOrOptions,
    foreignKey?: ForeignKey,
    foreignKeyValue?: DeserializedTesterApp[ForeignKey]
  ): Promise<SerializedTesterApp[]> {
    return apiGateway.get<SerializedTesterApp[]>(API.TESTER_APPS);
  }

  protected deserialize(
    serialized: SerializedTesterApp,
    queryOrOptions?: TesterAppQueryOrOptions,
    foreignKey?: ForeignKey,
    foreignKeyValue?: DeserializedTesterApp[ForeignKey]
  ): DeserializedTesterApp {
    const deserializedTesterApp: DeserializedTesterApp = {
      id: serialized.id,
      name: serialized.name,
      display_name: serialized.display_name,
      description: serialized.description,
      icon_url: serialized.icon_url,
      os: serialized.os,
      owner: serialized.owner,
      permissions: serialized.permissions,
    };

    return !serialized.release
      ? deserializedTesterApp
      : {
          ...deserializedTesterApp,
          build: {
            id: serialized.release.id.toString(),
            buildNumber: serialized.release.version,
            appVersion: serialized.release.short_version,
            timestamp: serialized.release.uploaded_at,
            size: serialized.release.size,
          } as DeserializedBuild,
        };
  }

  private get currentOs(): string {
    return getOsNameFromUserAgent();
  }

  private get orgName(): string | undefined {
    if (locationStore.ownerType === "orgs") {
      return locationStore.ownerName;
    }

    return;
  }

  private filterByOrg(apps: TesterApp[]): TesterApp[] {
    if (!this.orgName) {
      return [];
    }

    return apps.filter((app) => app.isOrgApp && app.owner.name.toLowerCase() === this.orgName?.toLowerCase());
  }
}

export const testerAppStore = new TesterAppStore();
