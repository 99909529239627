/**
 * releases
 * The Distribution-Releases API Service
 *
 * OpenAPI spec version: 0.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { apiGateway } from "@root/lib/http";
import { ApiClientOptions } from "../api-client-options";

interface ApiMethodResult<T> {
  statusCode: number;
  body: T;
}

function getApiMethod<T>(httpMethod, path, payload, options: ApiClientOptions = {}): Promise<ApiMethodResult<T>> {
  if (options.timeout !== undefined) {
    console.warn("API Client timeout option is ignored.");
  }
  return apiGateway.call<T>(httpMethod, path, payload).then((body) => {
    return { statusCode: 200, body };
  });
}

// @ts-ignore
function stringifyParams(params: { [key: string]: any }): { [key: string]: string | string[] } {
  return Object.assign(
    {},
    ...Object.keys(params)
      .filter((key) => params[key])
      .map((key) => ({ [key]: params[key].toString() }))
  );
}

function validateSwaggerResult(model, result, operation, shouldIterate = true) {
  if (process.env.NODE_ENV !== "production") {
    const swagger = require("./swagger.json");
    const Validator = require("swagger-model-validator");
    // @ts-ignore
    const validator = new Validator(swagger);
    if (model) {
      if (model.startsWith("Api")) {
        model = model.substr(3);
      }
      const validation = [];
      if (shouldIterate && Array.isArray(result)) {
        result.forEach((r) => {
          const validationResult = swagger.validateModel(model, r, true, true);
          if (!validationResult.valid) {
            // @ts-ignore
            validation.push(validationResult.GetFormattedErrors());
          }
        });
      } else {
        const validationResult = swagger.validateModel(model, result, true, true);
        if (!validationResult.valid) {
          // @ts-ignore
          validation.push(validationResult.GetFormattedErrors());
        }
      }
      if (validation.length > 0) {
        console.warn("Swagger validation for model " + model + " failed for API call '" + operation + "'", result, validation);
      }
    }
  }
}

export interface ApiAnalyticsReleasesParameter {
  /**
   * release id
   */
  releaseId: number;
  /**
   * distribution group id
   */
  distributionGroupId: string;
  /**
   * user id
   */
  userId: string;
}

// @ts-ignore
function deserializeApiAnalyticsReleasesParameter(input: any): ApiAnalyticsReleasesParameter {
  return {
    releaseId: input["release_id"],
    distributionGroupId: input["distribution_group_id"],
    userId: input["user_id"],
  };
}

// @ts-ignore
function serializeApiAnalyticsReleasesParameter(input: ApiAnalyticsReleasesParameter): any {
  return {
    release_id: input["releaseId"],
    distribution_group_id: input["distributionGroupId"],
    user_id: input["userId"],
  };
}

export interface ApiAnalyticsReleasesResponse {
  releases?: ApiAnalyticsReleasesParameter[];
}

// @ts-ignore
function deserializeApiAnalyticsReleasesResponse(input: any): ApiAnalyticsReleasesResponse {
  return {
    releases: input["releases"]
      ? (input["releases"] as ApiAnalyticsReleasesParameter[]).map((val) => deserializeApiAnalyticsReleasesParameter(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiAnalyticsReleasesResponse(input: ApiAnalyticsReleasesResponse): any {
  return {
    releases: input["releases"]
      ? (input["releases"] as ApiAnalyticsReleasesParameter[]).map((val) => deserializeApiAnalyticsReleasesParameter(val))
      : undefined,
  };
}

/**
 * An object containing a UUID for an architecture for an iOS app.
 */

export interface ApiArchIdentifier {
  /**
   * The architecture that the UUID belongs to, i.e. armv7 or arm64.
   */
  architecture: string;
  /**
   * The unique identifier.
   */
  uuid: string;
}

// @ts-ignore
function deserializeApiArchIdentifier(input: any): ApiArchIdentifier {
  return {
    architecture: input["architecture"],
    uuid: input["uuid"],
  };
}

// @ts-ignore
function serializeApiArchIdentifier(input: ApiArchIdentifier): any {
  return {
    architecture: input["architecture"],
    uuid: input["uuid"],
  };
}

/**
 * A request containing information for creating a Auto Provisioning Config.
 */

export interface ApiAutoProvisioningConfigRequest {
  /**
   * A key to a secret in customer-credential-store. apple_developer_account refers to the user's developer account that is used to log into https://developer.apple.com. Normally the user's email.
   */
  appleDeveloperAccountKey?: string;
  /**
   * A key to a secret in customer-credential-store. distribution_certificate refers to the customer's certificate (that holds the private key) that will be used to sign the app.
   */
  appleDistributionCertificateKey?: string;
  /**
   * When *true* enables auto provisioning
   */
  allowAutoProvisioning?: boolean;
}

// @ts-ignore
function deserializeApiAutoProvisioningConfigRequest(input: any): ApiAutoProvisioningConfigRequest {
  return {
    appleDeveloperAccountKey: input["apple_developer_account_key"],
    appleDistributionCertificateKey: input["apple_distribution_certificate_key"],
    allowAutoProvisioning: input["allow_auto_provisioning"],
  };
}

// @ts-ignore
function serializeApiAutoProvisioningConfigRequest(input: ApiAutoProvisioningConfigRequest): any {
  return {
    apple_developer_account_key: input["appleDeveloperAccountKey"],
    apple_distribution_certificate_key: input["appleDistributionCertificateKey"],
    allow_auto_provisioning: input["allowAutoProvisioning"],
  };
}

/**
 * A response from API containing information for a Auto Provisioning Config.
 */

export interface ApiAutoProvisioningConfigResponse {
  /**
   * The identifier of the config.
   */
  id?: number;
  /**
   * The identifier of the App.
   */
  appId?: string;
  /**
   * The identifier of the destination.
   */
  destinationId?: string;
  /**
   * A key to a secret in customer-credential-store. apple_developer_account refers to the user's developer account that is used to log into https://developer.apple.com. Normally the user's email.
   */
  appleDeveloperAccountKey?: string;
  /**
   * A key to a secret in customer-credential-store. distribution_certificate refers to the cusomer's certificate (that holds the private key) that will be used to sign the app.
   */
  appleDistributionCertificateKey?: string;
  /**
   * When *true* enables auto provisioning
   */
  allowAutoProvisioning?: boolean;
}

// @ts-ignore
function deserializeApiAutoProvisioningConfigResponse(input: any): ApiAutoProvisioningConfigResponse {
  return {
    id: input["id"],
    appId: input["app_id"],
    destinationId: input["destination_id"],
    appleDeveloperAccountKey: input["apple_developer_account_key"],
    appleDistributionCertificateKey: input["apple_distribution_certificate_key"],
    allowAutoProvisioning: input["allow_auto_provisioning"],
  };
}

// @ts-ignore
function serializeApiAutoProvisioningConfigResponse(input: ApiAutoProvisioningConfigResponse): any {
  return {
    id: input["id"],
    app_id: input["appId"],
    destination_id: input["destinationId"],
    apple_developer_account_key: input["appleDeveloperAccountKey"],
    apple_distribution_certificate_key: input["appleDistributionCertificateKey"],
    allow_auto_provisioning: input["allowAutoProvisioning"],
  };
}

/**
 * Basic information on a release
 */

export interface ApiBasicReleaseDetailsResponse {
  /**
   * ID identifying this unique release.
   */
  id: number;
  /**
   * The release's version.<br> For iOS: CFBundleVersion from info.plist.<br> For Android: android:versionCode from AppManifest.xml.
   */
  version: string;
  /**
   * The release's origin
   */
  origin?: ApiBasicReleaseDetailsResponse.ApiOriginEnum;
  /**
   * The release's short version.<br> For iOS: CFBundleShortVersionString from info.plist.<br> For Android: android:versionName from AppManifest.xml.
   */
  shortVersion: string;
  /**
   * This value determines the whether a release currently is enabled or disabled.
   */
  enabled: boolean;
  /**
   * UTC time in ISO 8601 format of the uploaded time.
   */
  uploadedAt: string;
  /**
   * OBSOLETE. Will be removed in next version. The destination type.<br> <b>group</b>: The release distributed to internal groups and distribution_groups details will be returned.<br> <b>store</b>: The release distributed to external stores and distribution_stores details will be returned. <br>
   */
  destinationType?: ApiBasicReleaseDetailsResponse.ApiDestinationTypeEnum;
  /**
   * OBSOLETE. Will be removed in next version. A list of distribution groups that are associated with this release.
   */
  distributionGroups?: ApiDistributionGroup[];
  /**
   * OBSOLETE. Will be removed in next version. A list of distribution stores that are associated with this release.
   */
  distributionStores?: ApiDistributionStore[];
  /**
   * A list of distribution groups or stores.
   */
  destinations?: ApiDestination[];
  /**
   * Build information for the release
   */
  build?: ApiBuildInfo;
  /**
   * This value determines if a release is external or not.
   */
  isExternalBuild?: boolean;
  /**
   * The file extension of the main (user-uploaded) package file.
   */
  fileExtension?: string;
}

// @ts-ignore
function deserializeApiBasicReleaseDetailsResponse(input: any): ApiBasicReleaseDetailsResponse {
  return {
    id: input["id"],
    version: input["version"],
    origin: input["origin"],
    shortVersion: input["short_version"],
    enabled: input["enabled"],
    uploadedAt: input["uploaded_at"],
    destinationType: input["destination_type"],
    distributionGroups: input["distribution_groups"]
      ? (input["distribution_groups"] as ApiDistributionGroup[]).map((val) => deserializeApiDistributionGroup(val))
      : (undefined as any),
    distributionStores: input["distribution_stores"]
      ? (input["distribution_stores"] as ApiDistributionStore[]).map((val) => deserializeApiDistributionStore(val))
      : (undefined as any),
    destinations: input["destinations"]
      ? (input["destinations"] as ApiDestination[]).map((val) => deserializeApiDestination(val))
      : (undefined as any),
    build: input["build"],
    isExternalBuild: input["is_external_build"],
    fileExtension: input["file_extension"],
  };
}

// @ts-ignore
function serializeApiBasicReleaseDetailsResponse(input: ApiBasicReleaseDetailsResponse): any {
  return {
    id: input["id"],
    version: input["version"],
    origin: input["origin"],
    short_version: input["shortVersion"],
    enabled: input["enabled"],
    uploaded_at: input["uploadedAt"],
    destination_type: input["destinationType"],
    distribution_groups: input["distributionGroups"]
      ? (input["distributionGroups"] as ApiDistributionGroup[]).map((val) => deserializeApiDistributionGroup(val))
      : undefined,
    distribution_stores: input["distributionStores"]
      ? (input["distributionStores"] as ApiDistributionStore[]).map((val) => deserializeApiDistributionStore(val))
      : undefined,
    destinations: input["destinations"]
      ? (input["destinations"] as ApiDestination[]).map((val) => deserializeApiDestination(val))
      : undefined,
    build: input["build"],
    is_external_build: input["isExternalBuild"],
    file_extension: input["fileExtension"],
  };
}

export namespace ApiBasicReleaseDetailsResponse {
  export type ApiOriginEnum = "hockeyapp" | "appcenter";
  export type ApiDestinationTypeEnum = "group" | "store" | "tester";
}
/**
 * Contains metadata about the build that produced the release being uploaded
 */

export interface ApiBuildInfo {
  /**
   * The branch name of the build producing the release
   */
  branchName?: string;
  /**
   * The commit hash of the build producing the release
   */
  commitHash?: string;
  /**
   * The commit message of the build producing the release
   */
  commitMessage?: string;
}

// @ts-ignore
function deserializeApiBuildInfo(input: any): ApiBuildInfo {
  return {
    branchName: input["branch_name"],
    commitHash: input["commit_hash"],
    commitMessage: input["commit_message"],
  };
}

// @ts-ignore
function serializeApiBuildInfo(input: ApiBuildInfo): any {
  return {
    branch_name: input["branchName"],
    commit_hash: input["commitHash"],
    commit_message: input["commitMessage"],
  };
}

export interface ApiCreateReleaseUploadResponse {
  /**
   * The ID for the newly created upload. It is going to be required later in the process.
   */
  id: string;
  /**
   * The URL domain used to upload the release.
   */
  uploadDomain: string;
  /**
   * The access token used for upload permissions.
   */
  token: string;
  /**
   * The access token used for upload permissions (URL encoded to use as a single query parameter).
   */
  urlEncodedToken: string;
  /**
   * The associated asset ID in the file management service associated with this uploaded.
   */
  packageAssetId: string;
}

// @ts-ignore
function deserializeApiCreateReleaseUploadResponse(input: any): ApiCreateReleaseUploadResponse {
  return {
    id: input["id"],
    uploadDomain: input["upload_domain"],
    token: input["token"],
    urlEncodedToken: input["url_encoded_token"],
    packageAssetId: input["package_asset_id"],
  };
}

// @ts-ignore
function serializeApiCreateReleaseUploadResponse(input: ApiCreateReleaseUploadResponse): any {
  return {
    id: input["id"],
    upload_domain: input["uploadDomain"],
    token: input["token"],
    url_encoded_token: input["urlEncodedToken"],
    package_asset_id: input["packageAssetId"],
  };
}

export interface ApiDestinationError {
  /**
   * Error Codes:<br> <b>invalid_store_secrets</b>: While distributing to store, secrets provided for store are not valid.<br> <b>store_release_bad_request</b>: Proper package release details for the store is not provided.<br> <b>store_release_unauthorized</b>: User is not authorized to publish to store due to invalid developer credentials.<br> <b>store_release_forbidden</b>: Publish to store is forbidden due to conflicts/errors in the release version and already existing version in the store.<br> <b>store_release_promotion</b>: Release already distributed, promoting a release is not supported.<br> <b>store_track_deactivated</b>: One or more tracks would be deactivated with this release. This is not supported yet.<br> <b>store_release_not_found</b>: App with the given package name is not found in the store.<br> <b>store_release_not_available</b>: The release is not available.<br> <b>internal_server_error</b>: Failed to distribute to a destination due to an internal server error.
   */
  code?: string;
  message?: string;
  id?: string;
  name?: string;
}

// @ts-ignore
function deserializeApiDestinationError(input: any): ApiDestinationError {
  return {
    code: input["code"],
    message: input["message"],
    id: input["id"],
    name: input["name"],
  };
}

// @ts-ignore
function serializeApiDestinationError(input: ApiDestinationError): any {
  return {
    code: input["code"],
    message: input["message"],
    id: input["id"],
    name: input["name"],
  };
}

export interface ApiDestinationGroupUpdateDetails {
  /**
   * Whether a release is mandatory for the given destination
   */
  mandatoryUpdate: boolean;
}

// @ts-ignore
function deserializeApiDestinationGroupUpdateDetails(input: any): ApiDestinationGroupUpdateDetails {
  return {
    mandatoryUpdate: input["mandatory_update"],
  };
}

// @ts-ignore
function serializeApiDestinationGroupUpdateDetails(input: ApiDestinationGroupUpdateDetails): any {
  return {
    mandatory_update: input["mandatoryUpdate"],
  };
}

/**
 * A unique identifier for a destination. A destination can be identified by an ID (guid) or by a name. DestinationId encapsulates both options. A destination can be either a distribution group or a store.
 */

export interface ApiDestinationId {
  /**
   * Name of a distribution group / distribution store. The release will be associated with this distribution group or store. If the distribution group / store doesn't exist a 400 is returned. If both distribution group / store name and id are passed, the id is taking precedence.
   */
  name?: string;
  /**
   * Id of a distribution group / store. The release will be associated with this distribution group / store. If the distribution group / store doesn't exist a 400 is returned. If both distribution group / store name and id are passed, the id is taking precedence.
   */
  id?: string;
}

// @ts-ignore
function deserializeApiDestinationId(input: any): ApiDestinationId {
  return {
    name: input["name"],
    id: input["id"],
  };
}

// @ts-ignore
function serializeApiDestinationId(input: ApiDestinationId): any {
  return {
    name: input["name"],
    id: input["id"],
  };
}

export interface ApiDestinationTesterUpdateDetails {
  /**
   * Whether a release is mandatory for the given destination
   */
  mandatoryUpdate: boolean;
}

// @ts-ignore
function deserializeApiDestinationTesterUpdateDetails(input: any): ApiDestinationTesterUpdateDetails {
  return {
    mandatoryUpdate: input["mandatory_update"],
  };
}

// @ts-ignore
function serializeApiDestinationTesterUpdateDetails(input: ApiDestinationTesterUpdateDetails): any {
  return {
    mandatory_update: input["mandatoryUpdate"],
  };
}

/**
 * Response for getting a list of releases in a distribution group
 */

export interface ApiDistributionGroupRelease {
  /**
   * ID identifying this unique release.
   */
  id: number;
  /**
   * The release's version.<br> For iOS: CFBundleVersion from info.plist.<br> For Android: android:versionCode from AppManifest.xml.
   */
  version: string;
  /**
   * The release's origin
   */
  origin?: ApiDistributionGroupRelease.ApiOriginEnum;
  /**
   * The release's short version.<br> For iOS: CFBundleShortVersionString from info.plist.<br> For Android: android:versionName from AppManifest.xml.
   */
  shortVersion: string;
  /**
   * A boolean which determines whether the release is a mandatory update or not.
   */
  mandatoryUpdate: boolean;
  /**
   * UTC time in ISO 8601 format of the uploaded time.
   */
  uploadedAt: string;
  /**
   * This value determines the whether a release currently is enabled or disabled.
   */
  enabled: boolean;
  /**
   * This value determines if a release is external or not.
   */
  isExternalBuild?: boolean;
}

// @ts-ignore
function deserializeApiDistributionGroupRelease(input: any): ApiDistributionGroupRelease {
  return {
    id: input["id"],
    version: input["version"],
    origin: input["origin"],
    shortVersion: input["short_version"],
    mandatoryUpdate: input["mandatory_update"],
    uploadedAt: input["uploaded_at"],
    enabled: input["enabled"],
    isExternalBuild: input["is_external_build"],
  };
}

// @ts-ignore
function serializeApiDistributionGroupRelease(input: ApiDistributionGroupRelease): any {
  return {
    id: input["id"],
    version: input["version"],
    origin: input["origin"],
    short_version: input["shortVersion"],
    mandatory_update: input["mandatoryUpdate"],
    uploaded_at: input["uploadedAt"],
    enabled: input["enabled"],
    is_external_build: input["isExternalBuild"],
  };
}

export namespace ApiDistributionGroupRelease {
  export type ApiOriginEnum = "hockeyapp" | "appcenter";
}
/**
 * Response for getting a list of releases in a distribution group
 */

export interface ApiDistributionGroupReleasesResponse extends Array<ApiDistributionGroupRelease> {}

// @ts-ignore
function deserializeApiDistributionGroupReleasesResponse(input: any): ApiDistributionGroupReleasesResponse {
  return input.map((item) => deserializeApiDistributionGroupRelease(item));
}

// @ts-ignore
function serializeApiDistributionGroupReleasesResponse(input: ApiDistributionGroupReleasesResponse): any {
  return input.map((item) => serializeApiDistributionGroupRelease(item));
}

export interface ApiDistributionGroupWithoutIsLatest {
  /**
   * ID identifying a unique distribution group.
   */
  id: string;
  /**
   * A name identifying a unique distribution group.
   */
  name?: string;
}

// @ts-ignore
function deserializeApiDistributionGroupWithoutIsLatest(input: any): ApiDistributionGroupWithoutIsLatest {
  return {
    id: input["id"],
    name: input["name"],
  };
}

// @ts-ignore
function serializeApiDistributionGroupWithoutIsLatest(input: ApiDistributionGroupWithoutIsLatest): any {
  return {
    id: input["id"],
    name: input["name"],
  };
}

export interface ApiDistributionSettingsResponse {
  /**
   * Is this app using the \"Default to Public Distribution Groups\" logic
   */
  defaultPublic: boolean;
}

// @ts-ignore
function deserializeApiDistributionSettingsResponse(input: any): ApiDistributionSettingsResponse {
  return {
    defaultPublic: input["default_public"],
  };
}

// @ts-ignore
function serializeApiDistributionSettingsResponse(input: ApiDistributionSettingsResponse): any {
  return {
    default_public: input["defaultPublic"],
  };
}

export interface ApiDistributionStoreWithoutIsLatest {
  /**
   * ID identifying a unique distribution store.
   */
  id: string;
  /**
   * A name identifying a unique distribution store.
   */
  name?: string;
  /**
   * type of the distribution store currently stores type can be intune, googleplay or windows.
   */
  type?: ApiDistributionStoreWithoutIsLatest.ApiTypeEnum;
  /**
   * publishing status of the release in the store.
   */
  publishingStatus?: string;
}

// @ts-ignore
function deserializeApiDistributionStoreWithoutIsLatest(input: any): ApiDistributionStoreWithoutIsLatest {
  return {
    id: input["id"],
    name: input["name"],
    type: input["type"],
    publishingStatus: input["publishing_status"],
  };
}

// @ts-ignore
function serializeApiDistributionStoreWithoutIsLatest(input: ApiDistributionStoreWithoutIsLatest): any {
  return {
    id: input["id"],
    name: input["name"],
    type: input["type"],
    publishing_status: input["publishingStatus"],
  };
}

export namespace ApiDistributionStoreWithoutIsLatest {
  export type ApiTypeEnum = "intune" | "googleplay" | "apple" | "none";
}

export interface ApiErrorDetails {
  /**
   * Error codes:<br> <b>not_found</b>: An app, a release or a distribution group can't be found.<br> <b>forbidden</b>: This operation is not allowed by App Center API. It may happen since the permission problem or trying to delete a release from a migrated HockeyApp distribution group through App cCnter api.<br> <b>no_upload_resource</b>: The upload resource ID doesn't match any upload resource <br> <b>release_not_uploaded</b>: Trying to commit a release before it is uploaded. <br> <b>filter_error</b>: A filter query string parameter is incorrect or not supported. <br> <b>internal_server_error</b>: An unknown internal error.<br> <b>not_supported</b>: An operation is not supported.<br> <b>no_releases_for_app</b>: Couldn't get a release because there are no releases for this app.<br> <b>no_releases_for_user</b>: Couldn't get a release because there are no releases for this user.<br> <b>bad_request</b>: One of the parameters is incorrect.<br> <b>distribution_group_not_found</b>: Distribution group or the app doesn't exist.<br> <b>not_implemented</b>: Request not implemented by this service.<br> <b>partially_deleted</b>: Deleted just part of a resource.<br> <b>partially_succeeded</b>: Partial succeeded. Look at the HTTP body to identify which part has failed.<br> <b>not_ready</b>: The requested resource is not ready. Try again later.<br> <b>dependent_service_error</b>: The dependent service can't be found.<br> <b>upload_not_found</b>: The upload can't be found.<br>
   */
  code: ApiErrorDetails.ApiCodeEnum;
  message: string;
}

// @ts-ignore
function deserializeApiErrorDetails(input: any): ApiErrorDetails {
  return {
    code: input["code"],
    message: input["message"],
  };
}

// @ts-ignore
function serializeApiErrorDetails(input: ApiErrorDetails): any {
  return {
    code: input["code"],
    message: input["message"],
  };
}

export namespace ApiErrorDetails {
  export type ApiCodeEnum =
    | "not_found"
    | "conflict"
    | "forbidden"
    | "release_not_found"
    | "no_upload_resource"
    | "release_not_uploaded"
    | "filter_error"
    | "internal_server_error"
    | "not_supported"
    | "no_releases_for_app"
    | "no_releases_for_user"
    | "bad_request"
    | "distribution_group_not_found"
    | "not_implemented"
    | "partially_deleted"
    | "partially_succeeded"
    | "not_ready"
    | "upload_not_found";
}
/**
 * A request containing information for creating an externally hosted release.
 */

export interface ApiExternallyHostedReleaseCreateRequest {
  /**
   * The build version of the uploaded binary
   */
  buildVersion: string;
  /**
   * The build number of the uploaded binary
   */
  buildNumber?: string;
  /**
   * The external URL to the release's binary.
   */
  externalDownloadUrl: string;
}

// @ts-ignore
function deserializeApiExternallyHostedReleaseCreateRequest(input: any): ApiExternallyHostedReleaseCreateRequest {
  return {
    buildVersion: input["build_version"],
    buildNumber: input["build_number"],
    externalDownloadUrl: input["external_download_url"],
  };
}

// @ts-ignore
function serializeApiExternallyHostedReleaseCreateRequest(input: ApiExternallyHostedReleaseCreateRequest): any {
  return {
    build_version: input["buildVersion"],
    build_number: input["buildNumber"],
    external_download_url: input["externalDownloadUrl"],
  };
}

/**
 * A request containing a set of release ids to validate
 */

export interface ApiGDPRValidationRequest {
  /**
   * a list of release ids to validate (AC flow)
   */
  releaseIds: number[];
  /**
   * a list of release ids to validate (HA flow)
   */
  hockeyappReleaseIds?: number[];
  /**
   * a list of release uploads ids to validate
   */
  releaseUploadIds?: string[];
}

// @ts-ignore
function deserializeApiGDPRValidationRequest(input: any): ApiGDPRValidationRequest {
  return {
    releaseIds: input["release_ids"] ? (input["release_ids"] as number[]).map((val) => val) : (undefined as any),
    hockeyappReleaseIds: input["hockeyapp_release_ids"]
      ? (input["hockeyapp_release_ids"] as number[]).map((val) => val)
      : (undefined as any),
    releaseUploadIds: input["release_upload_ids"] ? (input["release_upload_ids"] as string[]).map((val) => val) : (undefined as any),
  };
}

// @ts-ignore
function serializeApiGDPRValidationRequest(input: ApiGDPRValidationRequest): any {
  return {
    release_ids: input["releaseIds"] ? (input["releaseIds"] as number[]).map((val) => val) : undefined,
    hockeyapp_release_ids: input["hockeyappReleaseIds"] ? (input["hockeyappReleaseIds"] as number[]).map((val) => val) : undefined,
    release_upload_ids: input["releaseUploadIds"] ? (input["releaseUploadIds"] as string[]).map((val) => val) : undefined,
  };
}

export interface ApiGetReleaseStatusResponse {
  /**
   * The ID for the upload.
   */
  id: string;
  /**
   * The current upload status.
   */
  uploadStatus: ApiGetReleaseStatusResponse.ApiUploadStatusEnum;
  /**
   * Details describing what went wrong processing the upload. Will only be set if status = 'error'.
   */
  errorDetails?: string;
  /**
   * The distinct ID of the release. Will only be set when the status = 'readyToBePublished'.
   */
  releaseDistinctId?: number;
  /**
   * The URL of the release. Will only be set when the status = 'readyToBePublished'.
   */
  releaseUrl?: any;
}

// @ts-ignore
function deserializeApiGetReleaseStatusResponse(input: any): ApiGetReleaseStatusResponse {
  return {
    id: input["id"],
    uploadStatus: input["upload_status"],
    errorDetails: input["error_details"],
    releaseDistinctId: input["release_distinct_id"],
    releaseUrl: input["release_url"],
  };
}

// @ts-ignore
function serializeApiGetReleaseStatusResponse(input: ApiGetReleaseStatusResponse): any {
  return {
    id: input["id"],
    upload_status: input["uploadStatus"],
    error_details: input["errorDetails"],
    release_distinct_id: input["releaseDistinctId"],
    release_url: input["releaseUrl"],
  };
}

export namespace ApiGetReleaseStatusResponse {
  export type ApiUploadStatusEnum = "uploadStarted" | "uploadFinished" | "readyToBePublished" | "malwareDetected" | "error";
}

export interface ApiHockeyAppCompatibilityReleaseResponse {
  id?: number;
  shortversion?: string;
  version?: string;
  uploadedAt?: string;
  appsize?: number;
  notes?: string;
  mandatory?: boolean;
  external?: boolean;
  deviceFamily?: string;
  minimumOsVersion?: string;
  installUrl?: string;
  bundleIdentifier?: string;
}

// @ts-ignore
function deserializeApiHockeyAppCompatibilityReleaseResponse(input: any): ApiHockeyAppCompatibilityReleaseResponse {
  return {
    id: input["id"],
    shortversion: input["shortversion"],
    version: input["version"],
    uploadedAt: input["uploaded_at"],
    appsize: input["appsize"],
    notes: input["notes"],
    mandatory: input["mandatory"],
    external: input["external"],
    deviceFamily: input["device_family"],
    minimumOsVersion: input["minimum_os_version"],
    installUrl: input["install_url"],
    bundleIdentifier: input["bundle_identifier"],
  };
}

// @ts-ignore
function serializeApiHockeyAppCompatibilityReleaseResponse(input: ApiHockeyAppCompatibilityReleaseResponse): any {
  return {
    id: input["id"],
    shortversion: input["shortversion"],
    version: input["version"],
    uploaded_at: input["uploadedAt"],
    appsize: input["appsize"],
    notes: input["notes"],
    mandatory: input["mandatory"],
    external: input["external"],
    device_family: input["deviceFamily"],
    minimum_os_version: input["minimumOsVersion"],
    install_url: input["installUrl"],
    bundle_identifier: input["bundleIdentifier"],
  };
}

/**
 * Malware scan result from Malware Scan service
 */

export interface ApiMalwareScanResultPayload {
  /**
   * Scan request identifier
   */
  requestId: string;
  /**
   * Scan result
   */
  result: ApiMalwareScanResultPayload.ApiResultEnum;
}

// @ts-ignore
function deserializeApiMalwareScanResultPayload(input: any): ApiMalwareScanResultPayload {
  return {
    requestId: input["requestId"],
    result: input["result"],
  };
}

// @ts-ignore
function serializeApiMalwareScanResultPayload(input: ApiMalwareScanResultPayload): any {
  return {
    requestId: input["requestId"],
    result: input["result"],
  };
}

export namespace ApiMalwareScanResultPayload {
  export type ApiResultEnum = "clean" | "infected" | "error";
}
/**
 * Details of an uploaded release
 */

export interface ApiManagementReleaseDetailsResponse {
  /**
   * ID identifying this unique release.
   */
  distinctId?: number;
  /**
   * The release's origin
   */
  origin?: ApiManagementReleaseDetailsResponse.ApiOriginEnum;
  /**
   * The release's short version.<br> For iOS: CFBundleShortVersionString from info.plist.<br> For Android: android:versionName from AppManifest.xml.
   */
  version?: string;
  /**
   * The release's buildVersion.<br> For iOS: CFBundleVersion from info.plist.<br> For Android: android:versionCode from AppManifest.xml.
   */
  buildVersion?: string;
  /**
   * The release's sortVersion.
   */
  sortVersion?: string;
  /**
   * UTC time the release was created in ISO 8601 format.
   */
  createdAt?: string;
  /**
   * This value determines the whether a release currently is enabled or disabled.
   */
  enabled?: boolean;
  /**
   * UTC time the release was created in ISO 8601 format.
   */
  deletedAt?: string;
}

// @ts-ignore
function deserializeApiManagementReleaseDetailsResponse(input: any): ApiManagementReleaseDetailsResponse {
  return {
    distinctId: input["distinctId"],
    origin: input["origin"],
    version: input["version"],
    buildVersion: input["buildVersion"],
    sortVersion: input["sortVersion"],
    createdAt: input["createdAt"],
    enabled: input["enabled"],
    deletedAt: input["deletedAt"],
  };
}

// @ts-ignore
function serializeApiManagementReleaseDetailsResponse(input: ApiManagementReleaseDetailsResponse): any {
  return {
    distinctId: input["distinctId"],
    origin: input["origin"],
    version: input["version"],
    buildVersion: input["buildVersion"],
    sortVersion: input["sortVersion"],
    createdAt: input["createdAt"],
    enabled: input["enabled"],
    deletedAt: input["deletedAt"],
  };
}

export namespace ApiManagementReleaseDetailsResponse {
  export type ApiOriginEnum = "hockeyapp" | "appcenter";
}
/**
 * Patch the asset id of a release request body
 */

export interface ApiPatchReleaseAssetIdRequest {
  /**
   * The release new package id in ACFUS
   */
  packageAssetId: string;
  /**
   * The release upload id used to upload the release
   */
  uploadId: string;
  /**
   * The ipa UUIDs for this release, as a serialized JSON array
   */
  ipaUuids?: string;
}

// @ts-ignore
function deserializeApiPatchReleaseAssetIdRequest(input: any): ApiPatchReleaseAssetIdRequest {
  return {
    packageAssetId: input["package_asset_id"],
    uploadId: input["upload_id"],
    ipaUuids: input["ipa_uuids"],
  };
}

// @ts-ignore
function serializeApiPatchReleaseAssetIdRequest(input: ApiPatchReleaseAssetIdRequest): any {
  return {
    package_asset_id: input["packageAssetId"],
    upload_id: input["uploadId"],
    ipa_uuids: input["ipaUuids"],
  };
}

export interface ApiPatchReleaseUploadStatusRequest {
  /**
   * The new status of the release upload
   */
  uploadStatus: ApiPatchReleaseUploadStatusRequest.ApiUploadStatusEnum;
}

// @ts-ignore
function deserializeApiPatchReleaseUploadStatusRequest(input: any): ApiPatchReleaseUploadStatusRequest {
  return {
    uploadStatus: input["upload_status"],
  };
}

// @ts-ignore
function serializeApiPatchReleaseUploadStatusRequest(input: ApiPatchReleaseUploadStatusRequest): any {
  return {
    upload_status: input["uploadStatus"],
  };
}

export namespace ApiPatchReleaseUploadStatusRequest {
  export type ApiUploadStatusEnum = "uploadFinished" | "uploadCanceled";
}

export interface ApiPatchReleaseUploadStatusResponse {
  /**
   * The ID for the upload.
   */
  id: string;
  /**
   * The current upload status.
   */
  uploadStatus: ApiPatchReleaseUploadStatusResponse.ApiUploadStatusEnum;
}

// @ts-ignore
function deserializeApiPatchReleaseUploadStatusResponse(input: any): ApiPatchReleaseUploadStatusResponse {
  return {
    id: input["id"],
    uploadStatus: input["upload_status"],
  };
}

// @ts-ignore
function serializeApiPatchReleaseUploadStatusResponse(input: ApiPatchReleaseUploadStatusResponse): any {
  return {
    id: input["id"],
    upload_status: input["uploadStatus"],
  };
}

export namespace ApiPatchReleaseUploadStatusResponse {
  export type ApiUploadStatusEnum =
    | "uploadStarted"
    | "uploadFinished"
    | "uploadCanceled"
    | "readyToBePublished"
    | "malwareDetected"
    | "error";
}

export interface ApiPostCreateReleaseUploadRequest {
  /**
   * User defined build version
   */
  buildVersion?: string;
  /**
   * User defined build number
   */
  buildNumber?: string;
}

// @ts-ignore
function deserializeApiPostCreateReleaseUploadRequest(input: any): ApiPostCreateReleaseUploadRequest {
  return {
    buildVersion: input["build_version"],
    buildNumber: input["build_number"],
  };
}

// @ts-ignore
function serializeApiPostCreateReleaseUploadRequest(input: ApiPostCreateReleaseUploadRequest): any {
  return {
    build_version: input["buildVersion"],
    build_number: input["buildNumber"],
  };
}

/**
 * Set the download URL of an external build
 */

export interface ApiPostExternalDownloadUrl {
  /**
   * The new download URL
   */
  downloadUrl?: string;
}

// @ts-ignore
function deserializeApiPostExternalDownloadUrl(input: any): ApiPostExternalDownloadUrl {
  return {
    downloadUrl: input["download_url"],
  };
}

// @ts-ignore
function serializeApiPostExternalDownloadUrl(input: ApiPostExternalDownloadUrl): any {
  return {
    download_url: input["downloadUrl"],
  };
}

/**
 * Set additional details for the placeholder ReleaseUpload that will be scheduled for delete.
 */

export interface ApiPostPlaceholderReleaseUploadForDeleteRequest {
  /**
   * Additional details to identify why the placeholder ReleaseUpload is being created.
   */
  errorDetails?: string;
}

// @ts-ignore
function deserializeApiPostPlaceholderReleaseUploadForDeleteRequest(input: any): ApiPostPlaceholderReleaseUploadForDeleteRequest {
  return {
    errorDetails: input["error_details"],
  };
}

// @ts-ignore
function serializeApiPostPlaceholderReleaseUploadForDeleteRequest(input: ApiPostPlaceholderReleaseUploadForDeleteRequest): any {
  return {
    error_details: input["errorDetails"],
  };
}

/**
 * Basic information on a release for private apis
 */

export interface ApiPrivateBasicReleaseDetailsResponse {
  /**
   * ID identifying this unique release.
   */
  id?: number;
  /**
   * The release's version.<br> For iOS: CFBundleVersion from info.plist.<br> For Android: android:versionCode from AppManifest.xml.
   */
  version?: string;
  /**
   * The release's origin
   */
  origin?: ApiPrivateBasicReleaseDetailsResponse.ApiOriginEnum;
  /**
   * The release's short version.<br> For iOS: CFBundleShortVersionString from info.plist.<br> For Android: android:versionName from AppManifest.xml.
   */
  shortVersion?: string;
  /**
   * UTC time in ISO 8601 format of the uploaded time.
   */
  uploadedAt?: string;
  /**
   * the destination id of release where it is distributed.
   */
  distributionGroupId?: string;
  /**
   * The destination type.<br> <b>group</b>: The release distributed to internal groups and distribution_groups details will be returned.<br> <b>store</b>: The release distributed to external stores and distribution_stores details will be returned. <br>
   */
  destinationType?: ApiPrivateBasicReleaseDetailsResponse.ApiDestinationTypeEnum;
  /**
   * Indicates if this is the latest release in the group.
   */
  isLatest?: boolean;
  /**
   * A boolean which determines whether the release is a mandatory update or not.
   */
  mandatoryUpdate?: boolean;
  /**
   * the publishing status of the distributed release
   */
  publishingStatus?: string;
  /**
   * This value determines if a release is external or not.
   */
  isExternalBuild?: boolean;
}

// @ts-ignore
function deserializeApiPrivateBasicReleaseDetailsResponse(input: any): ApiPrivateBasicReleaseDetailsResponse {
  return {
    id: input["id"],
    version: input["version"],
    origin: input["origin"],
    shortVersion: input["short_version"],
    uploadedAt: input["uploaded_at"],
    distributionGroupId: input["distribution_group_id"],
    destinationType: input["destination_type"],
    isLatest: input["is_latest"],
    mandatoryUpdate: input["mandatory_update"],
    publishingStatus: input["publishing_status"],
    isExternalBuild: input["is_external_build"],
  };
}

// @ts-ignore
function serializeApiPrivateBasicReleaseDetailsResponse(input: ApiPrivateBasicReleaseDetailsResponse): any {
  return {
    id: input["id"],
    version: input["version"],
    origin: input["origin"],
    short_version: input["shortVersion"],
    uploaded_at: input["uploadedAt"],
    distribution_group_id: input["distributionGroupId"],
    destination_type: input["destinationType"],
    is_latest: input["isLatest"],
    mandatory_update: input["mandatoryUpdate"],
    publishing_status: input["publishingStatus"],
    is_external_build: input["isExternalBuild"],
  };
}

export namespace ApiPrivateBasicReleaseDetailsResponse {
  export type ApiOriginEnum = "hockeyapp" | "appcenter";
  export type ApiDestinationTypeEnum = "group" | "store" | "tester";
}
/**
 * Details of an uploaded release
 */

export interface ApiPrivateReleaseDetailsResponse {
  /**
   * ID identifying this unique release.
   */
  id?: number;
  /**
   * OBSOLETE. Will be removed in next version. The availability concept is now replaced with distributed. Any 'available' release will be associated with the default distribution group of an app.</br> The release state.<br> <b>available</b>: The uploaded release has been distributed.<br> <b>unavailable</b>: The uploaded release is not visible to the user. <br>
   */
  status?: ApiPrivateReleaseDetailsResponse.ApiStatusEnum;
  /**
   * The app's name (extracted from the uploaded release).
   */
  appName?: string;
  /**
   * The app's display name.
   */
  appDisplayName?: string;
  /**
   * The release's version.<br> For iOS: CFBundleVersion from info.plist. For Android: android:versionCode from AppManifest.xml.
   */
  version?: string;
  /**
   * The release's origin
   */
  origin?: ApiPrivateReleaseDetailsResponse.ApiOriginEnum;
  /**
   * The release's short version.<br> For iOS: CFBundleShortVersionString from info.plist. For Android: android:versionName from AppManifest.xml.
   */
  shortVersion?: string;
  /**
   * The release's release notes.
   */
  releaseNotes?: string;
  /**
   * The release's provisioning profile name.
   */
  provisioningProfileName?: string;
  /**
   * The type of the provisioning profile for the requested app version.
   */
  provisioningProfileType?: ApiPrivateReleaseDetailsResponse.ApiProvisioningProfileTypeEnum;
  /**
   * A flag that determines whether the release's provisioning profile is still extracted or not.
   */
  isProvisioningProfileSyncing?: boolean;
  /**
   * The release's size in bytes.
   */
  size?: number;
  /**
   * The release's minimum required operating system.
   */
  minOs?: string;
  /**
   * The release's device family.
   */
  deviceFamily?: string;
  /**
   * The release's minimum required Android API level.
   */
  androidMinApiLevel?: string;
  /**
   * The identifier of the apps bundle.
   */
  bundleIdentifier?: string;
  /**
   * MD5 checksum of the release binary.
   */
  fingerprint?: string;
  /**
   * UTC time in ISO 8601 format of the uploaded time.
   */
  uploadedAt?: string;
  /**
   * The URL that hosts the binary for this release.
   */
  downloadUrl?: string;
  /**
   * The URL that hosts the secondary binary for this release, such as the apk file for aab releases.
   */
  secondaryDownloadUrl?: string;
  /**
   * A URL to the app's icon.
   */
  appIconUrl?: string;
  /**
   * The href required to install a release on a mobile device. On iOS devices will be prefixed with `itms-services://?action=download-manifest&url=`
   */
  installUrl?: string;
  /**
   * the destination where release is distributed
   */
  distributionGroupId?: string;
  /**
   * the publishing status of the distributed release
   */
  publishingStatus?: string;
  /**
   * The destination type.<br> <b>group</b>: The release distributed to internal groups and distribution_groups details will be returned.<br> <b>store</b>: The release distributed to external stores and distribution_stores details will be returned. <br>
   */
  destinationType?: ApiPrivateReleaseDetailsResponse.ApiDestinationTypeEnum;
  /**
   * This value determines if a release is external or not.
   */
  isExternalBuild?: boolean;
}

// @ts-ignore
function deserializeApiPrivateReleaseDetailsResponse(input: any): ApiPrivateReleaseDetailsResponse {
  return {
    id: input["id"],
    status: input["status"],
    appName: input["app_name"],
    appDisplayName: input["app_display_name"],
    version: input["version"],
    origin: input["origin"],
    shortVersion: input["short_version"],
    releaseNotes: input["release_notes"],
    provisioningProfileName: input["provisioning_profile_name"],
    provisioningProfileType: input["provisioning_profile_type"],
    isProvisioningProfileSyncing: input["is_provisioning_profile_syncing"],
    size: input["size"],
    minOs: input["min_os"],
    deviceFamily: input["device_family"],
    androidMinApiLevel: input["android_min_api_level"],
    bundleIdentifier: input["bundle_identifier"],
    fingerprint: input["fingerprint"],
    uploadedAt: input["uploaded_at"],
    downloadUrl: input["download_url"],
    secondaryDownloadUrl: input["secondary_download_url"],
    appIconUrl: input["app_icon_url"],
    installUrl: input["install_url"],
    distributionGroupId: input["distribution_group_id"],
    publishingStatus: input["publishing_status"],
    destinationType: input["destination_type"],
    isExternalBuild: input["is_external_build"],
  };
}

// @ts-ignore
function serializeApiPrivateReleaseDetailsResponse(input: ApiPrivateReleaseDetailsResponse): any {
  return {
    id: input["id"],
    status: input["status"],
    app_name: input["appName"],
    app_display_name: input["appDisplayName"],
    version: input["version"],
    origin: input["origin"],
    short_version: input["shortVersion"],
    release_notes: input["releaseNotes"],
    provisioning_profile_name: input["provisioningProfileName"],
    provisioning_profile_type: input["provisioningProfileType"],
    is_provisioning_profile_syncing: input["isProvisioningProfileSyncing"],
    size: input["size"],
    min_os: input["minOs"],
    device_family: input["deviceFamily"],
    android_min_api_level: input["androidMinApiLevel"],
    bundle_identifier: input["bundleIdentifier"],
    fingerprint: input["fingerprint"],
    uploaded_at: input["uploadedAt"],
    download_url: input["downloadUrl"],
    secondary_download_url: input["secondaryDownloadUrl"],
    app_icon_url: input["appIconUrl"],
    install_url: input["installUrl"],
    distribution_group_id: input["distributionGroupId"],
    publishing_status: input["publishingStatus"],
    destination_type: input["destinationType"],
    is_external_build: input["isExternalBuild"],
  };
}

export namespace ApiPrivateReleaseDetailsResponse {
  export type ApiStatusEnum = "available" | "unavailable";
  export type ApiOriginEnum = "hockeyapp" | "appcenter";
  export type ApiProvisioningProfileTypeEnum = "adhoc" | "enterprise" | "other";
  export type ApiDestinationTypeEnum = "group" | "store" | "tester";
}
/**
 * A request containing information for updating a release.
 */

export interface ApiPrivateReleaseUpdateRequest {
  /**
   * The store publishing status.
   */
  publishingStatus?: ApiPrivateReleaseUpdateRequest.ApiPublishingStatusEnum;
}

// @ts-ignore
function deserializeApiPrivateReleaseUpdateRequest(input: any): ApiPrivateReleaseUpdateRequest {
  return {
    publishingStatus: input["publishing_status"],
  };
}

// @ts-ignore
function serializeApiPrivateReleaseUpdateRequest(input: ApiPrivateReleaseUpdateRequest): any {
  return {
    publishing_status: input["publishingStatus"],
  };
}

export namespace ApiPrivateReleaseUpdateRequest {
  export type ApiPublishingStatusEnum = "failed" | "processing" | "submitted" | "timeout";
}
/**
 * Details of the upload to patch
 */

export interface ApiPrivateUpdateUploadDetails {
  status: ApiPrivateUpdateUploadDetails.ApiStatusEnum;
  /**
   * Message of the error
   */
  errorMessage: string;
}

// @ts-ignore
function deserializeApiPrivateUpdateUploadDetails(input: any): ApiPrivateUpdateUploadDetails {
  return {
    status: input["status"],
    errorMessage: input["error_message"],
  };
}

// @ts-ignore
function serializeApiPrivateUpdateUploadDetails(input: ApiPrivateUpdateUploadDetails): any {
  return {
    status: input["status"],
    error_message: input["errorMessage"],
  };
}

export namespace ApiPrivateUpdateUploadDetails {
  export type ApiStatusEnum = "uploadStarted" | "uploadFinished" | "readyToBePublished" | "malwareDetected" | "error";
}
/**
 * An object containing information about an iOS provisioning profile.
 */

export interface ApiProvisioningProfile {
  /**
   * The name of the provisioning profile.
   */
  name: string;
  /**
   * The application identifier.
   */
  applicationIdentifier: string;
  /**
   * The team identifier.
   */
  teamIdentifier: string;
  profileType: ApiProvisioningProfile.ApiProfileTypeEnum;
  /**
   * The profile's expiration date in RFC 3339 format, i.e. 2017-07-21T17:32:28Z
   */
  expiredAt: string;
  udids?: string[];
}

// @ts-ignore
function deserializeApiProvisioningProfile(input: any): ApiProvisioningProfile {
  return {
    name: input["name"],
    applicationIdentifier: input["application_identifier"],
    teamIdentifier: input["team_identifier"],
    profileType: input["profile_type"],
    expiredAt: input["expired_at"],
    udids: input["udids"] ? (input["udids"] as string[]).map((val) => val) : (undefined as any),
  };
}

// @ts-ignore
function serializeApiProvisioningProfile(input: ApiProvisioningProfile): any {
  return {
    name: input["name"],
    application_identifier: input["applicationIdentifier"],
    team_identifier: input["teamIdentifier"],
    profile_type: input["profileType"],
    expired_at: input["expiredAt"],
    udids: input["udids"] ? (input["udids"] as string[]).map((val) => val) : undefined,
  };
}

export namespace ApiProvisioningProfile {
  export type ApiProfileTypeEnum = "adhoc" | "enterprise" | "other";
}
/**
 * Describes the migration schema for a provisioning profile defined in HockeyApp.
 */

export interface ApiProvisioningProfileMigration {
  /**
   * The name of the provisioning profile.
   */
  name: string;
  /**
   * The bundle/application identifier.
   */
  bundleId: string;
  /**
   * The team identifier.
   */
  teamIdentifier: string;
  /**
   * The type of provisoning profile.
   */
  type: number;
  /**
   * A boolean value that indicates whether the provisioning profile represents an app extension.
   */
  isAppex: boolean;
  /**
   * The provisioning profile's expiration date in RFC 3339 format, i.e. 2017-07-21T17:32:28Z.
   */
  expiredAt?: string;
  /**
   * A list of UDIDs of provisioned devices.
   */
  udids?: string[];
  /**
   * A provisioning profile URL that indicates where to download it from.
   */
  url?: string;
}

// @ts-ignore
function deserializeApiProvisioningProfileMigration(input: any): ApiProvisioningProfileMigration {
  return {
    name: input["name"],
    bundleId: input["bundle_id"],
    teamIdentifier: input["team_identifier"],
    type: input["type"],
    isAppex: input["is_appex"],
    expiredAt: input["expired_at"],
    udids: input["udids"] ? (input["udids"] as string[]).map((val) => val) : (undefined as any),
    url: input["url"],
  };
}

// @ts-ignore
function serializeApiProvisioningProfileMigration(input: ApiProvisioningProfileMigration): any {
  return {
    name: input["name"],
    bundle_id: input["bundleId"],
    team_identifier: input["teamIdentifier"],
    type: input["type"],
    is_appex: input["isAppex"],
    expired_at: input["expiredAt"],
    udids: input["udids"] ? (input["udids"] as string[]).map((val) => val) : undefined,
    url: input["url"],
  };
}

/**
 * A response containing information about an iOS provisioning profile.
 */

export interface ApiProvisioningProfileResponse {
  provisioningProfileType: ApiProvisioningProfileResponse.ApiProvisioningProfileTypeEnum;
  udids?: string[];
  /**
   * The name of the provisioning profile.
   */
  provisioningProfileName?: string;
  /**
   * The team identifier.
   */
  teamIdentifier?: string;
  /**
   * The bundle identifier associated with the profile.
   */
  provisioningBundleId?: string;
  /**
   * Array of provisioning profiles for any app extensions
   */
  appexProfiles?: ApiProvisioningProfileResponse[];
}

// @ts-ignore
function deserializeApiProvisioningProfileResponse(input: any): ApiProvisioningProfileResponse {
  return {
    provisioningProfileType: input["provisioning_profile_type"],
    udids: input["udids"] ? (input["udids"] as string[]).map((val) => val) : (undefined as any),
    provisioningProfileName: input["provisioning_profile_name"],
    teamIdentifier: input["team_identifier"],
    provisioningBundleId: input["provisioning_bundle_id"],
    appexProfiles: input["appex_profiles"]
      ? (input["appex_profiles"] as ApiProvisioningProfileResponse[]).map((val) => deserializeApiProvisioningProfileResponse(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiProvisioningProfileResponse(input: ApiProvisioningProfileResponse): any {
  return {
    provisioning_profile_type: input["provisioningProfileType"],
    udids: input["udids"] ? (input["udids"] as string[]).map((val) => val) : undefined,
    provisioning_profile_name: input["provisioningProfileName"],
    team_identifier: input["teamIdentifier"],
    provisioning_bundle_id: input["provisioningBundleId"],
    appex_profiles: input["appexProfiles"]
      ? (input["appexProfiles"] as ApiProvisioningProfileResponse[]).map((val) => deserializeApiProvisioningProfileResponse(val))
      : undefined,
  };
}

export namespace ApiProvisioningProfileResponse {
  export type ApiProvisioningProfileTypeEnum = "adhoc" | "enterprise" | "other";
}

export interface ApiPublicDistributionGroupsResponse {
  /**
   * The id of the distribution group
   */
  id: string;
}

// @ts-ignore
function deserializeApiPublicDistributionGroupsResponse(input: any): ApiPublicDistributionGroupsResponse {
  return {
    id: input["id"],
  };
}

// @ts-ignore
function serializeApiPublicDistributionGroupsResponse(input: ApiPublicDistributionGroupsResponse): any {
  return {
    id: input["id"],
  };
}

/**
 * A request containing information for creating a release.
 */

export interface ApiReleaseCreateRequest {
  /**
   * The release's version.<br> For iOS: CFBundleVersion from info.plist.<br> For Android: android:versionCode from AppManifest.xml.
   */
  version?: string;
  /**
   * The release's short version.<br> For iOS: CFBundleShortVersionString from info.plist.<br> For Android: android:versionName from AppManifest.xml.
   */
  buildVersion?: string;
  /**
   * The identifier of the app's bundle.
   */
  uniqueIdentifier?: string;
  /**
   * The release's minimum required operating system.
   */
  minimumOsVersion?: string;
  /**
   * The release's device family.
   */
  deviceFamily?: string;
  /**
   * The languages supported by the release. Limited to 510 characters in a serialized array.
   */
  languages?: string[];
  /**
   * MD5 checksum of the release binary.
   */
  fingerprint: string;
  /**
   * The release's size in bytes.
   */
  size: number;
  /**
   * The URL to the release's binary.
   */
  packageUrl?: string;
  /**
   * The file extension of the asset. Does not include the initial period.
   */
  fileExtension?: string;
  /**
   * The upload id associated with the release, to map to the releases upload table.
   */
  uploadId: string;
  /**
   * The assetId associated with the icon uploaded to app center file upload service.
   */
  iconAssetId?: string;
  /**
   * A list of UUIDs for architectures for an iOS app.
   */
  ipaUuids?: ApiArchIdentifier[];
  provision?: ApiProvisioningProfile;
  /**
   * iOS app extension provisioning profiles included in the release.
   */
  appexProvisioningProfiles?: ApiProvisioningProfile[];
  /**
   * If true this release was uploaded to the AKS upload proxy
   */
  proxyFlow?: boolean;
}

// @ts-ignore
function deserializeApiReleaseCreateRequest(input: any): ApiReleaseCreateRequest {
  return {
    version: input["version"],
    buildVersion: input["build_version"],
    uniqueIdentifier: input["unique_identifier"],
    minimumOsVersion: input["minimum_os_version"],
    deviceFamily: input["device_family"],
    languages: input["languages"] ? (input["languages"] as string[]).map((val) => val) : (undefined as any),
    fingerprint: input["fingerprint"],
    size: input["size"],
    packageUrl: input["package_url"],
    fileExtension: input["file_extension"],
    uploadId: input["upload_id"],
    iconAssetId: input["icon_asset_id"],
    ipaUuids: input["ipa_uuids"]
      ? (input["ipa_uuids"] as ApiArchIdentifier[]).map((val) => deserializeApiArchIdentifier(val))
      : (undefined as any),
    provision: input["provision"],
    appexProvisioningProfiles: input["appex_provisioning_profiles"]
      ? (input["appex_provisioning_profiles"] as ApiProvisioningProfile[]).map((val) => deserializeApiProvisioningProfile(val))
      : (undefined as any),
    proxyFlow: input["proxy_flow"],
  };
}

// @ts-ignore
function serializeApiReleaseCreateRequest(input: ApiReleaseCreateRequest): any {
  return {
    version: input["version"],
    build_version: input["buildVersion"],
    unique_identifier: input["uniqueIdentifier"],
    minimum_os_version: input["minimumOsVersion"],
    device_family: input["deviceFamily"],
    languages: input["languages"] ? (input["languages"] as string[]).map((val) => val) : undefined,
    fingerprint: input["fingerprint"],
    size: input["size"],
    package_url: input["packageUrl"],
    file_extension: input["fileExtension"],
    upload_id: input["uploadId"],
    icon_asset_id: input["iconAssetId"],
    ipa_uuids: input["ipaUuids"]
      ? (input["ipaUuids"] as ApiArchIdentifier[]).map((val) => deserializeApiArchIdentifier(val))
      : undefined,
    provision: input["provision"],
    appex_provisioning_profiles: input["appexProvisioningProfiles"]
      ? (input["appexProvisioningProfiles"] as ApiProvisioningProfile[]).map((val) => deserializeApiProvisioningProfile(val))
      : undefined,
    proxy_flow: input["proxyFlow"],
  };
}

/**
 * Details of an uploaded release
 */

export interface ApiReleaseDetailsResponse {
  /**
   * ID identifying this unique release.
   */
  id: number;
  /**
   * The app's name (extracted from the uploaded release).
   */
  appName: string;
  /**
   * The app's display name.
   */
  appDisplayName: string;
  /**
   * The app's OS.
   */
  appOs?: string;
  /**
   * The release's version.<br> For iOS: CFBundleVersion from info.plist. For Android: android:versionCode from AppManifest.xml.
   */
  version: string;
  /**
   * The release's origin
   */
  origin?: ApiReleaseDetailsResponse.ApiOriginEnum;
  /**
   * The release's short version.<br> For iOS: CFBundleShortVersionString from info.plist. For Android: android:versionName from AppManifest.xml.
   */
  shortVersion: string;
  /**
   * The release's release notes.
   */
  releaseNotes?: string;
  /**
   * The release's provisioning profile name.
   */
  provisioningProfileName?: string;
  /**
   * The type of the provisioning profile for the requested app version.
   */
  provisioningProfileType?: ApiReleaseDetailsResponse.ApiProvisioningProfileTypeEnum;
  /**
   * expiration date of provisioning profile in UTC format.
   */
  provisioningProfileExpiryDate?: string;
  /**
   * A flag that determines whether the release's provisioning profile is still extracted or not.
   */
  isProvisioningProfileSyncing?: boolean;
  /**
   * The release's size in bytes.
   */
  size?: number;
  /**
   * The release's minimum required operating system.
   */
  minOs?: string;
  /**
   * The release's device family.
   */
  deviceFamily?: string;
  /**
   * The release's minimum required Android API level.
   */
  androidMinApiLevel?: string;
  /**
   * The identifier of the apps bundle.
   */
  bundleIdentifier?: string;
  /**
   * Hashes for the packages.
   */
  packageHashes?: string[];
  /**
   * MD5 checksum of the release binary.
   */
  fingerprint?: string;
  /**
   * UTC time in ISO 8601 format of the uploaded time.
   */
  uploadedAt: string;
  /**
   * The URL that hosts the binary for this release.
   */
  downloadUrl?: string;
  /**
   * The URL that hosts the secondary binary for this release, such as the apk file for aab releases.
   */
  secondaryDownloadUrl?: string;
  /**
   * A URL to the app's icon.
   */
  appIconUrl: string;
  /**
   * The href required to install a release on a mobile device. On iOS devices will be prefixed with `itms-services://?action=download-manifest&url=`
   */
  installUrl?: string;
  /**
   * OBSOLETE. Will be removed in next version. The destination type.<br> <b>group</b>: The release distributed to internal groups and distribution_groups details will be returned.<br> <b>store</b>: The release distributed to external stores and distribution_stores details will be returned.<br> <b>tester</b>: The release distributed testers details will be returned.<br>
   */
  destinationType?: ApiReleaseDetailsResponse.ApiDestinationTypeEnum;
  /**
   * OBSOLETE. Will be removed in next version. A list of distribution groups that are associated with this release.
   */
  distributionGroups?: ApiDistributionGroupWithoutIsLatest[];
  /**
   * OBSOLETE. Will be removed in next version. A list of distribution stores that are associated with this release.
   */
  distributionStores?: ApiDistributionStoreWithoutIsLatest[];
  /**
   * A list of distribution groups or stores.
   */
  destinations?: ApiDestination[];
  /**
   * In calls that allow passing `udid` in the query string, this value will hold the provisioning status of that UDID in this release. Will be ignored for non-iOS platforms.
   */
  isUdidProvisioned?: boolean;
  /**
   * In calls that allow passing `udid` in the query string, this value determines if a release can be re-signed. When true, after a re-sign, the tester will be able to install the release from his registered devices. Will not be returned for non-iOS platforms.
   */
  canResign?: boolean;
  build?: ApiBuildInfo;
  /**
   * This value determines the whether a release currently is enabled or disabled.
   */
  enabled: boolean;
  /**
   * Status of the release.
   */
  status?: string;
  /**
   * This value determines if a release is external or not.
   */
  isExternalBuild?: boolean;
  /**
   * The file extension of the asset. Does not include the initial period.
   */
  fileExtension?: string;
}

// @ts-ignore
function deserializeApiReleaseDetailsResponse(input: any): ApiReleaseDetailsResponse {
  return {
    id: input["id"],
    appName: input["app_name"],
    appDisplayName: input["app_display_name"],
    appOs: input["app_os"],
    version: input["version"],
    origin: input["origin"],
    shortVersion: input["short_version"],
    releaseNotes: input["release_notes"],
    provisioningProfileName: input["provisioning_profile_name"],
    provisioningProfileType: input["provisioning_profile_type"],
    provisioningProfileExpiryDate: input["provisioning_profile_expiry_date"],
    isProvisioningProfileSyncing: input["is_provisioning_profile_syncing"],
    size: input["size"],
    minOs: input["min_os"],
    deviceFamily: input["device_family"],
    androidMinApiLevel: input["android_min_api_level"],
    bundleIdentifier: input["bundle_identifier"],
    packageHashes: input["package_hashes"] ? (input["package_hashes"] as string[]).map((val) => val) : (undefined as any),
    fingerprint: input["fingerprint"],
    uploadedAt: input["uploaded_at"],
    downloadUrl: input["download_url"],
    secondaryDownloadUrl: input["secondary_download_url"],
    appIconUrl: input["app_icon_url"],
    installUrl: input["install_url"],
    destinationType: input["destination_type"],
    distributionGroups: input["distribution_groups"]
      ? (input["distribution_groups"] as ApiDistributionGroupWithoutIsLatest[]).map((val) =>
          deserializeApiDistributionGroupWithoutIsLatest(val)
        )
      : (undefined as any),
    distributionStores: input["distribution_stores"]
      ? (input["distribution_stores"] as ApiDistributionStoreWithoutIsLatest[]).map((val) =>
          deserializeApiDistributionStoreWithoutIsLatest(val)
        )
      : (undefined as any),
    destinations: input["destinations"]
      ? (input["destinations"] as ApiDestination[]).map((val) => deserializeApiDestination(val))
      : (undefined as any),
    isUdidProvisioned: input["is_udid_provisioned"],
    canResign: input["can_resign"],
    build: input["build"],
    enabled: input["enabled"],
    status: input["status"],
    isExternalBuild: input["is_external_build"],
  };
}

// @ts-ignore
function serializeApiReleaseDetailsResponse(input: ApiReleaseDetailsResponse): any {
  return {
    id: input["id"],
    app_name: input["appName"],
    app_display_name: input["appDisplayName"],
    app_os: input["appOs"],
    version: input["version"],
    origin: input["origin"],
    short_version: input["shortVersion"],
    release_notes: input["releaseNotes"],
    provisioning_profile_name: input["provisioningProfileName"],
    provisioning_profile_type: input["provisioningProfileType"],
    provisioning_profile_expiry_date: input["provisioningProfileExpiryDate"],
    is_provisioning_profile_syncing: input["isProvisioningProfileSyncing"],
    size: input["size"],
    min_os: input["minOs"],
    device_family: input["deviceFamily"],
    android_min_api_level: input["androidMinApiLevel"],
    bundle_identifier: input["bundleIdentifier"],
    package_hashes: input["packageHashes"] ? (input["packageHashes"] as string[]).map((val) => val) : undefined,
    fingerprint: input["fingerprint"],
    uploaded_at: input["uploadedAt"],
    download_url: input["downloadUrl"],
    secondary_download_url: input["secondaryDownloadUrl"],
    app_icon_url: input["appIconUrl"],
    install_url: input["installUrl"],
    destination_type: input["destinationType"],
    distribution_groups: input["distributionGroups"]
      ? (input["distributionGroups"] as ApiDistributionGroupWithoutIsLatest[]).map((val) =>
          deserializeApiDistributionGroupWithoutIsLatest(val)
        )
      : undefined,
    distribution_stores: input["distributionStores"]
      ? (input["distributionStores"] as ApiDistributionStoreWithoutIsLatest[]).map((val) =>
          deserializeApiDistributionStoreWithoutIsLatest(val)
        )
      : undefined,
    destinations: input["destinations"]
      ? (input["destinations"] as ApiDestination[]).map((val) => deserializeApiDestination(val))
      : undefined,
    is_udid_provisioned: input["isUdidProvisioned"],
    can_resign: input["canResign"],
    build: input["build"],
    enabled: input["enabled"],
    status: input["status"],
    is_external_build: input["isExternalBuild"],
  };
}

export namespace ApiReleaseDetailsResponse {
  export type ApiOriginEnum = "hockeyapp" | "appcenter";
  export type ApiProvisioningProfileTypeEnum = "adhoc" | "enterprise" | "other";
  export type ApiDestinationTypeEnum = "group" | "store" | "tester";
}
/**
 * A request containing information for updating details of a release
 */

export interface ApiReleaseDetailsUpdateRequest {
  /**
   * Toggle this release to be enable distribute/download or not.
   */
  enabled?: boolean;
  /**
   * Release notes for this release.
   */
  releaseNotes?: string;
  build?: ApiBuildInfo;
}

// @ts-ignore
function deserializeApiReleaseDetailsUpdateRequest(input: any): ApiReleaseDetailsUpdateRequest {
  return {
    enabled: input["enabled"],
    releaseNotes: input["release_notes"],
    build: input["build"],
  };
}

// @ts-ignore
function serializeApiReleaseDetailsUpdateRequest(input: ApiReleaseDetailsUpdateRequest): any {
  return {
    enabled: input["enabled"],
    release_notes: input["releaseNotes"],
    build: input["build"],
  };
}

/**
 * Response for updating release details
 */

export interface ApiReleaseDetailsUpdateResponse {
  releaseNotes?: string;
}

// @ts-ignore
function deserializeApiReleaseDetailsUpdateResponse(input: any): ApiReleaseDetailsUpdateResponse {
  return {
    releaseNotes: input["release_notes"],
  };
}

// @ts-ignore
function serializeApiReleaseDetailsUpdateResponse(input: ApiReleaseDetailsUpdateResponse): any {
  return {
    release_notes: input["releaseNotes"],
  };
}

/**
 * Response for updating release external url
 */

export interface ApiReleaseExternalUrlUpdateResponse {
  externalDownloadUrl?: string;
}

// @ts-ignore
function deserializeApiReleaseExternalUrlUpdateResponse(input: any): ApiReleaseExternalUrlUpdateResponse {
  return {
    externalDownloadUrl: input["external_download_url"],
  };
}

// @ts-ignore
function serializeApiReleaseExternalUrlUpdateResponse(input: ApiReleaseExternalUrlUpdateResponse): any {
  return {
    external_download_url: input["externalDownloadUrl"],
  };
}

/**
 * An object containing all the release metadata.
 */

export interface ApiReleaseMetadata {
  /**
   * dsa signature of the release for the sparkle feed.
   */
  dsaSignature?: string;
  /**
   * edDSA signature of the release for the sparkle feed.
   */
  edSignature?: string;
}

// @ts-ignore
function deserializeApiReleaseMetadata(input: any): ApiReleaseMetadata {
  return {
    dsaSignature: input["dsa_signature"],
    edSignature: input["ed_signature"],
  };
}

// @ts-ignore
function serializeApiReleaseMetadata(input: ApiReleaseMetadata): any {
  return {
    dsa_signature: input["dsaSignature"],
    ed_signature: input["edSignature"],
  };
}

/**
 * Response for provisioning a release
 */

export interface ApiReleaseProvisionResponse {
  /**
   * The url to check provisioning status.
   */
  statusUrl?: string;
}

// @ts-ignore
function deserializeApiReleaseProvisionResponse(input: any): ApiReleaseProvisionResponse {
  return {
    statusUrl: input["status_url"],
  };
}

// @ts-ignore
function serializeApiReleaseProvisionResponse(input: ApiReleaseProvisionResponse): any {
  return {
    status_url: input["statusUrl"],
  };
}

export interface ApiReleaseStoreDestinationRequest {
  /**
   * Unique id of the release destination
   */
  id: string;
}

// @ts-ignore
function deserializeApiReleaseStoreDestinationRequest(input: any): ApiReleaseStoreDestinationRequest {
  return {
    id: input["id"],
  };
}

// @ts-ignore
function serializeApiReleaseStoreDestinationRequest(input: ApiReleaseStoreDestinationRequest): any {
  return {
    id: input["id"],
  };
}

export interface ApiReleaseStoreDestinationResponse {
  /**
   * Unique id for the release destination
   */
  id: string;
}

// @ts-ignore
function deserializeApiReleaseStoreDestinationResponse(input: any): ApiReleaseStoreDestinationResponse {
  return {
    id: input["id"],
  };
}

// @ts-ignore
function serializeApiReleaseStoreDestinationResponse(input: ApiReleaseStoreDestinationResponse): any {
  return {
    id: input["id"],
  };
}

export interface ApiReleaseTesterDestinationRequest {
  /**
   * Flag to mark the release for the provided destinations as mandatory
   */
  mandatoryUpdate?: boolean;
  /**
   * Tester's email address
   */
  email: string;
  /**
   * Flag to enable or disable notifications to testers
   */
  notifyTesters?: boolean;
}

// @ts-ignore
function deserializeApiReleaseTesterDestinationRequest(input: any): ApiReleaseTesterDestinationRequest {
  return {
    mandatoryUpdate: input["mandatory_update"],
    email: input["email"],
    notifyTesters: input["notify_testers"],
  };
}

// @ts-ignore
function serializeApiReleaseTesterDestinationRequest(input: ApiReleaseTesterDestinationRequest): any {
  return {
    mandatory_update: input["mandatoryUpdate"],
    email: input["email"],
    notify_testers: input["notifyTesters"],
  };
}

/**
 * A request containing information for updating a release.
 */

export interface ApiReleaseUpdateRequest {
  /**
   * OBSOLETE. Will be removed in future releases - use destinations instead. Name of a distribution group. The release will be associated with this distribution group. If the distribution group doesn't exist a 400 is returned. If both distribution group name and id are passed, the id is taking precedence.
   */
  distributionGroupName?: string;
  /**
   * OBSOLETE. Will be removed in future releases - use destinations instead. Id of a distribution group. The release will be associated with this distribution group. If the distribution group doesn't exist a 400 is returned. If both distribution group name and id are passed, the id is taking precedence.
   */
  distributionGroupId?: string;
  /**
   * OBSOLETE. Will be removed in future releases - use destinations instead. Name of a destination. The release will be associated with this destination. If the destination doesn't exist a 400 is returned. If both distribution group name and id are passed, the id is taking precedence.
   */
  destinationName?: string;
  /**
   * OBSOLETE. Will be removed in future releases - use destinations instead. Id of a destination. The release will be associated with this destination. If the destination doesn't exist a 400 is returned. If both destination name and id are passed, the id is taking precedence.
   */
  destinationId?: string;
  /**
   * Not used anymore.
   */
  destinationType?: string;
  /**
   * Release notes for this release.
   */
  releaseNotes?: string;
  /**
   * A boolean which determines whether this version should be a mandatory update or not.
   */
  mandatoryUpdate?: boolean;
  /**
   * Distribute this release under the following list of destinations (store groups or distribution groups).
   */
  destinations?: ApiDestinationId[];
  build?: ApiBuildInfo;
  /**
   * A boolean which determines whether to notify testers of a new release, default to true.
   */
  notifyTesters?: boolean;
  metadata?: ApiReleaseMetadata;
}

// @ts-ignore
function deserializeApiReleaseUpdateRequest(input: any): ApiReleaseUpdateRequest {
  return {
    distributionGroupName: input["distribution_group_name"],
    distributionGroupId: input["distribution_group_id"],
    destinationName: input["destination_name"],
    destinationId: input["destination_id"],
    destinationType: input["destination_type"],
    releaseNotes: input["release_notes"],
    mandatoryUpdate: input["mandatory_update"],
    destinations: input["destinations"]
      ? (input["destinations"] as ApiDestinationId[]).map((val) => deserializeApiDestinationId(val))
      : (undefined as any),
    build: input["build"],
    notifyTesters: input["notify_testers"],
    metadata: input["metadata"],
  };
}

// @ts-ignore
function serializeApiReleaseUpdateRequest(input: ApiReleaseUpdateRequest): any {
  return {
    distribution_group_name: input["distributionGroupName"],
    distribution_group_id: input["distributionGroupId"],
    destination_name: input["destinationName"],
    destination_id: input["destinationId"],
    destination_type: input["destinationType"],
    release_notes: input["releaseNotes"],
    mandatory_update: input["mandatoryUpdate"],
    destinations: input["destinations"]
      ? (input["destinations"] as ApiDestinationId[]).map((val) => deserializeApiDestinationId(val))
      : undefined,
    build: input["build"],
    notify_testers: input["notifyTesters"],
    metadata: input["metadata"],
  };
}

/**
 * Response for updating a release
 */

export interface ApiReleaseUpdateResponse {
  enabled?: boolean;
  mandatoryUpdate?: boolean;
  releaseNotes?: string;
  provisioningStatusUrl?: string;
  destinations?: ApiReleaseUpdateResponseDestinations[];
}

// @ts-ignore
function deserializeApiReleaseUpdateResponse(input: any): ApiReleaseUpdateResponse {
  return {
    enabled: input["enabled"],
    mandatoryUpdate: input["mandatory_update"],
    releaseNotes: input["release_notes"],
    provisioningStatusUrl: input["provisioning_status_url"],
    destinations: input["destinations"]
      ? (input["destinations"] as ApiReleaseUpdateResponseDestinations[]).map((val) =>
          deserializeApiReleaseUpdateResponseDestinations(val)
        )
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiReleaseUpdateResponse(input: ApiReleaseUpdateResponse): any {
  return {
    enabled: input["enabled"],
    mandatory_update: input["mandatoryUpdate"],
    release_notes: input["releaseNotes"],
    provisioning_status_url: input["provisioningStatusUrl"],
    destinations: input["destinations"]
      ? (input["destinations"] as ApiReleaseUpdateResponseDestinations[]).map((val) =>
          deserializeApiReleaseUpdateResponseDestinations(val)
        )
      : undefined,
  };
}

export interface ApiReleaseUpdateResponseDestinations {
  id?: string;
  name?: string;
}

// @ts-ignore
function deserializeApiReleaseUpdateResponseDestinations(input: any): ApiReleaseUpdateResponseDestinations {
  return {
    id: input["id"],
    name: input["name"],
  };
}

// @ts-ignore
function serializeApiReleaseUpdateResponseDestinations(input: ApiReleaseUpdateResponseDestinations): any {
  return {
    id: input["id"],
    name: input["name"],
  };
}

export interface ApiSendNotificationRequest {
  /**
   * user list to send email notification
   */
  userIds: string[];
}

// @ts-ignore
function deserializeApiSendNotificationRequest(input: any): ApiSendNotificationRequest {
  return {
    userIds: input["userIds"] ? (input["userIds"] as string[]).map((val) => val) : (undefined as any),
  };
}

// @ts-ignore
function serializeApiSendNotificationRequest(input: ApiSendNotificationRequest): any {
  return {
    userIds: input["userIds"] ? (input["userIds"] as string[]).map((val) => val) : undefined,
  };
}

export interface ApiStatusResponse {
  status: string;
}

// @ts-ignore
function deserializeApiStatusResponse(input: any): ApiStatusResponse {
  return {
    status: input["status"],
  };
}

// @ts-ignore
function serializeApiStatusResponse(input: ApiStatusResponse): any {
  return {
    status: input["status"],
  };
}

export interface ApiTesterAppWithReleaseResponse {
  /**
   * The unique ID (UUID) of the app
   */
  id?: string;
  release?: ApiTesterAppRelease;
  /**
   * The app's name.
   */
  name?: string;
  /**
   * The app's display name.
   */
  displayName?: string;
  /**
   * The description of the app
   */
  description?: string;
  /**
   * A URL to the app's icon.
   */
  iconUrl?: string;
  /**
   * The app's os.
   */
  os?: string;
  owner?: ApiTesterAppWithReleaseResponseOwner;
}

// @ts-ignore
function deserializeApiTesterAppWithReleaseResponse(input: any): ApiTesterAppWithReleaseResponse {
  return {
    id: input["id"],
    release: input["release"],
    name: input["name"],
    displayName: input["display_name"],
    description: input["description"],
    iconUrl: input["icon_url"],
    os: input["os"],
    owner: input["owner"],
  };
}

// @ts-ignore
function serializeApiTesterAppWithReleaseResponse(input: ApiTesterAppWithReleaseResponse): any {
  return {
    id: input["id"],
    release: input["release"],
    name: input["name"],
    display_name: input["displayName"],
    description: input["description"],
    icon_url: input["iconUrl"],
    os: input["os"],
    owner: input["owner"],
  };
}

/**
 * The information about the app's owner
 */

export interface ApiTesterAppWithReleaseResponseOwner {
  /**
   * The unique id (UUID) of the owner
   */
  id?: string;
  /**
   * The avatar URL of the owner
   */
  avatarUrl?: string;
  /**
   * The owner's display name
   */
  displayName?: string;
  /**
   * The owner's email address
   */
  email?: string;
  /**
   * The unique name that used to identify the owner
   */
  name?: string;
  /**
   * The owner type. Can either be 'org' or 'user'
   */
  type?: ApiTesterAppWithReleaseResponseOwner.ApiTypeEnum;
}

// @ts-ignore
function deserializeApiTesterAppWithReleaseResponseOwner(input: any): ApiTesterAppWithReleaseResponseOwner {
  return {
    id: input["id"],
    avatarUrl: input["avatar_url"],
    displayName: input["display_name"],
    email: input["email"],
    name: input["name"],
    type: input["type"],
  };
}

// @ts-ignore
function serializeApiTesterAppWithReleaseResponseOwner(input: ApiTesterAppWithReleaseResponseOwner): any {
  return {
    id: input["id"],
    avatar_url: input["avatarUrl"],
    display_name: input["displayName"],
    email: input["email"],
    name: input["name"],
    type: input["type"],
  };
}

export namespace ApiTesterAppWithReleaseResponseOwner {
  export type ApiTypeEnum = "org" | "user";
}
/**
 * A request containing information for creating an externally hosted release.
 */

export interface ApiUpdateExternalUrlRequest {
  /**
   * The external URL to the release's binary.
   */
  externalDownloadUrl: string;
}

// @ts-ignore
function deserializeApiUpdateExternalUrlRequest(input: any): ApiUpdateExternalUrlRequest {
  return {
    externalDownloadUrl: input["external_download_url"],
  };
}

// @ts-ignore
function serializeApiUpdateExternalUrlRequest(input: ApiUpdateExternalUrlRequest): any {
  return {
    external_download_url: input["externalDownloadUrl"],
  };
}

/**
 * Response for retrieving user interaction metrics.
 */

export interface ApiUserInteractionMetricsResponse {
  /**
   * check if the user has less than 100 apps.
   */
  lessThan100Apps?: boolean;
  /**
   * check if the user's whole apps has more than 1 releases.
   */
  hasMoreThan1Release?: boolean;
}

// @ts-ignore
function deserializeApiUserInteractionMetricsResponse(input: any): ApiUserInteractionMetricsResponse {
  return {
    lessThan100Apps: input["less_than_100_apps"],
    hasMoreThan1Release: input["has_more_than_1_release"],
  };
}

// @ts-ignore
function serializeApiUserInteractionMetricsResponse(input: ApiUserInteractionMetricsResponse): any {
  return {
    less_than_100_apps: input["lessThan100Apps"],
    has_more_than_1_release: input["hasMoreThan1Release"],
  };
}

export interface ApiDistributionGroup {
  /**
   * ID identifying a unique distribution group.
   */
  id: string;
  /**
   * A name identifying a unique distribution group.
   */
  name?: string;
  /**
   * Is the containing release the latest one in this distribution group.
   */
  isLatest?: boolean;
}

// @ts-ignore
function deserializeApiDistributionGroup(input: any): ApiDistributionGroup {
  return {
    id: input["id"],
    name: input["name"],
    isLatest: input["is_latest"],
  };
}

// @ts-ignore
function serializeApiDistributionGroup(input: ApiDistributionGroup): any {
  return {
    id: input["id"],
    name: input["name"],
    is_latest: input["isLatest"],
  };
}

export interface ApiDistributionStore {
  /**
   * ID identifying a unique distribution store.
   */
  id: string;
  /**
   * A name identifying a unique distribution store.
   */
  name?: string;
  /**
   * type of the distribution store currently stores type can be intune, googleplay or windows.
   */
  type?: ApiDistributionStore.ApiTypeEnum;
  /**
   * publishing status of the release in the store.
   */
  publishingStatus?: string;
  /**
   * Is the containing release the latest one in this distribution store.
   */
  isLatest?: boolean;
}

// @ts-ignore
function deserializeApiDistributionStore(input: any): ApiDistributionStore {
  return {
    id: input["id"],
    name: input["name"],
    type: input["type"],
    publishingStatus: input["publishing_status"],
    isLatest: input["is_latest"],
  };
}

// @ts-ignore
function serializeApiDistributionStore(input: ApiDistributionStore): any {
  return {
    id: input["id"],
    name: input["name"],
    type: input["type"],
    publishing_status: input["publishingStatus"],
    is_latest: input["isLatest"],
  };
}

export namespace ApiDistributionStore {
  export type ApiTypeEnum = "intune" | "googleplay" | "apple" | "none";
}

export interface ApiReleaseDestinationRequest {
  /**
   * Unique id of the release destination
   */
  id: string;
  /**
   * Flag to mark the release for the provided destinations as mandatory
   */
  mandatoryUpdate?: boolean;
  /**
   * Flag to enable or disable notifications to testers
   */
  notifyTesters?: boolean;
}

// @ts-ignore
function deserializeApiReleaseDestinationRequest(input: any): ApiReleaseDestinationRequest {
  return {
    id: input["id"],
    mandatoryUpdate: input["mandatory_update"],
    notifyTesters: input["notify_testers"],
  };
}

// @ts-ignore
function serializeApiReleaseDestinationRequest(input: ApiReleaseDestinationRequest): any {
  return {
    id: input["id"],
    mandatory_update: input["mandatoryUpdate"],
    notify_testers: input["notifyTesters"],
  };
}

export interface ApiReleaseDestinationResponse {
  /**
   * Unique id for the release destination
   */
  id: string;
  /**
   * Flag to mark the release for the provided destinations as mandatory
   */
  mandatoryUpdate: boolean;
  /**
   * The url to check provisioning status.
   */
  provisioningStatusUrl?: string;
}

// @ts-ignore
function deserializeApiReleaseDestinationResponse(input: any): ApiReleaseDestinationResponse {
  return {
    id: input["id"],
    mandatoryUpdate: input["mandatory_update"],
    provisioningStatusUrl: input["provisioning_status_url"],
  };
}

// @ts-ignore
function serializeApiReleaseDestinationResponse(input: ApiReleaseDestinationResponse): any {
  return {
    id: input["id"],
    mandatory_update: input["mandatoryUpdate"],
    provisioning_status_url: input["provisioningStatusUrl"],
  };
}

export interface ApiReleaseUpdateError {
  /**
   * Error codes:<br> <b>not_found</b>: An app, a release or a distribution group can't be found.<br> <b>forbidden</b>: This operation is not allowed by App Center API. It may happen since the permission problem or trying to delete a release from a migrated HockeyApp distribution group through App cCnter api.<br> <b>no_upload_resource</b>: The upload resource ID doesn't match any upload resource <br> <b>release_not_uploaded</b>: Trying to commit a release before it is uploaded. <br> <b>filter_error</b>: A filter query string parameter is incorrect or not supported. <br> <b>internal_server_error</b>: An unknown internal error.<br> <b>not_supported</b>: An operation is not supported.<br> <b>no_releases_for_app</b>: Couldn't get a release because there are no releases for this app.<br> <b>no_releases_for_user</b>: Couldn't get a release because there are no releases for this user.<br> <b>bad_request</b>: One of the parameters is incorrect.<br> <b>distribution_group_not_found</b>: Distribution group or the app doesn't exist.<br> <b>not_implemented</b>: Request not implemented by this service.<br> <b>partially_deleted</b>: Deleted just part of a resource.<br> <b>partially_succeeded</b>: Partial succeeded. Look at the HTTP body to identify which part has failed.<br> <b>not_ready</b>: The requested resource is not ready. Try again later.<br> <b>dependent_service_error</b>: The dependent service can't be found.<br> <b>upload_not_found</b>: The upload can't be found.<br>
   */
  code: ApiReleaseUpdateError.ApiCodeEnum;
  message: string;
  releaseNotes?: string;
  mandatoryUpdate?: boolean;
  destinations?: ApiDestinationError[];
}

// @ts-ignore
function deserializeApiReleaseUpdateError(input: any): ApiReleaseUpdateError {
  return {
    code: input["code"],
    message: input["message"],
    releaseNotes: input["release_notes"],
    mandatoryUpdate: input["mandatory_update"],
    destinations: input["destinations"]
      ? (input["destinations"] as ApiDestinationError[]).map((val) => deserializeApiDestinationError(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiReleaseUpdateError(input: ApiReleaseUpdateError): any {
  return {
    code: input["code"],
    message: input["message"],
    release_notes: input["releaseNotes"],
    mandatory_update: input["mandatoryUpdate"],
    destinations: input["destinations"]
      ? (input["destinations"] as ApiDestinationError[]).map((val) => deserializeApiDestinationError(val))
      : undefined,
  };
}

export namespace ApiReleaseUpdateError {
  export type ApiCodeEnum =
    | "not_found"
    | "conflict"
    | "forbidden"
    | "release_not_found"
    | "no_upload_resource"
    | "release_not_uploaded"
    | "filter_error"
    | "internal_server_error"
    | "not_supported"
    | "no_releases_for_app"
    | "no_releases_for_user"
    | "bad_request"
    | "distribution_group_not_found"
    | "not_implemented"
    | "partially_deleted"
    | "partially_succeeded"
    | "not_ready"
    | "upload_not_found";
}

export interface ApiTesterAppRelease {
  /**
   * ID identifying this unique release.
   */
  id: number;
  /**
   * The release's version.<br> For iOS: CFBundleVersion from info.plist.<br> For Android: android:versionCode from AppManifest.xml.
   */
  version: string;
  /**
   * The release's origin
   */
  origin?: ApiTesterAppRelease.ApiOriginEnum;
  /**
   * The release's short version.<br> For iOS: CFBundleShortVersionString from info.plist.<br> For Android: android:versionName from AppManifest.xml.
   */
  shortVersion: string;
  /**
   * A boolean which determines whether the release is a mandatory update or not.
   */
  mandatoryUpdate: boolean;
  /**
   * UTC time in ISO 8601 format of the uploaded time.
   */
  uploadedAt: string;
  /**
   * This value determines the whether a release currently is enabled or disabled.
   */
  enabled: boolean;
  /**
   * This value determines if a release is external or not.
   */
  isExternalBuild?: boolean;
  /**
   * The release's size in bytes.
   */
  size: number;
  /**
   * The href required to install a release on a mobile device. On iOS devices will be prefixed with `itms-services://?action=download-manifest&url=`
   */
  installUrl?: string;
  /**
   * The release's release notes.
   */
  releaseNotes?: string;
}

// @ts-ignore
function deserializeApiTesterAppRelease(input: any): ApiTesterAppRelease {
  return {
    id: input["id"],
    version: input["version"],
    origin: input["origin"],
    shortVersion: input["short_version"],
    mandatoryUpdate: input["mandatory_update"],
    uploadedAt: input["uploaded_at"],
    enabled: input["enabled"],
    isExternalBuild: input["is_external_build"],
    size: input["size"],
    installUrl: input["install_url"],
    releaseNotes: input["release_notes"],
  };
}

// @ts-ignore
function serializeApiTesterAppRelease(input: ApiTesterAppRelease): any {
  return {
    id: input["id"],
    version: input["version"],
    origin: input["origin"],
    short_version: input["shortVersion"],
    mandatory_update: input["mandatoryUpdate"],
    uploaded_at: input["uploadedAt"],
    enabled: input["enabled"],
    is_external_build: input["isExternalBuild"],
    size: input["size"],
    install_url: input["installUrl"],
    release_notes: input["releaseNotes"],
  };
}

export namespace ApiTesterAppRelease {
  export type ApiOriginEnum = "hockeyapp" | "appcenter";
}

export interface ApiDestination {
  /**
   * Id of a distribution group / store. The release will be associated with this distribution group / store. If the distribution group / store doesn't exist a 400 is returned. If both distribution group / store name and id are passed, the id is taking precedence.
   */
  id: string;
  /**
   * Name of a distribution group / distribution store. The release will be associated with this distribution group or store. If the distribution group / store doesn't exist a 400 is returned. If both distribution group / store name and id are passed, the id is taking precedence.
   */
  name?: string;
  /**
   * Is the containing release the latest one in this distribution store.
   */
  isLatest?: boolean;
  /**
   * type of the distribution store currently stores type can be intune, googleplay or windows.
   */
  type?: ApiDestination.ApiTypeEnum;
  /**
   * publishing status of the release in the store.
   */
  publishingStatus?: string;
  /**
   * Destination can be either store or group.
   */
  destinationType?: ApiDestination.ApiDestinationTypeEnum;
  /**
   * Display name for the group or tester
   */
  displayName?: string;
}

// @ts-ignore
function deserializeApiDestination(input: any): ApiDestination {
  return {
    id: input["id"],
    name: input["name"],
    isLatest: input["is_latest"],
    type: input["type"],
    publishingStatus: input["publishing_status"],
    destinationType: input["destination_type"],
    displayName: input["display_name"],
  };
}

// @ts-ignore
function serializeApiDestination(input: ApiDestination): any {
  return {
    id: input["id"],
    name: input["name"],
    is_latest: input["isLatest"],
    type: input["type"],
    publishing_status: input["publishingStatus"],
    destination_type: input["destinationType"],
    display_name: input["displayName"],
  };
}

export namespace ApiDestination {
  export type ApiTypeEnum = "intune" | "googleplay" | "apple" | "none";
  export type ApiDestinationTypeEnum = "group" | "store" | "tester";
}

export namespace ReleasesApi {
  export interface AddDistributionGroupReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: number;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeAddDistributionGroupReleasesParams(input: AddDistributionGroupReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/groups
   *
   * Distributes a release to a group
   **/
  export function addDistributionGroupReleases(
    params: AddDistributionGroupReleasesParams,
    body: ApiReleaseDestinationRequest,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDestinationResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/groups";
    const payload = {
      params: stringifyParams(serializeAddDistributionGroupReleasesParams(params)),
      body: serializeApiReleaseDestinationRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDestinationResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDestinationResponse",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/groups",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDestinationResponse(result);
        return resultOrResults as ApiReleaseDestinationResponse;
      });
  }
  export interface AddStoreReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: number;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeAddStoreReleasesParams(input: AddStoreReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/stores
   *
   * Distributes a release to a store
   **/
  export function addStoreReleases(
    params: AddStoreReleasesParams,
    body: ApiReleaseStoreDestinationRequest,
    options?: ApiClientOptions
  ): Promise<ApiReleaseStoreDestinationResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/stores";
    const payload = {
      params: stringifyParams(serializeAddStoreReleasesParams(params)),
      body: serializeApiReleaseStoreDestinationRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseStoreDestinationResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseStoreDestinationResponse",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/stores",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseStoreDestinationResponse(result);
        return resultOrResults as ApiReleaseStoreDestinationResponse;
      });
  }
  export interface AddTestersReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: number;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeAddTestersReleasesParams(input: AddTestersReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/testers
   *
   * Distributes a release to a user
   **/
  export function addTestersReleases(
    params: AddTestersReleasesParams,
    body: ApiReleaseTesterDestinationRequest,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDestinationResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/testers";
    const payload = {
      params: stringifyParams(serializeAddTestersReleasesParams(params)),
      body: serializeApiReleaseTesterDestinationRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDestinationResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDestinationResponse",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/testers",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDestinationResponse(result);
        return resultOrResults as ApiReleaseDestinationResponse;
      });
  }
  export interface AvailableToTesterReleasesParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // when *true*, filters out releases that were uploaded but were never distributed. Releases that under deleted distribution groups will not be filtered out.
    publishedOnly?: boolean;
  }

  // @ts-ignore
  function serializeAvailableToTesterReleasesParams(input: AvailableToTesterReleasesParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      published_only: input["publishedOnly"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/releases/filter_by_tester
   *
   * Return detailed information about releases avaiable to a tester.
   **/
  export function availableToTesterReleases(
    params: AvailableToTesterReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiBasicReleaseDetailsResponse[]> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/filter_by_tester";
    const payload = {
      params: stringifyParams(serializeAvailableToTesterReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiBasicReleaseDetailsResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiBasicReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/releases/filter_by_tester"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiBasicReleaseDetailsResponse(r));
        return resultOrResults as ApiBasicReleaseDetailsResponse[];
      });
  }
  export interface CreateProvisioningconfigParams {
    [key: string]: any;
    // The name of the destination.
    destinationName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeCreateProvisioningconfigParams(input: CreateProvisioningconfigParams): any {
    const pathParams = {
      destination_name: input["destinationName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/destinations/{destination_name}/provisioning_config
   *
   * Creates a new auto provisioning configuration.
   **/
  export function createProvisioningconfig(
    params: CreateProvisioningconfigParams,
    body: ApiAutoProvisioningConfigRequest,
    options?: ApiClientOptions
  ): Promise<ApiAutoProvisioningConfigResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/destinations/{destination_name}/provisioning_config";
    const payload = {
      params: stringifyParams(serializeCreateProvisioningconfigParams(params)),
      body: serializeApiAutoProvisioningConfigRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiAutoProvisioningConfigResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiAutoProvisioningConfigResponse",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/destinations/{destination_name}/provisioning_config",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiAutoProvisioningConfigResponse(result);
        return resultOrResults as ApiAutoProvisioningConfigResponse;
      });
  }
  export interface CreateReleaseUploadReleasesParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeCreateReleaseUploadReleasesParams(input: CreateReleaseUploadReleasesParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/uploads/releases
   *
   * Initiate a new release upload. This API is part of multi-step upload process.
   **/
  export function createReleaseUploadReleases(
    params: CreateReleaseUploadReleasesParams,
    body?: ApiPostCreateReleaseUploadRequest,
    options?: ApiClientOptions
  ): Promise<ApiCreateReleaseUploadResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/uploads/releases";
    const payload = {
      params: stringifyParams(serializeCreateReleaseUploadReleasesParams(params)),
      body: body && serializeApiPostCreateReleaseUploadRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiCreateReleaseUploadResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiCreateReleaseUploadResponse",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/uploads/releases",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiCreateReleaseUploadResponse(result);
        return resultOrResults as ApiCreateReleaseUploadResponse;
      });
  }
  export interface CreateReleasesParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeCreateReleasesParams(input: CreateReleasesParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/private/apps/{owner_name}/{app_name}/releases
   *
   * Creates a new release from release info.
   **/
  export function createReleases(
    params: CreateReleasesParams,
    body: ApiReleaseCreateRequest,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/private/apps/{owner_name}/{app_name}/releases";
    const payload = {
      params: stringifyParams(serializeCreateReleasesParams(params)),
      body: serializeApiReleaseCreateRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/private/apps/{owner_name}/{app_name}/releases", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DeleteDistributionGroupReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: number;
    // The id of the distribution group
    groupId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeDeleteDistributionGroupReleasesParams(input: DeleteDistributionGroupReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      group_id: input["groupId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/groups/{group_id}
   *
   * Delete the given distribution group from the release
   **/
  export function deleteDistributionGroupReleases(
    params: DeleteDistributionGroupReleasesParams,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/groups/{group_id}";
    const payload = {
      params: stringifyParams(serializeDeleteDistributionGroupReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "DELETE /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/groups/{group_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DeleteDistributionStoreReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: number;
    // The id of the distribution store
    storeId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeDeleteDistributionStoreReleasesParams(input: DeleteDistributionStoreReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      store_id: input["storeId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/stores/{store_id}
   *
   * Delete the given distribution store from the release
   **/
  export function deleteDistributionStoreReleases(
    params: DeleteDistributionStoreReleasesParams,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/stores/{store_id}";
    const payload = {
      params: stringifyParams(serializeDeleteDistributionStoreReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "DELETE /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/stores/{store_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DeleteDistributionTesterReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: number;
    // The id of the tester
    testerId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeDeleteDistributionTesterReleasesParams(input: DeleteDistributionTesterReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      tester_id: input["testerId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/testers/{tester_id}
   *
   * Delete the given tester from the release
   **/
  export function deleteDistributionTesterReleases(
    params: DeleteDistributionTesterReleasesParams,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/testers/{tester_id}";
    const payload = {
      params: stringifyParams(serializeDeleteDistributionTesterReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "DELETE /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/testers/{tester_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DeleteProvisioningconfigParams {
    [key: string]: any;
    // The name of the destination.
    destinationName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeDeleteProvisioningconfigParams(input: DeleteProvisioningconfigParams): any {
    const pathParams = {
      destination_name: input["destinationName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/destinations/{destination_name}/provisioning_config
   *
   * Deletes auto provisioning configuration for a given app id and destination.
   **/
  export function deleteProvisioningconfig(params: DeleteProvisioningconfigParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/destinations/{destination_name}/provisioning_config";
    const payload = {
      params: stringifyParams(serializeDeleteProvisioningconfigParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "DELETE /v0.1/apps/{owner_name}/{app_name}/destinations/{destination_name}/provisioning_config",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DeleteReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: number;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeDeleteReleasesParams(input: DeleteReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}
   *
   * Deletes a release.
   **/
  export function deleteReleases(params: DeleteReleasesParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}";
    const payload = {
      params: stringifyParams(serializeDeleteReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "DELETE /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DeleteStoreReleaseReleasesParams {
    [key: string]: any;
    // The ID of the release.
    releaseId: number;
    // The ID of the distribution store id.
    storeId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeDeleteStoreReleaseReleasesParams(input: DeleteStoreReleaseReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      store_id: input["storeId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/private/apps/{owner_name}/{app_name}/distribution_stores/{store_id}/releases/{release_id}
   *
   * Private API to delete the store&#39;s releases.
   **/
  export function deleteStoreReleaseReleases(params: DeleteStoreReleaseReleasesParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/private/apps/{owner_name}/{app_name}/distribution_stores/{store_id}/releases/{release_id}";
    const payload = {
      params: stringifyParams(serializeDeleteStoreReleaseReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "DELETE /v0.1/private/apps/{owner_name}/{app_name}/distribution_stores/{store_id}/releases/{release_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DeleteTesterFromDestinationsReleasesParams {
    [key: string]: any;
    // The id of the tester
    testerId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeDeleteTesterFromDestinationsReleasesParams(input: DeleteTesterFromDestinationsReleasesParams): any {
    const pathParams = {
      tester_id: input["testerId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/testers/{tester_id}
   *
   * Delete the given tester from the all releases
   **/
  export function deleteTesterFromDestinationsReleases(
    params: DeleteTesterFromDestinationsReleasesParams,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/testers/{tester_id}";
    const payload = {
      params: stringifyParams(serializeDeleteTesterFromDestinationsReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "DELETE /v0.1/apps/{owner_name}/{app_name}/testers/{tester_id}", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DeleteWithDistributionGroupIdReleasesParams {
    [key: string]: any;
    // The name of the app owner
    ownerName: string;
    // The name of the app
    appName: string;
    // The name of the distribution group.
    distributionGroupName: string;
    // The ID identifying the unique release.
    releaseId: number;
  }

  // @ts-ignore
  function serializeDeleteWithDistributionGroupIdReleasesParams(input: DeleteWithDistributionGroupIdReleasesParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
      distribution_group_name: input["distributionGroupName"],
      release_id: input["releaseId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/releases/{release_id}
   *
   * Deletes a release with id &#39;release_id&#39; in a given distribution group.
   **/
  export function deleteWithDistributionGroupIdReleases(
    params: DeleteWithDistributionGroupIdReleasesParams,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/releases/{release_id}";
    const payload = {
      params: stringifyParams(serializeDeleteWithDistributionGroupIdReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "DELETE /v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/releases/{release_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface GetDistributionReleasesStatusWithoutDbServicesParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeGetDistributionReleasesStatusWithoutDbServicesParams(
    input: GetDistributionReleasesStatusWithoutDbServicesParams
  ): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/status_without_db
   *
   * Returns the service status to the caller
   **/
  export function getDistributionReleasesStatusWithoutDbServices(
    params: GetDistributionReleasesStatusWithoutDbServicesParams,
    options?: ApiClientOptions
  ): Promise<ApiStatusResponse> {
    const path = "/v0.1/status_without_db";
    const payload = {
      params: stringifyParams(serializeGetDistributionReleasesStatusWithoutDbServicesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiStatusResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiStatusResponse", result?.body, "GET /v0.1/status_without_db", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiStatusResponse(result);
        return resultOrResults as ApiStatusResponse;
      });
  }
  export interface GetDistributionSettingsReleasesParams {
    [key: string]: any;
    // The secret of the target application
    appSecret: string;
  }

  // @ts-ignore
  function serializeGetDistributionSettingsReleasesParams(input: GetDistributionSettingsReleasesParams): any {
    const pathParams = {
      app_secret: input["appSecret"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/sdk/apps/{app_secret}/distribution_settings
   *
   * Returns list of Distribution related Settings
   **/
  export function getDistributionSettingsReleases(
    params: GetDistributionSettingsReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiDistributionSettingsResponse> {
    const path = "/v0.1/sdk/apps/{app_secret}/distribution_settings";
    const payload = {
      params: stringifyParams(serializeGetDistributionSettingsReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiDistributionSettingsResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiDistributionSettingsResponse",
            result?.body,
            "GET /v0.1/sdk/apps/{app_secret}/distribution_settings",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiDistributionSettingsResponse(result);
        return resultOrResults as ApiDistributionSettingsResponse;
      });
  }
  export interface GetIosManifestReleasesParams {
    [key: string]: any;
    // The ID of the application
    appId: string;
    // The release_id
    releaseId: number;
    // A hash that authorizes the download if it matches the release info.
    token: string;
  }

  // @ts-ignore
  function serializeGetIosManifestReleasesParams(input: GetIosManifestReleasesParams): any {
    const pathParams = {
      app_id: input["appId"],
      release_id: input["releaseId"],
    };

    const queryParams = {
      token: input["token"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/public/apps/{app_id}/releases/{release_id}/ios_manifest
   *
   * Returns the manifest.plist in XML format for installing the release on a device. Only available for iOS.
   **/
  export function getIosManifestReleases(params: GetIosManifestReleasesParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/public/apps/{app_id}/releases/{release_id}/ios_manifest";
    const payload = {
      params: stringifyParams(serializeGetIosManifestReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "GET /v0.1/public/apps/{app_id}/releases/{release_id}/ios_manifest", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface GetLatestAppReleasesForUserReleasesManagementParams {
    [key: string]: any;
    // The ID of the application
    appId: string;
    // The ID of the user for which admin want to see releases of specified app
    userId: string;
    // The number of releases to return
    top?: number;
  }

  // @ts-ignore
  function serializeGetLatestAppReleasesForUserReleasesManagementParams(
    input: GetLatestAppReleasesForUserReleasesManagementParams
  ): any {
    const pathParams = {
      app_id: input["appId"],
      user_id: input["userId"],
    };

    const queryParams = {
      top: input["top"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/apps/{app_id}/users/{user_id}/releases
   *
   * Gets the recent releases, including deleted releases, for the specified app for specified user.
   **/
  export function getLatestAppReleasesForUserReleasesManagement(
    params: GetLatestAppReleasesForUserReleasesManagementParams,
    options?: ApiClientOptions
  ): Promise<ApiManagementReleaseDetailsResponse[]> {
    const path = "/v0.1/management/apps/{app_id}/users/{user_id}/releases";
    const payload = {
      params: stringifyParams(serializeGetLatestAppReleasesForUserReleasesManagementParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiManagementReleaseDetailsResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiManagementReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/management/apps/{app_id}/users/{user_id}/releases"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiManagementReleaseDetailsResponse(r));
        return resultOrResults as ApiManagementReleaseDetailsResponse[];
      });
  }
  export interface GetLatestAppReleasesReleasesManagementParams {
    [key: string]: any;
    // The ID of the application
    appId: string;
    // The number of releases to return
    top?: number;
  }

  // @ts-ignore
  function serializeGetLatestAppReleasesReleasesManagementParams(input: GetLatestAppReleasesReleasesManagementParams): any {
    const pathParams = {
      app_id: input["appId"],
    };

    const queryParams = {
      top: input["top"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/apps/{app_id}/releases
   *
   * Gets the recent releases, including deleted releases, for the specified app.
   **/
  export function getLatestAppReleasesReleasesManagement(
    params: GetLatestAppReleasesReleasesManagementParams,
    options?: ApiClientOptions
  ): Promise<ApiManagementReleaseDetailsResponse[]> {
    const path = "/v0.1/management/apps/{app_id}/releases";
    const payload = {
      params: stringifyParams(serializeGetLatestAppReleasesReleasesManagementParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiManagementReleaseDetailsResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiManagementReleaseDetailsResponse", result?.body, "GET /v0.1/management/apps/{app_id}/releases");
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiManagementReleaseDetailsResponse(r));
        return resultOrResults as ApiManagementReleaseDetailsResponse[];
      });
  }
  export interface GetLatestByDistributionGroupReleasesParams {
    [key: string]: any;
    // The name of the app owner
    ownerName: string;
    // The name of the app
    appName: string;
    // The name of the distribution group.
    distributionGroupName: string;
    // Also supports the constant &#x60;latest&#x60;, which will return the latest release in the distribution group.
    releaseId: string;
  }

  // @ts-ignore
  function serializeGetLatestByDistributionGroupReleasesParams(input: GetLatestByDistributionGroupReleasesParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
      distribution_group_name: input["distributionGroupName"],
      release_id: input["releaseId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/releases/{release_id}
   *
   * Return detailed information about a distributed release in a given distribution group.
   **/
  export function getLatestByDistributionGroupReleases(
    params: GetLatestByDistributionGroupReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDetailsResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/releases/{release_id}";
    const payload = {
      params: stringifyParams(serializeGetLatestByDistributionGroupReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDetailsResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/releases/{release_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDetailsResponse(result);
        return resultOrResults as ApiReleaseDetailsResponse;
      });
  }
  export interface GetLatestByHashReleasesParams {
    [key: string]: any;
    // The secret of the target application
    appSecret: string;
    // The hash of the release or &#39;latest&#39; to get the latest release from all the distribution groups assigned to the current user.
    releaseHash: string;
    // When passing &#x60;udid&#x60; in the query string, a provisioning check for the given device ID will be done. Will be ignored for non-iOS platforms.
    udid?: string;
  }

  // @ts-ignore
  function serializeGetLatestByHashReleasesParams(input: GetLatestByHashReleasesParams): any {
    const pathParams = {
      app_secret: input["appSecret"],
      release_hash: input["releaseHash"],
    };

    const queryParams = {
      udid: input["udid"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/sdk/apps/{app_secret}/releases/{release_hash}
   *
   * If &#39;latest&#39; is not specified then it will return the specified release if it&#39;s enabled. If &#39;latest&#39; is specified, regardless of whether a release hash is provided, the latest enabled release is returned.
   **/
  export function getLatestByHashReleases(
    params: GetLatestByHashReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDetailsResponse> {
    const path = "/v0.1/sdk/apps/{app_secret}/releases/{release_hash}";
    const payload = {
      params: stringifyParams(serializeGetLatestByHashReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDetailsResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/sdk/apps/{app_secret}/releases/{release_hash}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDetailsResponse(result);
        return resultOrResults as ApiReleaseDetailsResponse;
      });
  }
  export interface GetLatestByPublicDistributionGroupReleasesParams {
    [key: string]: any;
    // The secret of the target application
    appSecret: string;
    // the id for destination
    distributionGroupId: string;
  }

  // @ts-ignore
  function serializeGetLatestByPublicDistributionGroupReleasesParams(input: GetLatestByPublicDistributionGroupReleasesParams): any {
    const pathParams = {
      app_secret: input["appSecret"],
      distribution_group_id: input["distributionGroupId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/public/sdk/apps/{app_secret}/distribution_groups/{distribution_group_id}/releases/latest
   *
   * Get a release with &#39;latest&#39; for the given public group.
   **/
  export function getLatestByPublicDistributionGroupReleases(
    params: GetLatestByPublicDistributionGroupReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDetailsResponse> {
    const path = "/v0.1/public/sdk/apps/{app_secret}/distribution_groups/{distribution_group_id}/releases/latest";
    const payload = {
      params: stringifyParams(serializeGetLatestByPublicDistributionGroupReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDetailsResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/public/sdk/apps/{app_secret}/distribution_groups/{distribution_group_id}/releases/latest",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDetailsResponse(result);
        return resultOrResults as ApiReleaseDetailsResponse;
      });
  }
  export interface GetLatestByUserPrivateReleasesParams {
    [key: string]: any;
    // The ID of the application
    appId: string;
    // The ID of the release, or &#x60;latest&#x60; to get the latest release from all the distribution groups assigned to the current user.
    releaseId: string;
    // when supplied, this call will also check if the given UDID is provisioned. Will be ignored for non-iOS platforms. The value will be returned in the property is_udid_provisioned.
    udid?: string;
  }

  // @ts-ignore
  function serializeGetLatestByUserPrivateReleasesParams(input: GetLatestByUserPrivateReleasesParams): any {
    const pathParams = {
      app_id: input["appId"],
      release_id: input["releaseId"],
    };

    const queryParams = {
      udid: input["udid"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
                * GET /v0.1/private/apps/{app_id}/releases/{release_id}
                * 
                * This API is only meant to be used by the other services and not to be public.
This API skips &#x60;isCollaborator&#x60; check and always returns &#x60;buildInfo&#x60; in Response.
Gets a release with id &#x60;release_id&#x60;. If &#x60;release_id&#x60; is &#x60;latest&#x60;, return the latest release that was distributed to the current user (from all the distribution groups).

                **/
  export function getLatestByUserPrivateReleases(
    params: GetLatestByUserPrivateReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDetailsResponse> {
    const path = "/v0.1/private/apps/{app_id}/releases/{release_id}";
    const payload = {
      params: stringifyParams(serializeGetLatestByUserPrivateReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDetailsResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/private/apps/{app_id}/releases/{release_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDetailsResponse(result);
        return resultOrResults as ApiReleaseDetailsResponse;
      });
  }
  export interface GetLatestByUserReleasesParams {
    [key: string]: any;
    // The ID of the release, or &#x60;latest&#x60; to get the latest release from all the distribution groups assigned to the current user.
    releaseId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // when supplied, this call will also check if the given UDID is provisioned. Will be ignored for non-iOS platforms. The value will be returned in the property is_udid_provisioned.
    udid?: string;
    // The check if the request is from Install page
    isInstallPage?: boolean;
  }

  // @ts-ignore
  function serializeGetLatestByUserReleasesParams(input: GetLatestByUserReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      udid: input["udid"],
      is_install_page: input["isInstallPage"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}
   *
   * Get a release with id &#x60;release_id&#x60;. If &#x60;release_id&#x60; is &#x60;latest&#x60;, return the latest release that was distributed to the current user (from all the distribution groups).
   **/
  export function getLatestByUserReleases(
    params: GetLatestByUserReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDetailsResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}";
    const payload = {
      params: stringifyParams(serializeGetLatestByUserReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDetailsResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDetailsResponse(result);
        return resultOrResults as ApiReleaseDetailsResponse;
      });
  }
  export interface GetLatestByUserReleasesManagementParams {
    [key: string]: any;
    // The ID of the application
    appId: string;
    // The ID of the release, or &#x60;latest&#x60; to get the latest release from all the distribution groups assigned to the current user.
    releaseId: string;
    // when supplied, this call will also check if the given UDID is provisioned. Will be ignored for non-iOS platforms. The value will be returned in the property is_udid_provisioned.
    udid?: string;
  }

  // @ts-ignore
  function serializeGetLatestByUserReleasesManagementParams(input: GetLatestByUserReleasesManagementParams): any {
    const pathParams = {
      app_id: input["appId"],
      release_id: input["releaseId"],
    };

    const queryParams = {
      udid: input["udid"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/apps/{app_id}/releases/{release_id}
   *
   * Get a release with id &#x60;release_id&#x60;. If &#x60;release_id&#x60; is &#x60;latest&#x60;, return the latest release that was distributed to the current user (from all the distribution groups).
   **/
  export function getLatestByUserReleasesManagement(
    params: GetLatestByUserReleasesManagementParams,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDetailsResponse> {
    const path = "/v0.1/management/apps/{app_id}/releases/{release_id}";
    const payload = {
      params: stringifyParams(serializeGetLatestByUserReleasesManagementParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDetailsResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/management/apps/{app_id}/releases/{release_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDetailsResponse(result);
        return resultOrResults as ApiReleaseDetailsResponse;
      });
  }
  export interface GetLatestPrivateReleaseReleasesParams {
    [key: string]: any;
    // The secret of the target application
    appSecret: string;
    // When passing &#x60;udid&#x60; in the query string, a provisioning check for the given device ID will be done. Will be ignored for non-iOS platforms.
    udid?: string;
  }

  // @ts-ignore
  function serializeGetLatestPrivateReleaseReleasesParams(input: GetLatestPrivateReleaseReleasesParams): any {
    const pathParams = {
      app_secret: input["appSecret"],
    };

    const queryParams = {
      udid: input["udid"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/sdk/apps/{app_secret}/releases/private/latest
   *
   * Get the latest release distributed to a private group the given user is a member of for the given app.
   **/
  export function getLatestPrivateReleaseReleases(
    params: GetLatestPrivateReleaseReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDetailsResponse> {
    const path = "/v0.1/sdk/apps/{app_secret}/releases/private/latest";
    const payload = {
      params: stringifyParams(serializeGetLatestPrivateReleaseReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDetailsResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/sdk/apps/{app_secret}/releases/private/latest",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDetailsResponse(result);
        return resultOrResults as ApiReleaseDetailsResponse;
      });
  }
  export interface GetLatestPublicReleaseReleasesParams {
    [key: string]: any;
    // The secret of the target application
    appSecret: string;
  }

  // @ts-ignore
  function serializeGetLatestPublicReleaseReleasesParams(input: GetLatestPublicReleaseReleasesParams): any {
    const pathParams = {
      app_secret: input["appSecret"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/public/sdk/apps/{app_secret}/releases/latest
   *
   * Get the latest public release for the given app.
   **/
  export function getLatestPublicReleaseReleases(
    params: GetLatestPublicReleaseReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDetailsResponse> {
    const path = "/v0.1/public/sdk/apps/{app_secret}/releases/latest";
    const payload = {
      params: stringifyParams(serializeGetLatestPublicReleaseReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDetailsResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/public/sdk/apps/{app_secret}/releases/latest",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDetailsResponse(result);
        return resultOrResults as ApiReleaseDetailsResponse;
      });
  }
  export interface GetLatestReleaseInDestinationReleasesParams {
    [key: string]: any;
    // The ID of the application
    appId: string;
    // the id for destination
    distributionGroupId: string;
  }

  // @ts-ignore
  function serializeGetLatestReleaseInDestinationReleasesParams(input: GetLatestReleaseInDestinationReleasesParams): any {
    const pathParams = {
      app_id: input["appId"],
      distribution_group_id: input["distributionGroupId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/private/apps/{app_id}/distribution_groups/{distribution_group_id}/latest_release
   *
   * Get the latest detailed information of release in provided destination. Since all store releases are created in App Center only, this API doesn’t support releases HockeyApp originated releases. This API is only meant to be used by the DistributionStore team.
   **/
  export function getLatestReleaseInDestinationReleases(
    params: GetLatestReleaseInDestinationReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiPrivateReleaseDetailsResponse[]> {
    const path = "/v0.1/private/apps/{app_id}/distribution_groups/{distribution_group_id}/latest_release";
    const payload = {
      params: stringifyParams(serializeGetLatestReleaseInDestinationReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiPrivateReleaseDetailsResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiPrivateReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/private/apps/{app_id}/distribution_groups/{distribution_group_id}/latest_release"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiPrivateReleaseDetailsResponse(r));
        return resultOrResults as ApiPrivateReleaseDetailsResponse[];
      });
  }
  export interface GetLatestReleasesForHockeySdkReleasesParams {
    [key: string]: any;
    // The ID of the application
    appSecret: string;
    // The ID of the default distribution group for the app
    defaultGroupId?: string;
  }

  // @ts-ignore
  function serializeGetLatestReleasesForHockeySdkReleasesParams(input: GetLatestReleasesForHockeySdkReleasesParams): any {
    const pathParams = {
      app_secret: input["appSecret"],
    };

    const queryParams = {
      default_group_id: input["defaultGroupId"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/sdk/apps/{app_secret}/latest_releases_for_hockeysdk
   *
   * Returns the basic information of all App Center releases an anonymous HockeySDK user has access to (up to 100 releases).
   **/
  export function getLatestReleasesForHockeySdkReleases(
    params: GetLatestReleasesForHockeySdkReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiHockeyAppCompatibilityReleaseResponse[]> {
    const path = "/v0.1/sdk/apps/{app_secret}/latest_releases_for_hockeysdk";
    const payload = {
      params: stringifyParams(serializeGetLatestReleasesForHockeySdkReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiHockeyAppCompatibilityReleaseResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiHockeyAppCompatibilityReleaseResponse",
            result?.body,
            "GET /v0.1/sdk/apps/{app_secret}/latest_releases_for_hockeysdk"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiHockeyAppCompatibilityReleaseResponse(r));
        return resultOrResults as ApiHockeyAppCompatibilityReleaseResponse[];
      });
  }
  export interface GetLatestReleasesForHockeySdkReleases0Params {
    [key: string]: any;
    // The ID of the default distribution group for the app
    defaultGroupId?: string;
    // The ID of the user
    userId?: string;
  }

  // @ts-ignore
  function serializeGetLatestReleasesForHockeySdkReleases0Params(input: GetLatestReleasesForHockeySdkReleases0Params): any {
    const pathParams = {};

    const queryParams = {
      default_group_id: input["defaultGroupId"],
      user_id: input["userId"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/private/apps/{app_id}/latest_releases_for_hockeysdk
   *
   * Returns the basic information of all App Center releases an anonymous HockeySDK user has access to (up to 100 releases).
   **/
  export function getLatestReleasesForHockeySdkReleases_1(
    params: GetLatestReleasesForHockeySdkReleases0Params,
    options?: ApiClientOptions
  ): Promise<ApiHockeyAppCompatibilityReleaseResponse[]> {
    const path = "/v0.1/private/apps/{app_id}/latest_releases_for_hockeysdk";
    const payload = {
      params: stringifyParams(serializeGetLatestReleasesForHockeySdkReleases0Params(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiHockeyAppCompatibilityReleaseResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiHockeyAppCompatibilityReleaseResponse",
            result?.body,
            "GET /v0.1/private/apps/{app_id}/latest_releases_for_hockeysdk"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiHockeyAppCompatibilityReleaseResponse(r));
        return resultOrResults as ApiHockeyAppCompatibilityReleaseResponse[];
      });
  }
  export interface GetProvisioningconfigParams {
    [key: string]: any;
    // The name of the destination.
    destinationName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeGetProvisioningconfigParams(input: GetProvisioningconfigParams): any {
    const pathParams = {
      destination_name: input["destinationName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/destinations/{destination_name}/provisioning_config
   *
   * Return information about auto provisioning configuration in a given app and destination.
   **/
  export function getProvisioningconfig(
    params: GetProvisioningconfigParams,
    options?: ApiClientOptions
  ): Promise<ApiAutoProvisioningConfigResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/destinations/{destination_name}/provisioning_config";
    const payload = {
      params: stringifyParams(serializeGetProvisioningconfigParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiAutoProvisioningConfigResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiAutoProvisioningConfigResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/destinations/{destination_name}/provisioning_config",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiAutoProvisioningConfigResponse(result);
        return resultOrResults as ApiAutoProvisioningConfigResponse;
      });
  }
  export interface GetPublicGroupsForReleaseByHashReleasesParams {
    [key: string]: any;
    // The secret of the target application
    appSecret: string;
    // The hash of the release
    releaseHash: string;
  }

  // @ts-ignore
  function serializeGetPublicGroupsForReleaseByHashReleasesParams(input: GetPublicGroupsForReleaseByHashReleasesParams): any {
    const pathParams = {
      app_secret: input["appSecret"],
      release_hash: input["releaseHash"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/public/sdk/apps/{app_secret}/releases/{release_hash}/public_distribution_groups
   *
   * Get all public distribution groups that a given release has been distributed to
   **/
  export function getPublicGroupsForReleaseByHashReleases(
    params: GetPublicGroupsForReleaseByHashReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiPublicDistributionGroupsResponse[]> {
    const path = "/v0.1/public/sdk/apps/{app_secret}/releases/{release_hash}/public_distribution_groups";
    const payload = {
      params: stringifyParams(serializeGetPublicGroupsForReleaseByHashReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiPublicDistributionGroupsResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiPublicDistributionGroupsResponse",
            result?.body,
            "GET /v0.1/public/sdk/apps/{app_secret}/releases/{release_hash}/public_distribution_groups"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiPublicDistributionGroupsResponse(r));
        return resultOrResults as ApiPublicDistributionGroupsResponse[];
      });
  }
  export interface GetRecentReleasesReleasesParams {
    [key: string]: any;
    // The ID of the application
    appId: string;
    // the destination type for recent releases can store for now
    destinationType?: "group" | "store" | "tester";
  }

  // @ts-ignore
  function serializeGetRecentReleasesReleasesParams(input: GetRecentReleasesReleasesParams): any {
    const pathParams = {
      app_id: input["appId"],
    };

    const queryParams = {
      destination_type: input["destinationType"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
                * GET /v0.1/private/apps/{app_id}/distribution_groups/recent_releases
                * 
                * Get the latest release of every distribution group in the app. This API is used by the store team to fetch the latest release of every store group.
All store releases are created in App Center and not in HockeyApp, so this API doesn’t support HockeyApp originated releases.
The Store API is calling it with destination_type&#x3D;store.

                **/
  export function getRecentReleasesReleases(
    params: GetRecentReleasesReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiPrivateBasicReleaseDetailsResponse[]> {
    const path = "/v0.1/private/apps/{app_id}/distribution_groups/recent_releases";
    const payload = {
      params: stringifyParams(serializeGetRecentReleasesReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiPrivateBasicReleaseDetailsResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiPrivateBasicReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/private/apps/{app_id}/distribution_groups/recent_releases"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiPrivateBasicReleaseDetailsResponse(r));
        return resultOrResults as ApiPrivateBasicReleaseDetailsResponse[];
      });
  }
  export interface GetReleaseUploadStatusReleasesParams {
    [key: string]: any;
    // The ID of the release upload
    uploadId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeGetReleaseUploadStatusReleasesParams(input: GetReleaseUploadStatusReleasesParams): any {
    const pathParams = {
      upload_id: input["uploadId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/uploads/releases/{upload_id}
   *
   * Get the current status of the release upload.
   **/
  export function getReleaseUploadStatusReleases(
    params: GetReleaseUploadStatusReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiGetReleaseStatusResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/uploads/releases/{upload_id}";
    const payload = {
      params: stringifyParams(serializeGetReleaseUploadStatusReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiGetReleaseStatusResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiGetReleaseStatusResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/uploads/releases/{upload_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiGetReleaseStatusResponse(result);
        return resultOrResults as ApiGetReleaseStatusResponse;
      });
  }
  export interface GetReleasesByPublicDistributionGroupReleasesParams {
    [key: string]: any;
    // The name of the app owner
    ownerName: string;
    // The name of the app
    appName: string;
    // The name of the distribution group.
    distributionGroupName: string;
  }

  // @ts-ignore
  function serializeGetReleasesByPublicDistributionGroupReleasesParams(
    input: GetReleasesByPublicDistributionGroupReleasesParams
  ): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
      distribution_group_name: input["distributionGroupName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/public_releases
   *
   * Returns basic release information for a given public distribution group.
   **/
  export function getReleasesByPublicDistributionGroupReleases(
    params: GetReleasesByPublicDistributionGroupReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDetailsResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/public_releases";
    const payload = {
      params: stringifyParams(serializeGetReleasesByPublicDistributionGroupReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDetailsResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/public_releases",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDetailsResponse(result);
        return resultOrResults as ApiReleaseDetailsResponse;
      });
  }
  export interface GetSparkleFeedReleasesParams {
    [key: string]: any;
    // The secret of the application.
    appSecret: string;
  }

  // @ts-ignore
  function serializeGetSparkleFeedReleasesParams(input: GetSparkleFeedReleasesParams): any {
    const pathParams = {
      app_secret: input["appSecret"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/public/sparkle/apps/{app_secret}
   *
   * Gets the sparkle feed of the releases that are distributed to all the public distribution groups.
   **/
  export function getSparkleFeedReleases(params: GetSparkleFeedReleasesParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/public/sparkle/apps/{app_secret}";
    const payload = {
      params: stringifyParams(serializeGetSparkleFeedReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "GET /v0.1/public/sparkle/apps/{app_secret}", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface GetUploadMetadataUploadsParams {
    [key: string]: any;
    // The ID of the upload
    uploadId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeGetUploadMetadataUploadsParams(input: GetUploadMetadataUploadsParams): any {
    const pathParams = {
      upload_id: input["uploadId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/private/apps/{owner_name}/{app_name}/uploads/metadata/{upload_id}
   *
   * Gets upload metadata for a release upload
   **/
  export function getUploadMetadataUploads(params: GetUploadMetadataUploadsParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/private/apps/{owner_name}/{app_name}/uploads/metadata/{upload_id}";
    const payload = {
      params: stringifyParams(serializeGetUploadMetadataUploadsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "GET /v0.1/private/apps/{owner_name}/{app_name}/uploads/metadata/{upload_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface InstallAnalyticsDistibutionReleasesParams {
    [key: string]: any;
    // The name of the app owner
    ownerName: string;
    // The name of the app
    appName: string;
  }

  // @ts-ignore
  function serializeInstallAnalyticsDistibutionReleasesParams(input: InstallAnalyticsDistibutionReleasesParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/public/apps/{owner_name}/{app_name}/install_analytics
   *
   * Notify download(s) for the provided distribution release(s).
   **/
  export function installAnalyticsDistibutionReleases(
    params: InstallAnalyticsDistibutionReleasesParams,
    body: ApiAnalyticsReleasesResponse,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/public/apps/{owner_name}/{app_name}/install_analytics";
    const payload = {
      params: stringifyParams(serializeInstallAnalyticsDistibutionReleasesParams(params)),
      body: serializeApiAnalyticsReleasesResponse(body),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/public/apps/{owner_name}/{app_name}/install_analytics", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface ListByDistributionGroupIdReleasesParams {
    [key: string]: any;
    // The id of the distribution group.
    distributionGroupId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeListByDistributionGroupIdReleasesParams(input: ListByDistributionGroupIdReleasesParams): any {
    const pathParams = {
      distribution_group_id: input["distributionGroupId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/private/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_id}/releases
   *
   * Return basic information about distributed releases in a given distribution group.
   **/
  export function listByDistributionGroupIdReleases(
    params: ListByDistributionGroupIdReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiPrivateBasicReleaseDetailsResponse[]> {
    const path = "/v0.1/private/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_id}/releases";
    const payload = {
      params: stringifyParams(serializeListByDistributionGroupIdReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiPrivateBasicReleaseDetailsResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiPrivateBasicReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/private/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_id}/releases"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiPrivateBasicReleaseDetailsResponse(r));
        return resultOrResults as ApiPrivateBasicReleaseDetailsResponse[];
      });
  }
  export interface ListByDistributionGroupReleasesParams {
    [key: string]: any;
    // The name of the distribution group.
    distributionGroupName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeListByDistributionGroupReleasesParams(input: ListByDistributionGroupReleasesParams): any {
    const pathParams = {
      distribution_group_name: input["distributionGroupName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/releases
   *
   * Return basic information about distributed releases in a given distribution group.
   **/
  export function listByDistributionGroupReleases(
    params: ListByDistributionGroupReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiDistributionGroupReleasesResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/releases";
    const payload = {
      params: stringifyParams(serializeListByDistributionGroupReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiDistributionGroupReleasesResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiDistributionGroupReleasesResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/releases",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiDistributionGroupReleasesResponse(result);
        return resultOrResults as ApiDistributionGroupReleasesResponse;
      });
  }
  export interface ListLatestReleasesParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeListLatestReleasesParams(input: ListLatestReleasesParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/recent_releases
   *
   * Get the latest release from every distribution group associated with an application.
   **/
  export function listLatestReleases(
    params: ListLatestReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiBasicReleaseDetailsResponse[]> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/recent_releases";
    const payload = {
      params: stringifyParams(serializeListLatestReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiBasicReleaseDetailsResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiBasicReleaseDetailsResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/recent_releases"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiBasicReleaseDetailsResponse(r));
        return resultOrResults as ApiBasicReleaseDetailsResponse[];
      });
  }
  export interface ListReleasesParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // When *true*, filters out releases that were uploaded but were never distributed. Releases that under deleted distribution groups will not be filtered out.
    publishedOnly?: boolean;
    // When the scope is &#39;tester&#39;, only includes releases that have been distributed to groups that the user belongs to.
    scope?: string;
  }

  // @ts-ignore
  function serializeListReleasesParams(input: ListReleasesParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      published_only: input["publishedOnly"],
      scope: input["scope"],
      top: input["top"],
      releaseId: input["releaseId"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/releases
   *
   * Return basic information about releases.
   **/
  export function listReleases(params: ListReleasesParams, options?: ApiClientOptions): Promise<ApiBasicReleaseDetailsResponse[]> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases";
    const payload = {
      params: stringifyParams(serializeListReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiBasicReleaseDetailsResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiBasicReleaseDetailsResponse", result?.body, "GET /v0.1/apps/{owner_name}/{app_name}/releases");
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiBasicReleaseDetailsResponse(r));
        return resultOrResults as ApiBasicReleaseDetailsResponse[];
      });
  }
  export interface ListTesterAppsReleasesParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeListTesterAppsReleasesParams(input: ListTesterAppsReleasesParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/tester/apps
   *
   * Return a list of applications that the user has tester permission to with the latest release for each.
   **/
  export function listTesterAppsReleases(
    params: ListTesterAppsReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiTesterAppWithReleaseResponse[]> {
    const path = "/v0.1/tester/apps";
    const payload = {
      params: stringifyParams(serializeListTesterAppsReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiTesterAppWithReleaseResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiTesterAppWithReleaseResponse", result?.body, "GET /v0.1/tester/apps");
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiTesterAppWithReleaseResponse(r));
        return resultOrResults as ApiTesterAppWithReleaseResponse[];
      });
  }
  export interface NotificationSendParams {
    [key: string]: any;
    // distribution group name.
    distributionGroupName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeNotificationSendParams(input: NotificationSendParams): any {
    const pathParams = {
      distribution_group_name: input["distributionGroupName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/notifications/latest_release
   *
   * Send a release notification email
   **/
  export function notificationSend(
    params: NotificationSendParams,
    body: ApiSendNotificationRequest,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/notifications/latest_release";
    const payload = {
      params: stringifyParams(serializeNotificationSendParams(params)),
      body: serializeApiSendNotificationRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_name}/notifications/latest_release",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface PatchExternalReleaseDownloadUrlPrivateReleasesParams {
    [key: string]: any;
    // The ID of the application
    appId: string;
    // The ID of the release
    releaseId: number;
  }

  // @ts-ignore
  function serializePatchExternalReleaseDownloadUrlPrivateReleasesParams(
    input: PatchExternalReleaseDownloadUrlPrivateReleasesParams
  ): any {
    const pathParams = {
      app_id: input["appId"],
      release_id: input["releaseId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
                * POST /v0.1/private/apps/{app_id}/releases/{release_id}/external_download_url
                * 
                * This API is only meant to be used by the other services and not to be public.
Particularly this API is use by HockeyApp when moving release from S3 to ACFUS

                **/
  export function patchExternalReleaseDownloadUrlPrivateReleases(
    params: PatchExternalReleaseDownloadUrlPrivateReleasesParams,
    body?: ApiPostExternalDownloadUrl,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDetailsResponse> {
    const path = "/v0.1/private/apps/{app_id}/releases/{release_id}/external_download_url";
    const payload = {
      params: stringifyParams(serializePatchExternalReleaseDownloadUrlPrivateReleasesParams(params)),
      body: body && serializeApiPostExternalDownloadUrl(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDetailsResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDetailsResponse",
            result?.body,
            "POST /v0.1/private/apps/{app_id}/releases/{release_id}/external_download_url",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDetailsResponse(result);
        return resultOrResults as ApiReleaseDetailsResponse;
      });
  }
  export interface PatchPublishingStatusReleasesParams {
    [key: string]: any;
    // The ID of the release.
    releaseId: number;
    // The ID of the distribution store id.
    storeId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializePatchPublishingStatusReleasesParams(input: PatchPublishingStatusReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      store_id: input["storeId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * PATCH /v0.1/private/apps/{owner_name}/{app_name}/distribution_stores/{store_id}/releases/{release_id}
   *
   * Private API to update the store&#39;s publish status.
   **/
  export function patchPublishingStatusReleases(
    params: PatchPublishingStatusReleasesParams,
    body: ApiPrivateReleaseUpdateRequest,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/private/apps/{owner_name}/{app_name}/distribution_stores/{store_id}/releases/{release_id}";
    const payload = {
      params: stringifyParams(serializePatchPublishingStatusReleasesParams(params)),
      body: serializeApiPrivateReleaseUpdateRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod("PATCH", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "PATCH /v0.1/private/apps/{owner_name}/{app_name}/distribution_stores/{store_id}/releases/{release_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface PatchReleaseAssetIdPrivateReleasesParams {
    [key: string]: any;
    // The ID of the application
    appId: string;
    // The ID of the release
    releaseId: number;
  }

  // @ts-ignore
  function serializePatchReleaseAssetIdPrivateReleasesParams(input: PatchReleaseAssetIdPrivateReleasesParams): any {
    const pathParams = {
      app_id: input["appId"],
      release_id: input["releaseId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
                * PATCH /v0.1/private/apps/{app_id}/releases/{release_id}
                * 
                * This API is only meant to be used by the other services and not to be public.
Particularly this API is use by HockeyApp when moving release from S3 to ACFUS

                **/
  export function patchReleaseAssetIdPrivateReleases(
    params: PatchReleaseAssetIdPrivateReleasesParams,
    body?: ApiPatchReleaseAssetIdRequest,
    options?: ApiClientOptions
  ): Promise<ApiBasicReleaseDetailsResponse> {
    const path = "/v0.1/private/apps/{app_id}/releases/{release_id}";
    const payload = {
      params: stringifyParams(serializePatchReleaseAssetIdPrivateReleasesParams(params)),
      body: body && serializeApiPatchReleaseAssetIdRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiBasicReleaseDetailsResponse>("PATCH", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiBasicReleaseDetailsResponse",
            result?.body,
            "PATCH /v0.1/private/apps/{app_id}/releases/{release_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiBasicReleaseDetailsResponse(result);
        return resultOrResults as ApiBasicReleaseDetailsResponse;
      });
  }
  export interface PatchReleaseExternalUrlReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: number;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializePatchReleaseExternalUrlReleasesParams(input: PatchReleaseExternalUrlReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
                * PATCH /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/external_download_url
                * 
                * API for updating external release release url.
Should work only if the url is empty or is still in HockeyApp.

                **/
  export function patchReleaseExternalUrlReleases(
    params: PatchReleaseExternalUrlReleasesParams,
    body: ApiUpdateExternalUrlRequest,
    options?: ApiClientOptions
  ): Promise<ApiReleaseExternalUrlUpdateResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/external_download_url";
    const payload = {
      params: stringifyParams(serializePatchReleaseExternalUrlReleasesParams(params)),
      body: serializeApiUpdateExternalUrlRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseExternalUrlUpdateResponse>("PATCH", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseExternalUrlUpdateResponse",
            result?.body,
            "PATCH /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/external_download_url",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseExternalUrlUpdateResponse(result);
        return resultOrResults as ApiReleaseExternalUrlUpdateResponse;
      });
  }
  export interface PatchUploadDetailsPrivateUploadsParams {
    [key: string]: any;
    // The ID of the upload
    uploadId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializePatchUploadDetailsPrivateUploadsParams(input: PatchUploadDetailsPrivateUploadsParams): any {
    const pathParams = {
      upload_id: input["uploadId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * PATCH /v0.1/private/apps/{owner_name}/{app_name}/uploads/releases/{upload_id}
   *
   * Updates the releases-uploads table with an error if the extraction fails
   **/
  export function patchUploadDetailsPrivateUploads(
    params: PatchUploadDetailsPrivateUploadsParams,
    body: ApiPrivateUpdateUploadDetails,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/private/apps/{owner_name}/{app_name}/uploads/releases/{upload_id}";
    const payload = {
      params: stringifyParams(serializePatchUploadDetailsPrivateUploadsParams(params)),
      body: serializeApiPrivateUpdateUploadDetails(body),
    };
    Object.assign(payload, options);

    return getApiMethod("PATCH", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "PATCH /v0.1/private/apps/{owner_name}/{app_name}/uploads/releases/{upload_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface PostExternalReleaseReleasesParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializePostExternalReleaseReleasesParams(input: PostExternalReleaseReleasesParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/releases
   *
   * Creates a new externally hosted release.
   **/
  export function postExternalReleaseReleases(
    params: PostExternalReleaseReleasesParams,
    body: ApiExternallyHostedReleaseCreateRequest,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDetailsResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases";
    const payload = {
      params: stringifyParams(serializePostExternalReleaseReleasesParams(params)),
      body: serializeApiExternallyHostedReleaseCreateRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDetailsResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiReleaseDetailsResponse", result?.body, "POST /v0.1/apps/{owner_name}/{app_name}/releases", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDetailsResponse(result);
        return resultOrResults as ApiReleaseDetailsResponse;
      });
  }
  export interface PostPurgeVerificationReleasesParams {
    [key: string]: any;
    // The ID of the application
    appId: string;
  }

  // @ts-ignore
  function serializePostPurgeVerificationReleasesParams(input: PostPurgeVerificationReleasesParams): any {
    const pathParams = {
      app_id: input["appId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{app_id}/verify_gdpr_purge
   *
   * Verify if GDPR purge operation has been performed successfully.
   **/
  export function postPurgeVerificationReleases(
    params: PostPurgeVerificationReleasesParams,
    body: ApiGDPRValidationRequest,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/apps/{app_id}/verify_gdpr_purge";
    const payload = {
      params: stringifyParams(serializePostPurgeVerificationReleasesParams(params)),
      body: serializeApiGDPRValidationRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<any>("POST", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("any", result?.body, "POST /v0.1/apps/{app_id}/verify_gdpr_purge", false);
      }
      return result.body;
    });
  }
  export interface PostReleaseUploadBackfillReleasesManagementParams {
    [key: string]: any;
    // The unique value to seed as the placeholder database ID for the ReleaseUpload.
    releaseUploadId: string;
  }

  // @ts-ignore
  function serializePostReleaseUploadBackfillReleasesManagementParams(input: PostReleaseUploadBackfillReleasesManagementParams): any {
    const pathParams = {
      release_upload_id: input["releaseUploadId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/management/gdpr_audit_tool/release_upload/{release_upload_id}
   *
   * Backfills a release upload that will be used to clean up orphaned data for GDPR.
   **/
  export function postReleaseUploadBackfillReleasesManagement(
    params: PostReleaseUploadBackfillReleasesManagementParams,
    body?: ApiPostPlaceholderReleaseUploadForDeleteRequest,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/management/gdpr_audit_tool/release_upload/{release_upload_id}";
    const payload = {
      params: stringifyParams(serializePostReleaseUploadBackfillReleasesManagementParams(params)),
      body: body && serializeApiPostPlaceholderReleaseUploadForDeleteRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/management/gdpr_audit_tool/release_upload/{release_upload_id}", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface PostScanResultReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializePostScanResultReleasesParams(input: PostScanResultReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/private/apps/{owner_name}/{app_name}/releases/{release_id}/scan_result
   *
   * Accepts scan results from Malware Scan service
   **/
  export function postScanResultReleases(
    params: PostScanResultReleasesParams,
    body: ApiMalwareScanResultPayload,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/private/apps/{owner_name}/{app_name}/releases/{release_id}/scan_result";
    const payload = {
      params: stringifyParams(serializePostScanResultReleasesParams(params)),
      body: serializeApiMalwareScanResultPayload(body),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "POST /v0.1/private/apps/{owner_name}/{app_name}/releases/{release_id}/scan_result",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface ProfileByDistributionGroupProvisioningParams {
    [key: string]: any;
    // The id of the distribution group.
    distributionGroupId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeProfileByDistributionGroupProvisioningParams(input: ProfileByDistributionGroupProvisioningParams): any {
    const pathParams = {
      distribution_group_id: input["distributionGroupId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/private/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_id}/provisioning_profile
   *
   * Return the provisioning profile of the latest release in the distribution group. This API is only supported for iOS apps.
   **/
  export function profileByDistributionGroupProvisioning(
    params: ProfileByDistributionGroupProvisioningParams,
    options?: ApiClientOptions
  ): Promise<ApiProvisioningProfileResponse> {
    const path = "/v0.1/private/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_id}/provisioning_profile";
    const payload = {
      params: stringifyParams(serializeProfileByDistributionGroupProvisioningParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiProvisioningProfileResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiProvisioningProfileResponse",
            result?.body,
            "GET /v0.1/private/apps/{owner_name}/{app_name}/distribution_groups/{distribution_group_id}/provisioning_profile",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiProvisioningProfileResponse(result);
        return resultOrResults as ApiProvisioningProfileResponse;
      });
  }
  export interface ProfileProvisioningParams {
    [key: string]: any;
    // The release_id
    releaseId: number;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeProfileProvisioningParams(input: ProfileProvisioningParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/provisioning_profile
   *
   * Return information about the provisioning profile. Only available for iOS.
   **/
  export function profileProvisioning(
    params: ProfileProvisioningParams,
    options?: ApiClientOptions
  ): Promise<ApiProvisioningProfileResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/provisioning_profile";
    const payload = {
      params: stringifyParams(serializeProfileProvisioningParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiProvisioningProfileResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiProvisioningProfileResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/provisioning_profile",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiProvisioningProfileResponse(result);
        return resultOrResults as ApiProvisioningProfileResponse;
      });
  }
  export interface ProvisionReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: number;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeProvisionReleasesParams(input: ProvisionReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
                * POST /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/provision
                * 
                * Provisions a release. The new release will have a provisioning profile that includes devices from all the release&#39;s destinations
that has auto-provisioning enabled.

                **/
  export function provisionReleases(
    params: ProvisionReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiReleaseProvisionResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/provision";
    const payload = {
      params: stringifyParams(serializeProvisionReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseProvisionResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseProvisionResponse",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/provision",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseProvisionResponse(result);
        return resultOrResults as ApiReleaseProvisionResponse;
      });
  }
  export interface PutDistributionGroupReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: number;
    // The id of the releases destination
    groupId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializePutDistributionGroupReleasesParams(input: PutDistributionGroupReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      group_id: input["groupId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * PUT /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/groups/{group_id}
   *
   * Update details about the specified distribution group associated with the release
   **/
  export function putDistributionGroupReleases(
    params: PutDistributionGroupReleasesParams,
    body?: ApiDestinationGroupUpdateDetails,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/groups/{group_id}";
    const payload = {
      params: stringifyParams(serializePutDistributionGroupReleasesParams(params)),
      body: body && serializeApiDestinationGroupUpdateDetails(body),
    };
    Object.assign(payload, options);

    return getApiMethod("PUT", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "PUT /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/groups/{group_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface PutDistributionTesterReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: number;
    // The id of the tester
    testerId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializePutDistributionTesterReleasesParams(input: PutDistributionTesterReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      tester_id: input["testerId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * PUT /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/testers/{tester_id}
   *
   * Update details about the specified tester associated with the release
   **/
  export function putDistributionTesterReleases(
    params: PutDistributionTesterReleasesParams,
    body?: ApiDestinationTesterUpdateDetails,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/testers/{tester_id}";
    const payload = {
      params: stringifyParams(serializePutDistributionTesterReleasesParams(params)),
      body: body && serializeApiDestinationTesterUpdateDetails(body),
    };
    Object.assign(payload, options);

    return getApiMethod("PUT", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "PUT /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}/testers/{tester_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface UpdateDetailsReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: number;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeUpdateDetailsReleasesParams(input: UpdateDetailsReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * PUT /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}
   *
   * Update details of a release.
   **/
  export function updateDetailsReleases(
    params: UpdateDetailsReleasesParams,
    body: ApiReleaseDetailsUpdateRequest,
    options?: ApiClientOptions
  ): Promise<ApiReleaseUpdateResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}";
    const payload = {
      params: stringifyParams(serializeUpdateDetailsReleasesParams(params)),
      body: serializeApiReleaseDetailsUpdateRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseUpdateResponse>("PUT", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseUpdateResponse",
            result?.body,
            "PUT /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseUpdateResponse(result);
        return resultOrResults as ApiReleaseUpdateResponse;
      });
  }
  export interface UpdateProvisioningconfigParams {
    [key: string]: any;
    // The name of the destination.
    destinationName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeUpdateProvisioningconfigParams(input: UpdateProvisioningconfigParams): any {
    const pathParams = {
      destination_name: input["destinationName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * PATCH /v0.1/apps/{owner_name}/{app_name}/destinations/{destination_name}/provisioning_config
   *
   * Updates provisioning configuration for a given app id and destination.
   **/
  export function updateProvisioningconfig(
    params: UpdateProvisioningconfigParams,
    body: ApiAutoProvisioningConfigRequest,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/destinations/{destination_name}/provisioning_config";
    const payload = {
      params: stringifyParams(serializeUpdateProvisioningconfigParams(params)),
      body: serializeApiAutoProvisioningConfigRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod("PATCH", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "PATCH /v0.1/apps/{owner_name}/{app_name}/destinations/{destination_name}/provisioning_config",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface UpdateReleaseUploadStatusReleasesParams {
    [key: string]: any;
    // The ID of the release upload
    uploadId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // A flag that indicates to extract release or not, true by default
    extract?: boolean;
  }

  // @ts-ignore
  function serializeUpdateReleaseUploadStatusReleasesParams(input: UpdateReleaseUploadStatusReleasesParams): any {
    const pathParams = {
      upload_id: input["uploadId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      extract: input["extract"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * PATCH /v0.1/apps/{owner_name}/{app_name}/uploads/releases/{upload_id}
   *
   * Update the current status of the release upload.
   **/
  export function updateReleaseUploadStatusReleases(
    params: UpdateReleaseUploadStatusReleasesParams,
    body: ApiPatchReleaseUploadStatusRequest,
    options?: ApiClientOptions
  ): Promise<ApiPatchReleaseUploadStatusResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/uploads/releases/{upload_id}";
    const payload = {
      params: stringifyParams(serializeUpdateReleaseUploadStatusReleasesParams(params)),
      body: serializeApiPatchReleaseUploadStatusRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiPatchReleaseUploadStatusResponse>("PATCH", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiPatchReleaseUploadStatusResponse",
            result?.body,
            "PATCH /v0.1/apps/{owner_name}/{app_name}/uploads/releases/{upload_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiPatchReleaseUploadStatusResponse(result);
        return resultOrResults as ApiPatchReleaseUploadStatusResponse;
      });
  }
  export interface UpdateReleasesParams {
    [key: string]: any;
    // The ID of the release
    releaseId: number;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeUpdateReleasesParams(input: UpdateReleasesParams): any {
    const pathParams = {
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * PATCH /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}
   *
   * Updates a release.
   **/
  export function updateReleases(
    params: UpdateReleasesParams,
    body: ApiReleaseUpdateRequest,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDetailsUpdateResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/releases/{release_id}";
    const payload = {
      params: stringifyParams(serializeUpdateReleasesParams(params)),
      body: serializeApiReleaseUpdateRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDetailsUpdateResponse>("PATCH", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDetailsUpdateResponse",
            result?.body,
            "PATCH /v0.1/apps/{owner_name}/{app_name}/releases/{release_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDetailsUpdateResponse(result);
        return resultOrResults as ApiReleaseDetailsUpdateResponse;
      });
  }
  export interface UserInteractionMetricsReleasesParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeUserInteractionMetricsReleasesParams(input: UserInteractionMetricsReleasesParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/private/releases/user_metrics
   *
   * Return user interaction metrics (if user has less than 100 apps, if user has made at least 1 release across all apps). This endpoint is used for HaTS feature.
   **/
  export function userInteractionMetricsReleases(
    params: UserInteractionMetricsReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiUserInteractionMetricsResponse> {
    const path = "/v0.1/private/releases/user_metrics";
    const payload = {
      params: stringifyParams(serializeUserInteractionMetricsReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiUserInteractionMetricsResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiUserInteractionMetricsResponse", result?.body, "GET /v0.1/private/releases/user_metrics", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiUserInteractionMetricsResponse(result);
        return resultOrResults as ApiUserInteractionMetricsResponse;
      });
  }
}
