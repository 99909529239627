/**
 * grand-staircase
 * Full path of the API will be for example https://api.crashes.apphub.com/v1/apps/...
 *
 * OpenAPI spec version: 0.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

// ===============================================
// This file is autogenerated - Please do not edit
// ===============================================

export interface AlertingCrashGroup {
  url?: string;
  app_display_name?: string;
  /**
   * SDK/Platform this thread is beeing generated from
   */
  app_platform?: AlertingCrashGroup.AppPlatformEnum;
  app_version?: string;
  id?: string;
  name?: string;
  reason?: string;
  stack_trace?: string[];
}

export namespace AlertingCrashGroup {
  export type AppPlatformEnum = "ios" | "android" | "xamarin" | "react-native" | "ndk" | "unity" | "other";
  export const app_platform = {
    Ios: "ios" as AppPlatformEnum,
    Android: "android" as AppPlatformEnum,
    Xamarin: "xamarin" as AppPlatformEnum,
    ReactNative: "react-native" as AppPlatformEnum,
    Ndk: "ndk" as AppPlatformEnum,
    Unity: "unity" as AppPlatformEnum,
    Other: "other" as AppPlatformEnum,
  };
}

export interface AppCrashesInfo {
  has_crashes: boolean;
  features: AppFeatures;
}

export interface AppFeatures {
  /**
   * App supports modification of crashgroup status
   */
  crashgroup_modify_status?: boolean;
  /**
   * App supports modification of crashgroup annotation
   */
  crashgroup_modify_annotation?: boolean;
  /**
   * App supports search API
   */
  search?: boolean;
  /**
   * App supports the 'crash free user' metric
   */
  crashgroup_analytics_crashfreeusers?: boolean;
  /**
   * App supports the 'impacted users' metric
   */
  crashgroup_analytics_impactedusers?: boolean;
  /**
   * App supports download of raw crashes
   */
  crash_download_raw?: boolean;
}

export interface AppVersion {
  app_version_id: string;
  app_id: string;
  display_name: string;
  app_version: string;
  build_number?: string;
}

export interface Crash {
  details?: CrashDetails;
  /**
   *
   */
  new_crash_group_id: string;
  crash_id: string;
  new_crash_id: string;
  display_id?: string;
  timestamp: string;
  /**
   *
   */
  version: string;
  /**
   *
   */
  build: string;
  /**
   *
   */
  device: string;
  /**
   *
   */
  device_name?: string;
  /**
   *
   */
  os_version: string;
  /**
   *
   */
  os_type?: string;
  stacktrace?: Stacktrace;
  /**
   *
   */
  user_name: string;
  /**
   *
   */
  user_email?: string;
}

export interface CrashAttachment {
  app_id: string;
  attachment_id: string;
  crash_id: string;
  blob_location: string;
  content_type: string;
  file_name: string;
  created_time: string;
  size: number;
}

/**
 * Location for downloading crash attachment
 */

export interface CrashAttachmentLocation {
  uri: string;
}

export interface CrashDetails {
  /**
   * Carrier country code (for mobile devices).
   */
  carrier_country?: string;
  /**
   * Carrier name (for mobile devices).
   */
  carrier_name?: string;
  /**
   * Language code (example: en_US).
   */
  locale: string;
  /**
   * OS build code (example: LMY47X).
   */
  os_build?: string;
  /**
   * Whether the device where the crash occurred is rooted or jailbroken
   */
  rooted: boolean;
  /**
   * Screen size of the device in pixels (example: 640x480).
   */
  screen_size: string;
  /**
   * Application launch timestamp (example: 1985-04-12T23:20:50.52Z).
   */
  app_start_timestamp?: string;
}

export interface CrashGroup {
  crash_group_id: string;
  new_crash_group_id: string;
  display_id: string;
  app_version: string;
  build: string;
  status: CrashGroup.StatusEnum;
  count: number;
  impacted_users?: number;
  first_occurrence: string;
  last_occurrence: string;
  exception?: string;
  crash_reason: string;
  reason_frame?: ReasonStackFrame;
  /**
   * Crash or handled exception
   */
  fatal: boolean;
  annotation: string;
}

export namespace CrashGroup {
  export type StatusEnum = "open" | "closed" | "ignored";
  export type ErrorTypeEnum = "handlederror" | "unhandlederror";
  export const status = {
    Open: "open" as StatusEnum,
    Closed: "closed" as StatusEnum,
    Ignored: "ignored" as StatusEnum,
  };
  export const errorType = {
    HandledError: "handlederror" as ErrorTypeEnum,
    UnhandledError: "unhandlederror" as ErrorTypeEnum,
  };
}

export interface CrashGroupChange {
  status?: CrashGroupChange.StatusEnum;
  annotation?: string;
}

export namespace CrashGroupChange {
  export type StatusEnum = "open" | "closed" | "ignored";
  export const status = {
    Open: "open" as StatusEnum,
    Closed: "closed" as StatusEnum,
    Ignored: "ignored" as StatusEnum,
  };
}

export interface CrashGroupStatusEnum {}

export interface CrashGroupsContainer {
  limited_result_set: boolean;
  /**
   * Cassandra request continuation token. The token is used for pagination.
   */
  continuation_token?: string;
  crash_groups: CrashGroup[];
}

/**
 * Location for downloading crash raw
 */

export interface CrashRawLocation {
  uri: string;
}

/**
 * a exception
 */

export interface Exception {
  /**
   * Reason of the exception
   */
  reason?: string;
  /**
   * Type of the exception (NSSomethingException, NullPointerException)
   */
  type?: string;
  /**
   * frames of the excetpion
   */
  frames: StackFrame[];
  /**
   * relevant exception (crashed)
   */
  relevant?: boolean;
  inner_exceptions?: Exception[];
  /**
   * SDK/Platform this thread is beeing generated from
   */
  platform?: Exception.PlatformEnum;
}

export namespace Exception {
  export type PlatformEnum = "ios" | "android" | "xamarin" | "react-native" | "ndk" | "unity" | "other";
  export const platform = {
    Ios: "ios" as PlatformEnum,
    Android: "android" as PlatformEnum,
    Xamarin: "xamarin" as PlatformEnum,
    ReactNative: "react-native" as PlatformEnum,
    Ndk: "ndk" as PlatformEnum,
    Unity: "unity" as PlatformEnum,
    Other: "other" as PlatformEnum,
  };
}

export interface Failure {
  code: string;
  message: string;
}

export interface NativeCrashLog {}

export interface RawCrashLog {}

/**
 * frame belonging to the reason of the crash
 */

export interface ReasonStackFrame {
  /**
   * name of the class
   */
  class_name?: string;
  /**
   * name of the method
   */
  method?: string;
  /**
   * is a class method
   */
  class_method?: boolean;
  /**
   * name of the file
   */
  file?: string;
  /**
   * line number
   */
  line?: number;
  /**
   * this line isn't from any framework
   */
  app_code?: boolean;
  /**
   * Name of the framework
   */
  framework_name?: string;
  /**
   * Formatted frame string
   */
  code_formatted?: string;
  /**
   * Unformatted Frame string
   */
  code_raw?: string;
  /**
   * programming language of the frame
   */
  language?: ReasonStackFrame.LanguageEnum;
  /**
   * parameters of the frames method
   */
  method_params?: string;
  /**
   * Exception type.
   */
  exception_type?: string;
  /**
   * OS exception type. (aka. SIGNAL)
   */
  os_exception_type?: string;
}

export namespace ReasonStackFrame {
  export type LanguageEnum = "JavaScript" | "CSharp" | "Objective-C" | "Objective-Cpp" | "Cpp" | "C" | "Swift" | "Java" | "Unknown";
  export const language = {
    JavaScript: "JavaScript" as LanguageEnum,
    CSharp: "CSharp" as LanguageEnum,
    ObjectiveC: "Objective-C" as LanguageEnum,
    ObjectiveCpp: "Objective-Cpp" as LanguageEnum,
    Cpp: "Cpp" as LanguageEnum,
    C: "C" as LanguageEnum,
    Swift: "Swift" as LanguageEnum,
    Java: "Java" as LanguageEnum,
    Unknown: "Unknown" as LanguageEnum,
  };
}
/**
 * a single frame of a stack trace
 */

export interface StackFrame {
  /**
   * address of the frame
   */
  address?: string;
  /**
   * name of the class
   */
  class_name?: string;
  /**
   * name of the method
   */
  method?: string;
  /**
   * is a class method
   */
  class_method?: boolean;
  /**
   * name of the file
   */
  file?: string;
  /**
   * line number
   */
  line?: number;
  /**
   * this line isn't from any framework
   */
  app_code: boolean;
  /**
   * Name of the framework
   */
  framework_name?: string;
  /**
   * Raw frame string
   */
  code_raw: string;
  /**
   * Formatted frame string
   */
  code_formatted: string;
  /**
   * programming language of the frame
   */
  language?: StackFrame.LanguageEnum;
  /**
   * frame should be shown always
   */
  relevant?: boolean;
  /**
   * parameters of the frames method
   */
  method_params?: string;
}

export namespace StackFrame {
  export type LanguageEnum = "JavaScript" | "CSharp" | "Objective-C" | "Objective-Cpp" | "Cpp" | "C" | "Swift" | "Java" | "Unknown";
  export const language = {
    JavaScript: "JavaScript" as LanguageEnum,
    CSharp: "CSharp" as LanguageEnum,
    ObjectiveC: "Objective-C" as LanguageEnum,
    ObjectiveCpp: "Objective-Cpp" as LanguageEnum,
    Cpp: "Cpp" as LanguageEnum,
    C: "C" as LanguageEnum,
    Swift: "Swift" as LanguageEnum,
    Java: "Java" as LanguageEnum,
    Unknown: "Unknown" as LanguageEnum,
  };
}
/**
 * a stacktrace in a processed and prettyfied way
 */

export interface Stacktrace {
  title?: string;
  reason?: string;
  threads?: Thread[];
  exception?: Exception;
}

export interface StatusResponse {
  status: string;
}

/**
 * a thread representation
 */

export interface Thread {
  /**
   * name of the thread
   */
  title: string;
  /**
   * frames of that thread
   */
  frames: StackFrame[];
  /**
   * potential additional exception happened in that thread (Last Exception Backtrace)
   */
  exception?: Exception;
  /**
   * Shows if a thread is relevant or not. Is false if all frames are non relevant, otherwise true
   */
  relevant?: boolean;
  /**
   * SDK/Platform this thread is beeing generated from
   */
  platform?: Thread.PlatformEnum;
  /**
   * True if this thread crashed
   */
  crashed?: boolean;
}

export namespace Thread {
  export type PlatformEnum = "ios" | "android" | "xamarin" | "react-native" | "ndk" | "unity" | "other";
  export const platform = {
    Ios: "ios" as PlatformEnum,
    Android: "android" as PlatformEnum,
    Xamarin: "xamarin" as PlatformEnum,
    ReactNative: "react-native" as PlatformEnum,
    Ndk: "ndk" as PlatformEnum,
    Unity: "unity" as PlatformEnum,
    Other: "other" as PlatformEnum,
  };
}
