import { apiGateway } from "@root/lib/http";
import { Store } from "../../lib";
import { Build, DeserializedBuild, SerializedBuild } from "../models/build";
import { API, LATEST } from "../constants";
import { omitBy } from "lodash";
import { IDeserializedBuildInfo, ISerializedBuildInfo } from "@root/data/install";

export type BuildQueryOrOptions = {
  ownerName?: string;
  appName?: string;
  groupName?: string;
  udid?: string;
  checkLatest?: boolean;
  public?: boolean;
  is_install_page?: boolean;
  top?: number;
};

export class BuildStore extends Store<DeserializedBuild, SerializedBuild, Build, BuildQueryOrOptions> {
  protected ModelClass = Build;

  protected generateIdFromResponse(resource: SerializedBuild, query?: BuildQueryOrOptions): string {
    return resource.is_latest ? LATEST : this.compoundKey(this.getOwnerAndAppNames(query), resource.id.toString());
  }

  protected getModelId(model: Build): string {
    return model.isLatest ? LATEST : this.compoundKey(model.appIdentifier, model.id);
  }

  protected getResource(id: string, query?: BuildQueryOrOptions): Promise<SerializedBuild> {
    const params = {
      owner_name: query?.ownerName,
      app_name: query?.appName,
    };

    const options = {};
    let apiUrl: string;
    const [, , releaseId] = this.splitKey(id);

    if (query?.groupName) {
      apiUrl = API.LATEST_FOR_GROUP;
      params["group_name"] = query.groupName;
      params["release_id"] = releaseId;
      options["noBifrostToken"] = true;
    } else {
      // private
      apiUrl = API.BUILD;
      params["package_id"] = releaseId;
    }

    if (query?.udid) {
      params["udid"] = query.udid;
    }

    if (query?.checkLatest) {
      params["check_latest"] = query.checkLatest;
    }

    if (query?.is_install_page) {
      params["is_install_page"] = query.is_install_page;
    }

    return apiGateway.get<SerializedBuild>(apiUrl, { params, ...options });
  }

  protected getCollection(query?: BuildQueryOrOptions): Promise<SerializedBuild[]> {
    let url = API.BUILDS_FOR_TESTER;
    const options = {};
    const params = {
      owner_id: query?.ownerName,
      app_id: query?.appName,
      scope: "tester",
      top: query?.top?.toString(),
    };
    if (query?.groupName) {
      url = API.PUBLIC_RELEASES_FOR_GROUP;
      params["distribution_group_name"] = query.groupName;
      if (query.public) {
        options["noBifrostToken"] = true;
      }
    }
    if (!query?.public) {
      params["published_only"] = true;
    }
    return apiGateway.get<SerializedBuild[]>(url, { params, ...options });
  }

  protected deserialize(serialized: SerializedBuild, query): DeserializedBuild {
    const deserialized = {
      id: serialized.id.toString(),
      appName: serialized.app_name,
      appDisplayName: serialized.app_display_name,
      appOs: serialized.app_os,
      appVersion: serialized.short_version,
      buildNumber: serialized.version,
      timestamp: serialized.uploaded_at,
      releaseNotes: serialized.release_notes,
      size: serialized.size,
      minOsVersion: serialized.min_os,
      iconLink: serialized.app_icon_url,
      downloadLink: this.setOriginInDownloadUrl(serialized.download_url),
      installLink: this.setOriginInDownloadUrl(serialized.install_url),
      distributionGroups: serialized.distribution_groups,
      packageHashes: serialized.package_hashes,
      bundleIdentifier: serialized.bundle_identifier,
      provisioningProfileType: serialized.provisioning_profile_type,
      isProvisioningProfileSyncing: serialized.is_provisioning_profile_syncing,
      owner: serialized.owner,
      isUdidProvisioned: serialized.is_udid_provisioned,
      isLatest: serialized.is_latest,
      canResign: serialized.can_resign,
      isExternalBuild: serialized.is_external_build,
      appIdentifier: query && query.ownerName && query.appName ? this.compoundKey(query.ownerName, query.appName) : null,
      build: this.deserializeBuildInfo(serialized.build),
    };

    return omitBy(deserialized, (value) => value === undefined) as DeserializedBuild;
  }

  private setOriginInDownloadUrl(url: string | undefined): string | undefined {
    if (!url) {
      return undefined;
    }

    const parsedUrl = new URL(url);
    const wrappedUrl = parsedUrl.searchParams.get("url");
    if (wrappedUrl) {
      // the actual download URL is wrapped in an install URL like itms-services://...
      parsedUrl.searchParams.set("url", this.setOriginInDownloadUrl(wrappedUrl) || "");
    } else {
      parsedUrl.searchParams.set("download_origin", "appcenter");
    }

    return parsedUrl.toString();
  }

  private deserializeBuildInfo(buildInfo: ISerializedBuildInfo | undefined): IDeserializedBuildInfo | null {
    if (!buildInfo) {
      return null;
    }
    return {
      branch: buildInfo.branch,
      commitHash: buildInfo.commit_hash,
      commitMessage: buildInfo.commit_message,
    };
  }

  private getOwnerAndAppNames(query: BuildQueryOrOptions | undefined): string {
    return query && query.ownerName && query.appName ? this.compoundKey(query.ownerName, query.appName) : "";
  }
}

export const buildStore = new BuildStore();
