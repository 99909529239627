import { Model } from "../../lib";
import { observable } from "mobx";
import { PaymentSource } from "./billing-resources";

export interface SerializedBillingTier {
  document: SerializedBillingTierDocument;
  etag: string;
}

export interface SerializedBillingTierDocument {
  id: string;
  parentId: string;
  name: string;
  service: BillingService;
  azureMeterId?: string;
  expirationTime: null;
  maximumDuration?: string;
  paymentSource: PaymentSource;
  limits: TierLimits;
  attributes: TierAttributes;
  version: string;
}

export interface TierAttributes {
  "test/regular/enabled"?: boolean;
  "test/regular/unlimitedHours"?: boolean;
  "test/launch/enabled"?: boolean;
  "build/unlimitedTotalBillableMinutes"?: boolean;
  internal?: boolean;
}

export interface TierLimits {
  "test/regular/concurrentDevices"?: number;
  "test/regular/deviceHours"?: number;
  "test/launch/deviceHours"?: number;
  "build/maximumConcurrentBuilds"?: number;
  "build/maximumBuildDurationInMinutes"?: number;
  "build/totalBillableMinutes"?: number;
  "build/totalFailedMinutes"?: number;
}

export interface DeserializedBillingTier {
  id: string;
  concurrentDevices?: number;
  deviceHours?: number;
  unlimitedDeviceHours?: boolean;
  service: BillingService;
  maximumDuration?: number;
  count: number;
  concurrentBuilds?: number;
  buildHours?: number;
  unlimitedBuildHours?: boolean;
  unlimitedAudiences?: boolean;
  paymentSource: PaymentSource;
  isInternal?: boolean;
  isFreeTier: boolean;
  isTrialPlan?: boolean;
  canSelectTrialPlan?: boolean;
  expirationDate?: Date;
  lastTrialPlanExpirationDate?: Date;
  trialDaysLeft?: number;
  isXTCTransition?: boolean;
  isCustomOffer: boolean;
}

export enum BillingService {
  Build = "Build",
  Test = "Test",
}

export class BillingTier extends Model<DeserializedBillingTier> implements DeserializedBillingTier {
  //BillingTierStore cast workaround
  @observable public id!: string;
  @observable public concurrentDevices!: number;
  @observable public deviceHours!: number;
  @observable public unlimitedDeviceHours!: boolean;
  @observable public service!: BillingService;
  @observable public maximumDuration!: number;
  @observable public count!: number;
  @observable public concurrentBuilds!: number;
  @observable public buildHours!: number;
  @observable public unlimitedBuildHours!: boolean;
  @observable public unlimitedAudiences!: boolean;
  @observable public paymentSource!: PaymentSource;
  @observable public isInternal!: boolean;
  @observable public isFreeTier!: boolean;
  @observable public isTrialPlan!: boolean;
  @observable public canSelectTrialPlan!: boolean;
  @observable public expirationDate!: Date;
  @observable public lastTrialPlanExpirationDate!: Date;
  @observable public trialDaysLeft!: number;
  @observable public isXTCTransition!: boolean;
  @observable public isCustomOffer!: boolean;
}
