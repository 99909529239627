/**
 * stores
 * Distribution Stores API Service
 *
 * OpenAPI spec version: 0.1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { apiGateway } from "@root/lib/http";
import { ApiClientOptions } from "../api-client-options";

interface ApiMethodResult<T> {
  statusCode: number;
  body: T;
}

function getApiMethod<T>(httpMethod, path, payload, options: ApiClientOptions = {}): Promise<ApiMethodResult<T>> {
  if (options.timeout !== undefined) {
    console.warn("API Client timeout option is ignored.");
  }
  return apiGateway.call<T>(httpMethod, path, payload).then((body) => {
    return { statusCode: 200, body };
  });
}

// @ts-ignore
function stringifyParams(params: { [key: string]: any }): { [key: string]: string | string[] } {
  return Object.assign(
    {},
    ...Object.keys(params)
      .filter((key) => params[key])
      .map((key) => ({ [key]: params[key].toString() }))
  );
}

function validateSwaggerResult(model, result, operation, shouldIterate = true) {
  if (process.env.NODE_ENV !== "production") {
    const swagger = require("./swagger.json");
    const Validator = require("swagger-model-validator");
    // @ts-ignore
    const validator = new Validator(swagger);
    if (model) {
      if (model.startsWith("Api")) {
        model = model.substr(3);
      }
      const validation = [];
      if (shouldIterate && Array.isArray(result)) {
        result.forEach((r) => {
          const validationResult = swagger.validateModel(model, r, true, true);
          if (!validationResult.valid) {
            // @ts-ignore
            validation.push(validationResult.GetFormattedErrors());
          }
        });
      } else {
        const validationResult = swagger.validateModel(model, result, true, true);
        if (!validationResult.valid) {
          // @ts-ignore
          validation.push(validationResult.GetFormattedErrors());
        }
      }
      if (validation.length > 0) {
        console.warn("Swagger validation for model " + model + " failed for API call '" + operation + "'", result, validation);
      }
    }
  }
}

/**
 * Apple Mapping Request Type
 */

export interface ApiAppleMappingRequest {
  /**
   * Id for the shared service connection. In case of Apple AppStore, this connection will be used to create and connect to the Apple AppStore in Mobile Center.
   */
  serviceConnectionId: string;
  /**
   * ID of the apple application in apple store, takes precedence over bundle_identifier when both are provided
   */
  appleId?: string;
  /**
   * Bundle Identifier of the apple package
   */
  bundleIdentifier?: string;
  /**
   * ID of the Team associated with the app in apple store
   */
  teamIdentifier: string;
}

// @ts-ignore
function deserializeApiAppleMappingRequest(input: any): ApiAppleMappingRequest {
  return {
    serviceConnectionId: input["service_connection_id"],
    appleId: input["apple_id"],
    bundleIdentifier: input["bundle_identifier"],
    teamIdentifier: input["team_identifier"],
  };
}

// @ts-ignore
function serializeApiAppleMappingRequest(input: ApiAppleMappingRequest): any {
  return {
    service_connection_id: input["serviceConnectionId"],
    apple_id: input["appleId"],
    bundle_identifier: input["bundleIdentifier"],
    team_identifier: input["teamIdentifier"],
  };
}

/**
 * Apple Mapping Request Type
 */

export interface ApiAppleMappingResponse {
  /**
   * ID of the apple application in Mobile Center
   */
  appId?: string;
  /**
   * Id for the shared service connection. In case of Apple AppStore, this connection will be used to create and connect to the Apple AppStore in Mobile Center.
   */
  serviceConnectionId?: string;
  /**
   * ID of the apple application in apple store
   */
  appleId?: string;
  /**
   * ID of the Team associated with the app in apple store
   */
  teamIdentifier?: string;
}

// @ts-ignore
function deserializeApiAppleMappingResponse(input: any): ApiAppleMappingResponse {
  return {
    appId: input["app_id"],
    serviceConnectionId: input["service_connection_id"],
    appleId: input["apple_id"],
    teamIdentifier: input["team_identifier"],
  };
}

// @ts-ignore
function serializeApiAppleMappingResponse(input: ApiAppleMappingResponse): any {
  return {
    app_id: input["appId"],
    service_connection_id: input["serviceConnectionId"],
    apple_id: input["appleId"],
    team_identifier: input["teamIdentifier"],
  };
}

/**
 * Apple Test Flight Groups Response Type
 */

export interface ApiAppleTestFlightGroupsResponse {
  /**
   * id of the group.
   */
  id?: string;
  /**
   * provider id of the group.
   */
  providerId?: number;
  /**
   * apple id of the group.
   */
  appleId?: number;
  /**
   * name of the group.
   */
  name?: string;
}

// @ts-ignore
function deserializeApiAppleTestFlightGroupsResponse(input: any): ApiAppleTestFlightGroupsResponse {
  return {
    id: input["id"],
    providerId: input["providerId"],
    appleId: input["appleId"],
    name: input["name"],
  };
}

// @ts-ignore
function serializeApiAppleTestFlightGroupsResponse(input: ApiAppleTestFlightGroupsResponse): any {
  return {
    id: input["id"],
    providerId: input["providerId"],
    appleId: input["appleId"],
    name: input["name"],
  };
}

export interface ApiCreateStoreSecretRequest {
  secretJson?: ApiSecretDetails;
  /**
   * the tenant id for user
   */
  tenantId?: string;
}

// @ts-ignore
function deserializeApiCreateStoreSecretRequest(input: any): ApiCreateStoreSecretRequest {
  return {
    secretJson: input["secret_json"],
    tenantId: input["tenant_id"],
  };
}

// @ts-ignore
function serializeApiCreateStoreSecretRequest(input: ApiCreateStoreSecretRequest): any {
  return {
    secret_json: input["secretJson"],
    tenant_id: input["tenantId"],
  };
}

export interface ApiCreateStoreSecretResponse {
  /**
   * the secret id for store secret
   */
  secretId?: string;
}

// @ts-ignore
function deserializeApiCreateStoreSecretResponse(input: any): ApiCreateStoreSecretResponse {
  return {
    secretId: input["secret_id"],
  };
}

// @ts-ignore
function serializeApiCreateStoreSecretResponse(input: ApiCreateStoreSecretResponse): any {
  return {
    secret_id: input["secretId"],
  };
}

/**
 * The diagnostic id for the given publish action
 */

export interface ApiDiagnosticIdResponse {
  /**
   * diagnostic id
   */
  diagnosticId?: string;
}

// @ts-ignore
function deserializeApiDiagnosticIdResponse(input: any): ApiDiagnosticIdResponse {
  return {
    diagnosticId: input["diagnostic_id"],
  };
}

// @ts-ignore
function serializeApiDiagnosticIdResponse(input: ApiDiagnosticIdResponse): any {
  return {
    diagnostic_id: input["diagnosticId"],
  };
}

export interface ApiErrorDetails {
  /**
   * Error codes: not_found: An app, a release or a distribution group can't be found. forbidden: This operation is not allowed by Mobile Center API. no_upload_resource: The upload resource ID doesn't match any upload resource release_not_uploaded: Trying to commit a release before it is uploaded. filter_error: A filter query string parameter is incorrect or not supported. internal_server_error: An unknown internal error. not_supported: An operation is not supported. no_releases_for_app: Couldn't get a release because there are no releases for this app. no_releases_for_user: Couldn't get a release because there are no releases for this user. bad_request: One of the parameters is incorrect. distribution_group_not_found: Distribution group or the app doesn't exist. not_implemented: Request not implemented by this service. partially_deleted: Deleted just part of a resource.
   */
  code: ApiErrorDetails.ApiCodeEnum;
  message: string;
}

// @ts-ignore
function deserializeApiErrorDetails(input: any): ApiErrorDetails {
  return {
    code: input["code"],
    message: input["message"],
  };
}

// @ts-ignore
function serializeApiErrorDetails(input: ApiErrorDetails): any {
  return {
    code: input["code"],
    message: input["message"],
  };
}

export namespace ApiErrorDetails {
  export type ApiCodeEnum =
    | "not_found"
    | "forbidden"
    | "release_not_found"
    | "no_upload_resource"
    | "release_not_uploaded"
    | "filter_error"
    | "internal_server_error"
    | "not_supported"
    | "no_releases_for_app"
    | "bad_request"
    | "distribution_group_not_found"
    | "not_implemented"
    | "partially_deleted"
    | "status_conflict";
}
/**
 * ExternalStoreRequest
 */

export interface ApiExternalStoreRequest {
  /**
   * store Type
   */
  type?: ApiExternalStoreRequest.ApiTypeEnum;
  /**
   * name of the store. In case of googleplay, and Apple store this is fixed to Production.
   */
  name?: string;
  /**
   * track of the store. Can be production, alpha & beta for googleplay. Can be production, testflight-internal & testflight-external for Apple Store.
   */
  track?: ApiExternalStoreRequest.ApiTrackEnum;
  intuneDetails?: ApiIntuneStoreRequest;
  /**
   * Id for the shared service connection. In case of Apple AppStore, this connection will be used to create and connect to the Apple AppStore in Mobile Center.
   */
  serviceConnectionId?: string;
}

// @ts-ignore
function deserializeApiExternalStoreRequest(input: any): ApiExternalStoreRequest {
  return {
    type: input["type"],
    name: input["name"],
    track: input["track"],
    intuneDetails: input["intune_details"],
    serviceConnectionId: input["service_connection_id"],
  };
}

// @ts-ignore
function serializeApiExternalStoreRequest(input: ApiExternalStoreRequest): any {
  return {
    type: input["type"],
    name: input["name"],
    track: input["track"],
    intune_details: input["intuneDetails"],
    service_connection_id: input["serviceConnectionId"],
  };
}

export namespace ApiExternalStoreRequest {
  export type ApiTypeEnum = "googleplay" | "apple" | "intune";
  export type ApiTrackEnum = "production" | "alpha" | "beta" | "testflight-internal" | "testflight-external";
}
/**
 * ExternalStoreResponse
 */

export interface ApiExternalStoreResponse {
  /**
   * Store id
   */
  id?: string;
  /**
   * Store Name
   */
  name?: string;
  /**
   * Store Type
   */
  type?: string;
  /**
   * Store track
   */
  track?: ApiExternalStoreResponse.ApiTrackEnum;
  /**
   * Store details for intune
   */
  intuneDetails?: ApiIntuneStoreResponse;
  /**
   * Id for the shared service connection. In case of Apple / GooglePlay stores, this connection will be used to connect to the Apple / Google stores in App Center.
   */
  serviceConnectionId?: string;
  /**
   * The ID of the principal that created the store.
   */
  createdBy?: string;
  /**
   * The type of the principal that created the store.
   */
  createdByPrincipalType?: string;
}

// @ts-ignore
function deserializeApiExternalStoreResponse(input: any): ApiExternalStoreResponse {
  return {
    id: input["id"],
    name: input["name"],
    type: input["type"],
    track: input["track"],
    intuneDetails: input["intune_details"],
    serviceConnectionId: input["service_connection_id"],
    createdBy: input["created_by"],
    createdByPrincipalType: input["created_by_principal_type"],
  };
}

// @ts-ignore
function serializeApiExternalStoreResponse(input: ApiExternalStoreResponse): any {
  return {
    id: input["id"],
    name: input["name"],
    type: input["type"],
    track: input["track"],
    intune_details: input["intuneDetails"],
    service_connection_id: input["serviceConnectionId"],
    created_by: input["createdBy"],
    created_by_principal_type: input["createdByPrincipalType"],
  };
}

export namespace ApiExternalStoreResponse {
  export type ApiTrackEnum = "production" | "alpha" | "beta" | "testflight-internal" | "testflight-external";
}
/**
 * The response for the build uploaded check.
 */

export interface ApiHasBuildUploadedResponse {
  /**
   * true if a build has been uploaded, false otherwise
   */
  hasBuildUploaded?: boolean;
}

// @ts-ignore
function deserializeApiHasBuildUploadedResponse(input: any): ApiHasBuildUploadedResponse {
  return {
    hasBuildUploaded: input["has_build_uploaded"],
  };
}

// @ts-ignore
function serializeApiHasBuildUploadedResponse(input: ApiHasBuildUploadedResponse): any {
  return {
    has_build_uploaded: input["hasBuildUploaded"],
  };
}

/**
 * The response for the testflight metadata check.
 */

export interface ApiHasTestflightMetadataResponse {
  /**
   * true if the app has the testflight metadata, false otherwise
   */
  hasTestflightMetadata?: boolean;
}

// @ts-ignore
function deserializeApiHasTestflightMetadataResponse(input: any): ApiHasTestflightMetadataResponse {
  return {
    hasTestflightMetadata: input["has_testflight_metadata"],
  };
}

// @ts-ignore
function serializeApiHasTestflightMetadataResponse(input: ApiHasTestflightMetadataResponse): any {
  return {
    has_testflight_metadata: input["hasTestflightMetadata"],
  };
}

export interface ApiIntuneAppCategory {
  /**
   * display name for the app category
   */
  name?: string;
}

// @ts-ignore
function deserializeApiIntuneAppCategory(input: any): ApiIntuneAppCategory {
  return {
    name: input["name"],
  };
}

// @ts-ignore
function serializeApiIntuneAppCategory(input: ApiIntuneAppCategory): any {
  return {
    name: input["name"],
  };
}

export interface ApiIntuneAppCategoryResponse {
  /**
   * display name for the app category
   */
  name?: string;
  /**
   * ID for the category.
   */
  id?: string;
}

// @ts-ignore
function deserializeApiIntuneAppCategoryResponse(input: any): ApiIntuneAppCategoryResponse {
  return {
    name: input["name"],
    id: input["id"],
  };
}

// @ts-ignore
function serializeApiIntuneAppCategoryResponse(input: ApiIntuneAppCategoryResponse): any {
  return {
    name: input["name"],
    id: input["id"],
  };
}

/**
 * IntuneAppsRequest
 */

export interface ApiIntuneAppsRequest {
  /**
   * PartitionKey year-month
   */
  createdMonth?: string;
}

// @ts-ignore
function deserializeApiIntuneAppsRequest(input: any): ApiIntuneAppsRequest {
  return {
    createdMonth: input["created_month"],
  };
}

// @ts-ignore
function serializeApiIntuneAppsRequest(input: ApiIntuneAppsRequest): any {
  return {
    created_month: input["createdMonth"],
  };
}

/**
 * IntuneAppsResponse
 */

export interface ApiIntuneAppsResponse {
  /**
   * PartitionKey year-month
   */
  createdMonth?: string;
  /**
   * App id
   */
  appId?: string;
  /**
   * Refresh Status
   */
  refreshStatus?: string;
}

// @ts-ignore
function deserializeApiIntuneAppsResponse(input: any): ApiIntuneAppsResponse {
  return {
    createdMonth: input["created_month"],
    appId: input["app_id"],
    refreshStatus: input["refreshStatus"],
  };
}

// @ts-ignore
function serializeApiIntuneAppsResponse(input: ApiIntuneAppsResponse): any {
  return {
    created_month: input["createdMonth"],
    app_id: input["appId"],
    refreshStatus: input["refreshStatus"],
  };
}

export interface ApiIntuneCategories {
  /**
   * context
   */
  odataContext?: string;
  /**
   * categories for intune app
   */
  value?: ApiIntuneCategoryValue[];
}

// @ts-ignore
function deserializeApiIntuneCategories(input: any): ApiIntuneCategories {
  return {
    odataContext: input["odata.context"],
    value: input["value"]
      ? (input["value"] as ApiIntuneCategoryValue[]).map((val) => deserializeApiIntuneCategoryValue(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiIntuneCategories(input: ApiIntuneCategories): any {
  return {
    "odata.context": input["odataContext"],
    value: input["value"]
      ? (input["value"] as ApiIntuneCategoryValue[]).map((val) => deserializeApiIntuneCategoryValue(val))
      : undefined,
  };
}

export interface ApiIntuneCategoryValue {
  /**
   * the id of the category
   */
  id?: string;
  /**
   * the display name for the category
   */
  displayName?: string;
  /**
   * modified date for category
   */
  lastModifiedDateTime?: string;
}

// @ts-ignore
function deserializeApiIntuneCategoryValue(input: any): ApiIntuneCategoryValue {
  return {
    id: input["id"],
    displayName: input["displayName"],
    lastModifiedDateTime: input["lastModifiedDateTime"],
  };
}

// @ts-ignore
function serializeApiIntuneCategoryValue(input: ApiIntuneCategoryValue): any {
  return {
    id: input["id"],
    displayName: input["displayName"],
    lastModifiedDateTime: input["lastModifiedDateTime"],
  };
}

export interface ApiIntuneGroup {
  /**
   * the id of the Group
   */
  id?: string;
  /**
   * the display name of the group
   */
  displayName?: string;
  /**
   * species if it is a security group
   */
  securityEnabled?: boolean;
}

// @ts-ignore
function deserializeApiIntuneGroup(input: any): ApiIntuneGroup {
  return {
    id: input["id"],
    displayName: input["displayName"],
    securityEnabled: input["securityEnabled"],
  };
}

// @ts-ignore
function serializeApiIntuneGroup(input: ApiIntuneGroup): any {
  return {
    id: input["id"],
    displayName: input["displayName"],
    securityEnabled: input["securityEnabled"],
  };
}

export interface ApiIntuneGroupValue {
  /**
   * the id of the Group
   */
  id?: string;
  /**
   * the display name of the group
   */
  displayName?: string;
}

// @ts-ignore
function deserializeApiIntuneGroupValue(input: any): ApiIntuneGroupValue {
  return {
    id: input["id"],
    displayName: input["displayName"],
  };
}

// @ts-ignore
function serializeApiIntuneGroupValue(input: ApiIntuneGroupValue): any {
  return {
    id: input["id"],
    displayName: input["displayName"],
  };
}

export interface ApiIntuneGroups {
  /**
   * context
   */
  odataContext?: string;
  /**
   * categories for intune app
   */
  value?: ApiIntuneGroupValue[];
}

// @ts-ignore
function deserializeApiIntuneGroups(input: any): ApiIntuneGroups {
  return {
    odataContext: input["odata.context"],
    value: input["value"]
      ? (input["value"] as ApiIntuneGroupValue[]).map((val) => deserializeApiIntuneGroupValue(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiIntuneGroups(input: ApiIntuneGroups): any {
  return {
    "odata.context": input["odataContext"],
    value: input["value"] ? (input["value"] as ApiIntuneGroupValue[]).map((val) => deserializeApiIntuneGroupValue(val)) : undefined,
  };
}

export interface ApiIntuneSecretDetails {
  /**
   * the id token of user
   */
  idToken?: string;
  /**
   * the refresh token for user
   */
  refreshToken?: string;
  /**
   * the expiry of refresh token
   */
  refreshTokenExpiry?: string;
}

// @ts-ignore
function deserializeApiIntuneSecretDetails(input: any): ApiIntuneSecretDetails {
  return {
    idToken: input["id_token"],
    refreshToken: input["refresh_token"],
    refreshTokenExpiry: input["refresh_token_expiry"],
  };
}

// @ts-ignore
function serializeApiIntuneSecretDetails(input: ApiIntuneSecretDetails): any {
  return {
    id_token: input["idToken"],
    refresh_token: input["refreshToken"],
    refresh_token_expiry: input["refreshTokenExpiry"],
  };
}

export interface ApiIntuneStoreRequest {
  secretJson?: ApiIntuneSecretDetails;
  targetAudience?: ApiIntuneTargetAudience;
  appCategory?: ApiIntuneAppCategory;
  /**
   * tenant id of the intune store
   */
  tenantId?: string;
}

// @ts-ignore
function deserializeApiIntuneStoreRequest(input: any): ApiIntuneStoreRequest {
  return {
    secretJson: input["secret_json"],
    targetAudience: input["target_audience"],
    appCategory: input["app_category"],
    tenantId: input["tenant_id"],
  };
}

// @ts-ignore
function serializeApiIntuneStoreRequest(input: ApiIntuneStoreRequest): any {
  return {
    secret_json: input["secretJson"],
    target_audience: input["targetAudience"],
    app_category: input["appCategory"],
    tenant_id: input["tenantId"],
  };
}

export interface ApiIntuneStoreResponse {
  targetAudience?: ApiIntuneTargetAudienceResponse;
  appCategory?: ApiIntuneAppCategoryResponse;
}

// @ts-ignore
function deserializeApiIntuneStoreResponse(input: any): ApiIntuneStoreResponse {
  return {
    targetAudience: input["target_audience"],
    appCategory: input["app_category"],
  };
}

// @ts-ignore
function serializeApiIntuneStoreResponse(input: ApiIntuneStoreResponse): any {
  return {
    target_audience: input["targetAudience"],
    app_category: input["appCategory"],
  };
}

export interface ApiIntuneTargetAudience {
  /**
   * display name for the target audience/group
   */
  name?: string;
}

// @ts-ignore
function deserializeApiIntuneTargetAudience(input: any): ApiIntuneTargetAudience {
  return {
    name: input["name"],
  };
}

// @ts-ignore
function serializeApiIntuneTargetAudience(input: ApiIntuneTargetAudience): any {
  return {
    name: input["name"],
  };
}

export interface ApiIntuneTargetAudienceResponse {
  /**
   * display name for the target audience/group
   */
  name?: string;
  /**
   * ID for the target audience/group.
   */
  id?: string;
}

// @ts-ignore
function deserializeApiIntuneTargetAudienceResponse(input: any): ApiIntuneTargetAudienceResponse {
  return {
    name: input["name"],
    id: input["id"],
  };
}

// @ts-ignore
function serializeApiIntuneTargetAudienceResponse(input: ApiIntuneTargetAudienceResponse): any {
  return {
    name: input["name"],
    id: input["id"],
  };
}

/**
 * org settings Request
 */

export interface ApiOrgComplianceSettingsRequest {
  /**
   * certificate connection id to wrap and resign the app after wrapping
   */
  certificateConnectionId: string;
}

// @ts-ignore
function deserializeApiOrgComplianceSettingsRequest(input: any): ApiOrgComplianceSettingsRequest {
  return {
    certificateConnectionId: input["certificate_connection_id"],
  };
}

// @ts-ignore
function serializeApiOrgComplianceSettingsRequest(input: ApiOrgComplianceSettingsRequest): any {
  return {
    certificate_connection_id: input["certificateConnectionId"],
  };
}

/**
 * org settings response
 */

export interface ApiOrgComplianceSettingsResponse {
  /**
   * The internal unique id (UUID) of the organization compliance setting
   */
  id: string;
  /**
   * The internal unique id (UUID) of the organization.
   */
  orgId: string;
  /**
   * certificate connection id to wrap and resign the app after wrapping
   */
  certificateConnectionId: string;
  /**
   * flag to tell if mam warpping is enabled on the Org
   */
  isMamEnabled?: boolean;
}

// @ts-ignore
function deserializeApiOrgComplianceSettingsResponse(input: any): ApiOrgComplianceSettingsResponse {
  return {
    id: input["id"],
    orgId: input["org_id"],
    certificateConnectionId: input["certificate_connection_id"],
    isMamEnabled: input["is_mam_enabled"],
  };
}

// @ts-ignore
function serializeApiOrgComplianceSettingsResponse(input: ApiOrgComplianceSettingsResponse): any {
  return {
    id: input["id"],
    org_id: input["orgId"],
    certificate_connection_id: input["certificateConnectionId"],
    is_mam_enabled: input["isMamEnabled"],
  };
}

export interface ApiPatchReleaseRequest {
  /**
   * updated status of release
   */
  status?: string;
  /**
   * Destination Publish Id
   */
  destPublishId?: string;
  /**
   * failure error details from store
   */
  errorDetails?: string;
  /**
   * contextId for failed error message
   */
  errorContextId?: string;
  /**
   * package url for wrapping request
   */
  wrapPackageUrl?: string;
  /**
   * request is for wrapping or not
   */
  isWrapperRequest?: boolean;
}

// @ts-ignore
function deserializeApiPatchReleaseRequest(input: any): ApiPatchReleaseRequest {
  return {
    status: input["status"],
    destPublishId: input["dest_publish_id"],
    errorDetails: input["error_details"],
    errorContextId: input["error_contextId"],
    wrapPackageUrl: input["wrap_package_url"],
    isWrapperRequest: input["is_wrapper_request"],
  };
}

// @ts-ignore
function serializeApiPatchReleaseRequest(input: ApiPatchReleaseRequest): any {
  return {
    status: input["status"],
    dest_publish_id: input["destPublishId"],
    error_details: input["errorDetails"],
    error_contextId: input["errorContextId"],
    wrap_package_url: input["wrapPackageUrl"],
    is_wrapper_request: input["isWrapperRequest"],
  };
}

/**
 * create the store through private API. Used by UI.
 */

export interface ApiPrivateCreateStoreRequest {
  /**
   * store Type
   */
  type?: ApiPrivateCreateStoreRequest.ApiTypeEnum;
  /**
   * name of the store.
   */
  name?: string;
  intuneDetails?: ApiPrivateIntuneStoreRequest;
}

// @ts-ignore
function deserializeApiPrivateCreateStoreRequest(input: any): ApiPrivateCreateStoreRequest {
  return {
    type: input["type"],
    name: input["name"],
    intuneDetails: input["intune_details"],
  };
}

// @ts-ignore
function serializeApiPrivateCreateStoreRequest(input: ApiPrivateCreateStoreRequest): any {
  return {
    type: input["type"],
    name: input["name"],
    intune_details: input["intuneDetails"],
  };
}

export namespace ApiPrivateCreateStoreRequest {
  export type ApiTypeEnum = "intune";
}

export interface ApiPrivateIntuneStoreRequest {
  targetAudience?: ApiIntuneTargetAudience;
  appCategory?: ApiIntuneAppCategory;
  /**
   * tenant id of the intune store
   */
  tenantId?: string;
}

// @ts-ignore
function deserializeApiPrivateIntuneStoreRequest(input: any): ApiPrivateIntuneStoreRequest {
  return {
    targetAudience: input["target_audience"],
    appCategory: input["app_category"],
    tenantId: input["tenant_id"],
  };
}

// @ts-ignore
function serializeApiPrivateIntuneStoreRequest(input: ApiPrivateIntuneStoreRequest): any {
  return {
    target_audience: input["targetAudience"],
    app_category: input["appCategory"],
    tenant_id: input["tenantId"],
  };
}

/**
 * ReleasePublishErrorResponse
 */

export interface ApiReleasePublishErrorResponse {
  /**
   * error Details
   */
  message?: string;
  /**
   * boolean property to tell if logs are available for download
   */
  isLogAvailable?: boolean;
}

// @ts-ignore
function deserializeApiReleasePublishErrorResponse(input: any): ApiReleasePublishErrorResponse {
  return {
    message: input["message"],
    isLogAvailable: input["is_log_available"],
  };
}

// @ts-ignore
function serializeApiReleasePublishErrorResponse(input: ApiReleasePublishErrorResponse): any {
  return {
    message: input["message"],
    is_log_available: input["isLogAvailable"],
  };
}

/**
 * status of the app from store
 */

export interface ApiReleaseRealTimeStatusResponse {
  /**
   * release id
   */
  releaseId?: string;
  /**
   * app id
   */
  appId?: string;
  status?: ApiStatusData;
}

// @ts-ignore
function deserializeApiReleaseRealTimeStatusResponse(input: any): ApiReleaseRealTimeStatusResponse {
  return {
    releaseId: input["release_id"],
    appId: input["app_id"],
    status: input["status"],
  };
}

// @ts-ignore
function serializeApiReleaseRealTimeStatusResponse(input: ApiReleaseRealTimeStatusResponse): any {
  return {
    release_id: input["releaseId"],
    app_id: input["appId"],
    status: input["status"],
  };
}

export interface ApiSecretDetails {
  /**
   * the id token of user
   */
  idToken?: string;
  /**
   * the refresh token for user
   */
  refreshToken?: string;
  /**
   * the expiry of refresh token
   */
  refreshTokenExpiry?: string;
}

// @ts-ignore
function deserializeApiSecretDetails(input: any): ApiSecretDetails {
  return {
    idToken: input["id_token"],
    refreshToken: input["refresh_token"],
    refreshTokenExpiry: input["refresh_token_expiry"],
  };
}

// @ts-ignore
function serializeApiSecretDetails(input: ApiSecretDetails): any {
  return {
    id_token: input["idToken"],
    refresh_token: input["refreshToken"],
    refresh_token_expiry: input["refreshTokenExpiry"],
  };
}

/**
 * Wheither or not to skip the validation for this release
 */

export interface ApiSkipValidationRequest {
  /**
   * true if we want to skip the validation, false otherwise
   */
  skipValidation?: boolean;
}

// @ts-ignore
function deserializeApiSkipValidationRequest(input: any): ApiSkipValidationRequest {
  return {
    skipValidation: input["skip_validation"],
  };
}

// @ts-ignore
function serializeApiSkipValidationRequest(input: ApiSkipValidationRequest): any {
  return {
    skip_validation: input["skipValidation"],
  };
}

/**
 * Status Data from store
 */

export interface ApiStatusData {
  /**
   * status from store
   */
  status?: string;
  /**
   * store type
   */
  storetype?: string;
  /**
   * track information from store
   */
  track?: string;
  /**
   * version of the app from store
   */
  version?: string;
}

// @ts-ignore
function deserializeApiStatusData(input: any): ApiStatusData {
  return {
    status: input["status"],
    storetype: input["storetype"],
    track: input["track"],
    version: input["version"],
  };
}

// @ts-ignore
function serializeApiStatusData(input: ApiStatusData): any {
  return {
    status: input["status"],
    storetype: input["storetype"],
    track: input["track"],
    version: input["version"],
  };
}

export interface ApiStatusResponse {
  status: string;
}

// @ts-ignore
function deserializeApiStatusResponse(input: any): ApiStatusResponse {
  return {
    status: input["status"],
  };
}

// @ts-ignore
function serializeApiStatusResponse(input: ApiStatusResponse): any {
  return {
    status: input["status"],
  };
}

export interface ApiStoreDestinationDetails {
  /**
   * destination ID identifying a unique id in distribution store.
   */
  destPublishId?: string;
  /**
   * type of store.
   */
  storeType?: ApiStoreDestinationDetails.ApiStoreTypeEnum;
  /**
   * app id of application.
   */
  appId?: string;
}

// @ts-ignore
function deserializeApiStoreDestinationDetails(input: any): ApiStoreDestinationDetails {
  return {
    destPublishId: input["dest_publish_id"],
    storeType: input["store_type"],
    appId: input["appId"],
  };
}

// @ts-ignore
function serializeApiStoreDestinationDetails(input: ApiStoreDestinationDetails): any {
  return {
    dest_publish_id: input["destPublishId"],
    store_type: input["storeType"],
    appId: input["appId"],
  };
}

export namespace ApiStoreDestinationDetails {
  export type ApiStoreTypeEnum = "intune";
}

export interface ApiStoreNotification {
  service?: string;
  status?: string;
  validUntil?: number;
}

// @ts-ignore
function deserializeApiStoreNotification(input: any): ApiStoreNotification {
  return {
    service: input["service"],
    status: input["status"],
    validUntil: input["valid_until"],
  };
}

// @ts-ignore
function serializeApiStoreNotification(input: ApiStoreNotification): any {
  return {
    service: input["service"],
    status: input["status"],
    valid_until: input["validUntil"],
  };
}

export interface ApiStorePatchRequest {
  /**
   * Service connection id to updated.
   */
  serviceConnectionId: string;
}

// @ts-ignore
function deserializeApiStorePatchRequest(input: any): ApiStorePatchRequest {
  return {
    serviceConnectionId: input["service_connection_id"],
  };
}

// @ts-ignore
function serializeApiStorePatchRequest(input: ApiStorePatchRequest): any {
  return {
    service_connection_id: input["serviceConnectionId"],
  };
}

/**
 * StoreSecretResponse
 */

export interface ApiStoreSecretResponse {
  /**
   * Store id
   */
  id?: string;
  /**
   * Store Name
   */
  name?: string;
  /**
   * Store Type
   */
  type?: string;
  /**
   * Secret Json
   */
  secret?: string;
  /**
   * Tenant Id for Intune
   */
  tenantId?: string;
}

// @ts-ignore
function deserializeApiStoreSecretResponse(input: any): ApiStoreSecretResponse {
  return {
    id: input["id"],
    name: input["name"],
    type: input["type"],
    secret: input["secret"],
    tenantId: input["tenant_id"],
  };
}

// @ts-ignore
function serializeApiStoreSecretResponse(input: ApiStoreSecretResponse): any {
  return {
    id: input["id"],
    name: input["name"],
    type: input["type"],
    secret: input["secret"],
    tenant_id: input["tenantId"],
  };
}

export interface ApiStoresBasicDetails {
  /**
   * ID identifying a unique distribution store.
   */
  id?: string;
  /**
   * A name identifying a unique distribution store.
   */
  name?: string;
  /**
   * type of the distribution store currently stores type can be intune or googleplay.
   */
  type?: ApiStoresBasicDetails.ApiTypeEnum;
  /**
   * publishing status of the release in the store.
   */
  publishingStatus?: string;
}

// @ts-ignore
function deserializeApiStoresBasicDetails(input: any): ApiStoresBasicDetails {
  return {
    id: input["id"],
    name: input["name"],
    type: input["type"],
    publishingStatus: input["publishing_status"],
  };
}

// @ts-ignore
function serializeApiStoresBasicDetails(input: ApiStoresBasicDetails): any {
  return {
    id: input["id"],
    name: input["name"],
    type: input["type"],
    publishing_status: input["publishingStatus"],
  };
}

export namespace ApiStoresBasicDetails {
  export type ApiTypeEnum = "intune" | "googleplay";
}
/**
 * Basic information on a release
 */

export interface ApiStoresBasicReleaseDetails {
  /**
   * ID identifying this unique release.
   */
  id?: number;
  /**
   * The release's version. For iOS: CFBundleVersion from info.plist. For Android: android:versionCode from AppManifest.xml.
   */
  version?: string;
  /**
   * The release's short version. For iOS: CFBundleShortVersionString from info.plist. For Android: android:versionName from AppManifest.xml.
   */
  shortVersion?: string;
  /**
   * UTC time in ISO 8601 format of the uploaded time.
   */
  uploadedAt?: string;
  /**
   * Destination for this release.
   */
  destinationType?: ApiStoresBasicReleaseDetails.ApiDestinationTypeEnum;
  /**
   * a list of distribution stores that are associated with this release.
   */
  distributionStores?: ApiStoresDetails[];
}

// @ts-ignore
function deserializeApiStoresBasicReleaseDetails(input: any): ApiStoresBasicReleaseDetails {
  return {
    id: input["id"],
    version: input["version"],
    shortVersion: input["short_version"],
    uploadedAt: input["uploaded_at"],
    destinationType: input["destination_type"],
    distributionStores: input["distribution_stores"]
      ? (input["distribution_stores"] as ApiStoresDetails[]).map((val) => deserializeApiStoresDetails(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiStoresBasicReleaseDetails(input: ApiStoresBasicReleaseDetails): any {
  return {
    id: input["id"],
    version: input["version"],
    short_version: input["shortVersion"],
    uploaded_at: input["uploadedAt"],
    destination_type: input["destinationType"],
    distribution_stores: input["distributionStores"]
      ? (input["distributionStores"] as ApiStoresDetails[]).map((val) => deserializeApiStoresDetails(val))
      : undefined,
  };
}

export namespace ApiStoresBasicReleaseDetails {
  export type ApiDestinationTypeEnum = "group" | "store" | "tester";
}

export interface ApiStoresDetails {
  /**
   * ID identifying a unique distribution store.
   */
  id?: string;
  /**
   * A name identifying a unique distribution store.
   */
  name?: string;
  /**
   * A type identifying the type of distribution store.
   */
  type?: ApiStoresDetails.ApiTypeEnum;
  /**
   * A status identifying the status of release in the distribution store.
   */
  publishingStatus?: string;
  /**
   * Is the containing release the latest one in this distribution store.
   */
  isLatest?: boolean;
}

// @ts-ignore
function deserializeApiStoresDetails(input: any): ApiStoresDetails {
  return {
    id: input["id"],
    name: input["name"],
    type: input["type"],
    publishingStatus: input["publishing_status"],
    isLatest: input["is_latest"],
  };
}

// @ts-ignore
function serializeApiStoresDetails(input: ApiStoresDetails): any {
  return {
    id: input["id"],
    name: input["name"],
    type: input["type"],
    publishing_status: input["publishingStatus"],
    is_latest: input["isLatest"],
  };
}

export namespace ApiStoresDetails {
  export type ApiTypeEnum = "googleplay" | "intune" | "apple";
}
/**
 * Details of an uploaded release
 */

export interface ApiStoresReleaseDetails {
  /**
   * ID identifying this unique release.
   */
  id?: number;
  /**
   * OBSOLETE. Will be removed in next version. The availability concept is now replaced with distributed. Any 'available' release will be associated with the default distribution group of an app.</br> The release state.<br> <b>available</b>: The uploaded release has been distributed.<br> <b>unavailable</b>: The uploaded release is not visible to the user. <br>
   */
  status?: ApiStoresReleaseDetails.ApiStatusEnum;
  /**
   * The app's name (extracted from the uploaded release).
   */
  appName?: string;
  /**
   * The app's display name.
   */
  appDisplayName?: string;
  /**
   * The release's version.<br> For iOS: CFBundleVersion from info.plist. For Android: android:versionCode from AppManifest.xml.
   */
  version?: string;
  /**
   * The release's short version.<br> For iOS: CFBundleShortVersionString from info.plist. For Android: android:versionName from AppManifest.xml.
   */
  shortVersion?: string;
  /**
   * The release's release notes.
   */
  releaseNotes?: string;
  /**
   * The release's size in bytes.
   */
  size?: number;
  /**
   * The release's minimum required operating system.
   */
  minOs?: string;
  /**
   * The release's minimum required Android API level.
   */
  androidMinApiLevel?: string;
  /**
   * The identifier of the apps bundle.
   */
  bundleIdentifier?: string;
  /**
   * MD5 checksum of the release binary.
   */
  fingerprint?: string;
  /**
   * UTC time in ISO 8601 format of the uploaded time.
   */
  uploadedAt?: string;
  /**
   * The URL that hosts the binary for this release.
   */
  downloadUrl?: string;
  /**
   * The href required to install a release on a mobile device. On iOS devices will be prefixed with `itms-services://?action=download-manifest&url=`
   */
  installUrl?: ApiStoresReleaseDetails.ApiInstallUrlEnum;
  /**
   * a list of distribution stores that are associated with this release.
   */
  distributionStores?: ApiStoresBasicDetails[];
}

// @ts-ignore
function deserializeApiStoresReleaseDetails(input: any): ApiStoresReleaseDetails {
  return {
    id: input["id"],
    status: input["status"],
    appName: input["app_name"],
    appDisplayName: input["app_display_name"],
    version: input["version"],
    shortVersion: input["short_version"],
    releaseNotes: input["release_notes"],
    size: input["size"],
    minOs: input["min_os"],
    androidMinApiLevel: input["android_min_api_level"],
    bundleIdentifier: input["bundle_identifier"],
    fingerprint: input["fingerprint"],
    uploadedAt: input["uploaded_at"],
    downloadUrl: input["download_url"],
    installUrl: input["install_url"],
    distributionStores: input["distribution_stores"]
      ? (input["distribution_stores"] as ApiStoresBasicDetails[]).map((val) => deserializeApiStoresBasicDetails(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiStoresReleaseDetails(input: ApiStoresReleaseDetails): any {
  return {
    id: input["id"],
    status: input["status"],
    app_name: input["appName"],
    app_display_name: input["appDisplayName"],
    version: input["version"],
    short_version: input["shortVersion"],
    release_notes: input["releaseNotes"],
    size: input["size"],
    min_os: input["minOs"],
    android_min_api_level: input["androidMinApiLevel"],
    bundle_identifier: input["bundleIdentifier"],
    fingerprint: input["fingerprint"],
    uploaded_at: input["uploadedAt"],
    download_url: input["downloadUrl"],
    install_url: input["installUrl"],
    distribution_stores: input["distributionStores"]
      ? (input["distributionStores"] as ApiStoresBasicDetails[]).map((val) => deserializeApiStoresBasicDetails(val))
      : undefined,
  };
}

export namespace ApiStoresReleaseDetails {
  export type ApiStatusEnum = "available" | "unavailable";
  export type ApiInstallUrlEnum = "group" | "store";
}
/**
 * The response which contains the validation data that the connection is valid.
 */

export interface ApiValidationResponse {
  /**
   * app id
   */
  appId?: string;
  status?: ApiStatusData;
}

// @ts-ignore
function deserializeApiValidationResponse(input: any): ApiValidationResponse {
  return {
    appId: input["app_id"],
    status: input["status"],
  };
}

// @ts-ignore
function serializeApiValidationResponse(input: ApiValidationResponse): any {
  return {
    app_id: input["appId"],
    status: input["status"],
  };
}

export namespace StoresApi {
  export interface ComplianceSettingsCreateParams {
    [key: string]: any;
    // The name of the org
    orgName: string;
  }

  // @ts-ignore
  function serializeComplianceSettingsCreateParams(input: ComplianceSettingsCreateParams): any {
    const pathParams = {
      org_name: input["orgName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/orgs/{org_name}/compliance_settings
   *
   * Post the compliance settings for the Intune
   **/
  export function complianceSettingsCreate(
    params: ComplianceSettingsCreateParams,
    body: ApiOrgComplianceSettingsRequest,
    options?: ApiClientOptions
  ): Promise<ApiOrgComplianceSettingsResponse[]> {
    const path = "/v0.1/orgs/{org_name}/compliance_settings";
    const payload = {
      params: stringifyParams(serializeComplianceSettingsCreateParams(params)),
      body: serializeApiOrgComplianceSettingsRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiOrgComplianceSettingsResponse[]>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiOrgComplianceSettingsResponse", result?.body, "POST /v0.1/orgs/{org_name}/compliance_settings");
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiOrgComplianceSettingsResponse(r));
        return resultOrResults as ApiOrgComplianceSettingsResponse[];
      });
  }
  export interface ComplianceSettingsDeleteParams {
    [key: string]: any;
    // The name of the org
    orgName: string;
    // The compliance setting id to update
    complianceSettingId: string;
  }

  // @ts-ignore
  function serializeComplianceSettingsDeleteParams(input: ComplianceSettingsDeleteParams): any {
    const pathParams = {
      org_name: input["orgName"],
      compliance_setting_id: input["complianceSettingId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/orgs/{org_name}/compliance_settings/{compliance_setting_id}
   *
   * delete the compliance setting for the Intune
   **/
  export function complianceSettingsDelete(
    params: ComplianceSettingsDeleteParams,
    body?: string,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/orgs/{org_name}/compliance_settings/{compliance_setting_id}";
    const payload = {
      params: stringifyParams(serializeComplianceSettingsDeleteParams(params)),
      body: body,
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "DELETE /v0.1/orgs/{org_name}/compliance_settings/{compliance_setting_id}", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface ComplianceSettingsFetchParams {
    [key: string]: any;
    // The name of the org
    orgName: string;
    // The compliance setting id to update
    complianceSettingId: string;
  }

  // @ts-ignore
  function serializeComplianceSettingsFetchParams(input: ComplianceSettingsFetchParams): any {
    const pathParams = {
      org_name: input["orgName"],
      compliance_setting_id: input["complianceSettingId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/orgs/{org_name}/compliance_settings/{compliance_setting_id}
   *
   * fetch the org compliance settings for the Intune
   **/
  export function complianceSettingsFetch(
    params: ComplianceSettingsFetchParams,
    options?: ApiClientOptions
  ): Promise<ApiOrgComplianceSettingsResponse[]> {
    const path = "/v0.1/orgs/{org_name}/compliance_settings/{compliance_setting_id}";
    const payload = {
      params: stringifyParams(serializeComplianceSettingsFetchParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiOrgComplianceSettingsResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiOrgComplianceSettingsResponse",
            result?.body,
            "GET /v0.1/orgs/{org_name}/compliance_settings/{compliance_setting_id}"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiOrgComplianceSettingsResponse(r));
        return resultOrResults as ApiOrgComplianceSettingsResponse[];
      });
  }
  export interface ComplianceSettingsFetchAllParams {
    [key: string]: any;
    // The name of the org
    orgName: string;
  }

  // @ts-ignore
  function serializeComplianceSettingsFetchAllParams(input: ComplianceSettingsFetchAllParams): any {
    const pathParams = {
      org_name: input["orgName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/orgs/{org_name}/compliance_settings
   *
   * fetch the org compliance settings for the Intune
   **/
  export function complianceSettingsFetchAll(
    params: ComplianceSettingsFetchAllParams,
    options?: ApiClientOptions
  ): Promise<ApiOrgComplianceSettingsResponse[]> {
    const path = "/v0.1/orgs/{org_name}/compliance_settings";
    const payload = {
      params: stringifyParams(serializeComplianceSettingsFetchAllParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiOrgComplianceSettingsResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiOrgComplianceSettingsResponse", result?.body, "GET /v0.1/orgs/{org_name}/compliance_settings");
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiOrgComplianceSettingsResponse(r));
        return resultOrResults as ApiOrgComplianceSettingsResponse[];
      });
  }
  export interface ComplianceSettingsUpdateParams {
    [key: string]: any;
    // The name of the org
    orgName: string;
    // The compliance setting id to update
    complianceSettingId: string;
  }

  // @ts-ignore
  function serializeComplianceSettingsUpdateParams(input: ComplianceSettingsUpdateParams): any {
    const pathParams = {
      org_name: input["orgName"],
      compliance_setting_id: input["complianceSettingId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * PATCH /v0.1/orgs/{org_name}/compliance_settings/{compliance_setting_id}
   *
   * patch the compliance setting for the Intune
   **/
  export function complianceSettingsUpdate(
    params: ComplianceSettingsUpdateParams,
    body: ApiOrgComplianceSettingsRequest,
    options?: ApiClientOptions
  ): Promise<ApiOrgComplianceSettingsResponse[]> {
    const path = "/v0.1/orgs/{org_name}/compliance_settings/{compliance_setting_id}";
    const payload = {
      params: stringifyParams(serializeComplianceSettingsUpdateParams(params)),
      body: serializeApiOrgComplianceSettingsRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiOrgComplianceSettingsResponse[]>("PATCH", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiOrgComplianceSettingsResponse",
            result?.body,
            "PATCH /v0.1/orgs/{org_name}/compliance_settings/{compliance_setting_id}"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiOrgComplianceSettingsResponse(r));
        return resultOrResults as ApiOrgComplianceSettingsResponse[];
      });
  }
  export interface CreateAppleMappingParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeCreateAppleMappingParams(input: CreateAppleMappingParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/apple_mapping
   *
   * Create a mapping for an existing app in apple store for the specified application.
   **/
  export function createAppleMapping(
    params: CreateAppleMappingParams,
    body: ApiAppleMappingRequest,
    options?: ApiClientOptions
  ): Promise<ApiAppleMappingResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/apple_mapping";
    const payload = {
      params: stringifyParams(serializeCreateAppleMappingParams(params)),
      body: serializeApiAppleMappingRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiAppleMappingResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiAppleMappingResponse",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/apple_mapping",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiAppleMappingResponse(result);
        return resultOrResults as ApiAppleMappingResponse;
      });
  }
  export interface CreateStoreParams {
    [key: string]: any;
    // The secret ID of the store secret.
    secretId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeCreateStoreParams(input: CreateStoreParams): any {
    const pathParams = {
      secret_id: input["secretId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/store_secrets/{secret_id}/create_store
   *
   * create store based on secret for intune.
   **/
  export function createStore(
    params: CreateStoreParams,
    body: ApiPrivateCreateStoreRequest,
    options?: ApiClientOptions
  ): Promise<ApiExternalStoreResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/store_secrets/{secret_id}/create_store";
    const payload = {
      params: stringifyParams(serializeCreateStoreParams(params)),
      body: serializeApiPrivateCreateStoreRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiExternalStoreResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiExternalStoreResponse",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/store_secrets/{secret_id}/create_store",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiExternalStoreResponse(result);
        return resultOrResults as ApiExternalStoreResponse;
      });
  }
  export interface CreateStoreSecretsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeCreateStoreSecretsParams(input: CreateStoreSecretsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/private/apps/{owner_name}/{app_name}/store_secrets
   *
   * Save store secret temporarily.
   **/
  export function createStoreSecrets(
    params: CreateStoreSecretsParams,
    body: ApiCreateStoreSecretRequest,
    options?: ApiClientOptions
  ): Promise<ApiCreateStoreSecretResponse> {
    const path = "/v0.1/private/apps/{owner_name}/{app_name}/store_secrets";
    const payload = {
      params: stringifyParams(serializeCreateStoreSecretsParams(params)),
      body: serializeApiCreateStoreSecretRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiCreateStoreSecretResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiCreateStoreSecretResponse",
            result?.body,
            "POST /v0.1/private/apps/{owner_name}/{app_name}/store_secrets",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiCreateStoreSecretResponse(result);
        return resultOrResults as ApiCreateStoreSecretResponse;
      });
  }
  export interface CreateStoresParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeCreateStoresParams(input: CreateStoresParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/distribution_stores
   *
   * Create a new external store for the specified application.
   **/
  export function createStores(
    params: CreateStoresParams,
    body: ApiExternalStoreRequest,
    options?: ApiClientOptions
  ): Promise<ApiExternalStoreResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores";
    const payload = {
      params: stringifyParams(serializeCreateStoresParams(params)),
      body: serializeApiExternalStoreRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiExternalStoreResponse>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiExternalStoreResponse",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/distribution_stores",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiExternalStoreResponse(result);
        return resultOrResults as ApiExternalStoreResponse;
      });
  }
  export interface DeleteAppleMappingParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeDeleteAppleMappingParams(input: DeleteAppleMappingParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/apple_mapping
   *
   * Delete mapping of apple app to an existing app in apple store.
   **/
  export function deleteAppleMapping(params: DeleteAppleMappingParams, body?: string, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/apple_mapping";
    const payload = {
      params: stringifyParams(serializeDeleteAppleMappingParams(params)),
      body: body,
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "DELETE /v0.1/apps/{owner_name}/{app_name}/apple_mapping", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DeleteStoreReleasesParams {
    [key: string]: any;
    // The name of the store
    storeName: string;
    // The id of the release
    releaseId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeDeleteStoreReleasesParams(input: DeleteStoreReleasesParams): any {
    const pathParams = {
      store_name: input["storeName"],
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}
   *
   * delete the release with release Id
   **/
  export function deleteStoreReleases(params: DeleteStoreReleasesParams, body?: string, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}";
    const payload = {
      params: stringifyParams(serializeDeleteStoreReleasesParams(params)),
      body: body,
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "DELETE /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DeleteStoresParams {
    [key: string]: any;
    // The name of the store
    storeName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeDeleteStoresParams(input: DeleteStoresParams): any {
    const pathParams = {
      store_name: input["storeName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}
   *
   * delete the store based on specific store name.
   **/
  export function deleteStores(params: DeleteStoresParams, body?: string, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}";
    const payload = {
      params: stringifyParams(serializeDeleteStoresParams(params)),
      body: body,
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "DELETE /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface GetAppleMappingParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeGetAppleMappingParams(input: GetAppleMappingParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/apple_mapping
   *
   * Get mapping of apple app to an existing app in apple store.
   **/
  export function getAppleMapping(params: GetAppleMappingParams, options?: ApiClientOptions): Promise<ApiAppleMappingResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/apple_mapping";
    const payload = {
      params: stringifyParams(serializeGetAppleMappingParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiAppleMappingResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiAppleMappingResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/apple_mapping",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiAppleMappingResponse(result);
        return resultOrResults as ApiAppleMappingResponse;
      });
  }
  export interface GetDiagnosticIdStoreParams {
    [key: string]: any;
    // The store destination ID
    storeId: string;
    // The release ID
    releaseId: number;
  }

  // @ts-ignore
  function serializeGetDiagnosticIdStoreParams(input: GetDiagnosticIdStoreParams): any {
    const pathParams = {
      store_id: input["storeId"],
      release_id: input["releaseId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/{store_id}/diagnostic_id/{release_id}
   *
   * Retrieves the diagnostic id for the given store id and release id.
   **/
  export function getDiagnosticIdStore(
    params: GetDiagnosticIdStoreParams,
    options?: ApiClientOptions
  ): Promise<ApiDiagnosticIdResponse> {
    const path = "/v0.1/management/{store_id}/diagnostic_id/{release_id}";
    const payload = {
      params: stringifyParams(serializeGetDiagnosticIdStoreParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiDiagnosticIdResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiDiagnosticIdResponse",
            result?.body,
            "GET /v0.1/management/{store_id}/diagnostic_id/{release_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiDiagnosticIdResponse(result);
        return resultOrResults as ApiDiagnosticIdResponse;
      });
  }
  export interface GetLatestStoreReleasesParams {
    [key: string]: any;
    // The name of the store
    storeName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeGetLatestStoreReleasesParams(input: GetLatestStoreReleasesParams): any {
    const pathParams = {
      store_name: input["storeName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/latest_release
   *
   * Returns the latest release published in a store.
   **/
  export function getLatestStoreReleases(
    params: GetLatestStoreReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiStoresReleaseDetails[]> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/latest_release";
    const payload = {
      params: stringifyParams(serializeGetLatestStoreReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiStoresReleaseDetails[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiStoresReleaseDetails",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/latest_release"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiStoresReleaseDetails(r));
        return resultOrResults as ApiStoresReleaseDetails[];
      });
  }
  export interface GetNotificationByAppIdStoreNotificationsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeGetNotificationByAppIdStoreNotificationsParams(input: GetNotificationByAppIdStoreNotificationsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/store_service_status
   *
   * Application specific store service status
   **/
  export function getNotificationByAppIdStoreNotifications(
    params: GetNotificationByAppIdStoreNotificationsParams,
    options?: ApiClientOptions
  ): Promise<ApiStoreNotification> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/store_service_status";
    const payload = {
      params: stringifyParams(serializeGetNotificationByAppIdStoreNotificationsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiStoreNotification>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiStoreNotification",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/store_service_status",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiStoreNotification(result);
        return resultOrResults as ApiStoreNotification;
      });
  }
  export interface GetPublishErrorStoreReleasesParams {
    [key: string]: any;
    // The name of the store
    storeName: string;
    // The id of the release
    releaseId: number;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeGetPublishErrorStoreReleasesParams(input: GetPublishErrorStoreReleasesParams): any {
    const pathParams = {
      store_name: input["storeName"],
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}/publish_error_details
   *
   * Return the Error Details of release which failed in publishing.
   **/
  export function getPublishErrorStoreReleases(
    params: GetPublishErrorStoreReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiReleasePublishErrorResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}/publish_error_details";
    const payload = {
      params: stringifyParams(serializeGetPublishErrorStoreReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleasePublishErrorResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleasePublishErrorResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}/publish_error_details",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleasePublishErrorResponse(result);
        return resultOrResults as ApiReleasePublishErrorResponse;
      });
  }
  export interface GetPublishErrorStoreReleasesManagementParams {
    [key: string]: any;
    // The ID of the application.
    appId: string;
    // The name of the store
    storeName: string;
    // The id of the release
    releaseId: number;
  }

  // @ts-ignore
  function serializeGetPublishErrorStoreReleasesManagementParams(input: GetPublishErrorStoreReleasesManagementParams): any {
    const pathParams = {
      app_id: input["appId"],
      store_name: input["storeName"],
      release_id: input["releaseId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/apps/{app_id}/distribution_stores/{store_name}/releases/{release_id}/publish_error_details
   *
   * Return the Error Details of release which failed in publishing.
   **/
  export function getPublishErrorStoreReleasesManagement(
    params: GetPublishErrorStoreReleasesManagementParams,
    options?: ApiClientOptions
  ): Promise<ApiReleasePublishErrorResponse> {
    const path = "/v0.1/management/apps/{app_id}/distribution_stores/{store_name}/releases/{release_id}/publish_error_details";
    const payload = {
      params: stringifyParams(serializeGetPublishErrorStoreReleasesManagementParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleasePublishErrorResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleasePublishErrorResponse",
            result?.body,
            "GET /v0.1/management/apps/{app_id}/distribution_stores/{store_name}/releases/{release_id}/publish_error_details",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleasePublishErrorResponse(result);
        return resultOrResults as ApiReleasePublishErrorResponse;
      });
  }
  export interface GetRealTimeStatusByReleaseIdStoreReleasesParams {
    [key: string]: any;
    // The name of the store
    storeName: string;
    // The id of the release
    releaseId: number;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeGetRealTimeStatusByReleaseIdStoreReleasesParams(input: GetRealTimeStatusByReleaseIdStoreReleasesParams): any {
    const pathParams = {
      store_name: input["storeName"],
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}/realtimestatus
   *
   * Return the Real time Status publishing of release from store.
   **/
  export function getRealTimeStatusByReleaseIdStoreReleases(
    params: GetRealTimeStatusByReleaseIdStoreReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiReleaseRealTimeStatusResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}/realtimestatus";
    const payload = {
      params: stringifyParams(serializeGetRealTimeStatusByReleaseIdStoreReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseRealTimeStatusResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseRealTimeStatusResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}/realtimestatus",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseRealTimeStatusResponse(result);
        return resultOrResults as ApiReleaseRealTimeStatusResponse;
      });
  }
  export interface GetStoreNotificationsParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeGetStoreNotificationsParams(input: GetStoreNotificationsParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/stores/notifications
   *
   * Service notification for all Stores.
   **/
  export function getStoreNotifications(
    params: GetStoreNotificationsParams,
    options?: ApiClientOptions
  ): Promise<ApiStoreNotification[]> {
    const path = "/v0.1/management/stores/notifications";
    const payload = {
      params: stringifyParams(serializeGetStoreNotificationsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiStoreNotification[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiStoreNotification", result?.body, "GET /v0.1/management/stores/notifications");
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiStoreNotification(r));
        return resultOrResults as ApiStoreNotification[];
      });
  }
  export interface GetStoreReleasePublishLogsParams {
    [key: string]: any;
    // The name of the store
    storeName: string;
    // The ID of the realease
    releaseId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeGetStoreReleasePublishLogsParams(input: GetStoreReleasePublishLogsParams): any {
    const pathParams = {
      store_name: input["storeName"],
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}/publish_logs
   *
   * Returns publish logs for a particular release published to a particular store
   **/
  export function getStoreReleasePublishLogs(params: GetStoreReleasePublishLogsParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}/publish_logs";
    const payload = {
      params: stringifyParams(serializeGetStoreReleasePublishLogsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}/publish_logs",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface GetStoreReleasePublishLogsManagementParams {
    [key: string]: any;
    // The ID of the application.
    appId: string;
    // The name of the store
    storeName: string;
    // The ID of the realease
    releaseId: string;
  }

  // @ts-ignore
  function serializeGetStoreReleasePublishLogsManagementParams(input: GetStoreReleasePublishLogsManagementParams): any {
    const pathParams = {
      app_id: input["appId"],
      store_name: input["storeName"],
      release_id: input["releaseId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/apps/{app_id}/distribution_stores/{store_name}/releases/{release_id}/publish_logs
   *
   * Returns publish logs for a particular release published to a particular store
   **/
  export function getStoreReleasePublishLogsManagement(
    params: GetStoreReleasePublishLogsManagementParams,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/management/apps/{app_id}/distribution_stores/{store_name}/releases/{release_id}/publish_logs";
    const payload = {
      params: stringifyParams(serializeGetStoreReleasePublishLogsManagementParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "GET /v0.1/management/apps/{app_id}/distribution_stores/{store_name}/releases/{release_id}/publish_logs",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface GetStoreReleasesParams {
    [key: string]: any;
    // The name of the store
    storeName: string;
    // The name of the store
    releaseId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeGetStoreReleasesParams(input: GetStoreReleasesParams): any {
    const pathParams = {
      store_name: input["storeName"],
      release_id: input["releaseId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}
   *
   * Return releases published in a store for releaseId and storeId
   **/
  export function getStoreReleases(params: GetStoreReleasesParams, options?: ApiClientOptions): Promise<ApiStoresReleaseDetails[]> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}";
    const payload = {
      params: stringifyParams(serializeGetStoreReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiStoresReleaseDetails[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiStoresReleaseDetails",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases/{release_id}"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiStoresReleaseDetails(r));
        return resultOrResults as ApiStoresReleaseDetails[];
      });
  }
  export interface GetStoresParams {
    [key: string]: any;
    // The name of the store
    storeName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeGetStoresParams(input: GetStoresParams): any {
    const pathParams = {
      store_name: input["storeName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}
   *
   * Return the store details for specified store name.
   **/
  export function getStores(params: GetStoresParams, options?: ApiClientOptions): Promise<ApiExternalStoreResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}";
    const payload = {
      params: stringifyParams(serializeGetStoresParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiExternalStoreResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiExternalStoreResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiExternalStoreResponse(result);
        return resultOrResults as ApiExternalStoreResponse;
      });
  }
  export interface GetValidateStoreCreationStoresParams {
    [key: string]: any;
    // The name of the package to validate
    packageName: string;
    // Service connection id to validate
    serviceConnectionId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeGetValidateStoreCreationStoresParams(input: GetValidateStoreCreationStoresParams): any {
    const pathParams = {
      package_name: input["packageName"],
      service_connection_id: input["serviceConnectionId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{package_name}/validate_creation/{service_connection_id}
   *
   * Validate that the store can be created for a given package name
   **/
  export function getValidateStoreCreationStores(
    params: GetValidateStoreCreationStoresParams,
    options?: ApiClientOptions
  ): Promise<ApiValidationResponse> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores/{package_name}/validate_creation/{service_connection_id}";
    const payload = {
      params: stringifyParams(serializeGetValidateStoreCreationStoresParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiValidationResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiValidationResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{package_name}/validate_creation/{service_connection_id}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiValidationResponse(result);
        return resultOrResults as ApiValidationResponse;
      });
  }
  export interface HasBuildUploadedAppleMappingParams {
    [key: string]: any;
    // The id of the application
    appId: string;
  }

  // @ts-ignore
  function serializeHasBuildUploadedAppleMappingParams(input: HasBuildUploadedAppleMappingParams): any {
    const pathParams = {
      app_id: input["appId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/apps/{app_id}/apple_has_build_uploaded
   *
   * Return true if a build have been uploaded for Apple
   **/
  export function hasBuildUploadedAppleMapping(
    params: HasBuildUploadedAppleMappingParams,
    options?: ApiClientOptions
  ): Promise<ApiHasBuildUploadedResponse> {
    const path = "/v0.1/management/apps/{app_id}/apple_has_build_uploaded";
    const payload = {
      params: stringifyParams(serializeHasBuildUploadedAppleMappingParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiHasBuildUploadedResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiHasBuildUploadedResponse",
            result?.body,
            "GET /v0.1/management/apps/{app_id}/apple_has_build_uploaded",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiHasBuildUploadedResponse(result);
        return resultOrResults as ApiHasBuildUploadedResponse;
      });
  }
  export interface HasTestflightMetadataAppleMappingParams {
    [key: string]: any;
    // The id of the application
    appId: string;
  }

  // @ts-ignore
  function serializeHasTestflightMetadataAppleMappingParams(input: HasTestflightMetadataAppleMappingParams): any {
    const pathParams = {
      app_id: input["appId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/apps/{app_id}/apple_has_testflight_metadata
   *
   * Return true if the app has the testflight metadata
   **/
  export function hasTestflightMetadataAppleMapping(
    params: HasTestflightMetadataAppleMappingParams,
    options?: ApiClientOptions
  ): Promise<ApiHasTestflightMetadataResponse> {
    const path = "/v0.1/management/apps/{app_id}/apple_has_testflight_metadata";
    const payload = {
      params: stringifyParams(serializeHasTestflightMetadataAppleMappingParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiHasTestflightMetadataResponse>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiHasTestflightMetadataResponse",
            result?.body,
            "GET /v0.1/management/apps/{app_id}/apple_has_testflight_metadata",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiHasTestflightMetadataResponse(result);
        return resultOrResults as ApiHasTestflightMetadataResponse;
      });
  }
  export interface ListRecentStoreReleasesParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeListRecentStoreReleasesParams(input: ListRecentStoreReleasesParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/recent_releases
   *
   * Return the recent releases in each store for a particular application.
   **/
  export function listRecentStoreReleases(
    params: ListRecentStoreReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiStoresBasicReleaseDetails[]> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores/recent_releases";
    const payload = {
      params: stringifyParams(serializeListRecentStoreReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiStoresBasicReleaseDetails[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiStoresBasicReleaseDetails",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/recent_releases"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiStoresBasicReleaseDetails(r));
        return resultOrResults as ApiStoresBasicReleaseDetails[];
      });
  }
  export interface ListStoreCategoriesParams {
    [key: string]: any;
    // The secret id of store secret
    secretId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeListStoreCategoriesParams(input: ListStoreCategoriesParams): any {
    const pathParams = {
      secret_id: input["secretId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/store_secrets/{secret_id}/categories
   *
   * Get the store category list.
   **/
  export function listStoreCategories(params: ListStoreCategoriesParams, options?: ApiClientOptions): Promise<ApiIntuneCategories> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/store_secrets/{secret_id}/categories";
    const payload = {
      params: stringifyParams(serializeListStoreCategoriesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiIntuneCategories>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiIntuneCategories",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/store_secrets/{secret_id}/categories",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiIntuneCategories(result);
        return resultOrResults as ApiIntuneCategories;
      });
  }
  export interface ListStoreGroupsParams {
    [key: string]: any;
    // The secret id of store secret
    secretId: string;
    // The Intune group displayName filter
    filterName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeListStoreGroupsParams(input: ListStoreGroupsParams): any {
    const pathParams = {
      secret_id: input["secretId"],
      filter_name: input["filterName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/store_secrets/{secret_id}/groups/{filter_name}
   *
   * Get the Intune Organization Groups list.
   **/
  export function listStoreGroups(params: ListStoreGroupsParams, options?: ApiClientOptions): Promise<ApiIntuneGroups> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/store_secrets/{secret_id}/groups/{filter_name}";
    const payload = {
      params: stringifyParams(serializeListStoreGroupsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiIntuneGroups>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiIntuneGroups",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/store_secrets/{secret_id}/groups/{filter_name}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiIntuneGroups(result);
        return resultOrResults as ApiIntuneGroups;
      });
  }
  export interface ListStoreReleasesParams {
    [key: string]: any;
    // The name of the store
    storeName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeListStoreReleasesParams(input: ListStoreReleasesParams): any {
    const pathParams = {
      store_name: input["storeName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases
   *
   * Return all releases published  in a store
   **/
  export function listStoreReleases(
    params: ListStoreReleasesParams,
    options?: ApiClientOptions
  ): Promise<ApiStoresBasicReleaseDetails[]> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases";
    const payload = {
      params: stringifyParams(serializeListStoreReleasesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiStoresBasicReleaseDetails[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiStoresBasicReleaseDetails",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}/releases"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiStoresBasicReleaseDetails(r));
        return resultOrResults as ApiStoresBasicReleaseDetails[];
      });
  }
  export interface ListStoreReleasesManagementParams {
    [key: string]: any;
    // The ID of the application.
    appId: string;
    // The name of the store
    storeName: string;
  }

  // @ts-ignore
  function serializeListStoreReleasesManagementParams(input: ListStoreReleasesManagementParams): any {
    const pathParams = {
      app_id: input["appId"],
      store_name: input["storeName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/apps/{app_id}/distribution_stores/{store_name}/releases
   *
   * Return all releases published  in a store
   **/
  export function listStoreReleasesManagement(
    params: ListStoreReleasesManagementParams,
    options?: ApiClientOptions
  ): Promise<ApiStoresBasicReleaseDetails[]> {
    const path = "/v0.1/management/apps/{app_id}/distribution_stores/{store_name}/releases";
    const payload = {
      params: stringifyParams(serializeListStoreReleasesManagementParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiStoresBasicReleaseDetails[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiStoresBasicReleaseDetails",
            result?.body,
            "GET /v0.1/management/apps/{app_id}/distribution_stores/{store_name}/releases"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiStoresBasicReleaseDetails(r));
        return resultOrResults as ApiStoresBasicReleaseDetails[];
      });
  }
  export interface ListStoresParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeListStoresParams(input: ListStoresParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores
   *
   * Get all the store details from Storage store table for a particular application.
   **/
  export function listStores(params: ListStoresParams, options?: ApiClientOptions): Promise<ApiExternalStoreResponse[]> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores";
    const payload = {
      params: stringifyParams(serializeListStoresParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiExternalStoreResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiExternalStoreResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/distribution_stores"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiExternalStoreResponse(r));
        return resultOrResults as ApiExternalStoreResponse[];
      });
  }
  export interface ListUserGroupsParams {
    [key: string]: any;
    // The secret id of store secret
    secretId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeListUserGroupsParams(input: ListUserGroupsParams): any {
    const pathParams = {
      secret_id: input["secretId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/store_secrets/{secret_id}/user_groups
   *
   * Get list of groups user is a member of.
   **/
  export function listUserGroups(params: ListUserGroupsParams, options?: ApiClientOptions): Promise<ApiIntuneGroup[]> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/store_secrets/{secret_id}/user_groups";
    const payload = {
      params: stringifyParams(serializeListUserGroupsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiIntuneGroup[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiIntuneGroup",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/store_secrets/{secret_id}/user_groups"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiIntuneGroup(r));
        return resultOrResults as ApiIntuneGroup[];
      });
  }
  export interface ListallStoresParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeListallStoresParams(input: ListallStoresParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/all_stores
   *
   * Get list of all distribution stores from store table as well as test flight groups for apple
   **/
  export function listallStores(params: ListallStoresParams, options?: ApiClientOptions): Promise<ApiExternalStoreResponse[]> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/all_stores";
    const payload = {
      params: stringifyParams(serializeListallStoresParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiExternalStoreResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiExternalStoreResponse", result?.body, "GET /v0.1/apps/{owner_name}/{app_name}/all_stores");
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiExternalStoreResponse(r));
        return resultOrResults as ApiExternalStoreResponse[];
      });
  }
  export interface ListallStoresManagementParams {
    [key: string]: any;
    // The ID of the application
    appId: string;
  }

  // @ts-ignore
  function serializeListallStoresManagementParams(input: ListallStoresManagementParams): any {
    const pathParams = {
      app_id: input["appId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/apps/{app_id}/all_stores
   *
   * Get list of all distribution stores from store table as well as test flight groups for apple
   **/
  export function listallStoresManagement(
    params: ListallStoresManagementParams,
    options?: ApiClientOptions
  ): Promise<ApiExternalStoreResponse[]> {
    const path = "/v0.1/management/apps/{app_id}/all_stores";
    const payload = {
      params: stringifyParams(serializeListallStoresManagementParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiExternalStoreResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiExternalStoreResponse", result?.body, "GET /v0.1/management/apps/{app_id}/all_stores");
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiExternalStoreResponse(r));
        return resultOrResults as ApiExternalStoreResponse[];
      });
  }
  export interface PatchStoresParams {
    [key: string]: any;
    // The name of the store
    storeName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializePatchStoresParams(input: PatchStoresParams): any {
    const pathParams = {
      store_name: input["storeName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * PATCH /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}
   *
   * Update the store.
   **/
  export function patchStores(params: PatchStoresParams, body: ApiStorePatchRequest, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}";
    const payload = {
      params: stringifyParams(serializePatchStoresParams(params)),
      body: serializeApiStorePatchRequest(body),
    };
    Object.assign(payload, options);

    return getApiMethod("PATCH", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "PATCH /v0.1/apps/{owner_name}/{app_name}/distribution_stores/{store_name}", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface PostStoreNotificationsParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializePostStoreNotificationsParams(input: PostStoreNotificationsParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/management/stores/notifications
   *
   * Set store service notification manually.
   **/
  export function postStoreNotifications(
    params: PostStoreNotificationsParams,
    body: ApiStoreNotification,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/management/stores/notifications";
    const payload = {
      params: stringifyParams(serializePostStoreNotificationsParams(params)),
      body: serializeApiStoreNotification(body),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/management/stores/notifications", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface PurgeStoreParams {
    [key: string]: any;
    // Delete every release that was soft deleted before &#x60;maxDays&#x60; ago. 60 is the minimum to avoid bugs that will cause unexpected deletion. max 365.
    maxDays?: number;
  }

  // @ts-ignore
  function serializePurgeStoreParams(input: PurgeStoreParams): any {
    const pathParams = {};

    const queryParams = {
      maxDays: input["maxDays"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/manage/store/purge
   *
   * Deletes every store that was soft deleted before &#x60;maxDays&#x60; ago.
   **/
  export function purgeStore(params: PurgeStoreParams, body?: string, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/manage/store/purge";
    const payload = {
      params: stringifyParams(serializePurgeStoreParams(params)),
      body: body,
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/manage/store/purge", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface TestFlightGroupsAppleMappingParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeTestFlightGroupsAppleMappingParams(input: TestFlightGroupsAppleMappingParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/apple_test_flight_groups
   *
   * Fetch all apple test flight groups
   **/
  export function testFlightGroupsAppleMapping(
    params: TestFlightGroupsAppleMappingParams,
    options?: ApiClientOptions
  ): Promise<ApiAppleTestFlightGroupsResponse[]> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/apple_test_flight_groups";
    const payload = {
      params: stringifyParams(serializeTestFlightGroupsAppleMappingParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiAppleTestFlightGroupsResponse[]>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiAppleTestFlightGroupsResponse",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/apple_test_flight_groups"
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result.map((r) => deserializeApiAppleTestFlightGroupsResponse(r));
        return resultOrResults as ApiAppleTestFlightGroupsResponse[];
      });
  }
}
