import { result } from "lodash";
import {
  SerializedBillingResource,
  DeserializedBillingResources,
  SerializedService,
  SerializedAccount,
} from "../models/billing-resources";
import { BuildBillingPlanPath, TestBillingPlanPath, TestUsagePath, BuildUsagePath, TestBillingPeriodPath } from "../constants";
import { translateTestPlan, translateBuildPlan, translateBuildUsage, translateTestUsage } from "./billing-plan-translator";

export const mergePlanTiers = (accountPlans: SerializedBillingResource): DeserializedBillingResources => {
  const { count: testCount, plan: testPlan } = result<SerializedBillingResource, SerializedAccount>(accountPlans, TestBillingPlanPath);
  const { count: buildCount, plan: buildPlan } = result<SerializedBillingResource, SerializedAccount>(
    accountPlans,
    BuildBillingPlanPath
  );

  const testUsage = result(accountPlans, TestUsagePath);
  const buildUsage = result(accountPlans, BuildUsagePath);

  const testBillingPeriod: SerializedService = result(accountPlans, TestBillingPeriodPath);

  return {
    test: {
      plan: translateTestPlan(testPlan, testCount, testBillingPeriod),
      usage: translateTestUsage(testUsage),
    },
    build: {
      plan: translateBuildPlan(buildPlan, buildCount),
      usage: translateBuildUsage(buildUsage),
    },
  };
};
