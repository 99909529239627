/**
 * analytics
 * Microsoft AppCenter Analytics REST API.
 *
 * OpenAPI spec version: 1.0.0-preview20161028
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { apiGateway } from "@root/lib/http";
import { ApiClientOptions } from "../api-client-options";

interface ApiMethodResult<T> {
  statusCode: number;
  body: T;
}

function getApiMethod<T>(httpMethod, path, payload, options: ApiClientOptions = {}): Promise<ApiMethodResult<T>> {
  if (options.timeout !== undefined) {
    console.warn("API Client timeout option is ignored.");
  }
  return apiGateway.call<T>(httpMethod, path, payload).then((body) => {
    return { statusCode: 200, body };
  });
}

// @ts-ignore
function stringifyParams(params: { [key: string]: any }): { [key: string]: string | string[] } {
  return Object.assign(
    {},
    ...Object.keys(params)
      .filter((key) => params[key])
      .map((key) => ({ [key]: params[key].toString() }))
  );
}

function validateSwaggerResult(model, result, operation, shouldIterate = true) {
  if (process.env.NODE_ENV !== "production") {
    const swagger = require("./swagger.json");
    const Validator = require("swagger-model-validator");
    // @ts-ignore
    const validator = new Validator(swagger);
    if (model) {
      if (model.startsWith("Api")) {
        model = model.substr(3);
      }
      const validation = [];
      if (shouldIterate && Array.isArray(result)) {
        result.forEach((r) => {
          const validationResult = swagger.validateModel(model, r, true, true);
          if (!validationResult.valid) {
            // @ts-ignore
            validation.push(validationResult.GetFormattedErrors());
          }
        });
      } else {
        const validationResult = swagger.validateModel(model, result, true, true);
        if (!validationResult.valid) {
          // @ts-ignore
          validation.push(validationResult.GetFormattedErrors());
        }
      }
      if (validation.length > 0) {
        console.warn("Swagger validation for model " + model + " failed for API call '" + operation + "'", result, validation);
      }
    }
  }
}

export interface ApiActiveDeviceCounts {
  /**
   * The active device count for each interval.
   */
  daily?: ApiDateTimeCounts[];
  /**
   * The active device count for each interval with a week's retention.
   */
  weekly?: ApiDateTimeCounts[];
  /**
   * The active device count for each interval with a month's retention.
   */
  monthly?: ApiDateTimeCounts[];
}

// @ts-ignore
function deserializeApiActiveDeviceCounts(input: any): ApiActiveDeviceCounts {
  return {
    daily: input["daily"]
      ? (input["daily"] as ApiDateTimeCounts[]).map((val) => deserializeApiDateTimeCounts(val))
      : (undefined as any),
    weekly: input["weekly"]
      ? (input["weekly"] as ApiDateTimeCounts[]).map((val) => deserializeApiDateTimeCounts(val))
      : (undefined as any),
    monthly: input["monthly"]
      ? (input["monthly"] as ApiDateTimeCounts[]).map((val) => deserializeApiDateTimeCounts(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiActiveDeviceCounts(input: ApiActiveDeviceCounts): any {
  return {
    daily: input["daily"] ? (input["daily"] as ApiDateTimeCounts[]).map((val) => deserializeApiDateTimeCounts(val)) : undefined,
    weekly: input["weekly"] ? (input["weekly"] as ApiDateTimeCounts[]).map((val) => deserializeApiDateTimeCounts(val)) : undefined,
    monthly: input["monthly"] ? (input["monthly"] as ApiDateTimeCounts[]).map((val) => deserializeApiDateTimeCounts(val)) : undefined,
  };
}

export interface ApiAnalyticsModels {
  total?: number;
  models?: ApiModel[];
}

// @ts-ignore
function deserializeApiAnalyticsModels(input: any): ApiAnalyticsModels {
  return {
    total: input["total"],
    models: input["models"] ? (input["models"] as ApiModel[]).map((val) => deserializeApiModel(val)) : (undefined as any),
  };
}

// @ts-ignore
function serializeApiAnalyticsModels(input: ApiAnalyticsModels): any {
  return {
    total: input["total"],
    models: input["models"] ? (input["models"] as ApiModel[]).map((val) => deserializeApiModel(val)) : undefined,
  };
}

export interface ApiAvailableAppBuilds {
  /**
   * List of available app builds.
   */
  appBuilds?: string[];
}

// @ts-ignore
function deserializeApiAvailableAppBuilds(input: any): ApiAvailableAppBuilds {
  return {
    appBuilds: input["app_builds"] ? (input["app_builds"] as string[]).map((val) => val) : (undefined as any),
  };
}

// @ts-ignore
function serializeApiAvailableAppBuilds(input: ApiAvailableAppBuilds): any {
  return {
    app_builds: input["appBuilds"] ? (input["appBuilds"] as string[]).map((val) => val) : undefined,
  };
}

export interface ApiAvailableVersions {
  /**
   * List of available versions.
   */
  versions?: string[];
  /**
   * The full number of versions across all pages.
   */
  totalCount?: number;
}

// @ts-ignore
function deserializeApiAvailableVersions(input: any): ApiAvailableVersions {
  return {
    versions: input["versions"] ? (input["versions"] as string[]).map((val) => val) : (undefined as any),
    totalCount: input["total_count"],
  };
}

// @ts-ignore
function serializeApiAvailableVersions(input: ApiAvailableVersions): any {
  return {
    versions: input["versions"] ? (input["versions"] as string[]).map((val) => val) : undefined,
    total_count: input["totalCount"],
  };
}

export interface ApiCrashCounts {
  /**
   * Total crash count.
   */
  count?: number;
  /**
   * The total crash count for day.
   */
  crashes?: ApiDateTimeCounts[];
}

// @ts-ignore
function deserializeApiCrashCounts(input: any): ApiCrashCounts {
  return {
    count: input["count"],
    crashes: input["crashes"]
      ? (input["crashes"] as ApiDateTimeCounts[]).map((val) => deserializeApiDateTimeCounts(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiCrashCounts(input: ApiCrashCounts): any {
  return {
    count: input["count"],
    crashes: input["crashes"] ? (input["crashes"] as ApiDateTimeCounts[]).map((val) => deserializeApiDateTimeCounts(val)) : undefined,
  };
}

export interface ApiCrashFreeDevicePercentages {
  /**
   * Average percentage.
   */
  averagePercentage?: number;
  /**
   * The crash-free percentage per day.
   */
  dailyPercentages?: ApiDateTimePercentages[];
}

// @ts-ignore
function deserializeApiCrashFreeDevicePercentages(input: any): ApiCrashFreeDevicePercentages {
  return {
    averagePercentage: input["average_percentage"],
    dailyPercentages: input["daily_percentages"]
      ? (input["daily_percentages"] as ApiDateTimePercentages[]).map((val) => deserializeApiDateTimePercentages(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiCrashFreeDevicePercentages(input: ApiCrashFreeDevicePercentages): any {
  return {
    average_percentage: input["averagePercentage"],
    daily_percentages: input["dailyPercentages"]
      ? (input["dailyPercentages"] as ApiDateTimePercentages[]).map((val) => deserializeApiDateTimePercentages(val))
      : undefined,
  };
}

export interface ApiCrashGroupAndVersion {
  crashGroupId?: string;
  appVersion?: string;
}

// @ts-ignore
function deserializeApiCrashGroupAndVersion(input: any): ApiCrashGroupAndVersion {
  return {
    crashGroupId: input["crash_group_id"],
    appVersion: input["app_version"],
  };
}

// @ts-ignore
function serializeApiCrashGroupAndVersion(input: ApiCrashGroupAndVersion): any {
  return {
    crash_group_id: input["crashGroupId"],
    app_version: input["appVersion"],
  };
}

export interface ApiCrashGroupCarrier {
  /**
   * Carrier name.
   */
  carrierName?: string;
  /**
   * Crash count of carrier.
   */
  crashCount?: number;
}

// @ts-ignore
function deserializeApiCrashGroupCarrier(input: any): ApiCrashGroupCarrier {
  return {
    carrierName: input["carrier_name"],
    crashCount: input["crash_count"],
  };
}

// @ts-ignore
function serializeApiCrashGroupCarrier(input: ApiCrashGroupCarrier): any {
  return {
    carrier_name: input["carrierName"],
    crash_count: input["crashCount"],
  };
}

export interface ApiCrashGroupCarriers {
  crashCount?: number;
  carriers?: ApiCrashGroupCarrier[];
}

// @ts-ignore
function deserializeApiCrashGroupCarriers(input: any): ApiCrashGroupCarriers {
  return {
    crashCount: input["crash_count"],
    carriers: input["carriers"]
      ? (input["carriers"] as ApiCrashGroupCarrier[]).map((val) => deserializeApiCrashGroupCarrier(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiCrashGroupCarriers(input: ApiCrashGroupCarriers): any {
  return {
    crash_count: input["crashCount"],
    carriers: input["carriers"]
      ? (input["carriers"] as ApiCrashGroupCarrier[]).map((val) => deserializeApiCrashGroupCarrier(val))
      : undefined,
  };
}

export interface ApiCrashGroupContainer {
  crashGroups: ApiCrashGroupAndVersion[];
}

// @ts-ignore
function deserializeApiCrashGroupContainer(input: any): ApiCrashGroupContainer {
  return {
    crashGroups: input["crash_groups"]
      ? (input["crash_groups"] as ApiCrashGroupAndVersion[]).map((val) => deserializeApiCrashGroupAndVersion(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiCrashGroupContainer(input: ApiCrashGroupContainer): any {
  return {
    crash_groups: input["crashGroups"]
      ? (input["crashGroups"] as ApiCrashGroupAndVersion[]).map((val) => deserializeApiCrashGroupAndVersion(val))
      : undefined,
  };
}

export interface ApiCrashGroupLanguage {
  /**
   * Language name.
   */
  languageName?: string;
  /**
   * Count of languages.
   */
  crashCount?: number;
}

// @ts-ignore
function deserializeApiCrashGroupLanguage(input: any): ApiCrashGroupLanguage {
  return {
    languageName: input["language_name"],
    crashCount: input["crash_count"],
  };
}

// @ts-ignore
function serializeApiCrashGroupLanguage(input: ApiCrashGroupLanguage): any {
  return {
    language_name: input["languageName"],
    crash_count: input["crashCount"],
  };
}

export interface ApiCrashGroupLanguages {
  crashCount?: number;
  languages?: ApiCrashGroupLanguage[];
}

// @ts-ignore
function deserializeApiCrashGroupLanguages(input: any): ApiCrashGroupLanguages {
  return {
    crashCount: input["crash_count"],
    languages: input["languages"]
      ? (input["languages"] as ApiCrashGroupLanguage[]).map((val) => deserializeApiCrashGroupLanguage(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiCrashGroupLanguages(input: ApiCrashGroupLanguages): any {
  return {
    crash_count: input["crashCount"],
    languages: input["languages"]
      ? (input["languages"] as ApiCrashGroupLanguage[]).map((val) => deserializeApiCrashGroupLanguage(val))
      : undefined,
  };
}

export interface ApiCrashGroupModel {
  /**
   * Model's name.
   */
  modelName?: string;
  /**
   * Count of model.
   */
  crashCount?: number;
}

// @ts-ignore
function deserializeApiCrashGroupModel(input: any): ApiCrashGroupModel {
  return {
    modelName: input["model_name"],
    crashCount: input["crash_count"],
  };
}

// @ts-ignore
function serializeApiCrashGroupModel(input: ApiCrashGroupModel): any {
  return {
    model_name: input["modelName"],
    crash_count: input["crashCount"],
  };
}

export interface ApiCrashGroupModels {
  crashCount?: number;
  models?: ApiCrashGroupModel[];
}

// @ts-ignore
function deserializeApiCrashGroupModels(input: any): ApiCrashGroupModels {
  return {
    crashCount: input["crash_count"],
    models: input["models"]
      ? (input["models"] as ApiCrashGroupModel[]).map((val) => deserializeApiCrashGroupModel(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiCrashGroupModels(input: ApiCrashGroupModels): any {
  return {
    crash_count: input["crashCount"],
    models: input["models"] ? (input["models"] as ApiCrashGroupModel[]).map((val) => deserializeApiCrashGroupModel(val)) : undefined,
  };
}

export interface ApiCrashGroupOperatingSystem {
  /**
   * OS name.
   */
  operatingSystemName?: string;
  /**
   * Count of OS.
   */
  crashCount?: number;
}

// @ts-ignore
function deserializeApiCrashGroupOperatingSystem(input: any): ApiCrashGroupOperatingSystem {
  return {
    operatingSystemName: input["operating_system_name"],
    crashCount: input["crash_count"],
  };
}

// @ts-ignore
function serializeApiCrashGroupOperatingSystem(input: ApiCrashGroupOperatingSystem): any {
  return {
    operating_system_name: input["operatingSystemName"],
    crash_count: input["crashCount"],
  };
}

export interface ApiCrashGroupOperatingSystems {
  crashCount?: number;
  operatingSystems?: ApiCrashGroupOperatingSystem[];
}

// @ts-ignore
function deserializeApiCrashGroupOperatingSystems(input: any): ApiCrashGroupOperatingSystems {
  return {
    crashCount: input["crash_count"],
    operatingSystems: input["operating_systems"]
      ? (input["operating_systems"] as ApiCrashGroupOperatingSystem[]).map((val) => deserializeApiCrashGroupOperatingSystem(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiCrashGroupOperatingSystems(input: ApiCrashGroupOperatingSystems): any {
  return {
    crash_count: input["crashCount"],
    operating_systems: input["operatingSystems"]
      ? (input["operatingSystems"] as ApiCrashGroupOperatingSystem[]).map((val) => deserializeApiCrashGroupOperatingSystem(val))
      : undefined,
  };
}

export interface ApiCrashGroupPlace {
  /**
   * Place name.
   */
  placeName?: string;
  /**
   * Count of places.
   */
  crashCount?: number;
}

// @ts-ignore
function deserializeApiCrashGroupPlace(input: any): ApiCrashGroupPlace {
  return {
    placeName: input["place_name"],
    crashCount: input["crash_count"],
  };
}

// @ts-ignore
function serializeApiCrashGroupPlace(input: ApiCrashGroupPlace): any {
  return {
    place_name: input["placeName"],
    crash_count: input["crashCount"],
  };
}

export interface ApiCrashGroupPlaces {
  crashCount?: number;
  places?: ApiCrashGroupPlace[];
}

// @ts-ignore
function deserializeApiCrashGroupPlaces(input: any): ApiCrashGroupPlaces {
  return {
    crashCount: input["crash_count"],
    places: input["places"]
      ? (input["places"] as ApiCrashGroupPlace[]).map((val) => deserializeApiCrashGroupPlace(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiCrashGroupPlaces(input: ApiCrashGroupPlaces): any {
  return {
    crash_count: input["crashCount"],
    places: input["places"] ? (input["places"] as ApiCrashGroupPlace[]).map((val) => deserializeApiCrashGroupPlace(val)) : undefined,
  };
}

export interface ApiCrashOverall {
  crashCount?: number;
  deviceCount?: number;
}

// @ts-ignore
function deserializeApiCrashOverall(input: any): ApiCrashOverall {
  return {
    crashCount: input["crash_count"],
    deviceCount: input["device_count"],
  };
}

// @ts-ignore
function serializeApiCrashOverall(input: ApiCrashOverall): any {
  return {
    crash_count: input["crashCount"],
    device_count: input["deviceCount"],
  };
}

/**
 * List of crash groups with overall crashes count and affected users.
 */

export interface ApiCrashesOverall extends Array<ApiCrashesOverallInner> {}

// @ts-ignore
function deserializeApiCrashesOverall(input: any): ApiCrashesOverall {
  return input.map((item) => deserializeApiCrashesOverallInner(item));
}

// @ts-ignore
function serializeApiCrashesOverall(input: ApiCrashesOverall): any {
  return input.map((item) => serializeApiCrashesOverallInner(item));
}

export interface ApiCrashesOverallInner {
  crashGroupId?: string;
  appVersion?: string;
  overall?: ApiCrashOverall;
}

// @ts-ignore
function deserializeApiCrashesOverallInner(input: any): ApiCrashesOverallInner {
  return {
    crashGroupId: input["crash_group_id"],
    appVersion: input["app_version"],
    overall: input["overall"],
  };
}

// @ts-ignore
function serializeApiCrashesOverallInner(input: ApiCrashesOverallInner): any {
  return {
    crash_group_id: input["crashGroupId"],
    app_version: input["appVersion"],
    overall: input["overall"],
  };
}

export interface ApiDailySession {
  /**
   * The ISO 8601 datetime.
   */
  datetime?: string;
  count?: number;
}

// @ts-ignore
function deserializeApiDailySession(input: any): ApiDailySession {
  return {
    datetime: input["datetime"],
    count: input["count"],
  };
}

// @ts-ignore
function serializeApiDailySession(input: ApiDailySession): any {
  return {
    datetime: input["datetime"],
    count: input["count"],
  };
}

export interface ApiDateTimeCounts {
  /**
   * The ISO 8601 datetime.
   */
  datetime?: string;
  /**
   * Count of the object.
   */
  count?: number;
}

// @ts-ignore
function deserializeApiDateTimeCounts(input: any): ApiDateTimeCounts {
  return {
    datetime: input["datetime"],
    count: input["count"],
  };
}

// @ts-ignore
function serializeApiDateTimeCounts(input: ApiDateTimeCounts): any {
  return {
    datetime: input["datetime"],
    count: input["count"],
  };
}

export interface ApiDateTimeDecimalCounts {
  /**
   * The ISO 8601 datetime.
   */
  datetime?: string;
  /**
   * Decimal count of the object.
   */
  count?: number;
}

// @ts-ignore
function deserializeApiDateTimeDecimalCounts(input: any): ApiDateTimeDecimalCounts {
  return {
    datetime: input["datetime"],
    count: input["count"],
  };
}

// @ts-ignore
function serializeApiDateTimeDecimalCounts(input: ApiDateTimeDecimalCounts): any {
  return {
    datetime: input["datetime"],
    count: input["count"],
  };
}

export interface ApiDateTimeDownloadReleaseCount {
  /**
   * The ISO 8601 datetime.
   */
  datetime?: string;
  total?: number;
  unique?: number;
}

// @ts-ignore
function deserializeApiDateTimeDownloadReleaseCount(input: any): ApiDateTimeDownloadReleaseCount {
  return {
    datetime: input["datetime"],
    total: input["total"],
    unique: input["unique"],
  };
}

// @ts-ignore
function serializeApiDateTimeDownloadReleaseCount(input: ApiDateTimeDownloadReleaseCount): any {
  return {
    datetime: input["datetime"],
    total: input["total"],
    unique: input["unique"],
  };
}

export interface ApiDateTimeDownloadReleaseCounts {
  total?: number;
  unique?: number;
  /**
   * Release counts per day.
   */
  counts?: ApiDateTimeDownloadReleaseCount[];
}

// @ts-ignore
function deserializeApiDateTimeDownloadReleaseCounts(input: any): ApiDateTimeDownloadReleaseCounts {
  return {
    total: input["total"],
    unique: input["unique"],
    counts: input["counts"]
      ? (input["counts"] as ApiDateTimeDownloadReleaseCount[]).map((val) => deserializeApiDateTimeDownloadReleaseCount(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiDateTimeDownloadReleaseCounts(input: ApiDateTimeDownloadReleaseCounts): any {
  return {
    total: input["total"],
    unique: input["unique"],
    counts: input["counts"]
      ? (input["counts"] as ApiDateTimeDownloadReleaseCount[]).map((val) => deserializeApiDateTimeDownloadReleaseCount(val))
      : undefined,
  };
}

export interface ApiDateTimePercentages {
  /**
   * The ISO 8601 datetime.
   */
  datetime?: string;
  /**
   * Percentage of the object.
   */
  percentage?: number;
}

// @ts-ignore
function deserializeApiDateTimePercentages(input: any): ApiDateTimePercentages {
  return {
    datetime: input["datetime"],
    percentage: input["percentage"],
  };
}

// @ts-ignore
function serializeApiDateTimePercentages(input: ApiDateTimePercentages): any {
  return {
    datetime: input["datetime"],
    percentage: input["percentage"],
  };
}

export interface ApiDeleteReleasesContainer {
  releases: ApiRelease[];
}

// @ts-ignore
function deserializeApiDeleteReleasesContainer(input: any): ApiDeleteReleasesContainer {
  return {
    releases: input["releases"] ? (input["releases"] as ApiRelease[]).map((val) => deserializeApiRelease(val)) : (undefined as any),
  };
}

// @ts-ignore
function serializeApiDeleteReleasesContainer(input: ApiDeleteReleasesContainer): any {
  return {
    releases: input["releases"] ? (input["releases"] as ApiRelease[]).map((val) => deserializeApiRelease(val)) : undefined,
  };
}

/**
 * Error
 */

export interface ApiError {
  error?: ApiErrorError;
}

// @ts-ignore
function deserializeApiError(input: any): ApiError {
  return {
    error: input["error"],
  };
}

// @ts-ignore
function serializeApiError(input: ApiError): any {
  return {
    error: input["error"],
  };
}

export interface ApiErrorDetails {
  code: ApiErrorDetails.ApiCodeEnum;
  message: string;
}

// @ts-ignore
function deserializeApiErrorDetails(input: any): ApiErrorDetails {
  return {
    code: input["code"],
    message: input["message"],
  };
}

// @ts-ignore
function serializeApiErrorDetails(input: ApiErrorDetails): any {
  return {
    code: input["code"],
    message: input["message"],
  };
}

export namespace ApiErrorDetails {
  export type ApiCodeEnum = "BadRequest" | "Conflict" | "NotAcceptable" | "NotFound" | "InternalServerError" | "Unauthorized";
}

export interface ApiErrorError {
  /**
   * The status code return by the API. It can be 400 or 403 or 500.
   */
  code?: number;
  /**
   * The reason for the request failed.
   */
  message?: string;
}

// @ts-ignore
function deserializeApiErrorError(input: any): ApiErrorError {
  return {
    code: input["code"],
    message: input["message"],
  };
}

// @ts-ignore
function serializeApiErrorError(input: ApiErrorError): any {
  return {
    code: input["code"],
    message: input["message"],
  };
}

export interface ApiErrorResponse {
  error: ApiErrorDetails;
}

// @ts-ignore
function deserializeApiErrorResponse(input: any): ApiErrorResponse {
  return {
    error: input["error"],
  };
}

// @ts-ignore
function serializeApiErrorResponse(input: ApiErrorResponse): any {
  return {
    error: input["error"],
  };
}

export interface ApiEvent {
  id?: string;
  name?: string;
  deviceCount?: number;
  /**
   * The device count of previous time range of the event.
   */
  previousDeviceCount?: number;
  count?: number;
  /**
   * The event count of previous time range of the event.
   */
  previousCount?: number;
  countPerDevice?: number;
  countPerSession?: number;
}

// @ts-ignore
function deserializeApiEvent(input: any): ApiEvent {
  return {
    id: input["id"],
    name: input["name"],
    deviceCount: input["device_count"],
    previousDeviceCount: input["previous_device_count"],
    count: input["count"],
    previousCount: input["previous_count"],
    countPerDevice: input["count_per_device"],
    countPerSession: input["count_per_session"],
  };
}

// @ts-ignore
function serializeApiEvent(input: ApiEvent): any {
  return {
    id: input["id"],
    name: input["name"],
    device_count: input["deviceCount"],
    previous_device_count: input["previousDeviceCount"],
    count: input["count"],
    previous_count: input["previousCount"],
    count_per_device: input["countPerDevice"],
    count_per_session: input["countPerSession"],
  };
}

export interface ApiEventCount {
  totalCount?: number;
  previousTotalCount?: number;
  count?: ApiDateTimeCounts[];
}

// @ts-ignore
function deserializeApiEventCount(input: any): ApiEventCount {
  return {
    totalCount: input["total_count"],
    previousTotalCount: input["previous_total_count"],
    count: input["count"]
      ? (input["count"] as ApiDateTimeCounts[]).map((val) => deserializeApiDateTimeCounts(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiEventCount(input: ApiEventCount): any {
  return {
    total_count: input["totalCount"],
    previous_total_count: input["previousTotalCount"],
    count: input["count"] ? (input["count"] as ApiDateTimeCounts[]).map((val) => deserializeApiDateTimeCounts(val)) : undefined,
  };
}

export interface ApiEventCountPerDevice {
  avgCountPerDevice?: number;
  previousAvgCountPerDevice?: number;
  countPerDevice?: ApiDateTimeDecimalCounts[];
}

// @ts-ignore
function deserializeApiEventCountPerDevice(input: any): ApiEventCountPerDevice {
  return {
    avgCountPerDevice: input["avg_count_per_device"],
    previousAvgCountPerDevice: input["previous_avg_count_per_device"],
    countPerDevice: input["count_per_device"]
      ? (input["count_per_device"] as ApiDateTimeDecimalCounts[]).map((val) => deserializeApiDateTimeDecimalCounts(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiEventCountPerDevice(input: ApiEventCountPerDevice): any {
  return {
    avg_count_per_device: input["avgCountPerDevice"],
    previous_avg_count_per_device: input["previousAvgCountPerDevice"],
    count_per_device: input["countPerDevice"]
      ? (input["countPerDevice"] as ApiDateTimeDecimalCounts[]).map((val) => deserializeApiDateTimeDecimalCounts(val))
      : undefined,
  };
}

export interface ApiEventCountPerSession {
  avgCountPerSession?: number;
  previousAvgCountPerSession?: number;
  countPerSession?: ApiDateTimeDecimalCounts[];
}

// @ts-ignore
function deserializeApiEventCountPerSession(input: any): ApiEventCountPerSession {
  return {
    avgCountPerSession: input["avg_count_per_session"],
    previousAvgCountPerSession: input["previous_avg_count_per_session"],
    countPerSession: input["count_per_session"]
      ? (input["count_per_session"] as ApiDateTimeDecimalCounts[]).map((val) => deserializeApiDateTimeDecimalCounts(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiEventCountPerSession(input: ApiEventCountPerSession): any {
  return {
    avg_count_per_session: input["avgCountPerSession"],
    previous_avg_count_per_session: input["previousAvgCountPerSession"],
    count_per_session: input["countPerSession"]
      ? (input["countPerSession"] as ApiDateTimeDecimalCounts[]).map((val) => deserializeApiDateTimeDecimalCounts(val))
      : undefined,
  };
}

export interface ApiEventDeviceCount {
  totalDevices?: number;
  totalDevicesWithEvent?: number;
  previousTotalDevicesWithEvent?: number;
  devicesCount?: ApiDateTimeCounts[];
}

// @ts-ignore
function deserializeApiEventDeviceCount(input: any): ApiEventDeviceCount {
  return {
    totalDevices: input["total_devices"],
    totalDevicesWithEvent: input["total_devices_with_event"],
    previousTotalDevicesWithEvent: input["previous_total_devices_with_event"],
    devicesCount: input["devices_count"]
      ? (input["devices_count"] as ApiDateTimeCounts[]).map((val) => deserializeApiDateTimeCounts(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiEventDeviceCount(input: ApiEventDeviceCount): any {
  return {
    total_devices: input["totalDevices"],
    total_devices_with_event: input["totalDevicesWithEvent"],
    previous_total_devices_with_event: input["previousTotalDevicesWithEvent"],
    devices_count: input["devicesCount"]
      ? (input["devicesCount"] as ApiDateTimeCounts[]).map((val) => deserializeApiDateTimeCounts(val))
      : undefined,
  };
}

/**
 * Event properties during the time range.
 */

export interface ApiEventProperties {
  eventProperties?: string[];
}

// @ts-ignore
function deserializeApiEventProperties(input: any): ApiEventProperties {
  return {
    eventProperties: input["event_properties"] ? (input["event_properties"] as string[]).map((val) => val) : (undefined as any),
  };
}

// @ts-ignore
function serializeApiEventProperties(input: ApiEventProperties): any {
  return {
    event_properties: input["eventProperties"] ? (input["eventProperties"] as string[]).map((val) => val) : undefined,
  };
}

/**
 * An event property value with counts.
 */

export interface ApiEventPropertyValue {
  /**
   * The event property value name.
   */
  name?: string;
  /**
   * The count of the the event property value.
   */
  count?: number;
  /**
   * The count of previous time range of the event property value.
   */
  previousCount?: number;
}

// @ts-ignore
function deserializeApiEventPropertyValue(input: any): ApiEventPropertyValue {
  return {
    name: input["name"],
    count: input["count"],
    previousCount: input["previous_count"],
  };
}

// @ts-ignore
function serializeApiEventPropertyValue(input: ApiEventPropertyValue): any {
  return {
    name: input["name"],
    count: input["count"],
    previous_count: input["previousCount"],
  };
}

/**
 * Event property value counts during the time range in descending order.
 */

export interface ApiEventPropertyValues {
  /**
   * The total property value counts.
   */
  total?: number;
  /**
   * The event property values.
   */
  values?: ApiEventPropertyValue[];
}

// @ts-ignore
function deserializeApiEventPropertyValues(input: any): ApiEventPropertyValues {
  return {
    total: input["total"],
    values: input["values"]
      ? (input["values"] as ApiEventPropertyValue[]).map((val) => deserializeApiEventPropertyValue(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiEventPropertyValues(input: ApiEventPropertyValues): any {
  return {
    total: input["total"],
    values: input["values"]
      ? (input["values"] as ApiEventPropertyValue[]).map((val) => deserializeApiEventPropertyValue(val))
      : undefined,
  };
}

export interface ApiEvents {
  events?: ApiEvent[];
  /**
   * The total count of events.
   */
  total?: number;
  /**
   * The active device over this period.
   */
  totalDevices?: number;
}

// @ts-ignore
function deserializeApiEvents(input: any): ApiEvents {
  return {
    events: input["events"] ? (input["events"] as ApiEvent[]).map((val) => deserializeApiEvent(val)) : (undefined as any),
    total: input["total"],
    totalDevices: input["total_devices"],
  };
}

// @ts-ignore
function serializeApiEvents(input: ApiEvents): any {
  return {
    events: input["events"] ? (input["events"] as ApiEvent[]).map((val) => deserializeApiEvent(val)) : undefined,
    total: input["total"],
    total_devices: input["totalDevices"],
  };
}

export interface ApiFilterReleasesContainer {
  releases?: ApiRelease[];
}

// @ts-ignore
function deserializeApiFilterReleasesContainer(input: any): ApiFilterReleasesContainer {
  return {
    releases: input["releases"] ? (input["releases"] as ApiRelease[]).map((val) => deserializeApiRelease(val)) : (undefined as any),
  };
}

// @ts-ignore
function serializeApiFilterReleasesContainer(input: ApiFilterReleasesContainer): any {
  return {
    releases: input["releases"] ? (input["releases"] as ApiRelease[]).map((val) => deserializeApiRelease(val)) : undefined,
  };
}

export interface ApiFilterVersionsContainer {
  versions?: ApiFilterVersionsContainerVersions[];
}

// @ts-ignore
function deserializeApiFilterVersionsContainer(input: any): ApiFilterVersionsContainer {
  return {
    versions: input["versions"]
      ? (input["versions"] as ApiFilterVersionsContainerVersions[]).map((val) => deserializeApiFilterVersionsContainerVersions(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiFilterVersionsContainer(input: ApiFilterVersionsContainer): any {
  return {
    versions: input["versions"]
      ? (input["versions"] as ApiFilterVersionsContainerVersions[]).map((val) => deserializeApiFilterVersionsContainerVersions(val))
      : undefined,
  };
}

export interface ApiFilterVersionsContainerVersions {
  /**
   * App version.
   */
  version: string;
  /**
   * App build number.
   */
  build: string;
}

// @ts-ignore
function deserializeApiFilterVersionsContainerVersions(input: any): ApiFilterVersionsContainerVersions {
  return {
    version: input["version"],
    build: input["build"],
  };
}

// @ts-ignore
function serializeApiFilterVersionsContainerVersions(input: ApiFilterVersionsContainerVersions): any {
  return {
    version: input["version"],
    build: input["build"],
  };
}

export interface ApiGetReleasesContainer {
  releases: ApiReleaseWithDistributionGroup[];
}

// @ts-ignore
function deserializeApiGetReleasesContainer(input: any): ApiGetReleasesContainer {
  return {
    releases: input["releases"]
      ? (input["releases"] as ApiReleaseWithDistributionGroup[]).map((val) => deserializeApiReleaseWithDistributionGroup(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiGetReleasesContainer(input: ApiGetReleasesContainer): any {
  return {
    releases: input["releases"]
      ? (input["releases"] as ApiReleaseWithDistributionGroup[]).map((val) => deserializeApiReleaseWithDistributionGroup(val))
      : undefined,
  };
}

export interface ApiLanguage {
  /**
   * Language's name.
   */
  languageName?: string;
  /**
   * Count current of language.
   */
  count?: number;
  /**
   * Count of previous lanugage.
   */
  previousCount?: number;
}

// @ts-ignore
function deserializeApiLanguage(input: any): ApiLanguage {
  return {
    languageName: input["language_name"],
    count: input["count"],
    previousCount: input["previous_count"],
  };
}

// @ts-ignore
function serializeApiLanguage(input: ApiLanguage): any {
  return {
    language_name: input["languageName"],
    count: input["count"],
    previous_count: input["previousCount"],
  };
}

export interface ApiLanguages {
  total?: number;
  languages?: ApiLanguage[];
}

// @ts-ignore
function deserializeApiLanguages(input: any): ApiLanguages {
  return {
    total: input["total"],
    languages: input["languages"]
      ? (input["languages"] as ApiLanguage[]).map((val) => deserializeApiLanguage(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiLanguages(input: ApiLanguages): any {
  return {
    total: input["total"],
    languages: input["languages"] ? (input["languages"] as ApiLanguage[]).map((val) => deserializeApiLanguage(val)) : undefined,
  };
}

export interface ApiMigration {
  appId: string;
  fromDate?: string;
  toDate?: string;
  whatIf?: boolean;
  waitForCompletion?: boolean;
  pauseService?: boolean;
}

// @ts-ignore
function deserializeApiMigration(input: any): ApiMigration {
  return {
    appId: input["AppId"],
    fromDate: input["FromDate"],
    toDate: input["ToDate"],
    whatIf: input["WhatIf"],
    waitForCompletion: input["WaitForCompletion"],
    pauseService: input["PauseService"],
  };
}

// @ts-ignore
function serializeApiMigration(input: ApiMigration): any {
  return {
    AppId: input["appId"],
    FromDate: input["fromDate"],
    ToDate: input["toDate"],
    WhatIf: input["whatIf"],
    WaitForCompletion: input["waitForCompletion"],
    PauseService: input["pauseService"],
  };
}

export interface ApiModel {
  /**
   * Model's name.
   */
  modelName?: string;
  /**
   * Count current of model.
   */
  count?: number;
  /**
   * Count of previous model.
   */
  previousCount?: number;
}

// @ts-ignore
function deserializeApiModel(input: any): ApiModel {
  return {
    modelName: input["model_name"],
    count: input["count"],
    previousCount: input["previous_count"],
  };
}

// @ts-ignore
function serializeApiModel(input: ApiModel): any {
  return {
    model_name: input["modelName"],
    count: input["count"],
    previous_count: input["previousCount"],
  };
}

export interface ApiModule {
  [key: string]: any;
}

// @ts-ignore
function deserializeApiModule(input: any): ApiModule {
  return input;
}

// @ts-ignore
function serializeApiModule(input: ApiModule): any {
  return input;
}

export interface ApiModules {
  modules?: { [key: string]: ApiModule };
}

// @ts-ignore
function deserializeApiModules(input: any): ApiModules {
  return {
    modules: input["modules"],
  };
}

// @ts-ignore
function serializeApiModules(input: ApiModules): any {
  return {
    modules: input["modules"],
  };
}

export interface ApiNotifyReleasesContainer {
  releases: ApiReleaseWithDistributionGroupAndUserId[];
}

// @ts-ignore
function deserializeApiNotifyReleasesContainer(input: any): ApiNotifyReleasesContainer {
  return {
    releases: input["releases"]
      ? (input["releases"] as ApiReleaseWithDistributionGroupAndUserId[]).map((val) =>
          deserializeApiReleaseWithDistributionGroupAndUserId(val)
        )
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiNotifyReleasesContainer(input: ApiNotifyReleasesContainer): any {
  return {
    releases: input["releases"]
      ? (input["releases"] as ApiReleaseWithDistributionGroupAndUserId[]).map((val) =>
          deserializeApiReleaseWithDistributionGroupAndUserId(val)
        )
      : undefined,
  };
}

export interface ApiOS {
  /**
   * OS name.
   */
  osName?: string;
  /**
   * Count current of OS.
   */
  count?: number;
  /**
   * Count of previous OS.
   */
  previousCount?: number;
}

// @ts-ignore
function deserializeApiOS(input: any): ApiOS {
  return {
    osName: input["os_name"],
    count: input["count"],
    previousCount: input["previous_count"],
  };
}

// @ts-ignore
function serializeApiOS(input: ApiOS): any {
  return {
    os_name: input["osName"],
    count: input["count"],
    previous_count: input["previousCount"],
  };
}

export interface ApiOSes {
  total?: number;
  oses?: ApiOS[];
}

// @ts-ignore
function deserializeApiOSes(input: any): ApiOSes {
  return {
    total: input["total"],
    oses: input["oses"] ? (input["oses"] as ApiOS[]).map((val) => deserializeApiOS(val)) : (undefined as any),
  };
}

// @ts-ignore
function serializeApiOSes(input: ApiOSes): any {
  return {
    total: input["total"],
    oses: input["oses"] ? (input["oses"] as ApiOS[]).map((val) => deserializeApiOS(val)) : undefined,
  };
}

/**
 * The place code and the count.
 */

export interface ApiPlace {
  /**
   * The place code.
   */
  code?: string;
  /**
   * The count of the this place.
   */
  count?: number;
  /**
   * The count of previous time range of the place.
   */
  previousCount?: number;
}

// @ts-ignore
function deserializeApiPlace(input: any): ApiPlace {
  return {
    code: input["code"],
    count: input["count"],
    previousCount: input["previous_count"],
  };
}

// @ts-ignore
function serializeApiPlace(input: ApiPlace): any {
  return {
    code: input["code"],
    count: input["count"],
    previous_count: input["previousCount"],
  };
}

/**
 * Places and count during the time range in descending order.
 */

export interface ApiPlaces {
  total?: number;
  places?: ApiPlace[];
}

// @ts-ignore
function deserializeApiPlaces(input: any): ApiPlaces {
  return {
    total: input["total"],
    places: input["places"] ? (input["places"] as ApiPlace[]).map((val) => deserializeApiPlace(val)) : (undefined as any),
  };
}

// @ts-ignore
function serializeApiPlaces(input: ApiPlaces): any {
  return {
    total: input["total"],
    places: input["places"] ? (input["places"] as ApiPlace[]).map((val) => deserializeApiPlace(val)) : undefined,
  };
}

export interface ApiRelease {
  /**
   * Release Id.
   */
  release: string;
}

// @ts-ignore
function deserializeApiRelease(input: any): ApiRelease {
  return {
    release: input["release"],
  };
}

// @ts-ignore
function serializeApiRelease(input: ApiRelease): any {
  return {
    release: input["release"],
  };
}

export interface ApiReleaseCount {
  releaseId: string;
  /**
   * Distribution group queried.
   */
  distributionGroup?: string;
  /**
   * Count of unique downloads against user id.
   */
  uniqueCount: number;
  /**
   * Total count of downloads.
   */
  totalCount: number;
}

// @ts-ignore
function deserializeApiReleaseCount(input: any): ApiReleaseCount {
  return {
    releaseId: input["release_id"],
    distributionGroup: input["distribution_group"],
    uniqueCount: input["unique_count"],
    totalCount: input["total_count"],
  };
}

// @ts-ignore
function serializeApiReleaseCount(input: ApiReleaseCount): any {
  return {
    release_id: input["releaseId"],
    distribution_group: input["distributionGroup"],
    unique_count: input["uniqueCount"],
    total_count: input["totalCount"],
  };
}

export interface ApiReleaseCounts {
  total?: number;
  counts: ApiReleaseCount[];
}

// @ts-ignore
function deserializeApiReleaseCounts(input: any): ApiReleaseCounts {
  return {
    total: input["total"],
    counts: input["counts"]
      ? (input["counts"] as ApiReleaseCount[]).map((val) => deserializeApiReleaseCount(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiReleaseCounts(input: ApiReleaseCounts): any {
  return {
    total: input["total"],
    counts: input["counts"] ? (input["counts"] as ApiReleaseCount[]).map((val) => deserializeApiReleaseCount(val)) : undefined,
  };
}

export interface ApiReleaseDailySessions {
  totalSessionCounts?: number;
  avgSessionsPerDay?: number;
  /**
   * Sessions per day.
   */
  sessions?: ApiDailySession[];
}

// @ts-ignore
function deserializeApiReleaseDailySessions(input: any): ApiReleaseDailySessions {
  return {
    totalSessionCounts: input["totalSessionCounts"],
    avgSessionsPerDay: input["avgSessionsPerDay"],
    sessions: input["sessions"]
      ? (input["sessions"] as ApiDailySession[]).map((val) => deserializeApiDailySession(val))
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiReleaseDailySessions(input: ApiReleaseDailySessions): any {
  return {
    totalSessionCounts: input["totalSessionCounts"],
    avgSessionsPerDay: input["avgSessionsPerDay"],
    sessions: input["sessions"] ? (input["sessions"] as ApiDailySession[]).map((val) => deserializeApiDailySession(val)) : undefined,
  };
}

export interface ApiSessionCounts extends Array<ApiDateTimeCounts> {}

// @ts-ignore
function deserializeApiSessionCounts(input: any): ApiSessionCounts {
  return input.map((item) => deserializeApiDateTimeCounts(item));
}

// @ts-ignore
function serializeApiSessionCounts(input: ApiSessionCounts): any {
  return input.map((item) => serializeApiDateTimeCounts(item));
}

export interface ApiSessionDurationsDistribution {
  /**
   * The count of sessions in these buckets.
   */
  distribution?: ApiSessionDurationsDistributionDistribution[];
  /**
   * The previous average session duration for previous time range.
   */
  previousAverageDuration?: string;
  /**
   * The average session duration for current time range.
   */
  averageDuration?: string;
}

// @ts-ignore
function deserializeApiSessionDurationsDistribution(input: any): ApiSessionDurationsDistribution {
  return {
    distribution: input["distribution"]
      ? (input["distribution"] as ApiSessionDurationsDistributionDistribution[]).map((val) =>
          deserializeApiSessionDurationsDistributionDistribution(val)
        )
      : (undefined as any),
    previousAverageDuration: input["previous_average_duration"],
    averageDuration: input["average_duration"],
  };
}

// @ts-ignore
function serializeApiSessionDurationsDistribution(input: ApiSessionDurationsDistribution): any {
  return {
    distribution: input["distribution"]
      ? (input["distribution"] as ApiSessionDurationsDistributionDistribution[]).map((val) =>
          deserializeApiSessionDurationsDistributionDistribution(val)
        )
      : undefined,
    previous_average_duration: input["previousAverageDuration"],
    average_duration: input["averageDuration"],
  };
}

export interface ApiSessionDurationsDistributionDistribution {
  /**
   * The bucket name.
   */
  bucket?: string;
  /**
   * The count of sessions in current bucket.
   */
  count?: number;
}

// @ts-ignore
function deserializeApiSessionDurationsDistributionDistribution(input: any): ApiSessionDurationsDistributionDistribution {
  return {
    bucket: input["bucket"],
    count: input["count"],
  };
}

// @ts-ignore
function serializeApiSessionDurationsDistributionDistribution(input: ApiSessionDurationsDistributionDistribution): any {
  return {
    bucket: input["bucket"],
    count: input["count"],
  };
}

export interface ApiSessionsPerDevice {
  /**
   * Average seesion per user.
   */
  averageSessionsPerUser?: number;
  /**
   * Previous average session per user.
   */
  previousAverageSessionsPerUser?: number;
  /**
   * Total session per device count.
   */
  totalCount?: number;
  /**
   * Previous total count.
   */
  previousTotalCount?: number;
  /**
   * The session count for each interval per device.
   */
  sessionsPerUser?: ApiSessionsPerDeviceSessionsPerUser[];
}

// @ts-ignore
function deserializeApiSessionsPerDevice(input: any): ApiSessionsPerDevice {
  return {
    averageSessionsPerUser: input["average_sessions_per_user"],
    previousAverageSessionsPerUser: input["previous_average_sessions_per_user"],
    totalCount: input["total_count"],
    previousTotalCount: input["previous_total_count"],
    sessionsPerUser: input["sessions_per_user"]
      ? (input["sessions_per_user"] as ApiSessionsPerDeviceSessionsPerUser[]).map((val) =>
          deserializeApiSessionsPerDeviceSessionsPerUser(val)
        )
      : (undefined as any),
  };
}

// @ts-ignore
function serializeApiSessionsPerDevice(input: ApiSessionsPerDevice): any {
  return {
    average_sessions_per_user: input["averageSessionsPerUser"],
    previous_average_sessions_per_user: input["previousAverageSessionsPerUser"],
    total_count: input["totalCount"],
    previous_total_count: input["previousTotalCount"],
    sessions_per_user: input["sessionsPerUser"]
      ? (input["sessionsPerUser"] as ApiSessionsPerDeviceSessionsPerUser[]).map((val) =>
          deserializeApiSessionsPerDeviceSessionsPerUser(val)
        )
      : undefined,
  };
}

export interface ApiSessionsPerDeviceSessionsPerUser {
  /**
   * The ISO 8601 datetime.
   */
  datetime?: string;
  /**
   * Count.
   */
  count?: number;
}

// @ts-ignore
function deserializeApiSessionsPerDeviceSessionsPerUser(input: any): ApiSessionsPerDeviceSessionsPerUser {
  return {
    datetime: input["datetime"],
    count: input["count"],
  };
}

// @ts-ignore
function serializeApiSessionsPerDeviceSessionsPerUser(input: ApiSessionsPerDeviceSessionsPerUser): any {
  return {
    datetime: input["datetime"],
    count: input["count"],
  };
}

export interface ApiVersion {
  /**
   * Version.
   */
  version?: string;
  /**
   * Version count.
   */
  count?: number;
  /**
   * The count of previous time range of the version.
   */
  previousCount?: number;
}

// @ts-ignore
function deserializeApiVersion(input: any): ApiVersion {
  return {
    version: input["version"],
    count: input["count"],
    previousCount: input["previous_count"],
  };
}

// @ts-ignore
function serializeApiVersion(input: ApiVersion): any {
  return {
    version: input["version"],
    count: input["count"],
    previous_count: input["previousCount"],
  };
}

export interface ApiVersions {
  /**
   * List of version count.
   */
  versions?: ApiVersion[];
  /**
   * The total count of versions.
   */
  total?: number;
}

// @ts-ignore
function deserializeApiVersions(input: any): ApiVersions {
  return {
    versions: input["versions"] ? (input["versions"] as ApiVersion[]).map((val) => deserializeApiVersion(val)) : (undefined as any),
    total: input["total"],
  };
}

// @ts-ignore
function serializeApiVersions(input: ApiVersions): any {
  return {
    versions: input["versions"] ? (input["versions"] as ApiVersion[]).map((val) => deserializeApiVersion(val)) : undefined,
    total: input["total"],
  };
}

export interface ApiReleaseWithDistributionGroup {
  /**
   * Release Id.
   */
  release: string;
}

// @ts-ignore
function deserializeApiReleaseWithDistributionGroup(input: any): ApiReleaseWithDistributionGroup {
  return {
    release: input["release"],
  };
}

// @ts-ignore
function serializeApiReleaseWithDistributionGroup(input: ApiReleaseWithDistributionGroup): any {
  return {
    release: input["release"],
  };
}

export interface ApiReleaseWithDistributionGroupAndUserId {
  /**
   * Release Id.
   */
  release: string;
}

// @ts-ignore
function deserializeApiReleaseWithDistributionGroupAndUserId(input: any): ApiReleaseWithDistributionGroupAndUserId {
  return {
    release: input["release"],
  };
}

// @ts-ignore
function serializeApiReleaseWithDistributionGroupAndUserId(input: ApiReleaseWithDistributionGroupAndUserId): any {
  return {
    release: input["release"],
  };
}

export namespace AnalyticsApi {
  export interface AnalyticsManagementMigrationStartedParams {
    [key: string]: any;
    // Name.
    name: string;
  }

  // @ts-ignore
  function serializeAnalyticsManagementMigrationStartedParams(input: AnalyticsManagementMigrationStartedParams): any {
    const pathParams = {
      name: input["name"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * HEAD /v0.1/management/analytics/migration/{name}
   *
   **/
  export function analyticsManagementMigrationStarted(
    params: AnalyticsManagementMigrationStartedParams,
    body: ApiMigration,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/management/analytics/migration/{name}";
    const payload = {
      params: stringifyParams(serializeAnalyticsManagementMigrationStartedParams(params)),
      body: serializeApiMigration(body),
    };
    Object.assign(payload, options);

    return getApiMethod("HEAD", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "HEAD /v0.1/management/analytics/migration/{name}", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface AppEventCountParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeAppEventCountParams(input: AppEventCountParams): any {
    const pathParams = {
      event_name: input["eventName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/event_count
   *
   * Count of events by interval in the time range (internal version used by portal to get around ad blockers blocking &#39;analytics/event&#39;).
   **/
  export function appEventCount(params: AppEventCountParams, options?: ApiClientOptions): Promise<ApiEventCount> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/event_count";
    const payload = {
      params: stringifyParams(serializeAppEventCountParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEventCount>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiEventCount",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/event_count",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEventCount(result);
        return resultOrResults as ApiEventCount;
      });
  }
  export interface AppEventDeviceCountParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeAppEventDeviceCountParams(input: AppEventDeviceCountParams): any {
    const pathParams = {
      event_name: input["eventName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/device_count
   *
   * Count of devices for an event by interval in the time range (internal version used by portal to get around ad blockers blocking &#39;analytics/event&#39;).
   **/
  export function appEventDeviceCount(params: AppEventDeviceCountParams, options?: ApiClientOptions): Promise<ApiEventDeviceCount> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/device_count";
    const payload = {
      params: stringifyParams(serializeAppEventDeviceCountParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEventDeviceCount>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiEventDeviceCount",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/device_count",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEventDeviceCount(result);
        return resultOrResults as ApiEventDeviceCount;
      });
  }
  export interface AppEventPerDeviceCountParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeAppEventPerDeviceCountParams(input: AppEventPerDeviceCountParams): any {
    const pathParams = {
      event_name: input["eventName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/count_per_device
   *
   * Count of events per device by interval in the time range (internal version used by portal to get around ad blockers blocking &#39;analytics/event&#39;).
   **/
  export function appEventPerDeviceCount(
    params: AppEventPerDeviceCountParams,
    options?: ApiClientOptions
  ): Promise<ApiEventCountPerDevice> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/count_per_device";
    const payload = {
      params: stringifyParams(serializeAppEventPerDeviceCountParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEventCountPerDevice>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiEventCountPerDevice",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/count_per_device",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEventCountPerDevice(result);
        return resultOrResults as ApiEventCountPerDevice;
      });
  }
  export interface AppEventPerSessionCountParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeAppEventPerSessionCountParams(input: AppEventPerSessionCountParams): any {
    const pathParams = {
      event_name: input["eventName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/count_per_session
   *
   * Count of events per session by interval in the time range (internal version used by portal to get around ad blockers blocking &#39;analytics/event&#39;).
   **/
  export function appEventPerSessionCount(
    params: AppEventPerSessionCountParams,
    options?: ApiClientOptions
  ): Promise<ApiEventCountPerSession> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/count_per_session";
    const payload = {
      params: stringifyParams(serializeAppEventPerSessionCountParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEventCountPerSession>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiEventCountPerSession",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/count_per_session",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEventCountPerSession(result);
        return resultOrResults as ApiEventCountPerSession;
      });
  }
  export interface AppEventPropertiesParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeAppEventPropertiesParams(input: AppEventPropertiesParams): any {
    const pathParams = {
      event_name: input["eventName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/properties
   *
   * Event properties (internal version used by portal to get around ad blockers blocking &#39;analytics/event&#39;).
   **/
  export function appEventProperties(params: AppEventPropertiesParams, options?: ApiClientOptions): Promise<ApiEventProperties> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/properties";
    const payload = {
      params: stringifyParams(serializeAppEventPropertiesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEventProperties>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiEventProperties",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/properties",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEventProperties(result);
        return resultOrResults as ApiEventProperties;
      });
  }
  export interface AppEventPropertyCountsParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The id of the event property.
    eventPropertyName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
    // The number of property values to return. Set to 0 in order to fetch all results available.
    top?: number;
  }

  // @ts-ignore
  function serializeAppEventPropertyCountsParams(input: AppEventPropertyCountsParams): any {
    const pathParams = {
      event_name: input["eventName"],
      event_property_name: input["eventPropertyName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
      $top: input["top"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/properties/{event_property_name}/counts
   *
   * Event properties value counts during the time range in descending order (internal version used by portal to get around ad blockers blocking &#39;analytics/event&#39;).
   **/
  export function appEventPropertyCounts(
    params: AppEventPropertyCountsParams,
    options?: ApiClientOptions
  ): Promise<ApiEventPropertyValues> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/properties/{event_property_name}/counts";
    const payload = {
      params: stringifyParams(serializeAppEventPropertyCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEventPropertyValues>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiEventPropertyValues",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}/properties/{event_property_name}/counts",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEventPropertyValues(result);
        return resultOrResults as ApiEventPropertyValues;
      });
  }
  export interface AppEventsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
    // To select the specific events.
    eventName?: string[];
    // The maximum number of results to return. Set to 0 in order to fetch all results available.
    top?: number;
    // The offset (starting at 0) of the first result to return. This parameter along with limit is used to perform pagination.
    skip?: number;
    // Controls whether or not to include a count of all the items across all pages.
    inlinecount?: "allpages" | "none";
    // Controls the sorting order and sorting based on which column.
    orderby?: string;
  }

  // @ts-ignore
  function serializeAppEventsParams(input: AppEventsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
      event_name: input["eventName"],
      $top: input["top"],
      $skip: input["skip"],
      $inlinecount: input["inlinecount"],
      $orderby: input["orderby"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events
   *
   * Count of active events in the time range ordered by event (internal version used by portal to get around ad blockers blocking &#39;analytics/event&#39;).
   **/
  export function appEvents(params: AppEventsParams, options?: ApiClientOptions): Promise<ApiEvents> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/app_events";
    const payload = {
      params: stringifyParams(serializeAppEventsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEvents>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiEvents", result?.body, "GET /v0.1/apps/{owner_name}/{app_name}/analytics/app_events", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEvents(result);
        return resultOrResults as ApiEvents;
      });
  }
  export interface AppEventsDeleteParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeAppEventsDeleteParams(input: AppEventsDeleteParams): any {
    const pathParams = {
      event_name: input["eventName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}
   *
   * Delete the set of Events with the specified event names (internal version used by portal to get around ad blockers blocking &#39;analytics/event&#39;).
   **/
  export function appEventsDelete(params: AppEventsDeleteParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}";
    const payload = {
      params: stringifyParams(serializeAppEventsDeleteParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "DELETE /v0.1/apps/{owner_name}/{app_name}/analytics/app_events/{event_name}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface AvailableAppBuildsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    version: string;
  }

  // @ts-ignore
  function serializeAvailableAppBuildsParams(input: AvailableAppBuildsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      version: input["version"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/available_app_builds
   *
   * Get all available app builds in the time range.
   **/
  export function availableAppBuilds(params: AvailableAppBuildsParams, options?: ApiClientOptions): Promise<ApiAvailableAppBuilds> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/available_app_builds";
    const payload = {
      params: stringifyParams(serializeAvailableAppBuildsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiAvailableAppBuilds>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiAvailableAppBuilds",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/available_app_builds",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiAvailableAppBuilds(result);
        return resultOrResults as ApiAvailableAppBuilds;
      });
  }
  export interface AvailableVersionsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    // The maximum number of results to return. Set to 0 in order to fetch all results available.
    top?: number;
    // The offset (starting at 0) of the first result to return. This parameter along with limit is used to perform pagination.
    skip?: number;
    // A filter as specified in https://github.com/Microsoft/api-guidelines/blob/master/Guidelines.md#97-filtering.
    filter?: string;
    // Controls whether or not to include a count of all the items across all pages.
    inlinecount?: "allpages" | "none";
  }

  // @ts-ignore
  function serializeAvailableVersionsParams(input: AvailableVersionsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      $top: input["top"],
      $skip: input["skip"],
      $filter: input["filter"],
      $inlinecount: input["inlinecount"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/available_versions
   *
   * Get all available versions in the time range.
   **/
  export function availableVersions(params: AvailableVersionsParams, options?: ApiClientOptions): Promise<ApiAvailableVersions> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/available_versions";
    const payload = {
      params: stringifyParams(serializeAvailableVersionsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiAvailableVersions>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiAvailableVersions",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/available_versions",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiAvailableVersions(result);
        return resultOrResults as ApiAvailableVersions;
      });
  }
  export interface ClearQueueParams {
    [key: string]: any;
    // Storage account name.
    storageAccount: string;
    // Name of the queue.
    queueName: string;
    // Type of the queue.
    queueType: "analytics" | "handledErrorParser" | "handledErrorAggregation";
  }

  // @ts-ignore
  function serializeClearQueueParams(input: ClearQueueParams): any {
    const pathParams = {};

    const queryParams = {
      storageAccount: input["storageAccount"],
      queueName: input["queueName"],
      queueType: input["queueType"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/management/analytics/queues/clear
   *
   **/
  export function clearQueue(params: ClearQueueParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/management/analytics/queues/clear";
    const payload = {
      params: stringifyParams(serializeClearQueueParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/management/analytics/queues/clear", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface ConfiguredModulesParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeConfiguredModulesParams(input: ConfiguredModulesParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/configuredModules
   *
   * Return a list of configured modules.
   **/
  export function configuredModules(params: ConfiguredModulesParams, options?: ApiClientOptions): Promise<ApiModules> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/configuredModules";
    const payload = {
      params: stringifyParams(serializeConfiguredModulesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiModules>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiModules",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/configuredModules",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiModules(result);
        return resultOrResults as ApiModules;
      });
  }
  export interface CrashCountsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeCrashCountsParams(input: CrashCountsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_counts
   * Available for UWP apps only.
   * Count of crashes by day in the time range based the selected versions. Available for UWP apps only.
   **/
  export function crashCounts(params: CrashCountsParams, options?: ApiClientOptions): Promise<ApiCrashCounts> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/crash_counts";
    const payload = {
      params: stringifyParams(serializeCrashCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiCrashCounts>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiCrashCounts",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_counts",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiCrashCounts(result);
        return resultOrResults as ApiCrashCounts;
      });
  }
  export interface CrashFreeDevicePercentagesParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    version: string;
  }

  // @ts-ignore
  function serializeCrashFreeDevicePercentagesParams(input: CrashFreeDevicePercentagesParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      version: input["version"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/crashfree_device_percentages
   *
   * Percentage of crash-free device by day in the time range based on the selected versions. Api will return -1 if crash devices is greater than active devices.
   **/
  export function crashFreeDevicePercentages(
    params: CrashFreeDevicePercentagesParams,
    options?: ApiClientOptions
  ): Promise<ApiCrashFreeDevicePercentages> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/crashfree_device_percentages";
    const payload = {
      params: stringifyParams(serializeCrashFreeDevicePercentagesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiCrashFreeDevicePercentages>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiCrashFreeDevicePercentages",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/crashfree_device_percentages",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiCrashFreeDevicePercentages(result);
        return resultOrResults as ApiCrashFreeDevicePercentages;
      });
  }
  export interface CrashGroupCarrierCountsParams {
    [key: string]: any;
    // The id of the crash group.
    crashGroupId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    //
    version: string;
    // The maximum number of results to return. Set to 0 in order to fetch all results available.
    top?: number;
  }

  // @ts-ignore
  function serializeCrashGroupCarrierCountsParams(input: CrashGroupCarrierCountsParams): any {
    const pathParams = {
      crash_group_id: input["crashGroupId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      version: input["version"],
      $top: input["top"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/carriers
   *
   * Top carriers of the selected crash group with selected version.
   **/
  export function crashGroupCarrierCounts(
    params: CrashGroupCarrierCountsParams,
    options?: ApiClientOptions
  ): Promise<ApiCrashGroupCarriers> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/carriers";
    const payload = {
      params: stringifyParams(serializeCrashGroupCarrierCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiCrashGroupCarriers>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiCrashGroupCarriers",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/carriers",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiCrashGroupCarriers(result);
        return resultOrResults as ApiCrashGroupCarriers;
      });
  }
  export interface CrashGroupCountsParams {
    [key: string]: any;
    // The id of the crash group.
    crashGroupId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    //
    version: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
  }

  // @ts-ignore
  function serializeCrashGroupCountsParams(input: CrashGroupCountsParams): any {
    const pathParams = {
      crash_group_id: input["crashGroupId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      version: input["version"],
      start: input["start"],
      end: input["end"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/crash_counts
   * Available for UWP apps only.
   * Count of crashes by day in the time range of the selected crash group with selected version. Available for UWP apps only.
   **/
  export function crashGroupCounts(params: CrashGroupCountsParams, options?: ApiClientOptions): Promise<ApiCrashCounts> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/crash_counts";
    const payload = {
      params: stringifyParams(serializeCrashGroupCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiCrashCounts>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiCrashCounts",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/crash_counts",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiCrashCounts(result);
        return resultOrResults as ApiCrashCounts;
      });
  }
  export interface CrashGroupLanguagesCountsParams {
    [key: string]: any;
    // The id of the crash group.
    crashGroupId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    //
    version: string;
    // The maximum number of results to return. Set to 0 in order to fetch all results available.
    top?: number;
  }

  // @ts-ignore
  function serializeCrashGroupLanguagesCountsParams(input: CrashGroupLanguagesCountsParams): any {
    const pathParams = {
      crash_group_id: input["crashGroupId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      version: input["version"],
      $top: input["top"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/languages
   *
   * Top languages of the selected crash group with selected version.
   **/
  export function crashGroupLanguagesCounts(
    params: CrashGroupLanguagesCountsParams,
    options?: ApiClientOptions
  ): Promise<ApiCrashGroupLanguages> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/languages";
    const payload = {
      params: stringifyParams(serializeCrashGroupLanguagesCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiCrashGroupLanguages>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiCrashGroupLanguages",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/languages",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiCrashGroupLanguages(result);
        return resultOrResults as ApiCrashGroupLanguages;
      });
  }
  export interface CrashGroupModelCountsParams {
    [key: string]: any;
    // The id of the crash group.
    crashGroupId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    //
    version: string;
    // The maximum number of results to return. Set to 0 in order to fetch all results available.
    top?: number;
  }

  // @ts-ignore
  function serializeCrashGroupModelCountsParams(input: CrashGroupModelCountsParams): any {
    const pathParams = {
      crash_group_id: input["crashGroupId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      version: input["version"],
      $top: input["top"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/models
   * Available for UWP apps only.
   * Top models of the selected crash group with selected version. Available for UWP apps only.
   **/
  export function crashGroupModelCounts(
    params: CrashGroupModelCountsParams,
    options?: ApiClientOptions
  ): Promise<ApiCrashGroupModels> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/models";
    const payload = {
      params: stringifyParams(serializeCrashGroupModelCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiCrashGroupModels>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiCrashGroupModels",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/models",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiCrashGroupModels(result);
        return resultOrResults as ApiCrashGroupModels;
      });
  }
  export interface CrashGroupOperatingSystemCountsParams {
    [key: string]: any;
    // The id of the crash group.
    crashGroupId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    //
    version: string;
    // The maximum number of results to return. Set to 0 in order to fetch all results available.
    top?: number;
  }

  // @ts-ignore
  function serializeCrashGroupOperatingSystemCountsParams(input: CrashGroupOperatingSystemCountsParams): any {
    const pathParams = {
      crash_group_id: input["crashGroupId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      version: input["version"],
      $top: input["top"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/operating_systems
   * Available for UWP apps only.
   * Top OSes of the selected crash group with selected version. Available for UWP apps only.
   **/
  export function crashGroupOperatingSystemCounts(
    params: CrashGroupOperatingSystemCountsParams,
    options?: ApiClientOptions
  ): Promise<ApiCrashGroupOperatingSystems> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/operating_systems";
    const payload = {
      params: stringifyParams(serializeCrashGroupOperatingSystemCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiCrashGroupOperatingSystems>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiCrashGroupOperatingSystems",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/operating_systems",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiCrashGroupOperatingSystems(result);
        return resultOrResults as ApiCrashGroupOperatingSystems;
      });
  }
  export interface CrashGroupPlacesCountsParams {
    [key: string]: any;
    // The id of the crash group.
    crashGroupId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    //
    version: string;
    // The maximum number of results to return. Set to 0 in order to fetch all results available.
    top?: number;
  }

  // @ts-ignore
  function serializeCrashGroupPlacesCountsParams(input: CrashGroupPlacesCountsParams): any {
    const pathParams = {
      crash_group_id: input["crashGroupId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      version: input["version"],
      $top: input["top"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/places
   * Available for UWP apps only.
   * Top places of the selected crash group with selected version. Available for UWP apps only.
   **/
  export function crashGroupPlacesCounts(
    params: CrashGroupPlacesCountsParams,
    options?: ApiClientOptions
  ): Promise<ApiCrashGroupPlaces> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/places";
    const payload = {
      params: stringifyParams(serializeCrashGroupPlacesCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiCrashGroupPlaces>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiCrashGroupPlaces",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/places",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiCrashGroupPlaces(result);
        return resultOrResults as ApiCrashGroupPlaces;
      });
  }
  export interface CrashGroupTotalsParams {
    [key: string]: any;
    // The id of the crash group.
    crashGroupId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    //
    version: string;
  }

  // @ts-ignore
  function serializeCrashGroupTotalsParams(input: CrashGroupTotalsParams): any {
    const pathParams = {
      crash_group_id: input["crashGroupId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      version: input["version"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/overall
   * Available for UWP apps only.
   * Overall crashes and affected users count of the selected crash group with selected version. Available for UWP apps only.
   **/
  export function crashGroupTotals(params: CrashGroupTotalsParams, options?: ApiClientOptions): Promise<ApiCrashOverall> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/overall";
    const payload = {
      params: stringifyParams(serializeCrashGroupTotalsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiCrashOverall>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiCrashOverall",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups/{crash_group_id}/overall",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiCrashOverall(result);
        return resultOrResults as ApiCrashOverall;
      });
  }
  export interface CrashGroupsTotalsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeCrashGroupsTotalsParams(input: CrashGroupsTotalsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups
   *
   * Overall crashes and affected users count of the selected crash groups with selected versions.
   **/
  export function crashGroupsTotals(
    params: CrashGroupsTotalsParams,
    body: ApiCrashGroupContainer,
    options?: ApiClientOptions
  ): Promise<ApiCrashesOverall> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups";
    const payload = {
      params: stringifyParams(serializeCrashGroupsTotalsParams(params)),
      body: serializeApiCrashGroupContainer(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiCrashesOverall>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiCrashesOverall",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/analytics/crash_groups",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiCrashesOverall(result);
        return resultOrResults as ApiCrashesOverall;
      });
  }
  export interface DeleteQueueParams {
    [key: string]: any;
    // Storage account name.
    storageAccount: string;
    // Name of the queue.
    queueName: string;
    // Type of the queue.
    queueType: "analytics" | "handledErrorParser" | "handledErrorAggregation";
  }

  // @ts-ignore
  function serializeDeleteQueueParams(input: DeleteQueueParams): any {
    const pathParams = {};

    const queryParams = {
      storageAccount: input["storageAccount"],
      queueName: input["queueName"],
      queueType: input["queueType"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/management/analytics/queues
   *
   **/
  export function deleteQueue(params: DeleteQueueParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/management/analytics/queues";
    const payload = {
      params: stringifyParams(serializeDeleteQueueParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "DELETE /v0.1/management/analytics/queues", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DeviceCountsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
    //
    appBuild: string;
  }

  // @ts-ignore
  function serializeDeviceCountsParams(input: DeviceCountsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
      app_build: input["appBuild"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/active_device_counts
   *
   * Count of active devices by interval in the time range.
   **/
  export function deviceCounts(params: DeviceCountsParams, options?: ApiClientOptions): Promise<ApiActiveDeviceCounts> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/active_device_counts";
    const payload = {
      params: stringifyParams(serializeDeviceCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiActiveDeviceCounts>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiActiveDeviceCounts",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/active_device_counts",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiActiveDeviceCounts(result);
        return resultOrResults as ApiActiveDeviceCounts;
      });
  }
  export interface DistributionReleaseCountsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeDistributionReleaseCountsParams(input: DistributionReleaseCountsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/analytics/distribution/release_counts
   *
   * Count of total downloads for the provided distribution releases.
   **/
  export function distributionReleaseCounts(
    params: DistributionReleaseCountsParams,
    body: ApiGetReleasesContainer,
    options?: ApiClientOptions
  ): Promise<ApiReleaseCounts> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/distribution/release_counts";
    const payload = {
      params: stringifyParams(serializeDistributionReleaseCountsParams(params)),
      body: serializeApiGetReleasesContainer(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseCounts>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseCounts",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/analytics/distribution/release_counts",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseCounts(result);
        return resultOrResults as ApiReleaseCounts;
      });
  }
  export interface DistributionReleaseDailySessionsParams {
    [key: string]: any;
    // The id of the distribution group.
    distributionId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
  }

  // @ts-ignore
  function serializeDistributionReleaseDailySessionsParams(input: DistributionReleaseDailySessionsParams): any {
    const pathParams = {
      distribution_id: input["distributionId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/analytics/distribution/{distribution_id}/release_daily_session
   *
   * Count of sessions for the provided distribution releases per day.
   **/
  export function distributionReleaseDailySessions(
    params: DistributionReleaseDailySessionsParams,
    body: ApiFilterVersionsContainer,
    options?: ApiClientOptions
  ): Promise<ApiReleaseDailySessions> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/distribution/{distribution_id}/release_daily_session";
    const payload = {
      params: stringifyParams(serializeDistributionReleaseDailySessionsParams(params)),
      body: serializeApiFilterVersionsContainer(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiReleaseDailySessions>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiReleaseDailySessions",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/analytics/distribution/{distribution_id}/release_daily_session",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiReleaseDailySessions(result);
        return resultOrResults as ApiReleaseDailySessions;
      });
  }
  export interface DistributionReleaseDeleteParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeDistributionReleaseDeleteParams(input: DistributionReleaseDeleteParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/analytics/distribution
   *
   * Delete analytics for the provided distribution release(s).
   **/
  export function distributionReleaseDelete(
    params: DistributionReleaseDeleteParams,
    body: ApiDeleteReleasesContainer,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/distribution";
    const payload = {
      params: stringifyParams(serializeDistributionReleaseDeleteParams(params)),
      body: serializeApiDeleteReleasesContainer(body),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "DELETE /v0.1/apps/{owner_name}/{app_name}/analytics/distribution", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DistributionReleaseDownloadCountsParams {
    [key: string]: any;
    // The id of the distribution group.
    distributionId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
  }

  // @ts-ignore
  function serializeDistributionReleaseDownloadCountsParams(input: DistributionReleaseDownloadCountsParams): any {
    const pathParams = {
      distribution_id: input["distributionId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/analytics/distribution/{distribution_id}/release_download_counts
   *
   * Count of total downloads for the provided distribution releases per day.
   **/
  export function distributionReleaseDownloadCounts(
    params: DistributionReleaseDownloadCountsParams,
    body: ApiFilterReleasesContainer,
    options?: ApiClientOptions
  ): Promise<ApiDateTimeDownloadReleaseCounts> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/distribution/{distribution_id}/release_download_counts";
    const payload = {
      params: stringifyParams(serializeDistributionReleaseDownloadCountsParams(params)),
      body: serializeApiFilterReleasesContainer(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiDateTimeDownloadReleaseCounts>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiDateTimeDownloadReleaseCounts",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/analytics/distribution/{distribution_id}/release_download_counts",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiDateTimeDownloadReleaseCounts(result);
        return resultOrResults as ApiDateTimeDownloadReleaseCounts;
      });
  }
  export interface DistributionReleaseNotifyParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeDistributionReleaseNotifyParams(input: DistributionReleaseNotifyParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/analytics/distribution
   *
   * Notify download(s) for the provided distribution release(s).
   **/
  export function distributionReleaseNotify(
    params: DistributionReleaseNotifyParams,
    body: ApiNotifyReleasesContainer,
    options?: ApiClientOptions
  ): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/distribution";
    const payload = {
      params: stringifyParams(serializeDistributionReleaseNotifyParams(params)),
      body: serializeApiNotifyReleasesContainer(body),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/apps/{owner_name}/{app_name}/analytics/distribution", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface DistributionReleaseSessionsDurationParams {
    [key: string]: any;
    // The id of the distribution group.
    distributionId: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
  }

  // @ts-ignore
  function serializeDistributionReleaseSessionsDurationParams(input: DistributionReleaseSessionsDurationParams): any {
    const pathParams = {
      distribution_id: input["distributionId"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/analytics/distribution/{distribution_id}/release_session_duration
   *
   * Session duration for a specific distribution grouped by the version in the time range.
   **/
  export function distributionReleaseSessionsDuration(
    params: DistributionReleaseSessionsDurationParams,
    body: ApiFilterVersionsContainer,
    options?: ApiClientOptions
  ): Promise<ApiSessionDurationsDistribution> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/distribution/{distribution_id}/release_session_duration";
    const payload = {
      params: stringifyParams(serializeDistributionReleaseSessionsDurationParams(params)),
      body: serializeApiFilterVersionsContainer(body),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiSessionDurationsDistribution>("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiSessionDurationsDistribution",
            result?.body,
            "POST /v0.1/apps/{owner_name}/{app_name}/analytics/distribution/{distribution_id}/release_session_duration",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiSessionDurationsDistribution(result);
        return resultOrResults as ApiSessionDurationsDistribution;
      });
  }
  export interface EventCountParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeEventCountParams(input: EventCountParams): any {
    const pathParams = {
      event_name: input["eventName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/event_count
   *
   * Count of events by interval in the time range.
   **/
  export function eventCount(params: EventCountParams, options?: ApiClientOptions): Promise<ApiEventCount> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/event_count";
    const payload = {
      params: stringifyParams(serializeEventCountParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEventCount>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiEventCount",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/event_count",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEventCount(result);
        return resultOrResults as ApiEventCount;
      });
  }
  export interface EventDeviceCountParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeEventDeviceCountParams(input: EventDeviceCountParams): any {
    const pathParams = {
      event_name: input["eventName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/device_count
   *
   * Count of devices for an event by interval in the time range.
   **/
  export function eventDeviceCount(params: EventDeviceCountParams, options?: ApiClientOptions): Promise<ApiEventDeviceCount> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/device_count";
    const payload = {
      params: stringifyParams(serializeEventDeviceCountParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEventDeviceCount>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiEventDeviceCount",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/device_count",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEventDeviceCount(result);
        return resultOrResults as ApiEventDeviceCount;
      });
  }
  export interface EventPerDeviceCountParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeEventPerDeviceCountParams(input: EventPerDeviceCountParams): any {
    const pathParams = {
      event_name: input["eventName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/count_per_device
   *
   * Count of events per device by interval in the time range.
   **/
  export function eventPerDeviceCount(params: EventPerDeviceCountParams, options?: ApiClientOptions): Promise<ApiEventCountPerDevice> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/count_per_device";
    const payload = {
      params: stringifyParams(serializeEventPerDeviceCountParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEventCountPerDevice>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiEventCountPerDevice",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/count_per_device",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEventCountPerDevice(result);
        return resultOrResults as ApiEventCountPerDevice;
      });
  }
  export interface EventPerSessionCountParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeEventPerSessionCountParams(input: EventPerSessionCountParams): any {
    const pathParams = {
      event_name: input["eventName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/count_per_session
   *
   * Count of events per session by interval in the time range.
   **/
  export function eventPerSessionCount(
    params: EventPerSessionCountParams,
    options?: ApiClientOptions
  ): Promise<ApiEventCountPerSession> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/count_per_session";
    const payload = {
      params: stringifyParams(serializeEventPerSessionCountParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEventCountPerSession>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiEventCountPerSession",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/count_per_session",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEventCountPerSession(result);
        return resultOrResults as ApiEventCountPerSession;
      });
  }
  export interface EventPropertiesParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeEventPropertiesParams(input: EventPropertiesParams): any {
    const pathParams = {
      event_name: input["eventName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/properties
   *
   * Event properties.
   **/
  export function eventProperties(params: EventPropertiesParams, options?: ApiClientOptions): Promise<ApiEventProperties> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/properties";
    const payload = {
      params: stringifyParams(serializeEventPropertiesParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEventProperties>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiEventProperties",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/properties",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEventProperties(result);
        return resultOrResults as ApiEventProperties;
      });
  }
  export interface EventPropertyCountsParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The id of the event property.
    eventPropertyName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
    // The number of property values to return. Set to 0 in order to fetch all results available.
    top?: number;
  }

  // @ts-ignore
  function serializeEventPropertyCountsParams(input: EventPropertyCountsParams): any {
    const pathParams = {
      event_name: input["eventName"],
      event_property_name: input["eventPropertyName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
      $top: input["top"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/properties/{event_property_name}/counts
   *
   * Event properties value counts during the time range in descending order.
   **/
  export function eventPropertyCounts(params: EventPropertyCountsParams, options?: ApiClientOptions): Promise<ApiEventPropertyValues> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/properties/{event_property_name}/counts";
    const payload = {
      params: stringifyParams(serializeEventPropertyCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEventPropertyValues>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiEventPropertyValues",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}/properties/{event_property_name}/counts",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEventPropertyValues(result);
        return resultOrResults as ApiEventPropertyValues;
      });
  }
  export interface EventsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
    // To select the specific events.
    eventName?: string[];
    // The maximum number of results to return. Set to 0 in order to fetch all results available.
    top?: number;
    // The offset (starting at 0) of the first result to return. This parameter along with limit is used to perform pagination.
    skip?: number;
    // Controls whether or not to include a count of all the items across all pages.
    inlinecount?: "allpages" | "none";
    // Controls the sorting order and sorting based on which column.
    orderby?: string;
  }

  // @ts-ignore
  function serializeEventsParams(input: EventsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
      event_name: input["eventName"],
      $top: input["top"],
      $skip: input["skip"],
      $inlinecount: input["inlinecount"],
      $orderby: input["orderby"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/events
   *
   * Count of active events in the time range ordered by event.
   **/
  export function events(params: EventsParams, options?: ApiClientOptions): Promise<ApiEvents> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/events";
    const payload = {
      params: stringifyParams(serializeEventsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiEvents>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiEvents", result?.body, "GET /v0.1/apps/{owner_name}/{app_name}/analytics/events", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiEvents(result);
        return resultOrResults as ApiEvents;
      });
  }
  export interface EventsDeleteParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeEventsDeleteParams(input: EventsDeleteParams): any {
    const pathParams = {
      event_name: input["eventName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}
   *
   * Delete the set of Events with the specified event names.
   **/
  export function eventsDelete(params: EventsDeleteParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}";
    const payload = {
      params: stringifyParams(serializeEventsDeleteParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "DELETE /v0.1/apps/{owner_name}/{app_name}/analytics/events/{event_name}", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface EventsDeleteLogsParams {
    [key: string]: any;
    // The id of the event.
    eventName: string;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializeEventsDeleteLogsParams(input: EventsDeleteLogsParams): any {
    const pathParams = {
      event_name: input["eventName"],
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * DELETE /v0.1/apps/{owner_name}/{app_name}/analytics/event_logs/{event_name}
   *
   * Delete the set of Events with the specified event names.
   **/
  export function eventsDeleteLogs(params: EventsDeleteLogsParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/event_logs/{event_name}";
    const payload = {
      params: stringifyParams(serializeEventsDeleteLogsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("DELETE", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "",
            result?.body,
            "DELETE /v0.1/apps/{owner_name}/{app_name}/analytics/event_logs/{event_name}",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface FlushPipelineParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeFlushPipelineParams(input: FlushPipelineParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/management/analytics/pipeline/flush
   *
   **/
  export function flushPipeline(params: FlushPipelineParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/management/analytics/pipeline/flush";
    const payload = {
      params: stringifyParams(serializeFlushPipelineParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/management/analytics/pipeline/flush", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface Get2DHllCounterParams {
    [key: string]: any;
    // Counter Id.
    counterId: string;
  }

  // @ts-ignore
  function serializeGet2DHllCounterParams(input: Get2DHllCounterParams): any {
    const pathParams = {
      counterId: input["counterId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/analytics/counters/2dhlls/{counterId}
   *
   **/
  export function get2DHllCounter(params: Get2DHllCounterParams, options?: ApiClientOptions): Promise<string> {
    const path = "/v0.1/management/analytics/counters/2dhlls/{counterId}";
    const payload = {
      params: stringifyParams(serializeGet2DHllCounterParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<string>("GET", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "GET /v0.1/management/analytics/counters/2dhlls/{counterId}", false);
      }
      return result.body;
    });
  }
  export interface Get2DIntCounterParams {
    [key: string]: any;
    // Counter Id.
    counterId: string;
  }

  // @ts-ignore
  function serializeGet2DIntCounterParams(input: Get2DIntCounterParams): any {
    const pathParams = {
      counterId: input["counterId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/analytics/counters/2dints/{counterId}
   *
   **/
  export function get2DIntCounter(params: Get2DIntCounterParams, options?: ApiClientOptions): Promise<string> {
    const path = "/v0.1/management/analytics/counters/2dints/{counterId}";
    const payload = {
      params: stringifyParams(serializeGet2DIntCounterParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<string>("GET", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "GET /v0.1/management/analytics/counters/2dints/{counterId}", false);
      }
      return result.body;
    });
  }
  export interface Get3DHllCounterParams {
    [key: string]: any;
    // Counter Id.
    counterId: string;
  }

  // @ts-ignore
  function serializeGet3DHllCounterParams(input: Get3DHllCounterParams): any {
    const pathParams = {
      counterId: input["counterId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/analytics/counters/3dhlls/{counterId}
   *
   **/
  export function get3DHllCounter(params: Get3DHllCounterParams, options?: ApiClientOptions): Promise<string> {
    const path = "/v0.1/management/analytics/counters/3dhlls/{counterId}";
    const payload = {
      params: stringifyParams(serializeGet3DHllCounterParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<string>("GET", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "GET /v0.1/management/analytics/counters/3dhlls/{counterId}", false);
      }
      return result.body;
    });
  }
  export interface Get3DIntCounterParams {
    [key: string]: any;
    // Counter Id.
    counterId: string;
  }

  // @ts-ignore
  function serializeGet3DIntCounterParams(input: Get3DIntCounterParams): any {
    const pathParams = {
      counterId: input["counterId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/analytics/counters/3dints/{counterId}
   *
   **/
  export function get3DIntCounter(params: Get3DIntCounterParams, options?: ApiClientOptions): Promise<string> {
    const path = "/v0.1/management/analytics/counters/3dints/{counterId}";
    const payload = {
      params: stringifyParams(serializeGet3DIntCounterParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<string>("GET", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "GET /v0.1/management/analytics/counters/3dints/{counterId}", false);
      }
      return result.body;
    });
  }
  export interface GetHllCounterParams {
    [key: string]: any;
    // Counter Id.
    counterId: string;
  }

  // @ts-ignore
  function serializeGetHllCounterParams(input: GetHllCounterParams): any {
    const pathParams = {
      counterId: input["counterId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/analytics/counters/hlls/{counterId}
   *
   **/
  export function getHllCounter(params: GetHllCounterParams, options?: ApiClientOptions): Promise<number> {
    const path = "/v0.1/management/analytics/counters/hlls/{counterId}";
    const payload = {
      params: stringifyParams(serializeGetHllCounterParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<number>("GET", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("number", result?.body, "GET /v0.1/management/analytics/counters/hlls/{counterId}", false);
      }
      return result.body;
    });
  }
  export interface GetIngestionPipelineSizeParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeGetIngestionPipelineSizeParams(input: GetIngestionPipelineSizeParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/analytics/ingestion
   *
   **/
  export function getIngestionPipelineSize(params: GetIngestionPipelineSizeParams, options?: ApiClientOptions): Promise<string> {
    const path = "/v0.1/management/analytics/ingestion";
    const payload = {
      params: stringifyParams(serializeGetIngestionPipelineSizeParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<string>("GET", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "GET /v0.1/management/analytics/ingestion", false);
      }
      return result.body;
    });
  }
  export interface GetPipelineSizeParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeGetPipelineSizeParams(input: GetPipelineSizeParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/analytics/pipeline
   *
   **/
  export function getPipelineSize(params: GetPipelineSizeParams, options?: ApiClientOptions): Promise<string> {
    const path = "/v0.1/management/analytics/pipeline";
    const payload = {
      params: stringifyParams(serializeGetPipelineSizeParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<string>("GET", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "GET /v0.1/management/analytics/pipeline", false);
      }
      return result.body;
    });
  }
  export interface GetSetCounterParams {
    [key: string]: any;
    // Counter Id.
    counterId: string;
  }

  // @ts-ignore
  function serializeGetSetCounterParams(input: GetSetCounterParams): any {
    const pathParams = {
      counterId: input["counterId"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/analytics/counters/set/{counterId}
   *
   **/
  export function getSetCounter(params: GetSetCounterParams, options?: ApiClientOptions): Promise<string> {
    const path = "/v0.1/management/analytics/counters/set/{counterId}";
    const payload = {
      params: stringifyParams(serializeGetSetCounterParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<string>("GET", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "GET /v0.1/management/analytics/counters/set/{counterId}", false);
      }
      return result.body;
    });
  }
  export interface GetShardingConfigParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeGetShardingConfigParams(input: GetShardingConfigParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/analytics/shardingConfig
   *
   **/
  export function getShardingConfig(params: GetShardingConfigParams, options?: ApiClientOptions): Promise<string> {
    const path = "/v0.1/management/analytics/shardingConfig";
    const payload = {
      params: stringifyParams(serializeGetShardingConfigParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<string>("GET", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "GET /v0.1/management/analytics/shardingConfig", false);
      }
      return result.body;
    });
  }
  export interface GetStorageAccountParams {
    [key: string]: any;
    // The id of the application.
    appId?: string;
  }

  // @ts-ignore
  function serializeGetStorageAccountParams(input: GetStorageAccountParams): any {
    const pathParams = {};

    const queryParams = {
      appId: input["appId"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/analytics/storageAccounts
   *
   **/
  export function getStorageAccount(params: GetStorageAccountParams, options?: ApiClientOptions): Promise<string> {
    const path = "/v0.1/management/analytics/storageAccounts";
    const payload = {
      params: stringifyParams(serializeGetStorageAccountParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<string>("GET", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "GET /v0.1/management/analytics/storageAccounts", false);
      }
      return result.body;
    });
  }
  export interface LanguageCountsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    // The maximum number of results to return. Set to 0 in order to fetch all results available.
    top?: number;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeLanguageCountsParams(input: LanguageCountsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      $top: input["top"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/languages
   *
   * Languages in the time range.
   **/
  export function languageCounts(params: LanguageCountsParams, options?: ApiClientOptions): Promise<ApiLanguages> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/languages";
    const payload = {
      params: stringifyParams(serializeLanguageCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiLanguages>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiLanguages", result?.body, "GET /v0.1/apps/{owner_name}/{app_name}/analytics/languages", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiLanguages(result);
        return resultOrResults as ApiLanguages;
      });
  }
  export interface ListAppsParams {
    [key: string]: any;
    // Storage account name.
    storageAccount?: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
  }

  // @ts-ignore
  function serializeListAppsParams(input: ListAppsParams): any {
    const pathParams = {};

    const queryParams = {
      storageAccount: input["storageAccount"],
      start: input["start"],
      end: input["end"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/analytics/apps
   *
   **/
  export function listApps(params: ListAppsParams, options?: ApiClientOptions): Promise<string[]> {
    const path = "/v0.1/management/analytics/apps";
    const payload = {
      params: stringifyParams(serializeListAppsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<string[]>("GET", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "GET /v0.1/management/analytics/apps");
      }
      return result.body;
    });
  }
  export interface ListContainersParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeListContainersParams(input: ListContainersParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/management/analytics/containers
   *
   **/
  export function listContainers(params: ListContainersParams, options?: ApiClientOptions): Promise<string[]> {
    const path = "/v0.1/management/analytics/containers";
    const payload = {
      params: stringifyParams(serializeListContainersParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<string[]>("GET", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "GET /v0.1/management/analytics/containers");
      }
      return result.body;
    });
  }
  export interface MigrateParams {
    [key: string]: any;
    // Name.
    name: string;
  }

  // @ts-ignore
  function serializeMigrateParams(input: MigrateParams): any {
    const pathParams = {
      name: input["name"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/management/analytics/migration/{name}
   *
   **/
  export function migrate(params: MigrateParams, body: ApiMigration, options?: ApiClientOptions): Promise<string> {
    const path = "/v0.1/management/analytics/migration/{name}";
    const payload = {
      params: stringifyParams(serializeMigrateParams(params)),
      body: serializeApiMigration(body),
    };
    Object.assign(payload, options);

    return getApiMethod<string>("POST", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "POST /v0.1/management/analytics/migration/{name}", false);
      }
      return result.body;
    });
  }
  export interface ModelCountsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    // The maximum number of results to return. Set to 0 in order to fetch all results available.
    top?: number;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeModelCountsParams(input: ModelCountsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      $top: input["top"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/models
   *
   * Models in the time range.
   **/
  export function modelCounts(params: ModelCountsParams, options?: ApiClientOptions): Promise<ApiAnalyticsModels> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/models";
    const payload = {
      params: stringifyParams(serializeModelCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiAnalyticsModels>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiAnalyticsModels", result?.body, "GET /v0.1/apps/{owner_name}/{app_name}/analytics/models", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiAnalyticsModels(result);
        return resultOrResults as ApiAnalyticsModels;
      });
  }
  export interface OperatingSystemCountsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    // The maximum number of results to return. Set to 0 in order to fetch all results available.
    top?: number;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeOperatingSystemCountsParams(input: OperatingSystemCountsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      $top: input["top"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/oses
   *
   * OSes in the time range.
   **/
  export function operatingSystemCounts(params: OperatingSystemCountsParams, options?: ApiClientOptions): Promise<ApiOSes> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/oses";
    const payload = {
      params: stringifyParams(serializeOperatingSystemCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiOSes>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiOSes", result?.body, "GET /v0.1/apps/{owner_name}/{app_name}/analytics/oses", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiOSes(result);
        return resultOrResults as ApiOSes;
      });
  }
  export interface PerDeviceCountsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    // Size of interval in ISO 8601 duration format. (PnYnMnDTnHnMnS|PnW|P&lt;date&gt;T&lt;time&gt;). The valid durations are 1 day (P1D), 1 week (P1W), and 28 days (P28D).
    interval: string;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializePerDeviceCountsParams(input: PerDeviceCountsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      interval: input["interval"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/sessions_per_device
   *
   * Count of sessions per device in the time range.
   **/
  export function perDeviceCounts(params: PerDeviceCountsParams, options?: ApiClientOptions): Promise<ApiSessionsPerDevice> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/sessions_per_device";
    const payload = {
      params: stringifyParams(serializePerDeviceCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiSessionsPerDevice>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiSessionsPerDevice",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/sessions_per_device",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiSessionsPerDevice(result);
        return resultOrResults as ApiSessionsPerDevice;
      });
  }
  export interface PlaceCountsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    // The maximum number of results to return. Set to 0 in order to fetch all results available.
    top?: number;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializePlaceCountsParams(input: PlaceCountsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      $top: input["top"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/places
   *
   * Places in the time range.
   **/
  export function placeCounts(params: PlaceCountsParams, options?: ApiClientOptions): Promise<ApiPlaces> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/places";
    const payload = {
      params: stringifyParams(serializePlaceCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiPlaces>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiPlaces", result?.body, "GET /v0.1/apps/{owner_name}/{app_name}/analytics/places", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiPlaces(result);
        return resultOrResults as ApiPlaces;
      });
  }
  export interface PrepareOverviewParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
  }

  // @ts-ignore
  function serializePrepareOverviewParams(input: PrepareOverviewParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/apps/{owner_name}/{app_name}/analytics/prepare_overview
   *
   * Warm up analytics overview and events page.
   **/
  export function prepareOverview(params: PrepareOverviewParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/prepare_overview";
    const payload = {
      params: stringifyParams(serializePrepareOverviewParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/apps/{owner_name}/{app_name}/analytics/prepare_overview", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface SessionCountsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    // Size of interval in ISO 8601 duration format. (PnYnMnDTnHnMnS|PnW|P&lt;date&gt;T&lt;time&gt;). The valid durations are 1 day (P1D), 1 week (P1W), and 28 days (P28D).
    interval: string;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeSessionCountsParams(input: SessionCountsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      interval: input["interval"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/session_counts
   *
   * Count of sessions in the time range.
   **/
  export function sessionCounts(params: SessionCountsParams, options?: ApiClientOptions): Promise<ApiSessionCounts> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/session_counts";
    const payload = {
      params: stringifyParams(serializeSessionCountsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiSessionCounts>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiSessionCounts",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/session_counts",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiSessionCounts(result);
        return resultOrResults as ApiSessionCounts;
      });
  }
  export interface SessionDurationsDistributionParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeSessionDurationsDistributionParams(input: SessionDurationsDistributionParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/session_durations_distribution
   *
   * Gets session duration.
   **/
  export function sessionDurationsDistribution(
    params: SessionDurationsDistributionParams,
    options?: ApiClientOptions
  ): Promise<ApiSessionDurationsDistribution> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/session_durations_distribution";
    const payload = {
      params: stringifyParams(serializeSessionDurationsDistributionParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiSessionDurationsDistribution>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult(
            "ApiSessionDurationsDistribution",
            result?.body,
            "GET /v0.1/apps/{owner_name}/{app_name}/analytics/session_durations_distribution",
            false
          );
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiSessionDurationsDistribution(result);
        return resultOrResults as ApiSessionDurationsDistribution;
      });
  }
  export interface SnapshotIngestionPartitionParams {
    [key: string]: any;
    //
    partitionIndex?: number;
  }

  // @ts-ignore
  function serializeSnapshotIngestionPartitionParams(input: SnapshotIngestionPartitionParams): any {
    const pathParams = {};

    const queryParams = {
      partitionIndex: input["partitionIndex"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/management/analytics/ingestion/snapshotPartition
   *
   **/
  export function snapshotIngestionPartition(params: SnapshotIngestionPartitionParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/management/analytics/ingestion/snapshotPartition";
    const payload = {
      params: stringifyParams(serializeSnapshotIngestionPartitionParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/management/analytics/ingestion/snapshotPartition", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface StartIngestionParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeStartIngestionParams(input: StartIngestionParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/management/analytics/ingestion/start
   *
   **/
  export function startIngestion(params: StartIngestionParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/management/analytics/ingestion/start";
    const payload = {
      params: stringifyParams(serializeStartIngestionParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/management/analytics/ingestion/start", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface StartPipelineParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeStartPipelineParams(input: StartPipelineParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/management/analytics/pipeline/start
   *
   **/
  export function startPipeline(params: StartPipelineParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/management/analytics/pipeline/start";
    const payload = {
      params: stringifyParams(serializeStartPipelineParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/management/analytics/pipeline/start", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface StopIngestionParams {
    [key: string]: any;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
  }

  // @ts-ignore
  function serializeStopIngestionParams(input: StopIngestionParams): any {
    const pathParams = {};

    const queryParams = {
      end: input["end"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/management/analytics/ingestion/stop
   *
   **/
  export function stopIngestion(params: StopIngestionParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/management/analytics/ingestion/stop";
    const payload = {
      params: stringifyParams(serializeStopIngestionParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/management/analytics/ingestion/stop", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface StopPipelineParams {
    [key: string]: any;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
  }

  // @ts-ignore
  function serializeStopPipelineParams(input: StopPipelineParams): any {
    const pathParams = {};

    const queryParams = {
      end: input["end"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/management/analytics/pipeline/stop
   *
   **/
  export function stopPipeline(params: StopPipelineParams, options?: ApiClientOptions): Promise<any> {
    const path = "/v0.1/management/analytics/pipeline/stop";
    const payload = {
      params: stringifyParams(serializeStopPipelineParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod("POST", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("", result?.body, "POST /v0.1/management/analytics/pipeline/stop", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = result;
        return resultOrResults as any;
      });
  }
  export interface TestShardingConfigParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeTestShardingConfigParams(input: TestShardingConfigParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * POST /v0.1/management/analytics/shardingConfig/test
   *
   **/
  export function testShardingConfig(params: TestShardingConfigParams, body?: string, options?: ApiClientOptions): Promise<string> {
    const path = "/v0.1/management/analytics/shardingConfig/test";
    const payload = {
      params: stringifyParams(serializeTestShardingConfigParams(params)),
      body: body,
    };
    Object.assign(payload, options);

    return getApiMethod<string>("POST", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "POST /v0.1/management/analytics/shardingConfig/test", false);
      }
      return result.body;
    });
  }
  export interface UpdateShardingConfigParams {
    [key: string]: any;
  }

  // @ts-ignore
  function serializeUpdateShardingConfigParams(input: UpdateShardingConfigParams): any {
    const pathParams = {};

    const queryParams = {};
    return { ...pathParams, ...queryParams };
  }

  /**
   * PUT /v0.1/management/analytics/shardingConfig
   *
   **/
  export function updateShardingConfig(
    params: UpdateShardingConfigParams,
    body?: string,
    options?: ApiClientOptions
  ): Promise<string> {
    const path = "/v0.1/management/analytics/shardingConfig";
    const payload = {
      params: stringifyParams(serializeUpdateShardingConfigParams(params)),
      body: body,
    };
    Object.assign(payload, options);

    return getApiMethod<string>("PUT", path, payload, options).then((result) => {
      if (result.statusCode >= 200 && result.statusCode <= 299) {
        validateSwaggerResult("string", result?.body, "PUT /v0.1/management/analytics/shardingConfig", false);
      }
      return result.body;
    });
  }
  export interface VersionsParams {
    [key: string]: any;
    // The name of the owner
    ownerName: string;
    // The name of the application
    appName: string;
    // Start date time in data in ISO 8601 date time format.
    start: Date;
    // Last date time in data in ISO 8601 date time format.
    end?: Date;
    // The maximum number of results to return. Set to 0 in order to fetch all results available.
    top?: number;
    //
    versions?: string[];
  }

  // @ts-ignore
  function serializeVersionsParams(input: VersionsParams): any {
    const pathParams = {
      owner_name: input["ownerName"],
      app_name: input["appName"],
    };

    const queryParams = {
      start: input["start"],
      end: input["end"],
      $top: input["top"],
      versions: input["versions"],
    };
    return { ...pathParams, ...queryParams };
  }

  /**
   * GET /v0.1/apps/{owner_name}/{app_name}/analytics/versions
   *
   * Count of active versions in the time range ordered by version.
   **/
  export function versions(params: VersionsParams, options?: ApiClientOptions): Promise<ApiVersions> {
    const path = "/v0.1/apps/{owner_name}/{app_name}/analytics/versions";
    const payload = {
      params: stringifyParams(serializeVersionsParams(params)),
    };
    Object.assign(payload, options);

    return getApiMethod<ApiVersions>("GET", path, payload, options)
      .then((result) => {
        if (result.statusCode >= 200 && result.statusCode <= 299) {
          validateSwaggerResult("ApiVersions", result?.body, "GET /v0.1/apps/{owner_name}/{app_name}/analytics/versions", false);
        }
        return result.body;
      })
      .then((result) => {
        const resultOrResults = deserializeApiVersions(result);
        return resultOrResults as ApiVersions;
      });
  }
}
