import { get } from "lodash";
import { apiGateway } from "@root/lib/http";
import { Store } from "../../lib";
import { BillingTier, SerializedBillingTier, DeserializedBillingTier, BillingService } from "../models/billing-tier";
import { API } from "../constants";
import { translateTestPlan, translateBuildPlan } from "@root/data/management/utils/billing-plan-translator";

export class BillingTierStore extends Store<DeserializedBillingTier, SerializedBillingTier, BillingTier> {
  protected ModelClass = BillingTier;

  protected getCollection(query?: any): Promise<SerializedBillingTier[]> {
    return apiGateway.get<SerializedBillingTier[]>(API.ALL_BILLING_PLANS);
  }

  protected deserialize(serialized: SerializedBillingTier): DeserializedBillingTier | undefined {
    const service = get(serialized, "document.service");
    const plan = serialized.document;
    if (service === BillingService.Test) {
      return translateTestPlan(plan) as DeserializedBillingTier;
    } else if (service === BillingService.Build) {
      return translateBuildPlan(plan) as DeserializedBillingTier;
    }
  }

  protected getModelId(model: BillingTier): string {
    return model.id;
  }

  protected generateIdFromResponse(resource: SerializedBillingTier, query?: any) {
    return get(resource, "document.id");
  }
}

export const billingTierStore = new BillingTierStore();
