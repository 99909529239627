export interface IDistributionGroup {
  id: string;
  name: string;
}

export const ProfileType = {
  AdHoc: "adhoc",
  Enterprise: "enterprise",
  Other: "other",
};

export interface ISerializedBuildInfo {
  branch: string;
  commit_hash: string;
  commit_message: string;
}

export interface IDeserializedBuildInfo {
  branch: string;
  commitHash: string;
  commitMessage: string;
}

export type ProfileType = keyof typeof ProfileType;
